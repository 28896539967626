import { gql } from '@apollo/client';

export const DELETE_BANNER_IMAGES_ADMIN = gql`
  mutation DeleteBannerImageAndVideoByAdmin($input: deleteBannerImageAndVideoByAdmin) {
    deleteBannerImageAndVideoByAdmin(input: $input) {
      status
      message
      errorCode
    }
  }
`;
