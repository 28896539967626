import { gql } from '@apollo/client';

export const GET_BUSINESS_DETAILS = gql`
  query GetBusinessDetail {
    getBusinessDetail {
      status
      message
      errorCode
      businessDetail {
        _id
        firstname
        lastname
        dob
        securityNo
        address
        city
        state_id {
          _id
          name
        }
        country_id {
          _id
          name
        }
        zipcode
        phonNo
        startupDate
        ownership
        accountNo
        routingNo
        ownershipType
        stateSaleTax
        fedralTax
        productSold
        merchantCategoryClassification
        monthInBusiness
        yearInBusiness
        createdAt
        business_data {
          isActive
          businessName
          address
          apartmentNo
          city
          state_id {
            _id
            name
          }
          country_id {
            _id
            name
          }
          zipcode
          website
          email
          category_id {
            _id
            name
          }
          subCategory_id {
            _id
            name
            refId {
              _id
              name
            }
          }
          socialUrl
          phoneno
          businessLogo
          bannerImages
          timings {
            status
            openingTime
            closingTime
            isOpenDay
          }
          businessStatus
          latitude
          longitude
          logoUrl
          businessDescription
        }
        email
        termsAndConditationStatus
        isActive
        isVoid
        userType
      }
    }
  }
`;
