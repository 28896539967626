import { gql } from '@apollo/client';

export const SUPER_ADMIN_SIGN_IN = gql`
  mutation SignInBySuperAdmin($input: SiginIn!) {
    signInBySuperAdmin(input: $input) {
      status
      message
      user {
        _id
        email
        password
      }
      jwttoken
      errorCode
    }
  }
`;
