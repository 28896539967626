import { gql } from '@apollo/client';

export const GET_RETURNING_NON_RETURNING_USER = gql`
  query Data {
    newVsReturningUserChart {
      status
      message
      data {
        newUsers
        returningUsers
      }
      errorCode
    }
  }
`;
