import { gql } from '@apollo/client';

export const DELETE_DEAL_IMAGE = gql`
  mutation DeleteDealImage($input: deleteDealImage) {
    deleteDealImage(input: $input) {
      status
      message
      errorCode
    }
  }
`;
