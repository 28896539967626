import { gql } from '@apollo/client';

export const GET_USER_DETAILS = gql`
  query GetUsersList($id: ID) {
    getUsersList(_id: $id) {
      status
      message
      user {
        _id
        email
        firstname
        lastname
        gender
        phoneno
        userType
        dob
        isActive
        termsAndConditationStatus
        profilepicture
        password
        createdBy
        createdAt
        updatedAt
        isSocial
        googleRefID
        facebookRefID
        appleRefID
        trackerType
        deviceType
        lifeTimeEarnings
        currentCentz
        timezone
        defaultAddress {
          _id
          user_id
          addressLine1
          addressLine2
          city
          state_id {
            _id
            name
            value
          }
          country_id {
            _id
            name
          }
          zipcode
        }
      }
      total
      limit
      offset
      errorCode
    }
  }
`;
