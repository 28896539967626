import { gql } from '@apollo/client';

export const UPDATE_DEAL_STATUS = gql`
  query UpdateDealStatus($updateDealStatusId: ID) {
    updateDealStatus(id: $updateDealStatusId) {
      status
      message
      data {
        _id
        isActive
      }
      errorCode
    }
  }
`;
