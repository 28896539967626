import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Image, Modal, FormCheck } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { DateRangePicker, createStaticRanges, defaultStaticRanges } from 'react-date-range';
import Images from '../../../constants/images';
import TextInput from '../../../components/common/text-input/TextInput';
import AppButton from '../../../components/common/button/Button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './styles.css';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import { GET_TX_HISTORY } from '../../../graphql/schema/query/admin/transactions/GET_TX_HISTORY';
import { getEndDateEOD, getUTCTimeDate } from '../../../services/dateUtils';
import { downloadCSV, downloadPDFFile } from '../../../services/downloadUtils';
import withMerchantAdmin from '../../../components/hoc/merchant/withMerchantAdmin';
import LoaderComponent from '../../../components/common/loader/LoaderComponent';
import { GET_TX_HISTORY_NOTIFICATION_UPDATE } from '../../../graphql/schema/query/admin/transactions/GET_TX_HISTORY_NOTIFICATION_UPDATE';
import RefundTransactionModal from '../../admin/marketplace/transactions/RefundTransactionModal';
import { REFUND_TRANSACTION } from '../../../graphql/schema/query/admin/transactions/REFUND_TRANSACTION';
import { toast } from 'react-toastify';

const ranges = createStaticRanges([
  ...defaultStaticRanges,
  {
    label: 'This Year',
    range: () => ({
      startDate: moment().startOf('year').toDate(),
      endDate: moment().toDate()
    })
  }
]);

const Export = ({ onExport }) => <div onClick={(e) => onExport(e.target.value)}>CSV</div>;

const MerchantTransactionPage = () => {
  const columns = [
    {
      name: 'Name',
      selector1: (row) => row.name,
      selector2: (row) => row.date_time,
      sortable: false,
      cell: (row) => (
        <div>
          <div className="name">{row.name}</div>
          <div className="date-time">{row.date_time}</div>
        </div>
      )
    },
    {
      name: 'Deal / Product',
      selector: (row) => row.deal,
      sortable: false,
      grow: 2
    },
    // {
    //   name: 'Method',
    //   selector: (row) => <div className="text-capitalize">{row.method}</div>,
    //   sortable: false
    // },
    {
      name: 'Transaction ID',
      selector: (row) => row?.paypalPayer_id || row.transaction_id,
      sortable: false
    },
    {
      name: 'Value',
      selector: (row) =>
        parseFloat(row?.value)?.toLocaleString?.('en-US', {
          style: 'currency',
          currency: 'USD'
        }) || '-',
      sortable: false
    },
    {
      name: 'Centz used',
      selector: (row) =>
        parseFloat(row?.centz_used)?.toLocaleString?.('en-US', {
          style: 'currency',
          currency: 'USD'
        }) || '-',
      sortable: false
    },
    {
      name: 'Payment Status',
      selector: (row) => (
        <span className="text-dark">{String(row.paymentStatus).toUpperCase()}</span>
      ),
      sortable: false
    },
    {
      name: 'Action',
      selector: (row) => row.action_full_fill,
      sortable: false,
      cell: (row) => {
        if (row?.issue_refunded) {
          return (
            <span className={row.action_full_fill === 'Fulfilled' ? 'text-success' : 'text-black'}>
              -
            </span>
          );
        }
        return (
          <div
            className={
              !row?.issue_refunded && row.action_full_fill === 'Fulfilled' ? '' : 'btn btn-cancel'
            }
            role="button"
            onClick={() => {
              if (row.action_full_fill === 'Fulfilled' && !row?.issue_refunded) {
                return true;
              }
              if ((row?.merchantFullfill == '0' || '-1') && !row?.issue_refunded) {
                doUpdateNotification({
                  variables: {
                    fullfill: 1,
                    tranId: row?.id
                  },
                  onCompleted: () => {
                    getTxs();
                  }
                });
              }
            }}>
            <span className={row.action_full_fill === 'Fulfilled' ? 'text-success' : 'text-black'}>
              {row?.issue_refunded ? 'NA' : row.action_full_fill}
            </span>
          </div>
        );
      }
    },
    {
      name: 'Issue refund',
      selector1: (row) => row.issue_refund,
      selector2: (row) => row.issue_refunded,
      sortable: false,
      center: true,
      cell: (row) => (
        <div>
          {row.issue_refund && !row.issue_refunded ? (
            <a
              href="javascript:void(0)"
              onClick={() => {
                console.log(row);
                if (row?.isItDealTx && row?.paymentStatus === 'refunded') {
                  toast.info('Deal is refunded already.');
                  return;
                }
                setRefundModalShow(row);
              }}>
              <img height="15px" width="18px" alt={row.name} src={row.issue_refund} />
            </a>
          ) : (
            <span className="refunded-text">{'Refunded'}</span>
          )}
        </div>
      )
    }
  ];

  const ref = useRef();
  const downloadCSRef = useRef();
  const downloadPDFRef = useRef();

  const [download, toggleDownload] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location?.search);

  const limit = query.get('limit');
  const givenPage = query.get('page');
  const startDate = query.get('startDate')
    ? moment(query.get('startDate').replace(' ', '+')).toDate()
    : null;
  const endDate = query.get('endDate')
    ? moment(query.get('endDate').replace(' ', '+')).toDate()
    : null;

  const purchase = query.get('purchase') === 'true';
  const refund = query.get('refund') === 'true';
  const search = query.get('search');

  //Track click outside
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (download && ref.current && !ref.current.contains(e.target)) {
        toggleDownload(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [download]);

  const [searchData, setSearchData] = useState(search || '');
  const [keyword, setKeyword] = useState(search || '');

  // paginations
  const [page, setPage] = useState(givenPage ? parseInt(givenPage, 10) : 1);
  const [perPage, setPerPage] = useState(limit ? parseInt(limit, 10) : 10);

  const [sorting, setSorting] = useState({
    date: null,
    time: null,
    transactionID: null,
    userName: null
  });
  const [filter, setFilter] = useState({
    startDate: startDate || null,
    endDate: endDate || null,
    purchase: purchase || null,
    refund: refund || null
  });

  // modal show hide
  const [refundModalShow, setRefundModalShow] = useState({});
  const [show, setShow] = useState(false);
  const modalClose = () => setShow(false);
  const modalShow = () => setShow(true);

  // api
  const [doGetTxs, { data: txs, loading }] = useLazyQuery(GET_TX_HISTORY, {
    fetchPolicy: 'network-only'
  });

  const [refundTx, { loading: refunding }] = useLazyQuery(REFUND_TRANSACTION, {
    fetchPolicy: 'network-only'
  });

  const [doDownloadCSV, { loading: csvDownloading }] = useLazyQuery(GET_TX_HISTORY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!downloadCSRef.current) {
        return;
      }
      downloadCSRef.current = false;

      downloadCSV(
        data?.transactionHistory?.data?.map((u) => {
          const dealName = u?.deal_id?.name;
          const productName = u?.transactionInfo?.[0]?.product_id?.title;
          return {
            id: u?._id,
            name: `${u?.user_id?.firstname || ''} ${u?.user_id?.lastname || ''}`,
            date_time: moment(u?.invoiceDate).format('MMM DD - HH:mm'),
            deal: `${dealName} ${productName ? ` / ${productName}` : ''}`,
            method: u?.paymentType,
            transaction_id: u?.invoiceNo,
            value: `${
              u?.total
                ? parseFloat(u?.total)?.toLocaleString?.('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  })
                : '-'
            }`,
            centz_used: `${
              u?.centzTotal
                ? parseFloat(u?.centzTotal)?.toLocaleString?.('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  })
                : '-'
            }`,
            action_full_fill: u?.merchantFullfill === 1 ? 'Fulfilled' : 'Fulfill',
            issue_refund: '/assets/images/refresh.png',
            issue_refunded: String(u?.transactionType).toLowerCase() === 'refund'
          };
        }),
        ['Name', 'Date', 'Deal / Product', 'Method', 'Transaction Id', 'Total', 'Total Centz'],
        ['name', 'date_time', 'deal', 'method', 'transaction_id', 'value', 'centz_used'],
        `txs-data-export-${moment().format('DD-MM-YYYY-HH-mm-ss')}.csv`
      );
    }
  });

  const [doDownloadPDF, { loading: pdfLoading }] = useLazyQuery(GET_TX_HISTORY, {
    fetchPolicy: 'network-only',

    onCompleted: (data) => {
      if (!downloadPDFRef.current) {
        return;
      }
      downloadPDFRef.current = false;
      if (data?.transactionHistory.pdfData?.length) {
        downloadPDFFile(
          data?.transactionHistory.pdfData,
          `txs-data-export-${moment().format('DD-MM-YYYY-HH-mm-ss')}`
        );
      }
    }
  });

  const [doUpdateNotification, { loading: updating }] = useLazyQuery(
    GET_TX_HISTORY_NOTIFICATION_UPDATE,
    {
      fetchPolicy: 'network-only'
    }
  );

  // api done

  const getTxs = () => {
    if (page <= 0) {
      return;
    }

    const filterObj = {
      startDate: filter?.startDate ? getUTCTimeDate(filter?.startDate) : null,
      endDate: filter?.endDate ? getEndDateEOD(filter?.endDate) : null,
      purchase: filter?.purchase ?? null,
      refund: filter?.refund ?? null
    };

    if (filter?.fixDate) {
      filterObj.fixDate = getUTCTimeDate(filter?.fixDate);
    }
    // update Query param
    navigate({
      pathname: '/merchant/transaction-history',
      search: `?${Object.keys(filterObj)
        ?.filter((k) => filterObj?.[k])
        ?.map((k) => `${k}=${filterObj?.[k]}`)
        .join('&')}&page=${page}&limit=${perPage}${keyword ? `&search=${keyword}` : ''}`
    });

    doGetTxs({
      variables: {
        filter: filterObj,
        pdf: null,
        sorting: {
          ...sorting,
          id: undefined
        },
        offset: (page - 1) * perPage,
        limit: perPage,
        search: keyword
      }
    });
  };

  useEffect(() => {
    getTxs();
  }, [page, perPage, keyword, sorting?.name, sorting?.status, sorting?.date]);

  const txList = useMemo(() => {
    return txs?.transactionHistory?.data?.map((u) => {
      const dealName = u?.deal_id?.name;
      const productName = u?.transactionInfo?.[0]?.product_id?.title;
      return {
        id: u?._id,
        name: `${u?.user_id?.firstname || ''} ${u?.user_id?.lastname || ''}`,
        date_time: moment(u?.invoiceDate).format('MMM DD - HH:mm'),
        deal: `${dealName} ${productName ? ` / ${productName}` : ''}`,
        method: u?.paymentType,
        transaction_id: u?.flexpointOrderPostingReport?.id ?? u?.invoiceNo,
        value: u?.total,
        centz_used: u?.centzTotal,
        action_full_fill: u?.merchantFullfill === 1 ? 'Fulfilled' : 'Fulfill',
        merchantFullfill: u?.merchantFullfill,
        issue_refund: '/assets/images/refresh.png',
        issue_refunded: String(u?.paymentStatus).toLowerCase() === 'refunded',
        transactionInfo: u?.transactionInfo,
        deal_id: u?.deal_id,
        merchant_id: u?.merchant_id,
        isItDealTx: u?.transactionType === 'deal',
        paymentStatus: u?.paymentStatus,
        details: u
      };
    });
  }, [txs?.transactionHistory?.data]);

  return (
    <div className="transaction-history-wrapper">
      {csvDownloading || pdfLoading || updating || refunding ? <LoaderComponent /> : null}
      <div className="transaction-history-header">
        <div className="header-title">
          <div className="image">
            <Image src={Images.transaction_history} />
          </div>
          <div className="text">All Transactions</div>
        </div>
        <div className="header-action">
          <div className="search-box">
            <TextInput
              placeholder="Enter a keyword to search"
              value={searchData}
              onChange={(e) => {
                setSearchData(e.target.value);
                if (e.target.value === '') {
                  setKeyword('');
                }
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter' && searchData?.trim()?.length > 0) {
                  setKeyword(searchData);
                }
              }}
            />
          </div>
          <div className="filter-btn-wrapper">
            <AppButton title={'Filter'} id="filter-btn" onClick={modalShow}></AppButton>
          </div>
          <div className="download-btn-wrapper" ref={ref}>
            <AppButton
              title={'Download'}
              id="download-btn"
              onClick={() => {
                toggleDownload(!download);
              }}></AppButton>
            {download && (
              <div className="download-dropdown-list">
                <ul>
                  <li className="csv-link">
                    <Link to="#">
                      <Export
                        onExport={() => {
                          downloadCSRef.current = true;
                          doDownloadCSV({
                            variables: {
                              limit: parseInt(txs?.transactionHistory?.total, 10),
                              offset: 0,
                              startDate: filter?.startDate
                                ? getUTCTimeDate(filter?.startDate)
                                : null,
                              endDate: filter?.endDate ? getEndDateEOD(filter?.endDate) : null,
                              purchase: filter?.purchase ?? null,
                              refund: filter?.refund ?? null,
                              fixDate: filter?.fixDate ? getUTCTimeDate(filter?.fixDate) : null,
                              search: keyword
                            }
                          });
                        }}
                      />
                    </Link>
                  </li>
                  <li className="pdf-link">
                    <Link
                      to="#javascript:void(0)"
                      onClick={() => {
                        downloadPDFRef.current = true;
                        doDownloadPDF({
                          variables: {
                            pdf: true,
                            limit: parseInt(txs?.transactionHistory?.total, 10),
                            offset: 0,
                            startDate: filter?.startDate ? getUTCTimeDate(filter?.startDate) : null,
                            endDate: filter?.endDate ? getEndDateEOD(filter?.endDate) : null,
                            purchase: filter?.purchase ?? null,
                            refund: filter?.refund ?? null,
                            fixDate: filter?.fixDate ? getUTCTimeDate(filter?.fixDate) : null,
                            search: keyword
                          }
                        });
                      }}>
                      PDF
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <DataTable
        paginationDefaultPage={page}
        defaultSortAsc={false}
        defaultSortFieldId={sorting?.id}
        columns={columns}
        data={txList}
        pagination={true}
        fixedHeader={true}
        responsive={true}
        loading={loading}
        onChangeRowsPerPage={(newPerPage) => {
          setPerPage(newPerPage);
        }}
        onChangePage={(page) => {
          setPage(page);
        }}
        paginationTotalRows={txs?.transactionHistory?.total}
        paginationServer
        progressPending={loading}
        paginationPerPage={perPage}
        sortServer
        conditionalRowStyles={[
          {
            when: (row) => row.issue_refunded,
            classNames: ['frozen-transaction']
          }
        ]}
        onSort={(sortData, sortDirection) => {
          const nameToKeyMap = {
            Name: 'name',
            Status: 'status',
            'Date joined': 'date'
          };
          const key = [nameToKeyMap?.[sortData.name]];
          setSorting({
            id: sortData?.id,
            date: null,
            time: null,
            transactionID: null,
            userName: null,
            [key]: sortDirection === 'asc'
          });
        }}
      />
      <RefundTransactionModal
        show={refundModalShow?.transactionInfo?.length > 0}
        onClose={() => setRefundModalShow({})}
        onClear={() => setRefundModalShow({})}
        products={refundModalShow?.transactionInfo}
        id={refundModalShow?.id}
        isItDealTx={refundModalShow?.isItDealTx}
        details={refundModalShow?.details}
        refundTx={(data) => {
          refundTx({
            variables: {
              id: data?.id,
              sku: data?.sku
            },
            onCompleted: () => {
              setRefundModalShow({});
              getTxs();
              toast.show('Refunded successfully.');
            }
          });
        }}
      />
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="filter-modal"
        show={show}
        onHide={modalClose}>
        <Modal.Header closeButton>
          <Image src={Images.filter_modal_icon} />
          <div className="heading">Filter Transactions</div>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-content-wrapper">
            <div className="date-filter">
              <div className="common-title">
                By date
                <span
                  className="float-end"
                  role="button"
                  onClick={() => {
                    setFilter({
                      ...filter,
                      startDate: null,
                      endDate: null
                    });
                  }}>
                  Clear
                </span>
              </div>
              <DateRangePicker
                staticRanges={ranges}
                onChange={(item) => {
                  setFilter({
                    ...filter,
                    startDate: item?.selection?.startDate,
                    endDate: item?.selection?.endDate
                  });
                }}
                ranges={[
                  {
                    key: 'selection',
                    startDate: filter?.startDate,
                    endDate: filter?.endDate
                  }
                ]}
                inputRanges={[false]}
              />
            </div>
            <div className="transaction-filter">
              <div className="common-title">Transaction type</div>
              <div className="filter-list">
                <div className="list-combo">
                  <FormCheck
                    type="radio"
                    checked={!filter?.refund && !filter?.purchase}
                    name="transaction-filter-group"
                    onChange={() => {
                      setFilter({
                        ...filter,
                        refund: null,
                        purchase: null
                      });
                    }}
                  />{' '}
                  <span>All</span>
                </div>
                <div className="list-combo">
                  <FormCheck
                    type="radio"
                    name="transaction-filter-group"
                    checked={filter?.purchase}
                    onChange={() => {
                      setFilter({
                        ...filter,
                        refund: null,
                        purchase: true
                      });
                    }}
                  />{' '}
                  <span>Purchase</span>
                </div>
                <div className="list-combo">
                  <FormCheck
                    checked={filter?.refund}
                    type="radio"
                    name="transaction-filter-group"
                    onChange={() => {
                      setFilter({
                        ...filter,
                        refund: true,
                        purchase: null
                      });
                    }}
                  />{' '}
                  <span>Refund</span>
                </div>
              </div>
            </div>
            <div className="order-filter">
              <div className="common-title">Order by</div>
              <div className="filter-list">
                <div className="list-combo">
                  <FormCheck
                    checked={sorting?.date}
                    type="radio"
                    name="order-filter-group"
                    onChange={() => {
                      setSorting({
                        id: 'date',
                        date: true,
                        time: null,
                        transactionID: null,
                        userName: null
                      });
                    }}
                  />{' '}
                  <span>Date</span>
                </div>
                <div className="list-combo">
                  <FormCheck
                    checked={sorting?.time}
                    type="radio"
                    name="order-filter-group"
                    onChange={() => {
                      setSorting({
                        id: 'time',
                        date: null,
                        time: true,
                        transactionID: null,
                        userName: null
                      });
                    }}
                  />{' '}
                  <span>Time</span>
                </div>
                <div className="list-combo">
                  <FormCheck
                    checked={sorting?.transactionID}
                    type="radio"
                    name="order-filter-group"
                    onChange={() => {
                      setSorting({
                        id: 'transactionID',
                        date: null,
                        time: null,
                        transactionID: true,
                        userName: null
                      });
                    }}
                  />{' '}
                  <span>Transaction ID</span>
                </div>
                <div className="list-combo">
                  <FormCheck
                    onChange={() => {
                      setSorting({
                        id: 'userName',
                        date: null,
                        time: null,
                        transactionID: null,
                        userName: true
                      });
                    }}
                    checked={sorting?.userName}
                    type="radio"
                    name="order-filter-group"
                  />{' '}
                  <span>Username</span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AppButton
            title={'Clear'}
            id="filter-cancel-btn"
            onClick={() => {
              setPage(0);
              setSorting({
                date: null,
                time: null,
                transactionID: null,
                userName: null
              });
              setFilter({
                ...filter,
                startDate: null,
                endDate: null,
                purchase: null,
                refund: null
              });

              setTimeout(() => {
                modalClose();
                setPage(1);
              }, 1000);
            }}></AppButton>
          <AppButton
            title={'Filter results'}
            id="filter-result-btn"
            onClick={() => {
              if (page === 1) {
                getTxs();
              } else {
                setPage(1);
              }
              modalClose();
            }}></AppButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withMerchantAdmin(MerchantTransactionPage);
