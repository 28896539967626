import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Image, Modal } from 'react-bootstrap';
import withAdmin from '../../../../components/hoc/admin/withAdmin';
import DataTable from 'react-data-table-component';
import { DateRangePicker } from 'react-date-range';
import Images from '../../../../constants/images';
import TextInput from '../../../../components/common/text-input/TextInput';
import SelectInput from '../../../../components/common/select-input/SelectInput';
import AppButton from '../../../../components/common/button/Button';
import FeedbackModal from '../../../../components/common/feedback-modal/FeedbackModal';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '../styles.css';
import { useLazyQuery } from '@apollo/client';
import { GET_MERCHANT_LIST } from '../../../../graphql/schema/query/admin/merchant/GET_MERCHANT_LIST';
import moment from 'moment';
import { baseFilePaths } from '../../../../constants';
import { getEndDateEOD, getUTCTimeDate } from '../../../../services/dateUtils';
import { DELETE_MERCHANT } from '../../../../graphql/schema/query/admin/merchant/DELETE_MERCHANT';
import ToastContext from '../../../../contexts/ToastContext';
import { FREEZE_MERCHANT } from '../../../../graphql/schema/query/admin/merchant/FREEZE_MERCHANT';
import { isEpxFlowDone } from '../../../../services';
import { UPDATE_BUSINESS_STATUS_BY_ADMIN } from '../../../../graphql/schema/query/admin/merchant/UPDATE_BUSINESS_STATUS_BY_ADMIN';
import { GET_STATES } from '../../../../graphql/schema/query/masters/GET_STATE';
import { downloadCSV, downloadPDFFile } from '../../../../services/downloadUtils';
import LoaderComponent from '../../../../components/common/loader/LoaderComponent';

const Export = ({ onExport }) => <div onClick={(e) => onExport(e.target.value)}>CSV</div>;

const MerchantList = () => {
  const columns = [
    {
      name: 'Name',
      selector1: (row) => row.name,
      selector2: (row) => row.email,
      sortable: true,
      cell: (row) => (
        <div className="merchant-detail">
          {row?.profile ? (
            <Image className="user-image" height="40px" width="40px" src={row?.profile} alt={''} />
          ) : (
            <div
              className="user-image d-flex justify-content-center align-items-center"
              style={{
                height: '40px',
                width: '40px',
                borderRadius: '50%',
                background: '#E7E6EC',
                color: '#0F0742',
                fontSize: '16px',
                fontWeight: 500
              }}>
              {row?.name?.[0]}
              {row?.name?.split?.(' ')?.[1]?.[0]?.toUpperCase()}
            </div>
          )}

          <div className="marchant-info">
            <div className="name">{row.name}</div>
            <div className="date-time">{row.email}</div>
          </div>
        </div>
      )
    },
    {
      name: 'Status',
      selector1: (row) => row.status_active,
      // selector2: (row) => row.status_frozen,
      sortable: true,
      cell: (row) => (
        <div>
          <span className={row.status_active === 'Active' ? 'active-status' : 'frozen-status'}>
            {row.status_active}
          </span>
        </div>
      )
    },
    {
      name: 'Date joined',
      selector: (row) => row.date_joined,
      sortable: true
    },
    {
      name: 'Timezone',
      selector1: (row) => row.timezone,
      sortable: true,
      cell: (row) => (
        <div>
          <div className="time-zone">{row.timezone}</div>
        </div>
      )
    },
    {
      name: '',
      selector: (row) => row.view_deal,
      sortable: true,
      cell: (row) => (
        <div className="view-deal">
          <Link
            to={`/admin/merchant/management/view-deal/${encodeURI(row?.name || '-')}/${row?.id}`}
            className="pause-icon">
            {row.view_deal}
          </Link>
        </div>
      )
    },
    {
      name: '',
      selector1: (row) => row.action_pause,
      selector2: (row) => row.action_delete,
      selector3: (row) => row.action_edit,
      center: true,
      cell: (row) => (
        <div className="action-btn-wrapper">
          <a
            href="javascript:void(0)"
            className="pause-icon"
            onClick={() => {
              doFreezeUser({
                variables: {
                  freezeMerchantId: row.id
                }
              });
            }}>
            <img height="16px" width="16px" alt={row.name} src={row.action_pause} />
            <span className="tooltip-custom">
              {row?.isActive ? 'Freeze merchant' : 'Activate merchant'}
            </span>
          </a>
          <a to="javascript:void(0)" className="delete-icon" onClick={() => deleteModalShow(row)}>
            <img height="16px" width="16px" alt={row.name} src={row.action_delete} />
          </a>
          <Link
            to={`/admin/merchant/management/edit/${encodeURI(row?.name || '-')}/${row?.id}`}
            className="edit-icon">
            <img height="16px" width="16px" alt={row.name} src={row.action_edit} />
          </Link>
        </div>
      )
    },
    !isEpxFlowDone()
      ? {
          name: '',
          selector1: (row) => row.updateStatusId,
          center: true,
          cell: (row) => (
            <div className="view-deal">
              <a
                href="javascript:void(0)"
                className="pause-icon"
                onClick={() =>
                  doUpdateStatusByAdmin({
                    variables: {
                      updateBusinessStatusByAdminId: row?.id
                    }
                  })
                }>
                Update Status
              </a>
            </div>
          )
        }
      : null
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location?.search);

  const limit = query.get('limit');
  const givenPage = query.get('page');
  const startDate = query.get('startDate')
    ? moment(query.get('startDate').replace(' ', '+')).toDate()
    : null;
  const endDate = query.get('endDate')
    ? moment(query.get('endDate').replace(' ', '+')).toDate()
    : null;
  const fixDate = query.get('fixDate')
    ? moment(query.get('fixDate').replace(' ', '+')).toDate()
    : null;

  const state_id = query.get('state_id');
  const country_id = query.get('country_id');
  const zipcode = query.get('zipcode');
  const search = query.get('search');
  const city = query.get('city');

  const ref = useRef();
  const downloadCSRef = useRef();
  const downloadPDFRef = useRef();

  const [download, toggleDownload] = useState(false);

  //Track click outside
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (download && ref.current && !ref.current.contains(e.target)) {
        toggleDownload(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [download]);
  // filter modal show hide
  const [show, setShow] = useState(false);
  const modalClose = () => setShow(false);
  const modalShow = () => setShow(true);

  // delete modal show hide
  const [deleteShow, setDeleteShow] = useState(false);
  const deleteModalShow = (selectedUser) => setDeleteShow(selectedUser);
  const deleteModalClose = () => setDeleteShow(false);

  // filter,p pagination and search
  const [searchData, setSearchData] = useState(search || '');
  const [keyword, setKeyword] = useState(search || '');
  const [page, setPage] = useState(givenPage ? parseInt(givenPage, 10) : 1);
  const [perPage, setPerPage] = useState(limit ? parseInt(limit, 10) : 10);
  const [sorting, setSorting] = useState({
    name: null,
    status: null,
    date: null
  });
  const [filter, setFilter] = useState({
    fixDate: fixDate || null,
    startDate: startDate || null,
    endDate: endDate || null,
    state_id: state_id || null,
    country_id: country_id || null,
    zipcode: zipcode || null,
    city: city || null
  });

  const [stateData, setStateData] = useState([]);

  // api
  const [doGetState] = useLazyQuery(GET_STATES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.state?.data?.find((d) => d?.name === null)) {
        return;
      }
      setStateData(data?.state?.data);
    }
  });

  const { setToastDetails } = useContext(ToastContext);
  const [doGetMerchants, { data: merchants, loading }] = useLazyQuery(GET_MERCHANT_LIST, {
    fetchPolicy: 'network-only'
  });
  const [doGetMerchantsPdf, { loading: pdfLoading }] = useLazyQuery(GET_MERCHANT_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!downloadPDFRef.current) {
        return;
      }
      downloadPDFRef.current = false;
      if (data?.getMerchantList.pdfData?.length) {
        downloadPDFFile(
          data?.getMerchantList.pdfData,
          `merchant-data-export-${moment().format('DD-MM-YYYY-HH-mm-ss')}`
        );
      }
    }
  });

  const [doDownloadCSV, { loading: csvDownloading }] = useLazyQuery(GET_MERCHANT_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!downloadCSRef.current) {
        return;
      }
      downloadCSRef.current = false;
      downloadCSV(
        data?.getMerchantList?.user?.map((u) => {
          let profile = '';
          if (u?.business_data?.businessLogo) {
            profile = `${baseFilePaths.businessProfile}${u?.business_data?.businessLogo}`;
          } else if (u?.business_data?.logoUrl) {
            profile = u?.business_data?.logoUrl;
          }
          return {
            id: u?._id,
            name: `${u?.business_data?.businessName ?? ''}`,
            email: u?.email,
            status_active: `${u?.isActive ? 'Active' : 'Frozen'}`,
            date_joined: moment(u?.createdAt).format('MMM DD, YYYY').toString(),
            timezone: u?.timezone ?? '-',
            dmt: '',
            // pause-user-frozen.svg
            action_pause: u?.isActive ? Images.user_pause : Images.user_pause_frozen,
            action_delete: Images.user_delete,
            action_edit: Images.user_edit,
            isActive: u?.isActive,
            profile: profile,
            view_deal: 'View Deals',
            updateStatusId: u?._id
          };
        }),
        ['Name', 'Email', 'Status', 'Joined Date'],
        ['name', 'email', 'status_active', 'date_joined'],
        `merchant-data-export-${moment().format('DD-MM-YYYY-HH-mm-ss')}.csv`
      );
    }
  });

  const [doDeleteUser, { data: userDelete, loading: deleteLoading }] = useLazyQuery(
    DELETE_MERCHANT,
    {
      fetchPolicy: 'cache-and-network'
    }
  );
  const [doFreezeUser, { data: userFreeze, loading: freezeLoading }] = useLazyQuery(
    FREEZE_MERCHANT,
    {
      fetchPolicy: 'cache-and-network'
    }
  );
  const [doUpdateStatusByAdmin, { data: updateStatus, loading: updatingStatus }] = useLazyQuery(
    UPDATE_BUSINESS_STATUS_BY_ADMIN,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  const getMerchantList = () => {
    if (page <= 0) {
      return;
    }
    const filterObj = {
      startDate: filter?.startDate ? getUTCTimeDate(filter?.startDate) : null,
      endDate: filter?.endDate ? getEndDateEOD(filter?.endDate) : null,
      state_id: filter?.state_id,
      country_id: filter?.country_id,
      zipcode: filter?.zipcode,
      city: filter?.city
    };

    if (filter?.fixDate) {
      filterObj.fixDate = getUTCTimeDate(filter?.fixDate);
    }
    // update Query param
    navigate({
      pathname: '/admin/merchant/management',
      search: `?${Object.keys(filterObj)
        ?.filter((k) => filterObj?.[k])
        ?.map((k) => `${k}=${filterObj?.[k]}`)
        .join('&')}&page=${page}&limit=${perPage}${keyword ? `&search=${keyword}` : ''}`
    });
    doGetMerchants({
      variables: {
        offset: (page - 1) * perPage,
        limit: perPage,
        search: keyword,
        filter: {
          ...filterObj,
          fixDate: undefined
        },
        fixDate: filterObj?.fixDate ? getUTCTimeDate(filterObj?.fixDate) : null,
        sorting: {
          ...sorting,
          id: undefined
        }
      }
    });
  };

  useEffect(() => {
    doGetState();
  }, []);

  useEffect(() => {
    getMerchantList();
  }, [page, perPage, keyword, sorting?.name, sorting?.status, sorting?.date]);

  useEffect(() => {
    if (
      !deleteLoading &&
      userDelete?.deleteMerchant?.message &&
      userDelete?.deleteMerchant?.errorCode &&
      userDelete?.deleteMerchant?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: userDelete?.deleteMerchant?.message,
        type: 'danger'
      });
    } else {
      if (!deleteLoading && userDelete?.deleteMerchant?.status) {
        setToastDetails({
          show: true,
          title: 'User Deleted',
          message: 'User deleted successfully.',
          type: 'success'
        });
        getMerchantList();
      }
    }
  }, [
    deleteLoading,
    userDelete?.deleteMerchant?.errorCode,
    userDelete?.deleteMerchant?.message,
    userDelete?.deleteMerchant?.status
  ]);

  useEffect(() => {
    if (
      !freezeLoading &&
      userFreeze?.freezeMerchant?.message &&
      userFreeze?.freezeMerchant?.errorCode &&
      userFreeze?.freezeMerchant?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: userFreeze?.freezeMerchant?.message,
        type: 'danger'
      });
    } else {
      if (!freezeLoading && userFreeze?.freezeMerchant?.status) {
        setToastDetails({
          show: true,
          title: 'User Freezed',
          message: userFreeze?.freezeMerchant?.user?.[0]?.isActive
            ? 'Merchant activated successfully.'
            : 'Merchant freezed successfully.',
          type: 'success'
        });
        getMerchantList();
      }
    }
  }, [
    freezeLoading,
    updateStatus?.updateBusinessStatusByAdmin?.errorCode,
    updateStatus?.updateBusinessStatusByAdmin?.message,
    updateStatus?.updateBusinessStatusByAdmin?.status
  ]);
  useEffect(() => {
    if (
      !updatingStatus &&
      updateStatus?.updateBusinessStatusByAdmin?.message &&
      updateStatus?.updateBusinessStatusByAdmin?.errorCode &&
      updateStatus?.updateBusinessStatusByAdmin?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: updateStatus?.updateBusinessStatusByAdmin?.message,
        type: 'danger'
      });
    } else {
      if (!updatingStatus && updateStatus?.updateBusinessStatusByAdmin?.status) {
        setToastDetails({
          show: true,
          title: 'Status Updated',
          message: 'Status Updated successfully.',
          type: 'success'
        });
        getMerchantList();
      }
    }
  }, [
    updatingStatus,
    updateStatus?.updateBusinessStatusByAdmin?.errorCode,
    updateStatus?.updateBusinessStatusByAdmin?.message,
    updateStatus?.updateBusinessStatusByAdmin?.status
  ]);
  // api done

  const merchantList = useMemo(() => {
    return merchants?.getMerchantList?.user?.map((u) => {
      let profile = '';
      if (u?.business_data?.businessLogo) {
        profile = `${baseFilePaths.businessProfile}${u?.business_data?.businessLogo}`;
      } else if (u?.business_data?.logoUrl) {
        profile = u?.business_data?.logoUrl;
      }
      return {
        id: u?._id,
        name: `${u?.business_data?.businessName ?? ''}`,
        email: u?.email,
        status_active: `${u?.isActive ? 'Active' : 'Frozen'}`,
        date_joined: moment(u?.createdAt).format('MMM DD, YYYY').toString(),
        timezone: u?.timezone ?? '-',
        dmt: '',
        // pause-user-frozen.svg
        action_pause: u?.isActive ? Images.user_pause : Images.user_play,
        action_delete: Images.user_delete,
        action_edit: Images.user_edit,
        isActive: u?.isActive,
        profile: profile,
        view_deal: 'View Deals',
        updateStatusId: u?._id
      };
    });
  }, [merchants?.getMerchantList?.user]);

  return (
    <>
      <div className="merchant-list-wrapper">
        {pdfLoading || csvDownloading || freezeLoading || deleteLoading ? (
          <LoaderComponent />
        ) : null}
        <div className="merchant-list-header">
          <div className="header-title">
            <div className="image">
              <Image src={Images.merchant_management_icon} />
            </div>
            <div className="text">
              <div>All Merchants</div>
              <div className="user-count">{merchants?.getMerchantList?.total || ''} merchants</div>
            </div>
          </div>
          <div className="header-action">
            <div className="search-box">
              <TextInput
                value={searchData}
                placeholder="Search merchants"
                onChange={(e) => {
                  setSearchData(e.target.value);
                  if (e.target.value === '') {
                    setKeyword('');
                  }
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter' && searchData?.trim()?.length > 0) {
                    setKeyword(searchData);
                  }
                }}
              />
            </div>
            <div className="filter-btn-wrapper">
              <AppButton title={'Filter'} id="filter-btn" onClick={modalShow}></AppButton>
            </div>
            <div className="download-btn-wrapper" ref={ref}>
              <AppButton
                title={'Download'}
                id="download-btn"
                onClick={() => toggleDownload((oldState) => !oldState)}></AppButton>
              {download && (
                <div className="download-dropdown-list">
                  <ul>
                    <li className="csv-link">
                      <Link to="#">
                        <Export
                          onExport={() => {
                            downloadCSRef.current = true;
                            doDownloadCSV({
                              variables: {
                                limit: merchants?.getMerchantList?.total,
                                offset: 0,
                                search: keyword,
                                filter: {
                                  startDate: filter?.startDate
                                    ? getUTCTimeDate(filter?.startDate)
                                    : null,
                                  endDate: filter?.endDate ? getEndDateEOD(filter?.endDate) : null,
                                  state_id: filter?.state_id,
                                  country_id: filter?.country_id,
                                  zipcode: filter?.zipcode,
                                  city: filter?.city
                                },
                                fixDate: filter?.fixDate ? getUTCTimeDate(filter?.fixDate) : null
                              }
                            });
                          }}
                        />
                      </Link>
                    </li>
                    <li className="pdf-link">
                      <Link
                        to="#javascript:void(0)"
                        onClick={() => {
                          downloadPDFRef.current = true;
                          doGetMerchantsPdf({
                            variables: {
                              limit: merchants?.getMerchantList?.total,
                              offset: 0,
                              search: keyword,
                              filter: {
                                startDate: filter?.startDate
                                  ? getUTCTimeDate(filter?.startDate)
                                  : null,
                                endDate: filter?.endDate ? getEndDateEOD(filter?.endDate) : null,
                                state_id: filter?.state_id,
                                country_id: filter?.country_id,
                                zipcode: filter?.zipcode,
                                city: filter?.city
                              },
                              fixDate: filter?.fixDate ? getUTCTimeDate(filter?.fixDate) : null,
                              pdf: true
                            }
                          });
                        }}>
                        PDF
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <DataTable
          paginationDefaultPage={page}
          defaultSortAsc={false}
          defaultSortFieldId={sorting?.id}
          columns={columns}
          data={merchantList}
          pagination={true}
          fixedHeader={true}
          responsive={true}
          loading={loading}
          onChangeRowsPerPage={(newPerPage) => {
            setPerPage(newPerPage);
          }}
          onChangePage={(page) => {
            setPage(page);
          }}
          paginationTotalRows={merchants?.getMerchantList?.total}
          paginationServer
          progressPending={loading}
          paginationPerPage={perPage}
          conditionalRowStyles={[
            {
              when: (row) => !row.isActive,
              classNames: ['frozen-user']
            }
          ]}
          sortServer
          onSort={(sortData, sortDirection) => {
            const nameToKeyMap = {
              Name: 'name',
              Status: 'status',
              'Date joined': 'date'
            };
            const key = [nameToKeyMap?.[sortData.name]];
            setSorting({
              id: sortData?.id,
              name: null,
              date: null,
              status: null,
              [key]: sortDirection === 'asc'
            });
          }}
        />
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="filter-modal merchant-list-filter-modal"
          show={show}
          onHide={modalClose}>
          <Modal.Header closeButton>
            <Image src={Images.filter_modal_icon} />
            <div className="heading">Filter merchants by</div>
          </Modal.Header>
          <Modal.Body>
            <div className="filter-content-wrapper">
              <div className="single-date-filter">
                <div className="common-title">Date joined</div>
                <TextInput
                  type="date"
                  label="Specific date"
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      fixDate: e.target.value
                    });
                  }}
                  value={filter?.fixDate ? moment(filter?.fixDate).format('YYYY-MM-DD') : null}
                />
              </div>
              <div className="date-filter">
                <div className="common-title-small">
                  Between
                  <span
                    className="float-end"
                    role="button"
                    onClick={() => {
                      setFilter({
                        ...filter,
                        startDate: null,
                        endDate: null
                      });
                    }}>
                    Clear
                  </span>
                </div>
                <DateRangePicker
                  onChange={(item) => {
                    setFilter({
                      ...filter,
                      startDate: item?.selection?.startDate,
                      endDate: item?.selection?.endDate
                    });
                  }}
                  ranges={[
                    {
                      key: 'selection',
                      startDate: filter?.startDate,
                      endDate: filter?.endDate
                    }
                  ]}
                  inputRanges={[false]}
                />
              </div>
              <div className="user-filter">
                <div className="common-title">Location</div>
                <div className="user-filter-list">
                  <TextInput
                    value={filter.city}
                    placeholder="City"
                    type="text"
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        city: e.target.value
                      });
                    }}
                  />
                  <SelectInput
                    label="State"
                    placeholder="Select State"
                    options={
                      Array.isArray(stateData)
                        ? [
                            {
                              name: 'Select State',
                              _id: null
                            },
                            ...stateData
                          ]
                        : []
                    }
                    onChange={(value) => {
                      setFilter({
                        ...filter,
                        state_id: value
                      });
                    }}
                    name="state_id"
                    id="user.filters.stateId"
                    value={filter?.state_id}
                  />
                  <div className="mt-2" />
                  <TextInput
                    placeholder="Zipcode"
                    type="text"
                    value={filter?.zipcode}
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        zipcode: e.target.value
                      });
                    }}
                  />
                  {/* <SelectInput
                    placeholder={'Timezone'}
                    options={[
                      {
                        name: 'Timezone1',
                        _id: '1'
                      },
                      {
                        name: 'Timezone2',
                        _id: '2'
                      }
                    ]}
                    id="deals.status"
                    onChange={(value) => console.log(value)}
                  /> */}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <AppButton
              title={'Clear'}
              id="filter-cancel-btn"
              onClick={() => {
                setPage(0);
                setFilter({
                  ...filter,
                  fixDate: null,
                  startDate: null,
                  endDate: null,
                  state_id: null,
                  country_id: null,
                  zipcode: null,
                  city: null
                });

                setTimeout(() => {
                  modalClose();
                  setPage(1);
                }, 1000);
              }}></AppButton>
            <AppButton
              title={'Filter results'}
              id="filter-result-btn"
              onClick={() => {
                if (page === 1) {
                  getMerchantList();
                } else {
                  setPage(1);
                }
                modalClose();
              }}></AppButton>
          </Modal.Footer>
        </Modal>
        <FeedbackModal
          show={deleteShow}
          onHide={deleteModalClose}
          icon={Images.delete_confirm}
          title={`Delete ${deleteShow?.name?.trim?.() || deleteShow?.email || ''}`}
          subTitle="Are you sure you want to delete this merchant? This action cannot be undone."
          className="delete-merchant-list-modal"
          buttons={[
            <AppButton
              title={'Cancel'}
              buttonClass={'btn-cancel'}
              key="1"
              onClick={deleteModalClose}
            />,
            <AppButton
              title={'Delete'}
              buttonClass={'error-bg'}
              key="2"
              onClick={() => {
                doDeleteUser({
                  variables: { deleteMerchantId: deleteShow?.id }
                });
                setDeleteShow();
              }}
            />
          ]}
        />
      </div>
    </>
  );
};

export default withAdmin(MerchantList);
