import React from 'react';
import { Col, Row } from 'react-bootstrap';

const PayPalMerchantAccount = ({ url }) => {
  return (
    <div className="merchant-paypal-view">
      <Col md="8">
        <h4 className="title">Registration for PayPal Business Account</h4>
        <span className="subtitle">Registration for PayPal Business Account</span>
        <Row className="mt-4">
          <Col md={6}>
            <a
              className="app-button w-100 merchant-paypal-btn btn btn-primary"
              data-paypal-button="true"
              href={`${url}&displayMode=minibrowser"`}
              target="PPFrame">
              Connect to PayPal Business Account
            </a>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default PayPalMerchantAccount;
