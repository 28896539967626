import { gql } from '@apollo/client';

export const MERCHANT_ADD_BUSINESS = gql`
  mutation UpdateBusiness($input: BusinessInput!) {
    updateBusiness(input: $input) {
      status
      message
      business {
        _id
      }
    }
  }
`;
