import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AppAlert from '../../../components/common/alerts/AppAlerts';
import AppButton from '../../../components/common/button/Button';
import CreateNewDeal from '../../../components/common/deals/CreateNewDeal';
import DealCard from '../../../components/common/deals/DealCard';
import LoaderComponent from '../../../components/common/loader/LoaderComponent';
import SelectInput from '../../../components/common/select-input/SelectInput';
import withMerchantAdmin from '../../../components/hoc/merchant/withMerchantAdmin';
import { baseFilePaths, MERCHANT_APPLICATION_SETTINGS } from '../../../constants';
import Images from '../../../constants/images';
import UserContext from '../../../contexts/user/UserContext';
import { GET_DEALS } from '../../../graphql/schema/query/deals/GET_DEALS';

const PAGE_LIMIT = 20;
const baseFilePathForDeal = baseFilePaths.dealImage;

const MerchantDealsPage = () => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({
    status: undefined
  });

  const [records, setRecords] = useState([]);
  const { details: userData } = useContext(UserContext);

  const status = userData?.me?.merchant?.business_data?.businessStatus;

  const isProfileCompleted = status === MERCHANT_APPLICATION_SETTINGS.approved;

  const [getDeals, { loading }] = useLazyQuery(GET_DEALS, {
    fetchPolicy: 'network-only',
    variables: {
      limit: PAGE_LIMIT,
      offset: (currentPage - 1) * PAGE_LIMIT,
      status: filter?.status || undefined
    },
    onCompleted: (data) => {
      const recordsAre = Array.isArray(data?.getDeals?.deals) ? data?.getDeals?.deals : [];
      const dealArray = [...records, ...recordsAre];
      setRecords(currentPage === 1 ? recordsAre : dealArray);
      if (data?.getDeals?.deals?.length !== 0) {
        setCurrentPage(currentPage + 1);
      }
    }
  });

  useEffect(() => {
    getDeals();
  }, [currentPage, filter?.status]);

  return (
    <>
      <Row className="alert-information deal-alert-box">
        <Col md={12}>
          <AppAlert
            show={!isProfileCompleted}
            message="You can't activate deals until your profile is complete"
            variant={'warning'}
            rightIcon
            rightIconText="Edit profile"
            to="/merchant/settings/merchant-account-status"
          />
        </Col>
      </Row>
      <div className="deal-top-bar">
        <div className="mt-2">
          <SelectInput
            placeholder={'View all'}
            options={[
              {
                name: 'View All',
                _id: null
              },
              {
                name: 'Active',
                _id: 'active'
              },
              {
                name: 'Inactive',
                _id: 'inactive'
              }
            ]}
            id="deals.status"
            onChange={(value) => {
              if (filter?.status === value) {
                return;
              }
              setCurrentPage(1);
              setRecords([]);
              setFilter({
                ...filter,
                status: value
              });
            }}
            value={filter?.status}
          />
        </div>
        <div className="d-flex justify-content-end">
          <AppButton
            title={'Create a new deal'}
            onClick={() => navigate('/merchant/deals/create')}
            id="deal.createNewDeal"
          />
        </div>
      </div>
      {filter?.status && !loading && records?.length === 0 ? (
        <p className="text-center mt-5 mb-5">No Deal Found</p>
      ) : null}

      {loading ? <LoaderComponent /> : null}

      <Row className="mt-4">
        {records.map((record) => {
          return (
            <Col xl={4} lg={6} md={6} key={record?._id} className="deal-box">
              <DealCard
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                image={
                  record?.dealImages?.[0]
                    ? `${baseFilePathForDeal}${record?.dealImages?.[0]}`
                    : Images.deal_1
                }
                type={record?.discountType}
                title={record?.name}
                from={moment(record?.startDate).format('MMM DD YYYY')}
                to={moment(record?.endDate).format('MMM DD YYYY')}
                rrp={`$${Number(record?.price).toFixed(2)}`}
                discount={`${
                  record?.discountType == 'flat'
                    ? Number(record?.discountValue).toFixed(2)
                    : record?.discountValue
                }`}
                id={record?._id}
                navigate={navigate}
                refresh={() => {
                  setRecords([]);
                  setCurrentPage(1);
                  getDeals();
                }}
                isProfileCompleted={isProfileCompleted}
                isActive={record?.isActive}
              />
            </Col>
          );
        })}

        <Col xl={4} lg={6} md={6} className="create-deal-box">
          <CreateNewDeal />
        </Col>
      </Row>
    </>
  );
};

export default withMerchantAdmin(MerchantDealsPage);
