import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TextInput from '../../common/text-input/TextInput';

const FinancialInformation = () => {
  return (
    <>
      <div className="wrapper-padding">
        <p className="form-title mb-0 fonts-quicksand-medium">Financial information</p>
        <span className="form-subtitle fonts-regular">Merchant&apos;s Financial Details</span>
        <Row className="mt-2">
          <Col md={12}>
            <TextInput
              label="Estimated Monthly Sales"
              placeholder="Estimated Monthly Sales"
              leftText="$"
              name="monthly-sale"
              id="merchantfininfo.monthly-sale"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FinancialInformation;
