import { gql } from '@apollo/client';

export const GET_ME = gql`
  query Query {
    me {
      merchant {
        _id
        firstname
        lastname
        dob
        securityNo
        address
        city
        state_id {
          _id
          name
        }
        country_id {
          _id
          name
        }
        zipcode
        phonNo
        startupDate
        ownership
        accountNo
        routingNo
        ownershipType
        stateSaleTax
        fedralTax
        productSold
        merchantCategoryClassification
        monthInBusiness
        yearInBusiness
        createdAt
        business_data {
          isActive
          businessName
          address
          apartmentNo
          city
          state_id {
            _id
            name
          }
          country_id {
            _id
            name
          }
          zipcode
          website
          email
          category_id {
            _id
            name
          }
          subCategory_id {
            _id
            name
            refId {
              _id
              name
            }
          }
          socialUrl
          phoneno
          businessDescription
          businessLogo
          logoUrl
          bannerImages
          timings {
            status
            openingTime
            closingTime
            isOpenDay
          }
          businessStatus
        }
        email
        termsAndConditationStatus
        isActive
        isVoid
        userType
      }
      status
      message
      errorCode
    }
  }
`;
