import { gql } from '@apollo/client';

export const GET_MERCHANT_LIST = gql`
  query GetMerchantList(
    $offset: Int
    $limit: Int
    $fixDate: Date
    $filter: filter
    $sorting: sorting
    $search: String
    $pdf: Boolean
    $id: ID
  ) {
    getMerchantList(
      offset: $offset
      limit: $limit
      fixDate: $fixDate
      filter: $filter
      sorting: $sorting
      search: $search
      pdf: $pdf
      _id: $id
    ) {
      status
      message
      user {
        _id
        firstname
        lastname
        dob
        securityNo
        apartmentNo
        address
        city
        state_id {
          _id
          name
          value
        }
        country_id {
          _id
          name
        }
        zipcode
        phonNo
        personalPhoneNo
        startupDate
        ownership
        accountNo
        routingNo
        ownershipType
        stateSaleTax
        fedralTax
        productSold
        merchantCategoryClassification
        monthInBusiness
        yearInBusiness
        createdAt
        timezone
        business_data {
          isActive
          businessName
          address
          businessDescription
          apartmentNo
          city
          state_id {
            _id
            name
            value
          }
          country_id {
            _id
            name
          }
          zipcode
          website
          email
          category_id {
            _id
            name
          }
          subCategory_id {
            _id
            name
            refId {
              _id
              name
            }
          }
          socialUrl
          phoneno
          businessLogo
          bannerImages
          timings {
            status
            openingTime
            closingTime
            isOpenDay
          }
          businessStatus
          latitude
          longitude
          logoUrl
        }
        email
        termsAndConditationStatus
        isActive
        isVoid
        userType
      }
      total
      limit
      offset
      errorCode
      pdfData
    }
  }
`;
