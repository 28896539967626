import moment from 'moment';
import * as Yup from 'yup';
import { URLRegex } from '../../../../constants';
import { sanitizeInput } from '../merchant-account/formUtils';

export const BusinessInfoSchema = Yup.object().shape({
  businessName: Yup.string()
    .typeError('Please enter name')
    .required('Please enter name')
    .max(30, 'Business name should not exceeds 30 characters.'),
  address: Yup.string().typeError('Please enter address').required('Please enter address'),
  apartmentNo: Yup.string().optional(),
  city: Yup.string().typeError('Please enter city').required('Please enter city'),
  zipcode: Yup.string().typeError('Please enter zip code').required('Please enter zip code'),
  website: Yup.string()
    .typeError('Please enter website')
    // .url('Please enter valid website address')
    .matches(URLRegex, 'Please enter valid website address.')
    // .test('notStartsWithWWW', 'Please enter website without www', (item) => {
    //   return !item?.startsWith('www.');
    // })
    .required('Please enter website'),
  email: Yup.string()
    .typeError('Please enter email')
    .email('Please enter valid email')
    .required('Please enter email'),
  phoneno: Yup.string()
    .test(
      'min12Digit',
      'Phone number should be of 10 digits',
      (value) => sanitizeInput(value).trim().length == 10
    )
    .typeError('Please enter phone no')
    .required('Please enter phone no'),
  category_id: Yup.string().typeError('Please select category').required('Please select category'),
  subCategory_id: Yup.mixed()
    .typeError('Please select sub category')
    .required('Please select sub category'),
  state_id: Yup.string().typeError('Please select state').required('Please select state'),
  timings: Yup.array().of(
    Yup.object().shape({
      status: Yup.boolean(),
      openingTime: Yup.string().required('Please enter opening time'),
      closingTime: Yup.string()
        .required('Please enter closing time')
        .when('openingTime', (openingTime) => {
          if (String(openingTime).trim() !== '') {
            return Yup.string()
              .required('Please enter closing time')
              .test('openingTime', 'Please enter valid close time', (value) => {
                const endTime = moment(value, 'hh:mm');
                // const opens = moment(openingTime, 'hh:mm');
                if (moment(value, 'hh:mm').get('hour') <= 6) {
                  // if (moment(value, 'hh:mm').get('hour') <= moment(opens, 'hh:mm').get('hour')) {
                  endTime.add(1, 'day');
                }
                if (openingTime && value) {
                  return moment(openingTime, 'hh:mm').isSameOrBefore(endTime);
                }
              });
          }
        }),
      isOpenDay: Yup.boolean()
    })
  ),
  socialUrl: Yup.string().nullable().matches(URLRegex, 'Please enter valid URL.'),
  latitude: Yup.number().typeError('Please enter valid Latitude').optional(),
  longitude: Yup.number().typeError('Please enter valid Longitude').optional(),
  businessDescription: Yup.string()
    .typeError('Please enter description')
    .max(250, 'Description should be of 250 characters')
    .optional()
});

export const BusinessInfoInitValues = {
  businessName: '',
  address: '',
  apartmentNo: '',
  city: '',
  state_id: '',
  country_id: '',
  zipcode: '',
  category_id: '',
  website: '',
  email: '',
  phoneno: '',
  subCategory_id: '',
  businessDescription: '',
  latitude: '',
  longitude: '',
  timings: [
    {
      status: true,
      openingTime: '',
      closingTime: '',
      isOpenDay: false
    },
    {
      status: true,
      openingTime: '',
      closingTime: '',
      isOpenDay: false
    },
    {
      status: true,
      openingTime: '',
      closingTime: '',
      isOpenDay: false
    },
    {
      status: true,
      openingTime: '',
      closingTime: '',
      isOpenDay: false
    },
    {
      status: true,
      openingTime: '',
      closingTime: '',
      isOpenDay: false
    },
    {
      status: true,
      openingTime: '',
      closingTime: '',
      isOpenDay: false
    },
    {
      status: true,
      openingTime: '',
      closingTime: '',
      isOpenDay: false
    }
  ]
};
