import React, { useContext, useEffect, useState } from 'react';
import withAdmin from '../../../components/hoc/admin/withAdmin';
import FormWrapper from '../../../components/common/form-wrapper/FormWrapper';
import SurveySettings from '../../../components/admin/common-form-sections/SurveySettings';
import SurveyContent from '../../../components/admin/common-form-sections/SurveyContent';
import SurveyResult from '../../../components/admin/common-form-sections/SurveyResult';
import './styles.css';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { initValues, surveySchema, surveySchemaEdit } from './formUtils';
import ErrorFocus from '../../../components/common/ErrorFocus/ErrorFocus';
import ToastContext from '../../../contexts/ToastContext';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_SURVEY } from '../../../graphql/schema/query/admin/survey/UPDATE_SURVEY';
import { GET_SURVEY_DETAILS } from '../../../graphql/schema/query/admin/survey/GET_SURVEY_DETAILS';
import moment from 'moment';
import { DELETE_SURVEY_OPTION } from '../../../graphql/schema/query/admin/survey/UPDATE_SURVEY_OPTION';
import { baseFilePaths } from '../../../constants';
import { GET_SURVEY_SUGGESTED_OPTIONS } from '../../../graphql/schema/query/admin/survey/GET_SURVEY_SUGGESTED_OPTIONS';
import LoaderComponent from '../../../components/common/loader/LoaderComponent';

const MerchantSurveyEdit = () => {
  const { setToastDetails } = useContext(ToastContext);

  const { id } = useParams();

  const navigate = useNavigate();
  const [surveyDetails, setSurveyDetails] = useState({});
  const [options, setOptions] = useState([
    {
      option: '',
      logo: null
    }
  ]);

  const localImagesPreview = options?.map((o) => {
    return o?.logo ? URL.createObjectURL(o?.logo) : null;
  });

  const [doAddSurvey, { loading, data, error }] = useMutation(UPDATE_SURVEY);
  const [doGetSurvey, { loading: fetching }] = useLazyQuery(GET_SURVEY_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted: (saved) => {
      setSurveyDetails(saved?.getSurveyForAdmin?.data?.[0]);
      doGetSuggestedOptions();
    }
  });
  const [doGetSuggestedOptions, { data: suggestedOptions }] = useLazyQuery(
    GET_SURVEY_SUGGESTED_OPTIONS,
    {
      variables: {
        surveyId: id
      },
      fetchPolicy: 'network-only',
      onCompleted: () => {
        let totalAnswers = parseInt(surveyDetails?.responses ?? 0, '10');

        const otherOptions = suggestedOptions?.getSuggestedOption?.data;
        // totalAnswers = totalAnswers + otherOptions?.length;

        setSurveyDetails({
          ...surveyDetails,
          otherOptions,
          totalAnswers
        });
      }
    }
  );

  const [doDeleteOption, { loading: deleting, data: deleted }] = useLazyQuery(
    DELETE_SURVEY_OPTION,
    {
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    doGetSuggestedOptions({
      variables: {
        surveyId: id
      }
    });
    doGetSurvey({
      variables: { id }
    });
  }, [id]);

  useEffect(() => {
    if (
      !loading &&
      data?.updateSurvey?.message &&
      data?.updateSurvey?.errorCode &&
      data?.updateSurvey?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: data?.updateSurvey?.message,
        type: 'danger'
      });
    } else {
      if (data?.updateSurvey?.data?._id) {
        setToastDetails({
          show: true,
          title: 'Survey Updated',
          message: 'Survey updated successfully',
          type: 'success'
        });
        navigate('/admin/merchant-survey', { replace: true });
      }
    }
  }, [
    loading,
    error,
    data?.updateSurvey?.errorCode,
    data?.updateSurvey?.message,
    data?.updateSurvey?.data?._id
  ]);

  useEffect(() => {
    if (
      !deleting &&
      deleted?.deleteOption?.message &&
      deleted?.deleteOption?.errorCode &&
      deleted?.deleteOption?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: deleted?.deleteOption?.message,
        type: 'danger'
      });
    } else {
      if (deleted?.deleteOption?.status) {
        setToastDetails({
          show: true,
          title: 'Survey Option Deleted',
          message: 'Survey Option Deleted successfully',
          type: 'success'
        });
        doGetSurvey({
          variables: { id }
        });
      }
    }
  }, [
    deleting,
    error,
    deleted?.deleteOption?.errorCode,
    deleted?.deleteOption?.message,
    deleted?.deleteOption?.status
  ]);

  const updateSurvey = (values) => {
    const options = values?.options?.map((o) => ({
      value: o?.option,
      file: o?.logo,
      _id: o?._id
    }));
    const input = {
      title: values?.title,
      startDate: moment(values?.startDate).startOf('date'),
      endDate: moment(values?.endDate).endOf('date'),
      question: values?.question,
      option: options,
      _id: id
    };

    doAddSurvey({
      variables: { input }
    });
  };

  const isActive = moment(surveyDetails?.startDate)?.isSameOrBefore(moment());

  return (
    <div className="merchantsurvey-create-edit-wrapper">
      {fetching || loading || deleting ? <LoaderComponent /> : null}
      {surveyDetails?._id ? (
        <Formik
          initialValues={{
            ...initValues,
            title: surveyDetails?.title,
            endDate: moment(surveyDetails?.endDate).toDate(),
            startDate: moment(surveyDetails?.startDate).toDate(),
            question: surveyDetails?.question,
            options: surveyDetails?.option?.map((o) => {
              return {
                option: o?.value,
                savedLogo: o?.logoUrl ? `${baseFilePaths.survey}${o?.logoUrl}` : o?.logoUrl,
                _id: o?._id
              };
            })
          }}
          validationSchema={surveyDetails?._id ? surveySchemaEdit : surveySchema}
          onCancel={() => {
            navigate('/admin/merchant-survey', { replace: true });
          }}
          onSubmit={updateSurvey}>
          {({ values, handleSubmit, handleChange, errors, touched, setFieldValue }) => {
            return (
              <FormWrapper
                handleSubmit={handleSubmit}
                onCancel={() => {
                  navigate('/admin/merchant-survey', { replace: true });
                }}>
                <SurveyResult surveyDetails={surveyDetails} id={id} />
                <hr />

                <SurveySettings
                  isActive={isActive}
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  init={{
                    startDate: moment(surveyDetails?.startDate).toDate()
                  }}
                />
                <hr />
                <SurveyContent
                  isActive={isActive}
                  values={values}
                  localImagesPreview={localImagesPreview}
                  setOptions={setOptions}
                  options={options}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  doDeleteOption={(id) => {
                    doDeleteOption({
                      variables: { id }
                    });
                  }}
                />
                <ErrorFocus />
              </FormWrapper>
            );
          }}
        </Formik>
      ) : null}
    </div>
  );
};

export default withAdmin(MerchantSurveyEdit);
