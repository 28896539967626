import moment from 'moment';
import React from 'react';
import { Image } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Images from '../../../constants/images';
import './styles.css';

const OffCanvas = (props) => {
  const renderNotification = (notification, index) => {
    const duration = moment.duration(moment().diff(notification?.date));
    const data = {
      title: '',
      icon: '',
      name: '',
      time: `${
        duration.asDays() > 1
          ? moment(notification?.date).format('MMM DD, YYYY hh:mm a')
          : `${duration.humanize()} ago`
      }`
    };

    if (notification?.newMerchant && notification?.newMerchant != null) {
      const name = `${notification?.newMerchant?.firstname ?? ''} ${
        notification?.newMerchant?.lastname ?? ''
      }`;

      data.title = `New Merchant ${name || ''} Joined ${
        notification?.newMerchant?.business_data?.businessName
          ? ` to ${notification?.newMerchant?.business_data?.businessName}, `
          : ''
      } ${notification?.newMerchant?.business_data?.city || ''} !!`;
      data.icon = Images.notification_new;
    }

    if (notification?.completedSurvey && notification?.completedSurvey != null) {
      data.title = `The survey ${notification?.completedSurvey.title} is completed!!`;
      data.icon = Images.notification_survey;
    }

    if (notification?.newSuggestedBusiness && notification?.newSuggestedBusiness != null) {
      data.title = `New Suggested Business ${notification?.newSuggestedBusiness.businessName} !!`;
      data.icon = Images.notification_business;
    }

    return (
      <div className="list-detail" key={index}>
        <div className="other-name">{data.title}</div>
        <div className="user-info">
          <div className="pic">
            <Image src={data.icon} />
          </div>
        </div>
        <div className="time">
          <span>{data.time}</span>
        </div>
        {!notification.read && (
          <div className="new-notification">
            <div>{props.new_notification}</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Offcanvas {...props} className={`off-canvas ${props.className}`}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="title">{props.title}</div>
            <div className="sub-title">{props.subtitle}</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {props.contentType === 'survey-participants' ? (
            props.users?.map?.((user) => {
              return (
                <div className="list-detail mb-3" key={user?._id}>
                  <div className="other-name">{user.option}</div>
                  <div className="user-info">
                    <div className="pic">
                      <Image src={user?.image} />
                    </div>
                    <div className="name">
                      <span>{user.name}</span>
                    </div>
                  </div>
                  <div className="time">
                    <span>{props.time}</span>
                  </div>
                </div>
              );
            })
          ) : (
            <>
              {props?.notifications
                ?.sort(
                  (a, b) => moment(b.date).toDate().getTime() - moment(a.date).toDate().getTime()
                )
                ?.map?.((notification, index) => renderNotification(notification, index))}
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default OffCanvas;
