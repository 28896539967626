import { useLazyQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';

import withMerchantAdmin from '../../../../components/hoc/merchant/withMerchantAdmin';

import FormWrapper from '../../../../components/common/form-wrapper/FormWrapper';
import MerchantFormBusinessDetails from '../../../../components/merchant/profile-form-sections/MerchantFormBusinessDetails';
import MerchantFormImagesAndVideo from '../../../../components/merchant/profile-form-sections/MerchantFormImagesAndVideo';
import MerchantFormLocationDetails from '../../../../components/merchant/profile-form-sections/MerchantFormLocationDetails';
import MerchantFormOpeningHoursDetails from '../../../../components/merchant/profile-form-sections/MerchantFormOpeningHoursDetails';

import ToastContext from '../../../../contexts/ToastContext';

import { BusinessInfoInitValues, BusinessInfoSchema } from './formUtils';

import { UPLOAD_BUSINESS_BANNER } from '../../../../graphql/schema/mutations/merchant/UPLOAD_BUSINESS_BANNER';
import { UPLOAD_BUSINESS_LOGO } from '../../../../graphql/schema/mutations/merchant/UPLOAD_BUSINESS_LOGO';
import { MERCHANT_ADD_BUSINESS } from '../../../../graphql/schema/mutations/onboarding/MERCHANT_ADD_BUSINESS';
import { GET_BUSINESS_DETAILS } from '../../../../graphql/schema/query/users/GET_BUSINESS_DETAILS';
import './styles.css';
import { DELETE_BUSINESS_BANNER } from '../../../../graphql/schema/mutations/merchant/DELETE_BUSINESS_BANNER';
import { UserContext } from '../../../../contexts';
import ErrorFocus from '../../../../components/common/ErrorFocus/ErrorFocus';
import AppAlert from '../../../../components/common/alerts/AppAlerts';
import { MERCHANT_APPLICATION_SETTINGS } from '../../../../constants';
import LoaderComponent from '../../../../components/common/loader/LoaderComponent';
import { sanitizeInput } from '../merchant-account/formUtils';
const cacheBuster = Math.random();
const MerchantSettingBusinessProfile = () => {
  const imageProgressRef = useRef();
  const { setToastDetails } = useContext(ToastContext);
  const { setUserDetails, details: userData } = useContext(UserContext);

  const [doGetDetails, { loading, data }] = useLazyQuery(GET_BUSINESS_DETAILS, {
    fetchPolicy: 'network-only'
  });

  const [doAddBusiness, { loading: saving, error, data: saved }] =
    useMutation(MERCHANT_ADD_BUSINESS);

  const [doUploadLogo, { loading: uploading, error: uploadError, data: logoSaved }] =
    useMutation(UPLOAD_BUSINESS_LOGO);

  const [doDeleteBusinessBanner, { loading: deleting, error: deleteError, data: deleted }] =
    useMutation(DELETE_BUSINESS_BANNER);

  const [
    doUploadBusinessBanner,
    { loading: uploadingBanner, error: uploadBannerError, data: uploadBannerData }
  ] = useMutation(UPLOAD_BUSINESS_BANNER, {
    onCompleted: () => {
      setSelectedBanners([]);
    },
    context: {
      fetchOptions: {
        useUpload: true,
        onProgress: (ev) => {
          imageProgressRef?.current(ev.loaded / ev.total);
        },
        onAbortPossible: (abortHandler) => {
          console.log({ abortHandler });
        }
      }
    }
  });

  const [selectedProfilePage, setSelectedProfilePage] = useState();
  const [selectedBanners, setSelectedBanners] = useState([]);
  const [profileImageCache, setProfileImageCache] = useState(cacheBuster);

  useEffect(() => {
    doGetDetails();
  }, []);

  const saveBusiness = (values) => {
    if (!values?.businessLogo && !values.logoUrl && !selectedProfilePage) {
      setToastDetails({
        show: true,
        title: 'Error',
        message: 'Please select logo image.',
        type: 'danger'
      });
      return;
    }

    const inputs = {
      businessName: values?.businessName,
      address: values?.address,
      apartmentNo: values?.apartmentNo,
      city: values?.city,
      zipcode: values?.zipcode,
      website: values?.website
        ? !values?.website?.startsWith('http')
          ? `https://${values?.website}`
          : values?.website
        : '',
      ...(values?.email && { email: values?.email }),
      category_id: values?.category_id?._id ?? values?.category_id,
      country_id: values?.country_id?._id ?? values?.country_id,
      state_id: values?.state_id?._id ?? values?.state_id,
      subCategory_id: values?.subCategory_id?._id ?? values?.subCategory_id,
      socialUrl: values?.socialUrl
        ? !values?.socialUrl?.startsWith('http')
          ? `https://${values?.socialUrl}`
          : values?.socialUrl
        : '',
      phoneno: sanitizeInput(values.phoneno),
      timings: values.timings,
      longitude: String(values?.longitude || ''),
      latitude: String(values?.latitude || ''),
      businessDescription: values?.businessDescription
    };

    doAddBusiness({
      variables: {
        input: inputs
      }
    });
  };

  useEffect(() => {
    if (
      !loading &&
      saved?.updateBusiness?.message &&
      saved?.updateBusiness?.errorCode &&
      saved?.updateBusiness?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: saved?.updateBusiness?.message,
        type: 'danger'
      });
    } else {
      if (saved?.updateBusiness?.business?._id) {
        setUserDetails();

        setToastDetails({
          show: true,
          title: 'Business Updated',
          message: 'Business details saved successfully.',
          type: 'success'
        });

        if (selectedProfilePage) {
          doUploadLogo({
            variables: {
              file: selectedProfilePage
            }
          });
          setSelectedProfilePage();
        }

        if (selectedBanners?.length > 0 && !uploadingBanner) {
          doUploadBusinessBanner({
            variables: {
              file: selectedBanners
            }
          });
        }
      }
    }
  }, [
    loading,
    error,
    saved?.updateBusiness?.errorCode,
    saved?.updateBusiness?.message,
    saved?.updateBusiness?.business?._id
  ]);

  useEffect(() => {
    if (!uploadingBanner && uploadBannerError) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: 'Something went wrong while uploading banner!, Please try again after sometime',
        type: 'danger'
      });
      setSelectedBanners([]);
    }
    if (
      !uploadingBanner &&
      uploadBannerData?.uploadBannerImageAndVideo?.message &&
      uploadBannerData?.uploadBannerImageAndVideo?.errorCode &&
      uploadBannerData?.uploadBannerImageAndVideo?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: uploadBannerData?.uploadBannerImageAndVideo?.message,
        type: 'danger'
      });
      setSelectedBanners([]);
    } else {
      if (uploadBannerData?.uploadBannerImageAndVideo?.business?.business_data?.bannerImages) {
        setSelectedBanners([]);
        doGetDetails();
      }
    }
  }, [
    uploadingBanner,
    uploadBannerError,
    uploadBannerData?.uploadBannerImageAndVideo?.errorCode,
    uploadBannerData?.uploadBannerImageAndVideo?.message,
    uploadBannerData?.uploadBannerImageAndVideo?.business?._id
  ]);

  useEffect(() => {
    if (!uploading && uploadError) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: 'Something went wrong while uploading logo!, Please try again after sometime',
        type: 'danger'
      });
    }
    if (
      !uploading &&
      logoSaved?.uploadBusinessLogo?.message &&
      logoSaved?.uploadBusinessLogo?.errorCode &&
      logoSaved?.uploadBusinessLogo?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: logoSaved?.uploadBusinessLogo?.message,
        type: 'danger'
      });
    } else {
      if (logoSaved?.uploadBusinessLogo?.business?.business_data?.businessLogo) {
        setProfileImageCache(Math.random());
        doGetDetails();
      }
    }
  }, [
    uploading,
    uploadError,
    logoSaved?.uploadBusinessLogo?.errorCode,
    logoSaved?.uploadBusinessLogo?.message,
    logoSaved?.uploadBusinessLogo?.business?._id
  ]);

  useEffect(() => {
    if (!deleting && deleteError) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: 'Something went wrong!, Please try again after sometime',
        type: 'danger'
      });
    }
    if (
      !deleting &&
      deleted?.deleteBannerImageAndVideo?.message &&
      deleted?.deleteBannerImageAndVideo?.errorCode &&
      deleted?.deleteBannerImageAndVideo?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: deleted?.deleteBannerImageAndVideo?.message,
        type: 'danger'
      });
    } else {
      if (deleted?.deleteBannerImageAndVideo?.status) {
        setToastDetails({
          show: true,
          title: 'Banner deleted',
          message: 'Business Banner deleted successfully.',
          type: 'success'
        });
        doGetDetails();
      }
    }
  }, [
    deleting,
    deleteError,
    deleted?.deleteBannerImageAndVideo?.errorCode,
    deleted?.deleteBannerImageAndVideo?.message,
    deleted?.deleteBannerImageAndVideo?.status
  ]);

  const details = data?.getBusinessDetail?.businessDetail?.[0].business_data;
  if (details && !details?.email) {
    details.email = data?.getBusinessDetail?.businessDetail?.[0]?.email;
  }
  let completed = 0;

  if (
    (details?.businessLogo || details?.logoUrl) &&
    details?.bannerImages?.length > 0 &&
    details?.socialUrl
  ) {
    completed += 25;
  }

  if (
    details?.businessName &&
    details?.phoneno &&
    details?.businessDescription &&
    details?.website &&
    details?.email &&
    details?.website &&
    details?.category_id?._id &&
    details?.subCategory_id?._id
  ) {
    completed += 25;
  }

  if (
    details?.address &&
    details?.apartmentNo &&
    details?.city &&
    details?.state_id?._id &&
    details?.zipcode
  ) {
    completed += 25;
  }

  if (details?.timings?.length > 0) {
    completed += 25;
  }

  if (loading) {
    return <LoaderComponent />;
  }

  const timings = details?.timings?.length > 0 ? details?.timings : BusinessInfoInitValues.timings;

  const status = userData?.me?.merchant?.business_data?.businessStatus;

  const isProfileCompleted = status === MERCHANT_APPLICATION_SETTINGS.approved;

  return (
    <>
      {saving ? <LoaderComponent /> : null}
      <Formik
        initialValues={{
          ...BusinessInfoInitValues,
          ...details,
          category_id: details?.category_id?._id,
          subCategory_id: details?.subCategory_id?._id,
          state_id: details?.state_id?._id,
          timings,
          _id: undefined,
          __typename: undefined
        }}
        validationSchema={BusinessInfoSchema}
        onSubmit={saveBusiness}>
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleSubmit,
          setFieldTouched,
          setFieldError
        }) => {
          return (
            <div className="business-profile-card">
              <FormWrapper loading={saving} handleSubmit={handleSubmit} showCancel={false}>
                <div className="progress-spacing">
                  {completed !== 100 && !isProfileCompleted ? (
                    <div className="p-4 progress-wrapper">
                      <span className="form-subtitle text-white fonts-regular">
                        Profile Completion
                      </span>

                      <ProgressBar
                        now={completed}
                        className={completed === 100 && 'bg-green-progress'}
                      />
                      <span className="progress-value">{`${completed}%`}</span>
                      {completed === 100 ? (
                        <AppAlert
                          className="w-100 mt-2 mb-0 border-radius-20"
                          show={!isProfileCompleted}
                          message="Your profile is complete but you still need to submit a merchant application."
                          variant={'warning'}
                          rightIcon
                          rightIconText="Get merchant account"
                          to="/merchant/settings/merchant-account-status"
                        />
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <MerchantFormImagesAndVideo
                  imageProgressRef={imageProgressRef}
                  loading={uploadingBanner}
                  deleting={deleting}
                  setToastDetails={setToastDetails}
                  setSelectedBanners={setSelectedBanners}
                  selectedBanners={selectedBanners}
                  setSelectedProfilePage={setSelectedProfilePage}
                  selectedProfilePage={selectedProfilePage}
                  doUploadLogo={doUploadLogo}
                  doUploadBusinessBanner={doUploadBusinessBanner}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  values={values}
                  touched={touched}
                  errors={errors}
                  profileImageCache={profileImageCache}
                  doDeleteBusinessBanner={(filename) => {
                    doDeleteBusinessBanner({
                      variables: {
                        input: {
                          filename
                        }
                      }
                    });
                  }}
                />
                <hr />
                <MerchantFormBusinessDetails
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldTouched={setFieldTouched}
                  setFieldError={setFieldError}
                />
                <hr />
                <MerchantFormLocationDetails
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  values={values}
                  touched={touched}
                  errors={errors}
                />
                <hr />
                <MerchantFormOpeningHoursDetails
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  values={values}
                  touched={touched}
                  errors={errors}
                  timings={timings}
                />
                <ErrorFocus />
              </FormWrapper>
            </div>
          );
        }}
      </Formik>
    </>
  );
};
export default withMerchantAdmin(MerchantSettingBusinessProfile);
