import { gql } from '@apollo/client';

export const ACCEPT_TERMS = gql`
  query Query {
    termsAndConditionStatus {
      status
      message
      user {
        _id
      }
      errorCode
    }
  }
`;
