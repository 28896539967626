import { gql } from '@apollo/client';

export const GET_SURVEY_DETAILS = gql`
  query GetSurveyForAdmin($id: ID, $pdf: Boolean) {
    getSurveyForAdmin(_id: $id, pdf: $pdf) {
      status
      message
      data {
        _id
        title
        startDate
        endDate
        question
        responses
        option {
          _id
          user_id {
            _id
            email
            firstname
            lastname
          }
          value
          logoUrl
        }
      }
      pdfData
      errorCode
    }
  }
`;
