export function convertArrayOfObjectsToCSV(array, columns, keyList) {
  let result;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = columns;
  const ls = keyList;

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    ls.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      result += `"${item[key].toString()}"`;

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

export function downloadCSV(array, columns, ls, filename) {
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(array, columns, ls);
  if (csv == null) return;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}

export function downloadPDFFile(pdfData, filename) {
  const arr = new Uint8Array(pdfData);
  const file = new Blob([arr], {
    type: 'application/pdf'
  });
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.setAttribute('href', fileURL);
  link.setAttribute('download', filename);
  link.click();
}
