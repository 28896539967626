const Images = {
  right_arrow: '/assets/images/right_arrow.png',
  plus: '/assets/images/plus.png',
  dollar: '/assets/images/dollar.png',
  percentage: '/assets/images/percentage.png',
  delete_confirm: '/assets/images/delete-confirm.png',
  edit_sm: '/assets/images/edit-sm.png',
  delete_sm: '/assets/images/delete-sm.png',
  upload_icon: '/assets/images/upload-icon.png',
  logo: '/assets/images/logo.svg',
  logo_with_name: '/assets/images/logo_with_name.svg',
  progress_incomplete: '/assets/images/progress_incomplete.png',
  progress_complete: '/assets/images/progress_complete.png',
  edit: '/assets/images/edit.png',
  home: '/assets/images/home.png',
  deals: '/assets/images/deals.png',
  tx_history: '/assets/images/tx_history.png',
  settings_active: '/assets/images/settings_active.png',
  default_avatar: '/assets/images/default_avatar.png',
  logout: '/assets/images/logout.png',
  question: '/assets/images/question.png',
  deal_1: '/assets/images/deal_1.png',
  success_icon: '/assets/images/success_icon.png',
  arrow_right: '/assets/images/arrow_right.png',
  cheque_img: '/assets/images/cheque_img.png',
  eye_cross: '/assets/images/eye_cross.png',
  eye: '/assets/images/eye.png',
  current_step: '/assets/images/current_step.svg',
  featured_icon: '/assets/images/featured_icon.svg',
  check_circle: '/assets/images/check-circle.svg',
  breadcrum_home: '/assets/images/breadcrum-home.svg',
  notification_bell: '/assets/images/notification-bell.svg',
  mail: '/assets/images/mail.png',
  phone: '/assets/images/phone.png',
  play: '/assets/images/play.png',
  calendar: '/assets/images/calendar.png',
  DashboardActive: '/assets/images/Dashboard Active.svg',
  DashboardInactive: '/assets/images/Dashboard Inacive.svg',
  DealInactive: '/assets/images/Deal Inactive.svg',
  DealsActive: '/assets/images/Deals Active.svg',
  SettingsActive: '/assets/images/Settings Active.svg',
  SettingsInactive: '/assets/images/Settings Inactive.svg',
  TransactionHistoryActive: '/assets/images/Transaction History Active.svg',
  TransactionHistoryInactive: '/assets/images/Transaction History Inactive.svg',
  deal_info_dummy_image: '/assets/images/dashboard-top-dummy.png',
  dashboard_logo: '/assets/images/dashboard-logo-contain.png',
  dashboard_edit: '/assets/images/dashboard-edit.svg',
  location: '/assets/images/location-pin.svg',
  dashboard_phone: '/assets/images/phone.svg',
  website: '/assets/images/website.svg',
  notification_box_icon: '/assets/images/notification-box-icon.svg',
  performance_box_icon: '/assets/images/performance-box-icon.svg',
  active_deal_box_icon: '/assets/images/active-deal-box-icon.svg',
  no_data_notification: '/assets/images/blank-notification.svg',
  no_data_active_deal: '/assets/images/blank-active-deal.svg',
  tag: '/assets/images/tag.svg',
  view_all: '/assets/images/view-all-link.svg',
  right_img: '/assets/images/right.svg',
  wrong_img: '/assets/images/wrong.svg',
  three_dots: '/assets/images/three-dots.svg',
  up: '/assets/images/up.svg',
  down: '/assets/images/down.svg',
  person: '/assets/images/person.svg',
  done_check: '/assets/images/done_check.svg',
  close: '/assets/images/close.svg',
  transaction_history: '/assets/images/transaction-history.png',
  refresh: '/assets/images/refresh.png',
  filter_modal_icon: '/assets/images/filter-modal-icon.png',
  user_pause: '/assets/images/pause-user.svg',
  user_pause_frozen: '/assets/images/pause-user-frozen.svg',
  user_play: '/assets/images/play-circle.svg',
  user_delete: '/assets/images/delete-user.svg',
  user_edit: '/assets/images/edit-user.svg',
  user_list: '/assets/images/user-list.png',
  wallet: '/assets/images/wallet.svg',
  piggy_bank: '/assets/images/piggy_bank.svg',
  merchant_profile: '/assets/images/merchant-profile.png',
  UserManagementActive: '/assets/images/user_management_active.svg',
  UserManagementDeactive: '/assets/images/user_management_deactive.svg',
  MerchantManagementActive: '/assets/images/merchant_management_active.svg',
  MerchantManagementDeactive: '/assets/images/merchant_management_deactive.svg',
  MerchantSurveyActive: '/assets/images/merchant_survey_active.svg',
  MerchantSurveyDeactive: '/assets/images/merchant_survey_deactive.svg',
  MarketplaceActive: '/assets/images/marketplace_active.svg',
  MarketplaceDeactive: '/assets/images/marketplace_deactive.svg',
  SuggestedBusinessActive: '/assets/images/suggested_business_active.svg',
  SuggestedBusinessDeactive: '/assets/images/suggested_business_deactive.svg',
  ProfileSettingActive: '/assets/images/profile_setting_active.svg',
  ProfileSettingDeactive: '/assets/images/profile_setting_deactive.svg',
  merchant_management_icon: '/assets/images/merchant-management.png',
  suggested_business_icon: '/assets/images/suggested-business.png',
  admin_logo: '/assets/images/admin-logo.png',
  close_light: '/assets/images/close-light.svg',
  dominos_logo: '/assets/images/dominos-logo.png',
  avtar: '/assets/images/avtar.png',
  'Automotive & Marine': '/assets/images/categories/Automotive & Marine.svg',
  'Bar & Nightlife': '/assets/images/categories/Bar & Nightlife.svg',
  'Beauty & Spas': '/assets/images/categories/Beauty & Spas.svg',
  'Contractor & Home Services': '/assets/images/categories/Contractor & Home Services.svg',
  Education: '/assets/images/categories/Education.svg',
  'Event & Planning Services': '/assets/images/categories/Event & Planning Services.svg',
  'Health & Medical': '/assets/images/categories/Health & Medical.svg',
  'Hotels & Travel': '/assets/images/categories/Hotels & Travel.svg',
  'Local Services & Repair': '/assets/images/categories/Local Services & Repair.svg',
  'Professional Services': '/assets/images/categories/Professional Services.svg',
  'Real Estate': '/assets/images/categories/Real Estate.svg',
  'Restaurants & Food Services': '/assets/images/categories/Restaurants & Food Services.svg',
  'Retail and Online Shopping': '/assets/images/categories/Retail and Online Shopping.svg',
  'Arts & Entertainment': '/assets/images/categories/Arts & Entertainment.svg',
  'Activity & Leisure Entertainment':
    '/assets/images/categories/Activity & Leisure Entertainment.svg',
  'Pet Supplies & Veterinary': '/assets/images/categories/Pet Supplies & Veterinary.svg',
  notification_new: '/assets/images/notification_new.png',
  notification_business: '/assets/images/notification_business.png',
  notification_survey: '/assets/images/notification_survey.png',
  upload_modal_icon: '/assets/images/upload_modal_icon.png',
  editOutline: '/assets/images/editOutline.png',
  deleteOutline: '/assets/images/delteOutline.png',
  refundGreen: '/assets/images/refund_green.png'
};
export default Images;
