import { useMutation, useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Images from '../../../constants/images';
import { GET_SUB_CATEGORIES } from '../../../graphql/schema/mutations/categories/GET_SUB_CATEGORIES';
import { GET_CATEGORIES } from '../../../graphql/schema/query/masters/GET_CATEGORIES';
import SelectInput from '../../common/select-input/SelectInput';
import TextInput from '../../common/text-input/TextInput';
const maxDescriptionChar = 250;

const MerchantFormBusinessDetails = ({
  handleChange,
  setFieldValue,
  values,
  touched,
  errors,
  setFieldTouched,
  setFieldError,
  isAdmin
}) => {
  const [categoryData, setCategoryData] = useState([]);

  const [doGetCategories] = useLazyQuery(GET_CATEGORIES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.category?.data?.find((d) => d?.name === null)) {
        return;
      }
      setCategoryData(data?.category?.data);
    }
  });

  const [doGetSubCategories, { data: subCategories }] = useMutation(GET_SUB_CATEGORIES);

  useEffect(() => {
    doGetCategories();
  }, []);

  useEffect(() => {
    if (values.category_id || values.category_id?._id) {
      doGetSubCategories({
        variables: {
          input: {
            refId: values.category_id?._id ?? values.category_id
          }
        }
      });
    }
  }, [values.category_id, values.category_id?._id]);

  console.log({ values });
  return (
    <div className="wrapper-padding">
      <p className="form-title mb-0 fonts-quicksand-medium">Business information</p>
      <span className="form-subtitle fonts-regular">Merchant&apos;s Business Details.</span>
      <Row className="mt-2">
        <Col md={6}>
          <TextInput
            label="Business Name"
            placeholder="Business Name"
            value={values.businessName}
            name="businessName"
            error={touched.businessName && errors.businessName}
            onChange={handleChange}
            id="merchantFormBusinessDetails.businessName"
          />
        </Col>
        <Col md={6}>
          <TextInput
            label="Contact Number"
            placeholder="Contact Number"
            value={values.phoneno}
            name="phoneno"
            error={touched.phoneno && errors.phoneno}
            onChange={handleChange}
            leftText="+1"
            // leftIcon={Images.phone}
            id="merchantFormBusinessDetails.contactNo"
            maskingProps={{
              mask: '999-999-9999',
              maskChar: ' '
            }}
          />
        </Col>
        <Col md={12}>
          <TextInput
            value={values.businessDescription}
            placeholder="Describe your business"
            name="businessDescription"
            error={touched.businessDescription && errors.businessDescription}
            as="textarea"
            rows={7}
            id="merchantFormBusinessDetails.description"
            label={`Business Description (max ${maxDescriptionChar} characters)`}
            onChange={(e) => {
              setFieldValue(
                'businessDescription',
                String(e.target?.value).slice(0, maxDescriptionChar)
              );
              if (String(e.target?.value).length > maxDescriptionChar) {
                setFieldTouched('businessDescription', true);
                setFieldError(
                  'businessDescription',
                  `Description should be of ${maxDescriptionChar} characters`
                );
              }
            }}
          />
          {String(values.businessDescription).length > 0 &&
          String(values.businessDescription).length <= maxDescriptionChar ? (
            <span className="float-end mt-1">
              {maxDescriptionChar - String(values.businessDescription).length}
            </span>
          ) : null}
        </Col>
        <Col md={12}>
          <TextInput
            label="Website"
            placeholder="Website"
            value={values.website}
            name="website"
            error={touched.website && errors.website}
            onChange={handleChange}
            leftText="www."
            id="merchantFormBusinessDetails.website"
          />
        </Col>
        <Col md={6}>
          <TextInput
            label="Email address"
            placeholder="Email address"
            value={values.email}
            name="email"
            error={touched.email && errors.email}
            onChange={handleChange}
            leftIcon={Images.mail}
            id="merchantFormBusinessDetails.email"
          />
        </Col>
        <Col md={6}>
          <TextInput
            label="Contact number"
            placeholder="Contact Number"
            name="phoneno"
            value={values.phoneno}
            error={touched.phoneno && errors.phoneno}
            onChange={handleChange}
            leftIcon={Images.phone}
            id="merchantFormBusinessDetails.phoneno"
            maskingProps={{
              mask: '999-999-9999',
              maskChar: ' '
            }}
          />
        </Col>

        <Col md={6}>
          <SelectInput
            label="Category"
            placeholder="Select Category"
            options={Array.isArray(categoryData) ? categoryData : []}
            value={values.category_id?._id ?? values.category_id}
            error={touched.category_id && errors.category_id}
            onChange={(value) => {
              setFieldValue('category_id', value);
              setFieldValue('subCategory_id', '');
            }}
            name="category_id"
            id="merchantFormBusinessDetails.categoryId"
          />
        </Col>
        <Col md={6}>
          <SelectInput
            parentId={categoryData?.find((d) => d?._id === values.category_id)?.name}
            label="Sub Category"
            placeholder="Select Sub Category"
            options={
              Array.isArray(subCategories?.getSubCategory?.data)
                ? subCategories?.getSubCategory?.data
                : []
            }
            value={values.subCategory_id?._id ?? values.subCategory_id}
            error={touched.subCategory_id && errors.subCategory_id}
            onChange={(value) => {
              setFieldValue('subCategory_id', value);
            }}
            name="subCategory_id"
            id="merchantFormBusinessDetails.subCategoryId"
          />
        </Col>
        {isAdmin ? (
          <>
            <Col md={6}>
              <TextInput
                label="EPI ID"
                placeholder="EPI ID"
                value={values.epxID}
                name="epxID"
                error={touched.epxID && errors.epxID}
                onChange={handleChange}
                id="merchantFormBusinessDetails.epxID"
              />
            </Col>
            <Col md={6}>
              <TextInput
                readOnly
                disabled
                label="MID"
                placeholder="MID"
                value={values.epxMID}
                name="epxMID"
                error={touched.epxMID && errors.epxMID}
                id="merchantFormBusinessDetails.epxMID"
              />
            </Col>
          </>
        ) : null}
      </Row>
    </div>
  );
};

export default MerchantFormBusinessDetails;
