import React from 'react';
import { Button } from 'react-bootstrap';
import './styles.css';

const AppButton = ({
  title,
  onClick = () => null,
  width = 'w-100',
  disabled,
  containerClass,
  buttonClass,
  style,
  id,
  type
}) => {
  return (
    <div className={`mt-2 ${containerClass}`}>
      <Button
        type={type}
        id={id}
        className={`app-button ${width} ${buttonClass}`}
        title={title}
        onClick={onClick}
        disabled={disabled}
        style={style}>
        {title}
      </Button>
    </div>
  );
};

export default AppButton;
