import moment from 'moment';
import * as Yup from 'yup';

export const dealsSchema = Yup.object().shape({
  name: Yup.string().typeError('Please enter name').required('Please enter name'),
  decription: Yup.string()
    .typeError('Please enter description')
    .max(150, 'Description should be of 150 characters')
    .required('Please enter description'),
  price: Yup.number()
    .typeError('Please enter price')
    .min(0, 'Please enter price')
    .required('Please enter price'),
  discountType: Yup.string()
    .typeError('Please select Discount Type')
    .required('Please select Discount Type'),
  discountValue: Yup.number().when(['discountType', 'price'], (discountType, price) => {
    if (discountType === 'percent') {
      return Yup.number()
        .typeError('Please enter Discount Value')
        .min(0, 'Please enter Discount Value')
        .max(99.99, 'Please enter discount percentage less than 100')
        .required('Please enter Discount Value');
    }
    return Yup.number()
      .typeError('Please enter Discount Value')
      .min(0, 'Please enter Discount Value')
      .max(price - 0.1, `Please enter discount price less than RRP ${price}`)
      .required('Please enter Discount Value');
  }),
  startDate: Yup.date()
    .typeError('Please enter start date')
    .test('minDateToday', 'Please enter valid date', (item) => {
      return moment(item).isSameOrAfter(moment(), 'd');
    })
    .required('Please enter start date'),
  endDate: Yup.date()
    .typeError('Please enter end date')
    .test('minDateToday', 'Please enter valid date', (item) => {
      return moment(item).isSameOrAfter(moment(), 'd');
    })
    .min(Yup.ref('startDate'), "End date can't be before start date")
    .required('Please enter end date')
});

export const dealsSchemaUpdate = Yup.object().shape({
  name: Yup.string().typeError('Please enter name').required('Please enter name'),
  decription: Yup.string()
    .typeError('Please enter description')
    .max(150, 'Description should be of 150 characters')
    .required('Please enter description'),
  price: Yup.number()
    .typeError('Please enter price')
    .min(0, 'Please enter price')
    .required('Please enter price'),
  discountType: Yup.string()
    .typeError('Please select Discount Type')
    .required('Please select Discount Type'),
  discountValue: Yup.number().when(['discountType', 'price'], (discountType, price) => {
    if (discountType === 'percent') {
      return Yup.number()
        .typeError('Please enter Discount Value')
        .min(0, 'Please enter Discount Value')
        .max(99.99, 'Please enter discount percentage less than 100')
        .required('Please enter Discount Value');
    }
    return Yup.number()
      .typeError('Please enter Discount Value')
      .min(0, 'Please enter Discount Value')
      .max(price - 0.1, `Please enter discount price less than RRP ${price}`)
      .required('Please enter Discount Value');
  }),
  startDate: Yup.date().typeError('Please enter start date').required('Please enter start date'),
  endDate: Yup.date()
    .typeError('Please enter end date')
    .test('minDateToday', 'Please enter valid date', (item) => {
      return moment(item).isSameOrAfter(moment(), 'd');
    })
    .min(Yup.ref('startDate'), "End date can't be before start date")
    .required('Please enter end date')
});
export const dealInitValues = {
  name: '',
  decription: '',
  price: '',
  discountType: 'percent',
  discountValue: '',
  startDate: '',
  endDate: '',
  isActive: ''
};
