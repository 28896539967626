import { gql } from '@apollo/client';

export const GET_USER_LIST = gql`
  query GetUsersList(
    $offset: Int
    $limit: Int
    $fixDate: Date
    $filter: filter
    $search: String
    $pdf: Boolean
    $sorting: sorting
  ) {
    getUsersList(
      offset: $offset
      limit: $limit
      fixDate: $fixDate
      filter: $filter
      search: $search
      pdf: $pdf
      sorting: $sorting
    ) {
      status
      message
      user {
        _id
        email
        firstname
        lastname
        gender
        phoneno
        userType
        dob
        isActive
        termsAndConditationStatus
        profilepicture
        password
        createdBy
        createdAt
        updatedAt
        timezone
      }
      total
      limit
      offset
      errorCode
      pdfData
    }
  }
`;
