import { useLazyQuery, useSubscription } from '@apollo/client';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, Image, Modal, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Images from '../../../constants/images';
import { GET_TX_HISTORY } from '../../../graphql/schema/query/admin/transactions/GET_TX_HISTORY';
import { GET_TX_HISTORY_NOTIFICATION_UPDATE } from '../../../graphql/schema/query/admin/transactions/GET_TX_HISTORY_NOTIFICATION_UPDATE';
import APP_SUBSCRIPTIONS from '../../../graphql/schema/subscriptions/Notifications';
import AppButton from '../button/Button';

const NotificationCard = ({ merchantId }) => {
  useSubscription(APP_SUBSCRIPTIONS.DEAL_TRANSACTION_SUBSCRIPTION, {
    variables: {
      merchantId
    },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData?.data) {
        getData();
      }
    }
  });

  const [show, setShow] = useState({});
  const [doGetTxs, { data: txs, loading }] = useLazyQuery(GET_TX_HISTORY, {
    fetchPolicy: 'network-only'
  });

  const [doUpdateNotification, { loading: updating }] = useLazyQuery(
    GET_TX_HISTORY_NOTIFICATION_UPDATE,
    {
      fetchPolicy: 'network-only'
    }
  );

  const getData = () => {
    doGetTxs({
      variables: {
        filter: {
          merchantFullfill: 0,
          purchase: true
        },
        limit: 10
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Card className="notification-card">
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`filter-modal`}
        show={show?._id}
        onHide={() => {
          setShow({});
        }}>
        <Modal.Header closeButton>
          <div className="heading">Purchase Details</div>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 600, overflow: 'hidden', overflowY: 'auto', padding: 12 }}>
          <Row>
            <Col>Customer name: </Col>
            <Col>
              <strong style={{ fontWeight: 'bold' }}>
                {show?.user_id?.firstname} {show?.user_id?.lastname}
                {!show?.user_id?.firstname && !show?.user_id?.lastname && show?.user_id?.email}
              </strong>
            </Col>
          </Row>
          <Row>
            <Col>Order Date: </Col>
            <Col>
              <strong style={{ fontWeight: 'bold' }}>
                {moment(show?.invoiceDate).format('MMM DD - HH:mm')}
              </strong>
            </Col>
          </Row>
          <Row>
            <Col>Order number: </Col>
            <Col>
              <strong style={{ fontWeight: 'bold' }}>{show?.invoiceNo}</strong>
            </Col>
          </Row>
          <Row>
            <Col>Deal name: </Col>
            <Col>
              <strong style={{ fontWeight: 'bold' }}>{show?.deal_id?.name}</strong>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="mt-4">
          <AppButton
            title={'Close'}
            id="filter-cancel-btn"
            onClick={() => {
              setShow({});
            }}></AppButton>
          <AppButton
            style={{
              outline: 'none',
              borderColor: 'transparent',
              color: '#fff'
            }}
            title={'Dismiss'}
            id="filter-result-btn"
            onClick={() => {
              doUpdateNotification({
                variables: {
                  fullfill: -1,
                  tranId: show?._id
                },
                onCompleted: () => {
                  getData();
                }
              });
              setShow({});
            }}
            buttonClass="bg-danger"></AppButton>
        </Modal.Footer>
      </Modal>
      <Card.Header>
        <div className="box-header">
          <Image src={Images.notification_box_icon} />
          <div className="title">
            Purchase Notifications
            {txs?.transactionHistory?.data?.length > 0 ? (
              <span className="active-deal-counter" style={{ background: '#ff8f50' }}>
                {txs?.transactionHistory?.data?.length}
              </span>
            ) : null}
          </div>
        </div>
        <div className="text-end">
          <Link to="/merchant/transaction-history" className="view-all-link">
            <Image src={Images.view_all} />
            View all
          </Link>
        </div>
      </Card.Header>

      <Card.Body>
        <div className="notification-content">
          <Table>
            <thead>
              <tr>
                <th className="dot-th"></th>
                <th className="buyer-th">Buyer</th>
                <th className="value-th">Value</th>
                <th className="action-th text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading || updating ? (
                <div className="d-flex justify-content-center w-100 p-2">
                  <span className="sm-loader"></span>
                </div>
              ) : null}
              {/* when no data is available please set as per required at the time of development */}
              {!loading && txs?.transactionHistory?.data?.length <= 0 ? (
                <tr>
                  <td colSpan={4} className="text-center p-0 border-0">
                    <div className="no-data">
                      <Image src={Images.no_data_notification} />
                      <div className="no-data-content">
                        <h5>No notifications</h5>
                        <span>You’ll receive notifications here</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : null}
              {txs?.transactionHistory?.data
                ?.filter((d) => !d?.merchantFullfill)
                ?.map((tx) => {
                  return (
                    <tr key={tx?._id} style={{ borderBottom: '1px solid #E4E7EC' }}>
                      <td></td>
                      <td>
                        <div className="byuer-name">
                          {tx?.user_id?.firstname} {tx?.user_id?.lastname}
                          {!tx?.user_id?.firstname && !tx?.user_id?.lastname && tx?.user_id?.email}
                        </div>
                        <div>{moment(tx?.invoiceDate).format('MMM DD - HH:mm')}</div>
                      </td>
                      <td>
                        <div className="value">${Number(tx?.total, '').toFixed(2)}</div>
                      </td>
                      <td className="action-items justify-content-center">
                        {!tx?.merchantFullfill ? (
                          <>
                            <div className="redeem">
                              <a
                                role="button"
                                href="javascript:void(0)"
                                onClick={() =>
                                  doUpdateNotification({
                                    variables: {
                                      fullfill: 1,
                                      tranId: tx?._id
                                    },
                                    onCompleted: () => {
                                      getData();
                                    }
                                  })
                                }>
                                <Image src={Images.right_img} />
                                Fulfill
                              </a>
                            </div>
                            <div className="dismiss">
                              <a
                                role="button"
                                href="javascript:void(0)"
                                onClick={() =>
                                  doUpdateNotification({
                                    variables: {
                                      fullfill: -1,
                                      tranId: tx?._id
                                    },
                                    onCompleted: () => {
                                      getData();
                                    }
                                  })
                                }>
                                <Image src={Images.wrong_img} />
                                Dismiss
                              </a>
                            </div>
                          </>
                        ) : null}
                        <div>
                          <a role="button" hreaf="javascript:void(0)" onClick={() => setShow(tx)}>
                            <Image src={Images.three_dots} />
                          </a>
                        </div>
                        <div></div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default NotificationCard;
