import React from 'react';
import { Image, Modal } from 'react-bootstrap';
import './styles.css';

const FeedbackModal = (props) => {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`feedback-modal ${props.className}`}>
      <Modal.Header closeButton>
        <Image src={props.icon} />
      </Modal.Header>
      <Modal.Body>
        <h4 className="fonts-medium">{props.title}</h4>
        <p className="text-secondary fonts-light">{props.subTitle}</p>
      </Modal.Body>
      <Modal.Footer>{props.buttons?.map((button) => button)}</Modal.Footer>
    </Modal>
  );
};
export default FeedbackModal;
