import { gql } from '@apollo/client';

export const UPDATE_MERCHANT_DETAILS = gql`
  mutation UpdateBusinessByAdmin($input: BusinessInputByAdmin!) {
    updateBusinessByAdmin(input: $input) {
      status
      message
      business {
        _id
      }
      errorCode
    }
  }
`;
