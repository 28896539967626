import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import ToastContext from '../../../contexts/ToastContext';

const fnMap = {
  success: toast.success,
  danger: toast.error,
  info: toast.info,
  warning: toast.warn
};
const AppToast = () => {
  const toastContext = useContext(ToastContext);
  const { show, message, title, type, setToastDetails } = toastContext ?? {};

  useEffect(() => {
    if (show) {
      fnMap?.[type]?.(message, {
        onClose: () => {
          setToastDetails({
            show: false,
            title: '',
            type: '',
            message: ''
          });
        }
      });
    }
  }, [show, message, title, type]);
  return null;
};

export default AppToast;
