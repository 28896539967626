import React, { useState } from 'react';
import AppButton from '../../common/button/Button';
import { ProgressBar } from 'react-bootstrap';
import OffCanvas from '../../common/offCanvas/offCanvas';
import { baseFilePaths } from '../../../constants';
import Images from '../../../constants/images';
import { useLazyQuery } from '@apollo/client';
import { GET_SURVEY_DETAILS } from '../../../graphql/schema/query/admin/survey/GET_SURVEY_DETAILS';
import { downloadPDFFile } from '../../../services/downloadUtils';

const SurveyResult = ({ surveyDetails, id }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (option) => setShow(option);

  const allOptions = surveyDetails?.option;

  const [doGetSurveyPdf, { loading: downloading }] = useLazyQuery(GET_SURVEY_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.getSurveyForAdmin?.pdfData?.length) {
        downloadPDFFile(data?.getSurveyForAdmin?.pdfData, surveyDetails?.title);
      }
    }
  });

  const _renderProgressBar = (d, index) => {
    return (
      <div className="progress-bar-items" key={index}>
        <span>{d?.value}</span>
        <div className="progress-bar-wrapper">
          <ProgressBar now={d?.user_id?.length ?? 0} />
          <span
            className="progress-value"
            onClick={() => {
              handleShow({
                title: d?.value,
                total: d?.user_id?.length ?? 0,
                users: d?.user_id?.map((d) => {
                  let name = d?.email;
                  if (d?.firstname) {
                    name = `${d?.firstname || ''} ${d?.lastname || ''}`;
                  }
                  return {
                    name,
                    _id: d?._id,
                    image: d?.profilepicture
                      ? baseFilePaths.userProfileImage + d?.profilepicture
                      : Images.admin_logo,
                    option: d?.otherOption
                  };
                })
              });
            }}>
            {d?.user_id?.length ?? 0}
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="wrapper-padding">
        <div className="top-box-bar">
          <div className="top-heading-title">
            <p className="form-title mb-0 fonts-quicksand-medium">Results</p>
            <span className="form-subtitle fonts-regular">
              Below you will find your survey results.
            </span>
          </div>
          <div className="right-download-btn">
            <AppButton
              type="button"
              title={downloading ? 'Downloading...' : 'Download results'}
              onClick={() => {
                doGetSurveyPdf({
                  variables: {
                    id,
                    pdf: true
                  }
                });
              }}
            />
          </div>
        </div>
        <div className="result-wrapper">
          <div className="result-box">
            <div className="text">Answer</div>
            <div className="result-answer">{surveyDetails?.totalAnswers}</div>
          </div>
          <div className="result-progress-bar">
            {allOptions?.map((d, index) => {
              return _renderProgressBar(d, index);
            })}
            {surveyDetails?.otherOptions?.length > 0
              ? _renderProgressBar(
                  {
                    value: 'Others',
                    user_id: surveyDetails?.otherOptions?.map((d) => {
                      return {
                        ...d?.user_id,
                        otherOption: d?.value
                      };
                    })
                  },
                  allOptions?.length
                )
              : null}
          </div>
        </div>
        <OffCanvas
          show={show}
          onHide={handleClose}
          placement="end"
          title="Result detail"
          subtitle={`${show?.title} - ${show?.total}`}
          othername="Ristorante Pizzeria"
          contentType="survey-participants"
          users={show?.users}
        />
      </div>
    </>
  );
};

export default SurveyResult;
