import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { APP_ROLES } from '../constants';
import { UserContext } from '../contexts';
import { NoAccessPage } from '../pages';
import MerchantDashboardPage from '../pages/merchant/dashboard';
import MerchantSettingBusinessProfile from '../pages/merchant/settings/business-profile';
import MerchantSettingAccount from '../pages/merchant/settings/merchant-account';
import MerchantAccountSettings from '../pages/merchant/settings/account-settings';
import MerchantAccountStatus from '../pages/merchant/settings/merchant-account/account-status';
import MerchantDealsPage from '../pages/merchant/deals';
import MerchantDealsCreate from '../pages/merchant/deals/create';
import MerchantTransactionPage from '../pages/merchant/transaction-history';
import AdminUserManagementList from '../pages/admin/user-management/list';
import AdminUserManagementListEdit from '../pages/admin/user-management/edit';
import AdminMerchantManagementList from '../pages/admin/merchant-management/list';
import AdminMerchantManagementListEdit from '../pages/admin/merchant-management/edit';
import AdminMerchantManagementViewDeal from '../pages/admin/merchant-management/view-deal';
import AdminMerchantCategory from '../pages/admin/merchant-management/category';
import AdminMarketplaceStock from '../pages/admin/marketplace/stock';
import AdminMarketplaceStockCategory from '../pages/admin/marketplace/stock-category';
import AdminMarketplaceTransactions from '../pages/admin/marketplace/transactions';
import AdminSuggestedBusiness from '../pages/admin/suggested-business';
import AdminProfileSettings from '../pages/admin/profile-settings';
import AdminMerchantSurvey from '../pages/admin/merchant-survey';
import AdminMerchantSurveyCreate from '../pages/admin/merchant-survey/create';
import AdminMerchantSurveyEdit from '../pages/admin/merchant-survey/edit';
import {
  BusinessSearchPage,
  SignUpPage,
  SingInPage,
  ForgotPasswordPage,
  EmailVerificationPage,
  NewPasswordPage,
  ResetPasswordPage,
  TermsAndConditionPage
} from '../pages/onboarding';
import { PrivateRoute } from './private';
import { useLazyQuery } from '@apollo/client';
import { GET_ME } from '../graphql/schema/query/users/GET_ME';
import { getLocalStorageItem, setLocalStorageItem } from '../services/localStorageService';

const AppRoutes = () => {
  let savedUserDetails = getLocalStorageItem('userDetails');
  let userJSON = {};
  try {
    userJSON = JSON.parse(savedUserDetails);
  } catch (error) {
    console.log({ error });
  }
  const [details, setUserDetails] = useState(userJSON ?? {});
  const [userSet, setUserSet] = useState(false);
  const [doGetMe, { loading }] = useLazyQuery(GET_ME, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!userSet) {
        setLocalStorageItem('userDetails', JSON.stringify(data));
        setUserSet(true);
        setUserDetails(data);
      }
    }
  });

  useEffect(() => {
    doGetMe();
  }, []);

  const updateUserDetails = () => {
    setUserSet(false);
    doGetMe();
  };

  return (
    <UserContext.Provider
      value={{
        details,
        setUserDetails: updateUserDetails,
        loading
      }}>
      <Router>
        <Routes>
          <Route path="/admin/login" element={<SingInPage />} />
          <Route
            path="/admin"
            element={<PrivateRoute role={APP_ROLES.ADMIN} redirectPath="/admin/login" />}>
            <Route path="/admin/user-management" element={<AdminUserManagementList />} />
            <Route
              path="/admin/user-management/edit/:userName/:id"
              element={<AdminUserManagementListEdit />}
            />
            <Route path="/admin/merchant/management" element={<AdminMerchantManagementList />} />
            <Route
              path="/admin/merchant/management/edit/:merchantName/:merchantId"
              element={<AdminMerchantManagementListEdit />}
            />
            <Route
              path="/admin/merchant/management/view-deal/:businessName/:merchantId"
              element={<AdminMerchantManagementViewDeal />}
            />
            <Route path="/admin/merchant/category" element={<AdminMerchantCategory />} />
            <Route path="/admin/marketplace/stock" element={<AdminMarketplaceStock />} />
            <Route
              path="/admin/marketplace/stock/stock-category/:categoryName/:categoryId"
              element={<AdminMarketplaceStockCategory />}
            />
            <Route
              path="/admin/marketplace/transactions"
              element={<AdminMarketplaceTransactions />}
            />
            <Route path="/admin/suggested-business" element={<AdminSuggestedBusiness />} />
            <Route path="/admin/profile-settings" element={<AdminProfileSettings />} />
            <Route path="/admin/merchant-survey" element={<AdminMerchantSurvey />} />
            <Route path="/admin/merchant-survey/create" element={<AdminMerchantSurveyCreate />} />
            <Route
              path="/admin/merchant-survey/edit/:surveyName/:id"
              element={<AdminMerchantSurveyEdit />}
            />
          </Route>
          <Route
            path="/merchant"
            element={<PrivateRoute role={APP_ROLES.MERCHANT} redirectPath="/login" />}>
            <Route path="/merchant/dashboard" element={<MerchantDashboardPage />} />
            <Route path="/merchant/deals/update/:id" element={<MerchantDealsCreate />} />
            <Route path="/merchant/deals/create" element={<MerchantDealsCreate />} />
            <Route path="/merchant/deals" element={<MerchantDealsPage />} />
            <Route path="/merchant/transaction-history" element={<MerchantTransactionPage />} />
            <Route
              path="/merchant/settings/business-profile"
              element={<MerchantSettingBusinessProfile />}
            />
            <Route
              path="/merchant/settings/merchant-account"
              element={<MerchantSettingAccount />}
            />
            <Route path="/merchant/settings/login-settings" element={<MerchantAccountSettings />} />
            <Route
              path="/merchant/settings/merchant-account-status"
              element={<MerchantAccountStatus />}
            />
          </Route>

          <Route
            path="/onboarding/terms-and-condition"
            element={<PrivateRoute role={APP_ROLES.MERCHANT} redirectPath="/onboarding" />}>
            <Route path="/onboarding/terms-and-condition" element={<TermsAndConditionPage />} />
          </Route>
          <Route
            path="/onboarding/business-search"
            element={<PrivateRoute role={APP_ROLES.MERCHANT} redirectPath="/onboarding" />}>
            <Route path="/onboarding/business-search" element={<BusinessSearchPage />} />
          </Route>

          <Route path="/onboarding" element={<SignUpPage />} />
          <Route path="/login" element={<SingInPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/email-verification" element={<EmailVerificationPage />} />
          <Route path="/forgotpassword/:token" element={<NewPasswordPage />} />
          <Route path="/reset-password-successfully" element={<ResetPasswordPage />} />
          <Route path="/login" element={<NoAccessPage />} />
          <Route path="/" element={<SingInPage />} />
        </Routes>
      </Router>
    </UserContext.Provider>
  );
};

export default AppRoutes;
