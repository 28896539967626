import React, { useState, useEffect } from 'react';

import AppRoutes from './app/routes';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import ToastContext from './app/contexts/ToastContext';
import AppToast from './app/components/common/toasts/AppToast';
import APP_SUBSCRIPTIONS from './app/graphql/schema/subscriptions/Notifications';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { GET_NOTIFICATION } from './app/graphql/schema/query/admin/Notification/GET_NOTIFICATION';
import NotificationContext from './app/contexts/NotificationContext';
import { READ_NOTIFICATION } from './app/graphql/schema/query/admin/Notification/READ_NOTIFICATONS';

function App() {
  const { data, loading } = useSubscription(APP_SUBSCRIPTIONS.MERCHANT_CREATE_SUBSCRIPTION);
  const businessSubscription = useSubscription(APP_SUBSCRIPTIONS.SUGGESTED_BUSINESS_SUBSCRIPTION);

  const [readNotifications] = useMutation(READ_NOTIFICATION);
  const [getNotifications, notificationData] = useLazyQuery(GET_NOTIFICATION, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const unread = data?.adminNotification?.data?.filter?.((d) => !d?.read).map((d) => d?._id);
      if (unread?.length > 0) {
        readNotifications({
          variables: {
            input: {
              id: data?.adminNotification?.data?.filter?.((d) => !d?.read).map((d) => d?._id)
            }
          }
        });
      }
    }
  });

  useEffect(() => {
    if (!loading) {
      if (data && (data['merchant_create'] || data['User_suggestedbusiness'])) {
        getNotifications();
      }
    }
  }, [loading, businessSubscription.loading]);
  const [toastDetails, setToastDetails] = useState({
    show: false,
    title: '',
    type: '',
    message: ''
  });

  const [notificationDetails, setNotificationDetails] = useState({
    newNotificaton: 0
  });

  useEffect(() => {
    if (
      notificationData?.data &&
      notificationData?.data?.adminNotification &&
      !notificationData?.loading
    ) {
      var count = 0;
      notificationData?.data?.adminNotification?.data?.map((item) => {
        if (item.read === false) {
          count++;
        }
      });
      setNotificationDetails({
        newNotificaton: count,
        notifications: notificationData?.data?.adminNotification?.data
      });
    }
  }, [notificationData.data, notificationData.loading]);

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <ToastContext.Provider
      value={{
        ...toastDetails,
        setToastDetails: setToastDetails
      }}>
      <NotificationContext.Provider
        value={{
          ...notificationDetails,
          setNotificationContextData: setNotificationDetails
        }}>
        <AppRoutes />
        <AppToast />
      </NotificationContext.Provider>
    </ToastContext.Provider>
  );
}

export default App;
