import * as Yup from 'yup';
import { passwordMessage, passwordRegex } from '../../../../constants';

export const LoginInfoSchema = Yup.object().shape({
  email: Yup.string().email('Please enter valid email').required('Please enter email'),
  currentPassword: Yup.string().required('Please enter current password'),
  password: Yup.string().required('Please enter password').matches(passwordRegex, passwordMessage),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Please enter confirm password')
    .matches(passwordRegex, passwordMessage)
});

export const BusinessInfoInitValues = {
  businessName: '',
  address: '',
  apartmentNo: '',
  city: '',
  state_id: '',
  country_id: '',
  zipcode: '',
  category_id: '',
  website: '',
  email: '',
  phoneno: '',
  subCategory_id: '',
  timings: [
    {
      status: true,
      openingTime: '',
      closingTime: '',
      isOpenDay: false
    },
    {
      status: true,
      openingTime: '',
      closingTime: '',
      isOpenDay: false
    },
    {
      status: true,
      openingTime: '',
      closingTime: '',
      isOpenDay: false
    },
    {
      status: true,
      openingTime: '',
      closingTime: '',
      isOpenDay: false
    },
    {
      status: true,
      openingTime: '',
      closingTime: '',
      isOpenDay: false
    },
    {
      status: true,
      openingTime: '',
      closingTime: '',
      isOpenDay: false
    },
    {
      status: true,
      openingTime: '',
      closingTime: '',
      isOpenDay: false
    }
  ]
};
