import React, { useContext } from 'react';
import UserContext from '../../../contexts/user/UserContext';

import PageHeader from '../../common/page-header/PageHeader';
import MerchantSideMenu from '../../merchant/side-bar-menu/MerchantSideMenu';

import './styles.css';

// eslint-disable-next-line react/display-name

// eslint-disable-next-line react/display-name
const withMerchantAdmin = (Component) => (props) => {
  const { details } = useContext(UserContext);

  return (
    <>
      <div fluid className="merchant-admin-container">
        <div className="main-dashboard-content-area">
          <div className="sidebar-menu dashboard-left-side">
            <MerchantSideMenu user={details?.me?.merchant} />
          </div>
          <div className="merchant-admin-content dashboard-right-side">
            <PageHeader />
            <Component {...props} />
          </div>
        </div>
      </div>
    </>
  );
};

export default withMerchantAdmin;
