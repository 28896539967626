import moment from 'moment';
import * as Yup from 'yup';

export const userFormSchema = Yup.object().shape({
  email: Yup.string().email('Please enter valid email'),
  dob: Yup.date()
    .typeError('Please enter dob')
    .test('minYear18', 'Please enter valid date, your age should be more then 18 years', (item) => {
      if (item) {
        return moment(item).isSameOrBefore(moment().subtract(18, 'years'), 'd');
      }
      return true;
    })
});

export const userFormInitValues = {
  email: '',
  firstname: '',
  lastname: '',
  gender: '',
  dob: '',
  phoneno: '',
  defaultAddress: {
    _id: null,
    addressLine1: '',
    addressLine2: '',
    city: '',
    state_id: '',
    country_id: '',
    zipcode: ''
  }
};
