import { gql } from '@apollo/client';

export const GET_SURVEY_SUGGESTED_OPTIONS = gql`
  query GetSuggestedOption($surveyId: ID!) {
    getSuggestedOption(survey_id: $surveyId) {
      status
      message
      data {
        _id
        user_id {
          _id
          email
          firstname
          lastname
          profilepicture
        }
        value
      }
      errorCode
    }
  }
`;
