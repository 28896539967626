import moment from 'moment';

export const getUTCTimeDate = (date) => {
  return `${moment(date).startOf('date').utcOffset(0, true).format()}`;
};

export const getFormatedDate = (date) => {
  return `${moment(date).format('DD / MM /YYYY')}`;
};

export const getEndDateEOD = (date) => {
  return `${moment(date).endOf('date').format()}`;
};
