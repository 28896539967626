import { gql } from '@apollo/client';

export const DELETE_ADMIN_PROFILE_LOGO = gql`
  query DeleteAdminProfileLogo {
    deleteAdminProfileLogo {
      status
      message
      errorCode
    }
  }
`;
