import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import FormWrapper from '../../../../components/common/form-wrapper/FormWrapper';
import withMerchantAdmin from '../../../../components/hoc/merchant/withMerchantAdmin';
import AccountStatusCard from '../../../../components/merchant/account-status-card/AccountStatusCard';
import { MERCHANT_APPLICATION_SETTINGS } from '../../../../constants';
import './styles.css';
import UserContext from '../../../../contexts/user/UserContext';
import { getFormatedDate } from '../../../../services/dateUtils';
import LoaderComponent from '../../../../components/common/loader/LoaderComponent';

const titles = {
  [MERCHANT_APPLICATION_SETTINGS.pending]: 'Register For PayPal',
  [MERCHANT_APPLICATION_SETTINGS.submitted]: 'Await approval',
  [MERCHANT_APPLICATION_SETTINGS.approved]: 'Create your deals'
};

const descriptions = {
  [MERCHANT_APPLICATION_SETTINGS.pending]: 'Register your business PayPal account.',
  [MERCHANT_APPLICATION_SETTINGS.submitted]: 'Our team will reach out once accepted.',
  [MERCHANT_APPLICATION_SETTINGS.approved]: 'Now you can create deals for your customers.'
};

const cardRightBtnText = {
  [MERCHANT_APPLICATION_SETTINGS.pending]: 'Pending Review',
  [MERCHANT_APPLICATION_SETTINGS.submitted]: 'Pending Review',
  [MERCHANT_APPLICATION_SETTINGS.approved]: 'Accepted'
};

const cardCTAText = {
  [MERCHANT_APPLICATION_SETTINGS.pending]: 'View Merchant Account',
  [MERCHANT_APPLICATION_SETTINGS.submitted]: 'View Merchant Account',
  [MERCHANT_APPLICATION_SETTINGS.approved]: 'View Merchant Account'
};

const line2Text = {
  [MERCHANT_APPLICATION_SETTINGS.submitted]: 'Submitted ',
  [MERCHANT_APPLICATION_SETTINGS.approved]: 'Accepted'
};

const MerchantAccountStatus = () => {
  const navigate = useNavigate();
  const { details: data, loading } = useContext(UserContext);
  const status = data?.me?.merchant?.business_data?.businessStatus;

  if (loading) {
    return null;
  }

  return (
    <>
      {loading ? <LoaderComponent /> : null}

      <FormWrapper loading={loading} showFooter={false} showCancel={false}>
        <div className="wrapper-padding">
          <p className="form-title mb-0 fonts-quicksand-medium">Merchant Application Status</p>
          <span className="form-subtitle fonts-regular">
            Check your Centavizer merchant application status below.
          </span>
          <Row className="mt-4">
            {Object.values(MERCHANT_APPLICATION_SETTINGS).map((key) => {
              if (key === MERCHANT_APPLICATION_SETTINGS.rejected) {
                return null;
              }

              let active = false;
              if (status === MERCHANT_APPLICATION_SETTINGS.approved) {
                active = true;
              }
              if (status === MERCHANT_APPLICATION_SETTINGS.submitted) {
                if (
                  key === MERCHANT_APPLICATION_SETTINGS.pending ||
                  key === MERCHANT_APPLICATION_SETTINGS.submitted
                ) {
                  active = true;
                }
              }

              let isCurrentStep = status === key;

              if (status === MERCHANT_APPLICATION_SETTINGS.approved) {
                isCurrentStep = false;
              }

              return (
                <Col
                  md={4}
                  className={`${key} ${status} ${active ? 'active' : ''} ${
                    isCurrentStep ? 'current' : ''
                  } status-info`}
                  key={key}>
                  <div
                    className={`line-status ${key} ${status} ${active ? 'active' : ''}  ${
                      isCurrentStep ? 'current' : ''
                    }`}></div>
                  <p className="m-0 fonts-medium">{titles?.[key]}</p>
                  <span className="fonts-light">{descriptions?.[key]}</span>
                </Col>
              );
            })}
          </Row>
          <AccountStatusCard
            status={status}
            line2={line2Text?.[status] && `${line2Text?.[status]}: ${getFormatedDate()}`}
            rightBtnText={cardRightBtnText?.[status]}
            ctaText={cardCTAText?.[status]}
            onCTAClick={() => {
              navigate('/merchant/settings/merchant-account');
            }}
            line1={
              status === MERCHANT_APPLICATION_SETTINGS.approved ? 'Merchant Application' : undefined
            }
          />
        </div>
      </FormWrapper>
    </>
  );
};
export default withMerchantAdmin(MerchantAccountStatus);
