import moment from 'moment';
import * as Yup from 'yup';

export const MerchantApplicationSchema = Yup.object().shape({
  zipcode: Yup.string().typeError('Please enter zip code').required('Please enter zipcode'),
  // yearInBusiness: Yup.string()
  //   .typeError('Please enter years in business')
  //   .required('Please enter years in business'),
  startupDate: Yup.string()
    .typeError('Please enter start up date')
    .test('minDateToday', 'Please enter valid date', (item) => {
      return moment(item).isSameOrBefore(moment(), 'd');
    })
    .required('Please enter start up date'),
  securityNo: Yup.string()
    .matches(/^\d{3}-?\d{2}-?\d{4}$/, 'Please enter valid SSN')
    .typeError('Please enter security Number')
    .required('Please enter security Number'),
  // routingNo: Yup.string()
  //   .typeError('Please enter routing number')
  //   .required('Please enter routing number'),
  // productSold: Yup.string()
  //   .typeError('Please enter product sold')
  //   .required('Please enter product sold'),
  phonNo: Yup.string()
    .typeError('Please enter phone number')
    .test(
      'min12Digit',
      'Phone number should be of 10 digits',
      (value) => sanitizeInput(value)?.trim?.()?.length == 10
    )
    .required('Please enter phone number'),
  personalPhoneNo: Yup.string()
    .typeError('Please enter personal phone number')
    .test(
      'min12Digit',
      'Phone personal number should be of 10 digits',
      (value) => sanitizeInput(value)?.trim?.()?.length == 10
    )
    .required('Please enter personal phone number'),
  ownership: Yup.string().typeError('Please enter ownership').required('Please enter ownership'),
  // merchandiseServicesSold: Yup.string()
  //   // .matches(/^[A-Za-z]+$/, 'Merchandise services sold should contain characters only')
  //   .typeError('Please enter Merchandise services sold')
  //   .required('Please enter Merchandise services sold'),
  // monthInBusiness: Yup.number()
  //   .min(0, 'Months should more than or equals to 0.')
  //   .max(11, 'Months should be less then 12, if 12 or more than 12, please enter as year field.')
  //   .typeError('Please enter months in business')
  //   .required('Please enter months in business'),
  // merchantCategoryClassification: Yup.string()
  //   // .matches(/^[0-9]*$/, 'MCC should contains digit only')
  //   // .max(4, 'Please enter valid Merchant Category Classification')
  //   .typeError('Please enter Merchant Category Classification')
  //   .required('Please enter Merchant Category Classification'),
  lastname: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, 'Last name should only contains letter')
    .typeError('Please enter last name')
    .required('Please enter last name'),
  firstname: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, 'Last name should only contains letter')
    .typeError('Please enter first name')
    .required('Please enter first name'),
  // fedralTax: Yup.string()
  //   .typeError('Please enter federal tax')
  //   .required('Please enter federal tax'),
  dob: Yup.date()
    .typeError('Please enter dob')
    .test('minYear18', 'Please enter valid date, your age should be more then 18 years', (item) => {
      return moment(item).isSameOrBefore(moment(), 'd');
    })
    .required('Please enter date of birth'),
  city: Yup.string().typeError('Please enter city').required('Please enter city'),
  address: Yup.string().typeError('Please enter address').required('Please enter address'),
  // accountNo: Yup.string()
  //   .matches(/^[0-9]*$/, 'Account number should contains digit only')
  //   .min(6, 'Please enter valid account number')
  //   .max(17, 'Please enter valid account number')
  //   .typeError('Please enter account number')
  //   .required('Please enter account number'),
  // title: Yup.string().typeError('Please select title').required('Please select title'),

  // averageTicketAmount: Yup.string()
  //   .typeError('Please select Average Sale Value')
  //   .required('Please select Average Sale Value'),
  // averageMonthlyVolume: Yup.string()
  //   .required('Please select Average Monthly Sales')
  //   .typeError('Please select Average Monthly Sales'),
  // highTicketAmount: Yup.string()
  //   .matches(/^[0-9]*$/, 'High Ticket Amount should contains digit only')
  //   .required('Please enter high ticket amount')
  //   .typeError('Please enter high ticket amount'),
  state_id: Yup.mixed().typeError('Please select State').required('Please select state'),
  country_id: Yup.mixed().typeError('Please select Country').required('Please select country')
  // accountType: Yup.mixed()
  //   .typeError('Please select account type')
  //   .required('Please select account type'),
  // ownershipType: Yup.mixed()
  //   .typeError('Please select ownership type')
  //   .required('Please select ownership type'),
  // category_id: Yup.mixed().typeError('Please select category').required('Please select category'),
  // subCategory_id: Yup.mixed()
  //   .typeError('Please select sub category')
  //   .required('Please select sub category'),
  // federalTaxIdType: Yup.mixed()
  //   .typeError('Please select federal tax type')
  //   .required('Please select federal tax type')
});

export const MerchantApplicationSaveSchema = Yup.object().shape({
  zipcode: Yup.string().typeError('Please enter zip code'),
  // yearInBusiness: Yup.string().typeError('Please enter years in business'),
  startupDate: Yup.string()
    .typeError('Please enter start up date')
    .test('minDateToday', 'Please enter valid date', (item) => {
      return moment(item).isSameOrBefore(moment(), 'd');
    }),
  securityNo: Yup.string()
    .matches(/^\d{3}-?\d{2}-?\d{4}$/, 'Please enter valid SSN')
    .typeError('Please enter security Number'),
  // routingNo: Yup.string().typeError('Please enter routing number'),
  // productSold: Yup.string().typeError('Please enter product sold'),
  phonNo: Yup.string()
    .typeError('Please enter phone number')
    .test(
      'min12Digit',
      'Phone number should be of 10 digits',
      (value) => sanitizeInput(value)?.trim?.()?.length == 10
    ),
  personalPhoneNo: Yup.string()
    .typeError('Please enter personal phone number')
    .test(
      'min12Digit',
      'Phone personal number should be of 10 digits',
      (value) => sanitizeInput(value)?.trim?.()?.length == 10
    ),
  ownership: Yup.string().typeError('Please enter ownership'),
  // merchandiseServicesSold: Yup.string()
  //   // .matches(/^[A-Za-z]+$/, 'Merchandise services sold should contain characters only')
  //   .typeError('Please enter Merchandise services sold'),
  // monthInBusiness: Yup.number()
  //   .min(0, 'Months should more than or equals to 0.')
  //   .max(11, 'Months should be less then 12, if 12 or more than 12, please enter as year field.')
  //   .typeError('Please enter months in business'),
  // merchantCategoryClassification: Yup.string()
  //   .matches(/^[0-9]*$/, 'MCC should contains digit only')
  //   .max(4, 'Please enter valid Merchant Category Classification')
  //   .typeError('Please enter Merchant Category Classification'),
  lastname: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, 'Last name should only contains letter')
    .typeError('Please enter last name'),
  firstname: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, 'Last name should only contains letter')
    .typeError('Please enter first name'),
  // fedralTax: Yup.string().typeError('Please enter federal tax'),
  dob: Yup.date()
    .typeError('Please enter dob')
    .test('minYear18', 'Please enter valid date, your age should be more then 18 years', (item) => {
      return moment(item).isSameOrBefore(moment(), 'd');
    }),
  city: Yup.string().typeError('Please enter city'),
  address: Yup.string().typeError('Please enter address'),
  accountNo: Yup.string()
    .matches(/^[0-9]*$/, 'Account number should contains digit only')
    .min(6, 'Please enter valid account number')
    .max(17, 'Please enter valid account number')
    .typeError('Please enter account number')
  // title: Yup.string().typeError('Please select title').required('Please select title'),
  // averageTicketAmount: Yup.string().typeError('Please select Average Sale Value'),
  // averageMonthlyVolume: Yup.string().typeError('Please select Average Monthly Sales'),
  // highTicketAmount: Yup.string()
  //   .matches(/^[0-9]*$/, 'High Ticket Amount should contains digit only')
  //   .typeError('Please enter high ticket amount')
});

export const MerChantApplicationInitValues = {
  zipcode: '',
  yearInBusiness: '',
  state_id: '',
  stateSaleTax: '',
  startupDate: '',
  securityNo: '',
  routingNo: '',
  productSold: '',
  phonNo: '',
  ownershipType: '',
  ownership: '',
  monthInBusiness: '',
  merchantCategoryClassification: '',
  lastname: '',
  firstname: '',
  fedralTax: '',
  dob: '',
  country_id: '',
  city: '',
  address: '',
  accountNo: '',
  personalPhoneNo: '',
  cardSwiped: '0',
  mailOrPhoneOrder: '0',
  keyedCardPresentNotImprinted: '0',
  internet: '1000'
};

export const selectInputData = {
  titles: [
    {
      _id: 'ceo',
      name: 'CEO'
    },
    {
      _id: 'manager',
      name: 'Manager'
    },
    {
      _id: 'owner',
      name: 'Owner'
    },
    {
      _id: 'partner',
      name: 'Partner'
    },
    {
      _id: 'president',
      name: 'President'
    },
    {
      _id: 'vice president',
      name: 'Vice President'
    }
  ],
  federalTaxIdTypes: [
    {
      _id: 'SSN',
      name: 'SSN'
    },
    {
      _id: 'EIN',
      name: 'EIN'
    }
  ],
  accountTypes: [
    {
      _id: 'checking',
      name: 'Checking'
    },
    {
      _id: 'savings',
      name: 'Saving'
    }
  ],
  averageMonthlyVolume: [
    {
      _id: '1000',
      name: '$1,000'
    },
    {
      _id: '10000',
      name: '$10,000'
    },
    {
      _id: '25000',
      name: '$25,000'
    },
    {
      _id: '50000',
      name: '$50,000'
    }
  ],
  averageTicketAmount: [
    { _id: '100', name: 'under $100' },
    { _id: '500', name: '$100-$500' },
    { _id: '1000', name: '$500-$1,000' },
    { _id: '5000', name: '$1,000+' }
  ],
  mccOptions: []
};

export const sanitizeInput = (input, returnUndefined = false) => {
  try {
    if (!input || input === 'undefined' || input === 'null') {
      return returnUndefined ? undefined : '';
    }
    let output = input?.replace(/ /g, '');
    output = output?.replace(/-/g, '');
    output = output?.replace(/_/g, '');
    return output;
  } catch (error) {
    return input;
  }
};

export const mccCodeMappingWithCategory = {
  'Activity & Leisure Entertainment': '7999',
  'Arts & Entertainment': '7991',
  'Automotive & Marine': '5533',
  'Bar & Nightlife': '5813',
  'Beauty & Spas': '7230',
  'Contractor & Home Services': '1520',
  Education: '8299',
  'Event & Planning Services': '7399',
  'Health & Medical': '8011',
  'Hotels & Travel': '7011',
  'Local Services & Repair': '7699',
  'Pet Supplies & Veterinary': '5995',
  'Professional Services': '8999',
  'Real Estate': '6513',
  'Restaurants & Food Services': '5812',
  'Retail and Online Shopping': '5699'
};
