import { gql } from '@apollo/client';

export const SYNC_MARKETPLACE = gql`
  query ToSyncMarketplace {
    toSyncMarketplace {
      status
      message
      errorCode
    }
  }
`;
