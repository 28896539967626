import { gql } from '@apollo/client';

export const GET_COUNTRIES = gql`
  query Query {
    country {
      status
      message
      errorCode
      data {
        _id
        name
      }
    }
  }
`;
