import * as Yup from 'yup';
import { passwordMessage, passwordRegex } from '../../../constants';

export const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Please enter valid email').required('Please enter email'),
  password: Yup.string().required('Please enter password').matches(passwordRegex, passwordMessage),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Please enter confirm password')
    .matches(passwordRegex, passwordMessage)
});

export const SignUpInitValues = {
  email: '',
  password: '',
  passwordConfirm: ''
};
