import { gql } from '@apollo/client';

export const EDIT_USER_ADDRESS = gql`
  mutation EditUserAddressByAdmin($input: editUserAddressByAdmin!) {
    editUserAddressByAdmin(input: $input) {
      status
      message
      data {
        _id
      }
      errorCode
    }
  }
`;
