import React from 'react';
import { FormLabel } from 'react-bootstrap';
import Select from 'react-select';
import Images from '../../../constants/images';

const SelectInput = (props) => {
  const {
    label,
    placeholder,
    onChange,
    error,
    name,
    options = [],
    value,
    id,
    parentId,
    isDisabled
  } = props;

  const formatOptions = options.map((option) => {
    return {
      value: option._id,
      name: option.name,
      isDisabled: option?.isDisabled,
      label: (
        <div>
          {option?.icon || Images?.[option.name] || Images?.[parentId] ? (
            <img
              src={option?.icon || Images?.[option.name] || Images?.[parentId]}
              height="12px"
              width="12px"
            />
          ) : null}
          {' ' + option.name}
        </div>
      )
    };
  });
  const option = formatOptions.find((o) => o.value === value);
  return (
    <div>
      {label ? (
        <FormLabel className={label ? 'mt-3' : 'mt-1'} title={label}>
          {label}
        </FormLabel>
      ) : null}
      <Select
        isOptionDisabled={(o) => o?.isDisabled}
        isDisabled={isDisabled}
        className="app-select"
        placeholder={placeholder}
        onChange={(e) => {
          onChange?.(e?.value);
        }}
        name={name}
        value={option || null}
        options={formatOptions}
        id={id}
        classNamePrefix="app-select-option"
        formatOptionLabel="name"
        filterOption={(candidate, input) => {
          if (!input) {
            return true;
          }
          return String(candidate?.data?.name)
            .toLowerCase()
            .startsWith(String(input).toLowerCase());
        }}
      />
      <span className="text-helper text-danger">{error}</span>
    </div>
  );
};

export default SelectInput;
