import React, { useContext, useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { doLogout } from '../../../services/logoutService';
import FormWrapper from '../../common/form-wrapper/FormWrapper';
import TextInput from '../../common/text-input/TextInput';
import { passwordMessage, passwordRegex } from '../../../constants';
import { Formik } from 'formik';
import Images from '../../../constants/images';
import ErrorFocus from '../../common/ErrorFocus/ErrorFocus';
import { useMutation } from '@apollo/client';
import { CHANGE_ADMIN_PASSWORD } from '../../../graphql/schema/query/admin/user/CHANGE_ADMIN_PASSWORD';
import ToastContext from '../../../contexts/ToastContext';
import LoaderComponent from '../../common/loader/LoaderComponent';

export const FormSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Please enter current password'),
  password: Yup.string()
    .required('Please enter new password')
    .matches(passwordRegex, passwordMessage),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Please enter confirm password')
    .matches(passwordRegex, passwordMessage)
});

const UpdatePassword = () => {
  const formRef = useRef();
  const { setToastDetails } = useContext(ToastContext);

  const navigate = useNavigate();

  const [doUpdatePassword, { data, loading, error }] = useMutation(CHANGE_ADMIN_PASSWORD);

  useEffect(() => {
    if (
      !loading &&
      data?.changePasswordBySuperAdmin?.message &&
      data?.changePasswordBySuperAdmin?.errorCode &&
      data?.changePasswordBySuperAdmin?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: data?.changePasswordBySuperAdmin?.message,
        type: 'danger'
      });
    } else if (data?.changePasswordBySuperAdmin && !loading) {
      setToastDetails({
        show: true,
        title: 'Password Updated',
        message: 'Password updated successfully.',
        type: 'success'
      });
      formRef.current.resetForm();
      formRef.current.setFieldValue('email', data?.me?.merchant?.email);
    }
  }, [
    loading,
    error,
    data?.changePasswordBySuperAdmin?.errorCode,
    data?.changePasswordBySuperAdmin?.message
  ]);

  return (
    <Formik
      innerRef={formRef}
      validationSchema={FormSchema}
      initialValues={{
        currentPassword: '',
        password: '',
        passwordConfirm: ''
      }}
      onSubmit={(values) => {
        doUpdatePassword({
          variables: {
            input: {
              oldPassword: values?.currentPassword,
              newPassword: values?.password
            }
          }
        });
      }}>
      {({ values, errors, touched, handleChange, handleSubmit }) => {
        return (
          <FormWrapper handleSubmit={handleSubmit}>
            <div className="wrapper-padding">
              {loading ? <LoaderComponent /> : null}
              <p className="form-title mb-0 fonts-quicksand-medium">Update Password</p>
              <span className="form-subtitle fonts-regular">
                To change your password, update the information below.
              </span>
              <Row className="mt-2">
                <Col md={12}>
                  <TextInput
                    label="Current password"
                    name="currentPassword"
                    type="password"
                    value={values.currentPassword}
                    error={touched.currentPassword && errors.currentPassword}
                    onChange={handleChange}
                    rightIcon={Images.eye_cross}
                    autoComplete="new-password"
                    id="accountSettings.currentPassword"
                  />
                </Col>
                <div className="mt-3 mb-2 forgot-password-link">
                  <a
                    href="javascript:void(0)"
                    className="link-color"
                    onClick={() => {
                      doLogout({ soft: true });
                      navigate('/forgot-password?return=/admin/login', { replace: true });
                    }}>
                    Forgot Password?
                  </a>
                </div>
                <Col md={12}>
                  <TextInput
                    label="Enter new password"
                    name="password"
                    type="password"
                    value={values.password}
                    error={touched.password && errors.password}
                    onChange={handleChange}
                    rightIcon={Images.eye_cross}
                    autoComplete="new-password"
                    id="accountSettings.newPassword"
                  />
                </Col>
                <Col md={12}>
                  <TextInput
                    label="Confirm new password"
                    value={values.passwordConfirm}
                    error={touched.passwordConfirm && errors.passwordConfirm}
                    onChange={handleChange}
                    name="passwordConfirm"
                    type="password"
                    rightIcon={Images.eye_cross}
                    autoComplete="new-password"
                    id="accountSettings.newPasswordConfirm"
                  />
                </Col>
              </Row>
            </div>
            <ErrorFocus />
          </FormWrapper>
        );
      }}
    </Formik>
  );
};

export default UpdatePassword;
