import React, { useContext, useEffect, useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import withAdmin from '../../../../components/hoc/admin/withAdmin';
import Images from '../../../../constants/images';
import TextInput from '../../../../components/common/text-input/TextInput';
import SelectInput from '../../../../components/common/select-input/SelectInput';
import AppButton from '../../../../components/common/button/Button';
import UploadModal from '../../../../components/common/upload-modal/UploadModal';
import '../styles.css';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_CATEGORIES } from '../../../../graphql/schema/query/masters/GET_CATEGORIES';
import ToastContext from '../../../../contexts/ToastContext';
import { UPDATE_MERCHANT_CATEGORY_IMAGE } from '../../../../graphql/schema/query/admin/merchant/UPDATE_MERCHANT_CATEGORY_IMAGE';
import { baseFilePaths } from '../../../../constants';
import LoaderComponent from '../../../../components/common/loader/LoaderComponent';

const MerchantCategory = () => {
  const imageProgressRef = useRef();

  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState(null);
  const [cacheBuster, setCacheBuster] = useState(1);

  const modalClose = () => {
    setShow({});
    setSelectedImage();
    imageProgressRef?.current(0);
  };
  const modalShow = (item) => setShow(item);
  const [selectedImage, setSelectedImage] = useState();

  // @todo
  // change with right API
  const [
    doUpdateCategoryImage,
    { data: updateImage, loading: updateImageLoading, error: updateImageError }
  ] = useMutation(UPDATE_MERCHANT_CATEGORY_IMAGE, {
    context: {
      fetchOptions: {
        useUpload: true,
        onProgress: (ev) => {
          imageProgressRef?.current(ev.loaded / ev.total);
        },
        onAbortPossible: (abortHandler) => {
          console.log({ abortHandler });
        }
      }
    }
  });

  const [doGetCategories, { loading, data }] = useLazyQuery(GET_CATEGORIES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      setCacheBuster(Math.random());
    }
  });

  useEffect(() => {
    doGetCategories();
  }, []);

  useEffect(() => {
    if (!updateImageLoading && updateImageError) {
      modalClose();
      setToastDetails({
        show: true,
        title: 'Error!',
        message: 'Something went wrong!, Please try again after sometime',
        type: 'danger'
      });
    }
    if (
      !updateImageLoading &&
      updateImage?.uploadImageMerchatnCategory?.message &&
      updateImage?.uploadImageMerchatnCategory?.errorCode &&
      updateImage?.uploadImageMerchatnCategory?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: updateImage?.uploadImageMerchatnCategory?.message,
        type: 'danger'
      });
    } else {
      if (updateImage?.uploadImageMerchatnCategory?.data?.catImage) {
        modalClose();
        doGetCategories();
        setToastDetails({
          show: true,
          title: 'Image Updated',
          message: 'Category image updated successfully.',
          type: 'success'
        });
      }
    }
  }, [
    updateImageLoading,
    updateImageError,
    updateImage?.uploadImageMerchatnCategory?.errorCode,
    updateImage?.uploadImageMerchatnCategory?.message,
    updateImage?.uploadImageMerchatnCategory?.data?.catImage
  ]);

  const { setToastDetails } = useContext(ToastContext);

  let searched = data?.category?.data?.slice();

  if (searched) {
    searched.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    if (sorting) {
      searched.reverse();
    }
  }

  if (search) {
    searched = searched?.filter((d) => {
      return d?.name?.toLowerCase().includes(search?.toLowerCase());
    });
  }

  return (
    <>
      <div className="merchant-category-wrapper">
        {loading ? <LoaderComponent /> : null}
        <div className="search-header">
          <div className="search-box">
            <TextInput
              name="Search Category"
              type="text"
              placeholder="Search Category"
              value={search}
              onChange={(e) => setSearch(e?.target?.value)}
            />
          </div>
          <div className="filter-option">
            <div className="d-flex justify-content-end">
              <SelectInput
                placeholder={'Sort By: A - Z'}
                value={sorting}
                options={[
                  {
                    name: 'A - Z',
                    _id: null
                  },
                  {
                    name: 'Z - A',
                    _id: 'reverse'
                  }
                ]}
                onChange={(v) => setSorting(v)}
              />
            </div>
          </div>
        </div>
        {!loading && searched?.length <= 0 ? (
          <p className="text-center m-5">No data found</p>
        ) : null}
        <div className="category-list">
          {searched?.map((d) => {
            return (
              <div className="category-box" key={d?._id}>
                <div className="image-wrapper">
                  <Image
                    className="category-img"
                    src={
                      d?.catImage
                        ? `${baseFilePaths.category}${d?.catImage}?cacheBuster=${cacheBuster}`
                        : Images.deal_info_dummy_image
                    }
                  />
                  <div className="edit-icon-wrapper" onClick={() => modalShow(d)}>
                    <Image className="edit-icon" src={Images.edit} />
                  </div>
                </div>
                <div className="category-name">{d?.name}</div>
              </div>
            );
          })}
        </div>
        <UploadModal
          show={show?._id}
          onHide={() => modalClose()}
          title="Upload category image"
          subTitle="Upload display picture for this category."
          categoryName={show?.name}
          className="category-upload-modal"
          onSelectImage={(file) => {
            setSelectedImage(file);
          }}
          selectedImage={selectedImage}
          setToastDetails={setToastDetails}
          onError={() => modalClose()}
          imageProgressRef={imageProgressRef}
          uploading={updateImageLoading}
          buttons={[
            <AppButton
              key="1"
              title={'Cancel'}
              id="upload-cancel-btn"
              buttonClass={'btn-cancel'}
              onClick={() => modalClose()}></AppButton>,
            <AppButton
              key="2"
              title={updateImageLoading ? 'Uploading...' : 'Save'}
              id="upload-attach-btn"
              onClick={() => {
                if (selectedImage && selectedImage?.length > 0) {
                  doUpdateCategoryImage({
                    variables: {
                      input: {
                        file: selectedImage?.[0],
                        cat_id: show?._id
                      }
                    }
                  });
                  return;
                }
                modalClose();
                setToastDetails({
                  show: true,
                  title: 'Error!',
                  message: 'Please select image',
                  type: 'danger'
                });
              }}></AppButton>
          ]}></UploadModal>
      </div>
    </>
  );
};

export default withAdmin(MerchantCategory);
