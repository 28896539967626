import { Formik } from 'formik';
import React, { useContext, useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import FormWrapper from '../../../../components/common/form-wrapper/FormWrapper';
import TextInput from '../../../../components/common/text-input/TextInput';
import withMerchantAdmin from '../../../../components/hoc/merchant/withMerchantAdmin';
import { LoginInfoSchema } from './formUtils';
import { CHANGE_MERCHANT_PASSWORD } from '../../../../graphql/schema/mutations/merchant/CHANGE_MERCHANT_PASSWORD';
import ToastContext from '../../../../contexts/ToastContext';
import Images from '../../../../constants/images';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { doLogout } from '../../../../services/logoutService';
import UserContext from '../../../../contexts/user/UserContext';
import ErrorFocus from '../../../../components/common/ErrorFocus/ErrorFocus';
import LoaderComponent from '../../../../components/common/loader/LoaderComponent';

const MerchantAccountSettings = () => {
  const navigate = useNavigate();
  const { setToastDetails } = useContext(ToastContext);

  const formRef = useRef();
  const { details: data } = useContext(UserContext);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue('email', data?.me?.merchant?.email);
    }
  }, [data?.me?.merchant?.email]);

  const [doChangePassword, { loading, data: saved, error }] = useMutation(CHANGE_MERCHANT_PASSWORD);

  useEffect(() => {
    if (error) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: error?.message,
        type: 'danger'
      });
      return;
    }
    if (
      !loading &&
      saved?.changePasswordMerchant?.message &&
      saved?.changePasswordMerchant?.errorCode &&
      saved?.changePasswordMerchant?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: saved?.changePasswordMerchant?.message,
        type: 'danger'
      });
    } else if (saved?.changePasswordMerchant && !loading) {
      setToastDetails({
        show: true,
        title: 'Password Updated',
        message: 'Password updated successfully.',
        type: 'success'
      });
      formRef.current.resetForm();
      formRef.current.setFieldValue('email', data?.me?.merchant?.email);
    }
  }, [
    loading,
    error,
    saved?.changePasswordMerchant?.errorCode,
    saved?.changePasswordMerchant?.message
  ]);

  return (
    <>
      {loading ? <LoaderComponent /> : null}
      <Formik
        innerRef={formRef}
        initialValues={{
          email: '',
          currentPassword: '',
          password: '',
          passwordConfirm: ''
        }}
        validationSchema={LoginInfoSchema}
        onSubmit={(values) =>
          doChangePassword({
            variables: {
              input: {
                oldPassword: values.currentPassword,
                newPassword: values.password
              }
            }
          })
        }>
        {({ values, errors, touched, handleChange, handleSubmit }) => {
          return (
            <FormWrapper loading={loading} handleSubmit={handleSubmit}>
              <div className="login-information-wrapper">
                <p className="form-title mb-0 fonts-quicksand-medium">Login Information</p>
                <span className="form-subtitle fonts-regular">
                  Fill out the form below to change your password.
                </span>
                <Row className="mt-2">
                  <Col md={12}>
                    <TextInput
                      label="Email"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      error={touched.email && errors.email}
                      onChange={handleChange}
                      readOnly
                      id="accountSettings.email"
                    />
                  </Col>
                  <Col md={12}>
                    <p className="form-title mt-4 mb-0 fonts-quicksand-medium">Password</p>
                  </Col>
                  <Col md={12}>
                    <TextInput
                      label="Current password"
                      name="currentPassword"
                      type="password"
                      value={values.currentPassword}
                      error={touched.currentPassword && errors.currentPassword}
                      onChange={handleChange}
                      rightIcon={Images.eye_cross}
                      autoComplete="new-password"
                      id="accountSettings.currentPassword"
                    />
                  </Col>
                  <div className="mt-2 bottom-link fonts-medium font-14">
                    <a
                      href="javascript:void(0)"
                      className="link-color"
                      onClick={() => {
                        doLogout({ soft: true });
                        navigate('/forgot-password', { replace: true });
                      }}>
                      Forgot Password?
                    </a>
                  </div>
                  <Col md={12}>
                    <TextInput
                      label="Enter new password"
                      name="password"
                      type="password"
                      value={values.password}
                      error={touched.password && errors.password}
                      onChange={handleChange}
                      rightIcon={Images.eye_cross}
                      autoComplete="new-password"
                      id="accountSettings.newPassword"
                    />
                  </Col>
                  <Col md={12}>
                    <TextInput
                      label="Confirm password"
                      value={values.passwordConfirm}
                      error={touched.passwordConfirm && errors.passwordConfirm}
                      onChange={handleChange}
                      name="passwordConfirm"
                      type="password"
                      rightIcon={Images.eye_cross}
                      autoComplete="new-password"
                      id="accountSettings.newPasswordConfirm"
                    />
                  </Col>
                </Row>
              </div>
              <ErrorFocus />
            </FormWrapper>
          );
        }}
      </Formik>
    </>
  );
};
export default withMerchantAdmin(MerchantAccountSettings);
