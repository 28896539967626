import React, { useEffect, useState } from 'react';
import { Image, ListGroup } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { MERCHANT_APPLICATION_SETTINGS } from '../../../constants';
import Images from '../../../constants/images';
import { doLogout } from '../../../services/logoutService';
import AppButton from '../../common/button/Button';
import FeedbackModal from '../../common/feedback-modal/FeedbackModal';
import './styles.css';

const menuItems = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    link: '/merchant/dashboard',
    icon: Images.DashboardActive,
    iconInactive: Images.DashboardInactive
  },
  {
    key: 'deals',
    title: 'Deals',
    link: '/merchant/deals',
    icon: Images.DealsActive,
    iconInactive: Images.DealInactive
  },
  {
    key: 'tx_history',
    title: 'Transaction History',
    link: '/merchant/transaction-history',
    icon: Images.TransactionHistoryActive,
    iconInactive: Images.TransactionHistoryInactive
  },
  {
    key: 'settings',
    title: 'Settings',
    link: '/merchant/settings/business-profile',
    icon: Images.SettingsActive,
    iconInactive: Images.SettingsInactive,
    children: [
      {
        key: 'settings/business_profile',
        title: 'Business Profile',
        link: '/merchant/settings/business-profile',
        icon: ''
      },
      {
        key: 'settings/account_settings',
        title: 'Account Settings',
        link: '/merchant/settings/login-settings',
        icon: ''
      },
      {
        key: 'settings/merchant_application',
        title: 'Merchant Application',
        link: '/merchant/settings/merchant-account-status',
        icon: ''
      }
    ]
  }
];
const MerchantSideMenu = ({ user }) => {
  const status = user?.business_data?.businessStatus;

  const applicationSubmitted =
    status === MERCHANT_APPLICATION_SETTINGS.submitted ||
    status === MERCHANT_APPLICATION_SETTINGS.approved;
  const canAddDeal = status === MERCHANT_APPLICATION_SETTINGS.approved;

  let completed = 0;
  const details = user?.business_data;
  if (
    (details?.businessLogo || details?.logoUrl) &&
    details?.bannerImages?.length > 0 &&
    details?.socialUrl
  ) {
    completed += 25;
  }

  if (
    details?.businessName &&
    details?.phoneno &&
    details?.businessDescription &&
    details?.website &&
    details?.email &&
    details?.website &&
    details?.category_id?._id &&
    details?.subCategory_id?._id
  ) {
    completed += 25;
  }

  if (
    details?.address &&
    details?.apartmentNo &&
    details?.city &&
    details?.state_id?._id &&
    details?.zipcode
  ) {
    completed += 25;
  }

  if (details?.timings?.length > 0) {
    completed += 25;
  }

  const addedBusinessData = completed >= 100;

  const location = useLocation();
  const [openMenu, setOpenMenu] = useState();
  const [showLogoutModal, setShowLogoutModal] = useState();

  useEffect(() => {
    if (location.pathname.includes('settings')) {
      setOpenMenu('settings');
    }
  }, [location.pathname]);

  return (
    <>
      <FeedbackModal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        title="Logout"
        subTitle="Are you sure you want to logout?"
        className="delete-deal-modal"
        buttons={[
          <AppButton
            title={'Cancel'}
            key="1"
            buttonClass={'btn-cancel'}
            onClick={() => setShowLogoutModal(false)}
            id="logout.cancel"
          />,
          <AppButton
            buttonClass={'error-bg'}
            title={'Yes, Logout'}
            key="2"
            onClick={() => {
              doLogout();
              setShowLogoutModal(false);
            }}
            id="logout.confirm"
          />
        ]}
      />
      <div className="onboarding-step-bar side-menu">
        <Image src={Images.logo_with_name} alt="centavizer-logo" className="logo" />
        <input type="checkbox" className="mobile-menu-checkbox" />
        <div className="menu-icon">
          <span className="hemburger"></span>
          <span className="hemburger"></span>
          <span className="hemburger"></span>
        </div>
        <div className="menu-items">
          <div className="mt-3 menu-links" user={user}>
            <ListGroup as="ul">
              {menuItems.map((menuItem) => {
                let isActive = location?.pathname?.includes(menuItem?.link);
                if (Array.isArray(menuItem.children)) {
                  menuItem.children.forEach((c) => {
                    if (location?.pathname?.includes(c?.link)) {
                      isActive = true;
                    }
                  });
                }
                const haveChild = Array.isArray(menuItem.children) && menuItem.children.length;
                return (
                  <ListGroup.Item
                    action
                    key={menuItem.key}
                    as="li"
                    active={isActive}
                    className={`${isActive ? 'active' : ''} ${haveChild ? 'active-child' : ''}`}
                    href={menuItem.link}>
                    {haveChild ? (
                      <a
                        href="javascript:void(0)"
                        onClick={() => setOpenMenu(openMenu === menuItem.key ? 0 : menuItem.key)}>
                        <Image src={isActive ? menuItem.icon : menuItem.iconInactive} />
                        <span className="fonts-regular">&nbsp;&nbsp;{menuItem.title}</span>
                        <Image
                          className="float-end dropdown-icon"
                          src={openMenu === menuItem.key ? Images.up : Images.down}
                        />
                      </a>
                    ) : (
                      <Link to={menuItem.link}>
                        <Image src={isActive ? menuItem.icon : menuItem.iconInactive} />
                        <span className="fonts-regular">&nbsp;&nbsp;{menuItem.title}</span>
                      </Link>
                    )}

                    {Array.isArray(menuItem.children) && openMenu === menuItem.key ? (
                      <div className="child-menu mt-3">
                        {menuItem.children.map((_menuItem) => {
                          const _isActive = location?.pathname?.includes(_menuItem?.link);
                          return (
                            <ListGroup.Item
                              action
                              key={_menuItem.key}
                              as="li"
                              active={_isActive}
                              className={`${_isActive ? 'active' : ''}`}
                              href={_menuItem.link}>
                              <Link to={_menuItem.link}>
                                <span className="fonts-regular">&nbsp;&nbsp;{_menuItem.title}</span>
                              </Link>
                            </ListGroup.Item>
                          );
                        })}
                      </div>
                    ) : null}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </div>
          <div className="sidebar-bottom">
            <div className="sidebar-info-box">
              <Image src={Images.featured_icon} alt="featured-icon" className="feature-icon" />
              <div className="title fonts-quicksand-bold">Complete your business setup</div>
              <div className="sub-title">
                We need a couple more details to to complete your profile
              </div>
              <div className="feature-list">
                <Image
                  src={addedBusinessData ? Images.done_check : Images.check_circle}
                  alt="circle-check"
                />
                <span className={`${addedBusinessData ? 'text-strikethrough' : ''}`}>
                  Complete your business info
                </span>
              </div>
              <div className="feature-list">
                <Image
                  src={applicationSubmitted ? Images.done_check : Images.check_circle}
                  alt="circle-check"
                />
                <span className={`${applicationSubmitted ? 'text-strikethrough' : ''}`}>
                  Submit merchant application
                </span>
              </div>
              <div className="feature-list">
                <Image
                  src={canAddDeal ? Images.done_check : Images.check_circle}
                  alt="circle-check"
                />
                <span className={`${canAddDeal ? 'text-strikethrough' : ''}`}>Add deals</span>
              </div>
            </div>
            <div className="sidebar-footer d-flex align-items-center justify-content-between">
              <Image className="company-img" src={Images.default_avatar} />
              &nbsp;
              <span className="text-white">{user?.business_id?.businessName}</span>
              <Image
                id="logout-btn"
                className="logout-img"
                src={Images.logout}
                onClick={() => setShowLogoutModal(true)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantSideMenu;
