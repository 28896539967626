import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SelectInput from '../../common/select-input/SelectInput';
import TextInput from '../../common/text-input/TextInput';

const LocationInformation = ({
  values,
  stateData,
  countryData,
  handleChange,
  setFieldValue,
  errors,
  touched,
  userManagement
}) => {
  return (
    <>
      <div className="wrapper-padding">
        <p className="form-title mb-0 fonts-quicksand-medium">Location information</p>
        <span className="form-subtitle fonts-regular">
          {userManagement
            ? `User's Location Details`
            : values?._id
            ? "Merchant's business location details."
            : 'The default address has been not added by user yet.'}
        </span>
        {values?._id ? (
          <Row className="mt-2">
            <Col md={6}>
              <TextInput
                label="Street No./Street Name"
                placeholder="Street No./Street Name"
                name="defaultAddress.addressLine1"
                id="userLocation.apartmentNo"
                value={values?.addressLine1}
                onChange={handleChange}
                error={
                  touched?.defaultAddress?.addressLine1 && errors?.defaultAddress?.addressLine1
                }
              />
            </Col>
            <Col md={6}>
              <TextInput
                label="Address Line 2"
                placeholder="Address Line 2"
                name="defaultAddress.addressLine2"
                id="userLocation.addressLine2"
                value={values?.addressLine2}
                onChange={handleChange}
                error={
                  touched?.defaultAddress?.addressLine2 && errors?.defaultAddress?.addressLine2
                }
              />
            </Col>
            <Col lg={4} md={6}>
              <TextInput
                label="City"
                placeholder="City"
                name="defaultAddress.city"
                id="userLocation.city"
                value={values?.city}
                onChange={handleChange}
                error={touched?.defaultAddress?.city && errors?.defaultAddress?.city}
              />
            </Col>
            <Col lg={4} md={6}>
              <SelectInput
                label="State"
                placeholder="Select State"
                name="defaultAddress.state_id"
                id="userLocation.stateId"
                value={values?.state_id?._id || values?.state_id}
                options={stateData}
                onChange={(value) => {
                  setFieldValue('defaultAddress.state_id', value);
                }}
                error={touched?.defaultAddress?.state_id && errors?.defaultAddress?.state_id}
              />
            </Col>
            <Col lg={4} md={6}>
              <SelectInput
                label="Country"
                placeholder="Select Country"
                name="defaultAddress.country_id"
                id="userLocation.countryId"
                value={values?.country_id?._id || values?.country_id}
                options={countryData}
                onChange={(value) => {
                  setFieldValue('defaultAddress.country_id', value);
                }}
                error={touched?.defaultAddress?.country_id && errors?.defaultAddress?.country_id}
              />
            </Col>
            <Col lg={4} md={6}>
              <TextInput
                label="Zip code"
                placeholder="Zip code"
                name="defaultAddress.zipcode"
                id="userLocation.zipcode"
                value={values?.zipcode}
                onChange={handleChange}
                maskingProps={{
                  mask: '99999',
                  maskChar: ' '
                }}
                error={touched?.defaultAddress?.zipcode && errors?.defaultAddress?.zipcode}
              />
            </Col>
          </Row>
        ) : null}
      </div>
    </>
  );
};

export default LocationInformation;
