import { gql } from '@apollo/client';

export const GET_TX_HISTORY = gql`
  query TransactionHistory(
    $search: String
    $offset: Int
    $limit: Int
    $filter: filter
    $pdf: Boolean
    $sorting: sorting
  ) {
    transactionHistory(
      search: $search
      offset: $offset
      limit: $limit
      filter: $filter
      pdf: $pdf
      sorting: $sorting
    ) {
      status
      message
      data {
        _id
        invoiceNo
        invoiceDate
        subTotal
        centzTotal
        total
        paymentType
        paymentStatus
        merchantFullfill
        paypalPayer_id
        merchant_id {
          _id
          firstname
          lastname
          dob
          securityNo
          apartmentNo
          address
          city
          zipcode
          phonNo
          personalPhoneNo
          startupDate
          ownership
          accountNo
          routingNo
          ownershipType
          stateSaleTax
          fedralTax
          productSold
          merchantCategoryClassification
          monthInBusiness
          yearInBusiness
          createdAt
          email
          termsAndConditationStatus
          isActive
          isVoid
          userType
          title
          isPersonalGuarantor
          federalTaxIdType
          mcc
          averageTicketAmount
          averageMonthlyVolume
          highTicketAmount
          cardSwiped
          keyedCardPresentNotImprinted
          mailOrPhoneOrder
          internet
          accountType
          timezone
        }
        deal_id {
          _id
          isActive
          name
          decription
        }
        transactionType
        user_id {
          _id
          email
          firstname
          lastname
        }
        address_id {
          _id
          user_id
          name
          addressLine1
          addressLine2
          city
          state_id {
            _id
            name
            value
          }
          country_id {
            _id
            name
          }
          zipcode
          addressType
          isDefault
        }
        transactionInfo {
          variant_id {
            _id
            product_id {
              _id
              account_id
              sku
              title
              description
              manufacturer
              primaryCat
              secondaryCat
              tertiaryCat
              images
            }
            sku
            title
            msrp
            weight
            length
            width
            height
            cost
            quantity
            images
            price
            estimatedShippingCost
            weightUnit
            featured
            sort
            category {
              _id
              primaryCat
              secondaryCat
              tertiaryCat
              discount
              catImage
            }
            options {
              _id
              product_id
              variant_id
              name
              value
            }
          }
          productSku
          price
          discount
          centz
          productName
          quantity
          paymentStatus
        }
        deliveryCharge
        flexpointOrderPostingReport {
          id
          shippingText
          shippedStatus
          orderStatus
        }
        refundTransaction {
          status
          paymentGatewayResponse
        }
      }
      errorCode
      total
      limit
      offset
      pdfData
    }
  }
`;
