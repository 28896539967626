import { useLazyQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Col, Form, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import FormWrapper from '../../../components/common/form-wrapper/FormWrapper';
import TextInput from '../../../components/common/text-input/TextInput';
import withMerchantAdmin from '../../../components/hoc/merchant/withMerchantAdmin';
import { ADD_DEAL } from '../../../graphql/schema/mutations/deals/CREATE_DEAL';
import { dealInitValues, dealsSchema, dealsSchemaUpdate } from './formUtils';
import { GET_DEALS } from '../../../graphql/schema/query/deals/GET_DEALS';
import { useNavigate, useParams } from 'react-router-dom';
import { UPDATE_DEAL } from '../../../graphql/schema/mutations/deals/UPDATE_DEAL';
import { baseFilePaths, MERCHANT_APPLICATION_SETTINGS } from '../../../constants';
import ToastContext from '../../../contexts/ToastContext';
import { UPDATE_DEAL_STATUS } from '../../../graphql/schema/query/deals/UPDATE_DEAL_STATUS';
import Images from '../../../constants/images';
import FileDragNDrop from '../../../components/common/file-drag-and-drop/FileDragNDrop';
import { UPLOAD_DEAL_IMAGES } from '../../../graphql/schema/mutations/deals/UPLOAD_DEAL_IMAGES';
import { DELETE_DEAL_IMAGE } from '../../../graphql/schema/mutations/deals/DELETE_DEAL_IMAGES';
import UserContext from '../../../contexts/user/UserContext';
import ErrorFocus from '../../../components/common/ErrorFocus/ErrorFocus';
import ImagePreviews from '../../../components/common/ImagePreviews/ImagePreviews';
import LoaderComponent from '../../../components/common/loader/LoaderComponent';

const baseFilePathForDeal = baseFilePaths.dealImage;
const maxDescriptionChar = 150;
const MerchantDealsCreate = () => {
  const imageProgressRef = useRef();

  const navigate = useNavigate();
  const params = useParams();
  const formRef = useRef();

  const { details: userData } = useContext(UserContext);

  const [selectedImages, setSelectedImages] = useState([]);

  const [doAddDeal, { loading: saving, error, data: saved }] = useMutation(ADD_DEAL);
  const [doUpdateDeal, { loading: updating, data: updated }] = useMutation(UPDATE_DEAL);
  const [doUploadDealImages, { loading: uploading, error: uploadError, data: uploadData }] =
    useMutation(UPLOAD_DEAL_IMAGES, {
      context: {
        fetchOptions: {
          useUpload: true,
          onProgress: (ev) => {
            imageProgressRef?.current(ev.loaded / ev.total);
          },
          onAbortPossible: (abortHandler) => {
            console.log({ abortHandler });
          }
        }
      }
    });

  const [doDeleteDealImage, { loading: deleting, error: deleteError, data: deleted }] =
    useMutation(DELETE_DEAL_IMAGE);

  useEffect(() => {
    doGetDeals();
  }, []);

  useEffect(() => {
    if (!uploading && uploadError) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: 'Something went wrong!, Please try again after sometime',
        type: 'danger'
      });
    }
    if (
      !uploading &&
      uploadData?.uploadDealImage?.message &&
      uploadData?.uploadDealImage?.errorCode &&
      uploadData?.uploadDealImage?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: uploadData?.uploadDealImage?.message,
        type: 'danger'
      });
    } else {
      if (uploadData?.uploadDealImage?.data?.dealImages) {
        setSelectedImages([]);
        setToastDetails({
          show: true,
          title: 'Images saved',
          message: 'Images saved successfully.',
          type: 'success'
        });
        doGetDeals();
        navigate('/merchant/deals', { replace: true });
      }
    }
  }, [
    uploading,
    uploadError,
    uploadData?.uploadDealImage?.errorCode,
    uploadData?.uploadDealImage?.message,
    uploadData?.uploadDealImage?.data?.dealImages
  ]);

  const [doUpdateStatus, { data: statusData, loading: statusLoading }] = useLazyQuery(
    UPDATE_DEAL_STATUS,
    {
      fetchPolicy: 'network-only',
      variables: {
        updateDealStatusId: params?.id
      }
    }
  );
  const status = userData?.me?.merchant?.business_data?.businessStatus;

  const isProfileCompleted = status === MERCHANT_APPLICATION_SETTINGS.approved;

  const { setToastDetails } = useContext(ToastContext);

  const [doGetDeals, { data, loading }] = useLazyQuery(GET_DEALS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      dealId: params?.id
    }
  });

  useEffect(() => {
    const records = Array.isArray(data?.getDeals?.deals) ? data?.getDeals?.deals : [];
    const details = records?.find((record) => record?._id === params?.id) ?? {};
    if (formRef && details) {
      Object.keys(details).map((d) => {
        formRef.current?.setFieldValue(d, details?.[d]);
      });
    }
  }, [data?.getDeals?.deals]);

  const records = Array.isArray(data?.getDeals?.deals) ? data?.getDeals?.deals : [];
  const details = records?.find((record) => record?._id === params?.id) ?? {};

  const saveDeal = (values) => {
    if (updating || saving || uploading) {
      return;
    }
    const input = {
      name: String(values.name),
      decription: String(values.decription),
      price: String(values.price),
      discountType: String(values.discountType),
      discountValue: String(values.discountValue),
      startDate: moment(values.startDate).toISOString(),
      endDate: moment(values.endDate).toISOString(),
      isActive: !isProfileCompleted ? false : values.isActive ? values.isActive : false
    };

    if (params?.id) {
      doUpdateDeal({
        variables: {
          input: {
            _id: params?.id,
            ...input
          }
        }
      });
      return;
    }
    doAddDeal({
      variables: {
        input
      }
    });
  };

  useEffect(() => {
    if (
      !statusLoading &&
      statusData?.updateDealStatus?.message &&
      statusData?.updateDealStatus?.errorCode &&
      statusData?.updateDealStatus?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: statusData?.updateDealStatus?.message,
        type: 'danger'
      });
    } else {
      if (statusData?.updateDealStatus?.data?._id) {
        setToastDetails({
          show: true,
          title: 'Deal Update',
          message: statusData?.updateDealStatus?.message,
          type: 'success'
        });
      }
    }
  }, [
    statusLoading,
    statusData?.data?.errorCode,
    statusData?.data?.message,
    statusData?.data?._id
  ]);

  useEffect(() => {
    if (
      !saving &&
      saved?.addDeal?.message &&
      saved?.addDeal?.errorCode &&
      saved?.addDeal?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: saved?.addDeal?.message,
        type: 'danger'
      });
      document?.getElementById('deal.name')?.focus();
    } else {
      if (saved?.addDeal?.data?._id) {
        if (selectedImages?.length > 0) {
          doUploadDealImages({
            variables: {
              input: {
                _id: saved?.addDeal?.data?._id,
                file: selectedImages
              }
            }
          });
        }
        setToastDetails({
          show: true,
          title: 'Deal Created',
          message: 'Deal created successfully',
          type: 'success'
        });
        if (selectedImages?.length <= 0) {
          navigate('/merchant/deals', { replace: true });
        }
      }
    }
  }, [
    saving,
    error,
    saved?.addDeal?.errorCode,
    saved?.addDeal?.message,
    saved?.addDeal?.data?._id
  ]);

  useEffect(() => {
    if (
      !updating &&
      updated?.updateDeal?.message &&
      updated?.updateDeal?.errorCode &&
      updated?.updateDeal?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: updated?.updateDeal?.message,
        type: 'danger'
      });
      document?.getElementById('deal.name')?.focus();
    } else {
      if (updated?.updateDeal?.data?._id) {
        if (selectedImages?.length > 0) {
          doUploadDealImages({
            variables: {
              input: {
                _id: updated?.updateDeal?.data?._id,
                file: selectedImages
              }
            }
          });
        }
        setToastDetails({
          show: true,
          title: 'Deal Updated',
          message: 'Deal updated successfully',
          type: 'success'
        });
        if (selectedImages?.length <= 0) {
          navigate('/merchant/deals', { replace: true });
        }
      }
    }
  }, [
    updating,
    updated?.updateDeal?.errorCode,
    updated?.updateDeal?.message,
    updated?.updateDeal?.data?._id
  ]);

  useEffect(() => {
    if (!deleting && deleteError) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: 'Something went wrong!, Please try again after sometime',
        type: 'danger'
      });
    }
    if (
      !deleting &&
      deleted?.deleteDealImage?.message &&
      deleted?.deleteDealImage?.errorCode &&
      deleted?.deleteDealImage?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: deleted?.deleteDealImage?.message,
        type: 'danger'
      });
    } else {
      if (deleted?.deleteDealImage?.status) {
        setToastDetails({
          show: true,
          title: 'Image deleted',
          message: 'Image deleted successfully.',
          type: 'success'
        });
        doGetDeals();
      }
    }
  }, [
    deleting,
    deleteError,
    deleted?.deleteDealImage?.errorCode,
    deleted?.deleteDealImage?.message,
    deleted?.deleteDealImage?.status
  ]);

  if (loading) {
    return <LoaderComponent />;
  }

  const selectedImagesFormated = selectedImages?.map((s, index) => {
    return {
      url: URL.createObjectURL(s),
      name: s?.name,
      file: s,
      index
    };
  });

  return (
    <>
      <div className="deal-wrapper">
        {saving || updating || uploading ? <LoaderComponent /> : null}

        <Formik
          innerRef={formRef}
          initialValues={{
            ...dealInitValues,
            ...details
          }}
          onSubmit={saveDeal}
          validationSchema={params?.id ? dealsSchemaUpdate : dealsSchema}>
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            setFieldValue,
            setFieldError,
            setFieldTouched
          }) => {
            let previewImages =
              values?.dealImages?.map((d) => {
                return {
                  url: `${baseFilePathForDeal}${d}`,
                  name: d
                };
              }) ?? [];

            return (
              <FormWrapper
                title={!params?.id ? 'Create Deal' : 'Save changes'}
                loading={updating || saving}
                handleSubmit={handleSubmit}
                onCancel={() => {
                  navigate('/merchant/deals', { replace: true });
                }}>
                <div className="wrapper-padding">
                  <div className="form-title mb-0 fonts-quicksand-medium top-deal-section">
                    <div className="left-title">
                      Deal Title
                      <div className="form-subtitle fonts-regular">
                        Tell Centavizer users an understanding of what they’ll get from the deal. A
                        good example &quot;50% off food&quot;
                      </div>
                    </div>
                    <div className="right-deal-info">
                      <Form.Check
                        className="deal-status-switch"
                        disabled={!isProfileCompleted}
                        type="switch"
                        label={values.isActive ? 'Active' : 'Inactive'}
                        checked={values.isActive}
                        id="status-switch"
                        onChange={(e) => {
                          doUpdateStatus();
                          setFieldValue('isActive', e.target.checked);
                        }}
                      />
                      {!isProfileCompleted ? (
                        <OverlayTrigger
                          placement="top"
                          trigger={'click'}
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) => (
                            <Tooltip id="info" {...props} className="app-tooltip">
                              You can&apos;t activate deals until your profile is complete
                            </Tooltip>
                          )}>
                          <Image src={Images.question} className="float-end" />
                        </OverlayTrigger>
                      ) : null}
                    </div>
                  </div>
                  <Row className="mt-2">
                    <Col md={12}>
                      <TextInput
                        value={values.name}
                        placeholder="Deal title"
                        onChange={handleChange}
                        name="name"
                        error={touched.name && errors.name}
                        id="deal.name"
                      />
                    </Col>
                    <Col md={12}>
                      <TextInput
                        value={values.decription}
                        placeholder="Enter a description..."
                        label={`Short Description (max ${maxDescriptionChar} characters)`}
                        onChange={(e) => {
                          setFieldValue(
                            'decription',
                            String(e.target?.value).slice(0, maxDescriptionChar)
                          );
                          if (String(e.target?.value).length > maxDescriptionChar) {
                            setFieldTouched('decription', true);
                            setFieldError(
                              'decription',
                              `Description should be of ${maxDescriptionChar} characters`
                            );
                          }
                        }}
                        name="decription"
                        error={touched.decription && errors.decription}
                        as="textarea"
                        rows={7}
                        id="deal.decription"
                      />
                      {String(values.decription).length > 0 &&
                      String(values.decription).length <= maxDescriptionChar ? (
                        <span className="float-end mt-1">
                          {maxDescriptionChar - String(values.decription).length}
                        </span>
                      ) : null}
                    </Col>
                  </Row>
                </div>
                <hr />
                <div className="wrapper-padding">
                  <p className="form-title mb-0 fonts-quicksand-medium">Images</p>
                  <span className="form-subtitle fonts-regular">
                    Add a maximum of 5 images that support the deal
                  </span>
                  <Row className="mt-2">
                    <Col md={12}>
                      <FileDragNDrop
                        setToastDetails={setToastDetails}
                        maxFiles={5}
                        onSelectImage={(images) => {
                          if (images?.length > 0) {
                            if (
                              [...selectedImagesFormated, ...previewImages, ...images]?.length > 5
                            ) {
                              setToastDetails?.({
                                show: true,
                                title: 'Error!',
                                message: `You can select maximum 5 images`,
                                type: 'danger'
                              });
                              return;
                            }
                            const totalImages = [...images, ...selectedImages];
                            setSelectedImages(totalImages?.slice(0, 5));
                          }
                        }}
                      />
                    </Col>
                    <ImagePreviews
                      previewImages={previewImages}
                      selectedImagesFormated={selectedImagesFormated}
                      uploading={uploading}
                      deleting={deleting}
                      doDeleteDealImage={doDeleteDealImage}
                      _id={values?._id}
                      selectedImages={selectedImages}
                      setSelectedImages={setSelectedImages}
                      imageProgressRef={imageProgressRef}
                    />
                  </Row>
                </div>

                <hr />
                <div className="wrapper-padding mb-3">
                  <p className="form-title mb-0 fonts-quicksand-medium">Deal information</p>
                  <span className="form-subtitle fonts-regular">
                    Add the discount type and dates below.
                  </span>
                  <Row className="mt-2">
                    <Col md={12}>
                      <TextInput
                        value={values.price}
                        placeholder="0.00"
                        label="RRP"
                        onChange={handleChange}
                        name="price"
                        error={touched.price && errors.price}
                        id="deal.price"
                        leftText="$"
                        type="number"
                      />
                    </Col>
                    <Col md={12}>
                      <TextInput
                        onSelectOption={(value) => {
                          setFieldValue('discountType', value);
                        }}
                        dropdownValue={values.discountType}
                        withOptions
                        options={[
                          {
                            name: 'Percentage (%)',
                            _id: 'percent'
                          },
                          {
                            name: 'Flat Amount',
                            _id: 'flat'
                          }
                        ]}
                        value={values.discountValue}
                        placeholder={values?.discountType === 'flat' ? '0.00' : '0 %'}
                        label="Discount type"
                        onChange={handleChange}
                        name="discountValue"
                        error={touched.discountValue && errors.discountValue}
                        id="deal.discount"
                        type="number"
                      />
                    </Col>
                    <Col md={6}>
                      <TextInput
                        value={values.startDate && moment(values.startDate).format('YYYY-MM-DD')}
                        placeholder="Date from"
                        label="Date from"
                        type="date"
                        onChange={handleChange}
                        name="startDate"
                        error={touched.startDate && errors.startDate}
                        min={moment().format('YYYY-MM-DD')}
                        max={'9999-12-31'}
                        id="deal.startDate"
                      />
                    </Col>
                    <Col md={6}>
                      <TextInput
                        value={values.endDate && moment(values.endDate).format('YYYY-MM-DD')}
                        placeholder="Date to"
                        label="Date to"
                        type="date"
                        onChange={handleChange}
                        name="endDate"
                        error={touched.endDate && errors.endDate}
                        min={moment(values.startDate || new Date()).format('YYYY-MM-DD')}
                        id="deal.endDate"
                        max={'9999-12-31'}
                      />
                    </Col>
                  </Row>
                </div>
                <ErrorFocus />
              </FormWrapper>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default withMerchantAdmin(MerchantDealsCreate);
