import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

const LinkText = ({ title, to, className, color, onClick }) => {
  if (onClick) {
    return (
      <div to={to} className={`link-text ${className}`} style={{ color }} onClick={onClick}>
        {title}
      </div>
    );
  }
  return (
    <Link to={to} className={`link-text ${className}`} style={{ color }}>
      {title}
    </Link>
  );
};
export default LinkText;
