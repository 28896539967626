import React, { useEffect, useState } from 'react';
import { Col, FormCheck, Image, Modal, Row } from 'react-bootstrap';
import AppButton from '../../../../components/common/button/Button';
import Images from '../../../../constants/images';

const RefundTransactionModal = ({
  isItDealTx,
  refundTx,
  onClear,
  onClose,
  show,
  products = [],
  id,
  details
}) => {
  const [checked, setChecked] = useState(
    products?.length === 1
      ? {
          0: true
        }
      : {}
  );

  useEffect(() => {
    if (products?.length > 0) {
      setChecked(
        products?.length === 1
          ? {
              0: true
            }
          : {}
      );
    }
  }, [id, products]);

  const [showRefundAlert, setShowRefundAlert] = useState(false);

  const selectedItems = Object.keys(checked).map((index) => products?.[index]);

  const isAllRefunded =
    products?.length ===
    products?.filter((product) => product?.paymentStatus === 'refunded')?.length;

  const renderProductRefund = () => {
    if (isItDealTx) {
      return null;
    }

    return (
      <>
        {showRefundAlert ? (
          <div className="filter-content-wrapper mt-2 mb-4">
            Do you really want a refund on the selected items{' '}
            {selectedItems?.map((selectedItem) => selectedItem?.variant_id?.title).join(', ')}?
          </div>
        ) : (
          <div className="filter-content-wrapper">
            <p>Please select the items that have to be refunded.</p>
            {products?.map((product, index) => {
              const totalAmount = Number(
                (product?.price || 0) * (product?.quantity || 0) +
                  parseFloat(product?.variant_id?.estimatedShippingCost)
              ).toFixed(2);

              const totalDiscount = Number(
                (product?.centz ?? '0') * (product?.quantity || 0)
              ).toFixed(2);
              return (
                <>
                  <Row
                    className={`p-2 mt-2 mb-2 ${
                      product?.paymentStatus === 'refunded' ? 'refund-row' : ''
                    }`}
                    key={index}>
                    <Col md={2}>
                      <FormCheck
                        disabled={products?.length === 1}
                        checked={checked?.[index] || product?.paymentStatus === 'refunded'}
                        onChange={(e) => {
                          if (product?.paymentStatus === 'refunded') {
                            return;
                          }
                          if (e.target.checked) {
                            setChecked({
                              ...checked,
                              [index]: e.target.checked
                            });
                          } else {
                            delete checked?.[index];
                            setChecked({
                              ...checked
                            });
                          }
                        }}></FormCheck>
                    </Col>
                    <Col md={2}>
                      <Image
                        style={{ height: 100, width: 100 }}
                        src={product?.variant_id?.images?.[0] ?? product?.variant_id?.images}
                      />
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col>
                          <h4>{product?.variant_id?.title}</h4>
                          <p>{product?.variant_id?.primaryCat}</p>
                        </Col>
                        {product?.paymentStatus === 'refunded' ? (
                          <Col>
                            <h4 className="text-green">REFUNDED</h4>
                          </Col>
                        ) : null}
                      </Row>
                      <Row>
                        <Col>
                          <h6>Purchase amount: ${totalAmount}</h6>
                        </Col>
                        <Col>
                          <h6 className="text-green">Centavizer discount: ${totalDiscount}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h6>
                            Shipping fee: ${product?.variant_id?.estimatedShippingCost ?? '0'}
                          </h6>
                        </Col>
                        <Col>
                          <h6>Quantity: {product?.quantity}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h6>Total amount: ${Number(totalAmount - totalDiscount).toFixed(2)}</h6>
                        </Col>
                        <Col>
                          <h6>
                            Refund amount: ${Number(totalAmount - totalDiscount).toFixed(2)}
                            {/* {Number(
                              Number(totalAmount - totalDiscount).toFixed(2) -
                                parseFloat(product?.variant_id?.estimatedShippingCost || '0')
                            ).toFixed(2)} */}
                          </h6>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr className="mt-2" />
                </>
              );
            })}
          </div>
        )}
      </>
    );
  };
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`filter-modal ${showRefundAlert ? '' : 'refund'}`}
      show={show}
      onHide={() => {
        if (showRefundAlert) {
          setShowRefundAlert(false);
        } else {
          setShowRefundAlert(false);
          setChecked({});
          onClose();
        }
      }}>
      <Modal.Header closeButton>
        <Image src={Images.refundGreen} />
        <div className="heading">REFUND ITEMS LIST</div>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: 600, overflow: 'hidden', overflowY: 'auto' }}>
        {isItDealTx ? (
          <div className="filter-content-wrapper mt-2 mb-4">
            Do you really want a refund on this deal?
            <Row className="mt-2">
              <Col>
                <h6>Deal name: {details?.deal_id?.name}</h6>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>
                <h6>Value : ${Number(details?.total || '0')?.toFixed(2)}</h6>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>
                <h6>Centavizer discount : ${Number(details?.centzTotal, '0')?.toFixed(2)}</h6>
              </Col>
            </Row>
          </div>
        ) : (
          renderProductRefund()
        )}
      </Modal.Body>
      {Object.keys(checked ?? {})?.length > 0 && !showRefundAlert && !isItDealTx && !isAllRefunded && (
        <Modal.Footer>
          <AppButton
            title={'Cancel'}
            id="filter-cancel-btn"
            onClick={() => {
              setShowRefundAlert(false);
              setChecked({});
              onClose();
              onClear();
            }}></AppButton>
          <AppButton
            title={'Refund'}
            id="filter-result-btn"
            onClick={() => {
              setShowRefundAlert(true);
            }}></AppButton>
        </Modal.Footer>
      )}
      {(showRefundAlert || isItDealTx) && (
        <Modal.Footer>
          <AppButton
            title={'Cancel'}
            id="filter-cancel-btn"
            onClick={() => {
              if (isItDealTx) {
                setShowRefundAlert(false);
                setChecked({});
                onClose();
                onClear();
              } else {
                setShowRefundAlert(false);
              }
            }}></AppButton>
          <AppButton
            title={'Yes, Refund'}
            id="filter-result-btn"
            onClick={() => {
              refundTx({
                id,
                sku: !isItDealTx
                  ? selectedItems?.map((selectedItem) => selectedItem?.variant_id?.sku)
                  : null
              });
              setShowRefundAlert(false);
              setChecked({});
              onClose();
            }}></AppButton>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default RefundTransactionModal;
