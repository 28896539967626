import Images from './images';

export const APP_ROLES = {
  ADMIN: 'superadmin',
  MERCHANT: 'merchant'
};

export const MERCHANT_APPLICATION_SETTINGS = {
  pending: 'pending',
  submitted: 'review',
  approved: 'completed',
  rejected: 'rejected'
};
export const PageTitleAndBreadcrumbs = {
  '/merchant/settings/business-profile': {
    title: 'Business Profile',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Dashboard',
        icon: '',
        link: '/merchant/dashboard'
      },
      {
        title: 'Settings',
        icon: '',
        link: '/merchant/settings/business-profile'
      },
      {
        title: 'Business Profile',
        icon: '',
        link: '/merchant/settings/business-profile'
      }
    ]
  },
  '/merchant/settings/merchant-account': {
    title: 'Merchant application',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Dashboard',
        icon: '',
        link: '/merchant/dashboard'
      },
      {
        title: 'Profile',
        icon: '',
        link: '/merchant/settings/business-profile'
      },
      {
        title: 'Account Settings',
        icon: '',
        link: '/merchant/settings/merchant-account'
      }
    ]
  },
  '/merchant/settings/login-settings': {
    title: 'Account Settings',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Dashboard',
        icon: '',
        link: '/merchant/dashboard'
      },
      {
        title: 'Settings',
        icon: '',
        link: '/merchant/settings/business-profile'
      },
      {
        title: 'Account Settings',
        icon: '',
        link: '/merchant/settings/login-settings'
      }
    ]
  },
  '/merchant/settings/merchant-account-status': {
    title: 'Merchant Application',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Dashboard',
        icon: '',
        link: '/merchant/dashboard'
      },
      {
        title: 'Profile',
        icon: '',
        link: '/merchant/settings/business-profile'
      },
      {
        title: 'Account Settings',
        icon: '',
        link: '/merchant/settings/merchant-account-status'
      }
    ]
  },
  '/merchant/deals': {
    title: 'Your deals',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Dashboard',
        icon: '',
        link: '/merchant/dashboard'
      },
      {
        title: 'Deal management',
        icon: '',
        link: '/merchant/deals'
      },
      {
        title: 'All deals',
        icon: '',
        link: '/merchant/deals'
      }
    ]
  },
  '/merchant/deals/create': {
    title: 'Add a new deal',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Dashboard',
        icon: '',
        link: '/merchant/dashboard'
      },
      {
        title: 'Deal management',
        icon: '',
        link: '/merchant/deals'
      },
      {
        title: 'New Deal',
        icon: '',
        link: '/merchant/deals/create'
      }
    ]
  },
  '/merchant/deals/update': {
    title: 'Edit deal',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Dashboard',
        icon: '',
        link: '/merchant/dashboard'
      },
      {
        title: 'Deal management',
        icon: '',
        link: '/merchant/deals'
      },
      {
        title: 'Edit Deal',
        icon: '',
        link: ''
      }
    ]
  },
  '/merchant/dashboard': {
    title: 'Dashboard',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Dashboard',
        icon: '',
        link: '/merchant/dashboard'
      },
      {
        title: 'Deal management',
        icon: '',
        link: '/merchant/deals'
      },
      {
        title: 'All deals',
        icon: '',
        link: ''
      }
    ]
  },
  '/merchant/transaction-history': {
    title: 'All Transactions',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Dashboard',
        icon: '',
        link: '/merchant/dashboard'
      },
      {
        title: 'All Transactions',
        icon: '',
        link: ''
      }
    ]
  },
  '/admin/user-management': {
    title: 'User Management',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'User Management',
        icon: '',
        link: '/admin/user-management',
        same: true
      }
    ]
  },
  '/admin/user-management/edit': {
    title: 'User Management',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'User Management',
        icon: '',
        link: '/admin/user-management',
        goBack: true
      },
      {
        title: '[username]',
        icon: '',
        link: ''
      }
    ]
  },
  '/admin/merchant/management': {
    title: 'Merchant Management',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Merchant Management',
        icon: '',
        link: '/admin/merchant/management',
        same: true
      }
    ]
  },
  '/admin/merchant/management/edit': {
    title: 'Merchant Management',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Merchant Management',
        icon: '',
        link: '/admin/merchant/management',
        goBack: true
      },
      {
        title: '[business name]',
        icon: '',
        link: ''
      }
    ]
  },
  '/admin/merchant/management/view-deal': {
    title: 'Merchant Deals',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Merchant Management',
        icon: '',
        link: '/admin/merchant/management',
        goBack: true
      },
      {
        title: '[business name]',
        icon: '',
        link: ''
      },
      {
        title: 'View deals',
        icon: '',
        link: ''
      }
    ]
  },
  '/admin/merchant/category': {
    title: 'Merchant Category',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Merchant Category',
        icon: '',
        link: '/admin/merchant/category',
        same: true
      }
    ]
  },
  '/admin/merchant-survey': {
    title: 'Merchant Survey',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Merchant Survey',
        icon: '',
        link: ''
      }
    ]
  },
  '/admin/merchant-survey/create': {
    title: 'Merchant Survey',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Merchant Survey',
        icon: '',
        link: '/admin/merchant-survey'
      },
      {
        title: 'New Survey',
        icon: '',
        link: ''
      }
    ]
  },
  '/admin/merchant-survey/edit': {
    title: 'Merchant Survey',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Merchant Survey',
        icon: '',
        link: '/admin/merchant-survey'
      },
      {
        title: '[survey name]',
        icon: '',
        link: ''
      }
    ]
  },
  '/admin/marketplace/transactions': {
    title: 'Marketplace Transactions',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Marketplace',
        icon: '',
        link: '/admin/marketplace'
      },
      {
        title: 'All Transactions',
        icon: '',
        link: ''
      }
    ]
  },
  '/admin/marketplace/stock': {
    title: 'Marketplace Category',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Marketplace',
        icon: '',
        link: '/admin/marketplace/stock'
      },
      {
        title: 'Stock Category',
        icon: '',
        link: ''
      }
    ]
  },
  '/admin/marketplace/stock/stock-category': {
    title: 'Marketplace Category',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Marketplace',
        icon: '',
        link: '/admin/marketplace/stock'
      },
      {
        title: 'Stock Category',
        icon: '',
        link: '/admin/marketplace/stock'
      },
      {
        title: '[Stock category name]',
        icon: '',
        link: ''
      }
    ]
  },
  '/admin/suggested-business': {
    title: 'Suggested Business',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Suggested Business',
        icon: '',
        link: ''
      }
    ]
  },
  '/admin/profile-settings': {
    title: 'Admin Profile',
    breadcrumb: [
      {
        title: '',
        icon: '',
        link: ''
      },
      {
        title: 'Admin Profile',
        icon: '',
        link: ''
      }
    ]
  }
};

export const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&~`^().[\]{}><:'",/\\_=+-])[A-Za-z\d@$!%*#?&~`^().[\]{}><:'",/\\_=+-]{8,}$/;

export const passwordMessage =
  'Password must contain at least 8 characters, one number and one special case character and one capital letter.';
export const ownershipTypes = [
  { name: 'Association/Estate/Trust', _id: 'A' },
  { name: 'Corporation', _id: 'C' },
  { name: 'Government', _id: 'G' },
  { name: 'International Organization', _id: 'I' },
  { name: 'LLC', _id: 'L' },
  { name: 'Medical/Legal Corporation', _id: 'M' },
  { name: 'Non-Profit', _id: 'N' },
  { name: 'Partnership', _id: 'P' },
  { name: 'Sole Proprietor', _id: 'S' },
  { name: 'Tax Exempt Organization', _id: 'T' }
];

export const baseFilePaths = {
  dealImage: `${process.env.REACT_APP_GOOGLE_STORAGE_BASE_PATH}dealimages/`,
  businessProfile: `${process.env.REACT_APP_GOOGLE_STORAGE_BASE_PATH}businessprofile/`,
  businessBanner: `${process.env.REACT_APP_GOOGLE_STORAGE_BASE_PATH}businessbanner/`,
  userProfileImage: `${process.env.REACT_APP_GOOGLE_STORAGE_BASE_PATH}userprofile/`,
  marketplace: `${process.env.REACT_APP_GOOGLE_STORAGE_BASE_PATH}marketplace/`,
  survey: `${process.env.REACT_APP_GOOGLE_STORAGE_BASE_PATH}survey/`,
  category: `${process.env.REACT_APP_GOOGLE_STORAGE_BASE_PATH}category/`
};

export const StaticNotifications = [
  {
    title:
      'New Merchant Joined!! ultricies laoreet venenatis, sit maecenas. Eu quisque tristique dolor sed.',
    icon: Images.notification_new,
    time: 'Just Now',
    isSeen: true
  },
  {
    title:
      'The survey is complete!! ultricies laoreet venenatis, sit maecenas. Eu quisque tristique dolor sed.',
    icon: Images.notification_survey,
    time: '1 hour',
    isSeen: true
  },
  {
    title:
      'New Suggested Business!! ultricies laoreet venenatis, sit maecenas. Eu quisque tristique dolor sed.',
    icon: Images.notification_business,
    time: '1 week',
    isSeen: false
  }
];

export const totalFeatureAllowedProducts = 5;

export const URLRegex =
  /^((https?|ftp|smtp):\/\/)?(www\.)?[a-z0-9@\\-]+(\.[a-z\\-]{2,}){1,3}(#?\/?[a-zA-Z0-9@\-#]+)*\/?(\?[a-zA-Z0-9-_@\\-]+=[a-zA-Z0-9-%@]+&?)?$/;
