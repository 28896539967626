import * as Yup from 'yup';

export const BusinessInfoSchema = Yup.object().shape({
  businessName: Yup.string().required('Please enter name'),
  address: Yup.string().required('Please enter address'),
  apartmentNo: Yup.string().optional(),
  city: Yup.string().required('Please enter city'),
  state_id: Yup.string().required('Please select state'),
  country_id: Yup.string().required('Please select country'),
  zipcode: Yup.string().required('Please enter zip code'),
  latitude: Yup.number().typeError('Please enter valid Latitude').optional(),
  longitude: Yup.number().typeError('Please enter valid Longitude').optional(),
  category_id: Yup.string().required('Please select category')
});

export const BusinessInfoSchemaManualEntry = Yup.object().shape({
  businessName: Yup.string().required('Please enter name'),
  address: Yup.string().required('Please enter address'),
  apartmentNo: Yup.string().optional(),
  city: Yup.string().required('Please enter city'),
  state_id: Yup.string().required('Please select state'),
  country_id: Yup.string().required('Please select country'),
  zipcode: Yup.string().required('Please enter zip code'),
  latitude: Yup.number().typeError('Please enter valid Latitude').optional(),
  longitude: Yup.number().typeError('Please enter valid Longitude').optional(),
  category_id: Yup.string().required('Please select category')
});

export const BusinessInfoInitValues = {
  businessName: '',
  address: '',
  apartmentNo: '',
  city: '',
  state_id: '',
  country_id: '',
  zipcode: '',
  category_id: '',
  latitude: '',
  longitude: '',
  businessDescription: ''
};
