import React from 'react';
import withAdmin from '../../../components/hoc/admin/withAdmin';
import ProfileInformation from '../../../components/admin/common-form-sections/ProfileInformation';
import UpdatePassword from '../../../components/admin/common-form-sections/UpdatePassword';
import './styles.css';

const ProfileSettings = () => {
  return (
    <>
      <div className="business-profile-card profile-setting-wrapper">
        <ProfileInformation></ProfileInformation>
        <UpdatePassword></UpdatePassword>
      </div>
    </>
  );
};

export default withAdmin(ProfileSettings);
