import { gql } from '@apollo/client';

export const UPLOAD_DEAL_IMAGES = gql`
  mutation UploadDealImage($input: dealImageUpload) {
    uploadDealImage(input: $input) {
      status
      message
      data {
        dealImages
        _id
      }
      errorCode
    }
  }
`;
