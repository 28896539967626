import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import LoaderComponent from '../../components/common/loader/LoaderComponent';
import UserContext from '../../contexts/user/UserContext';

export const PrivateRoute = ({ children, redirectPath, role }) => {
  const { details, loading } = useContext(UserContext);

  const renderPage = () => {
    if (details?.me?.merchant?.userType === role) {
      return children ?? <Outlet />;
    }

    return <Navigate to={redirectPath} replace />;
  };

  return (
    <>
      {loading ? <LoaderComponent /> : null}
      {renderPage()}
    </>
  );
};
