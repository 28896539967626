import { gql } from '@apollo/client';

export const GET_PRODUCTS_VARIANTS = gql`
  query GetVariants(
    $limit: Int
    $category: String
    $offset: Int
    $isFeatured: Boolean
    $search: String
  ) {
    getVariants(
      limit: $limit
      category: $category
      offset: $offset
      isFeatured: $isFeatured
      search: $search
    ) {
      status
      message
      data {
        _id
        sku
        title
        msrp
        weight
        length
        width
        height
        cost
        quantity
        images
        price
        estimatedShippingCost
        weightUnit
        featured
        sort
      }
      total
      limit
      offset
      errorCode
    }
  }
`;
