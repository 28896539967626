import React from 'react';
import { Card, Image } from 'react-bootstrap';
import Images from '../../../constants/images';
import PerformanceBarChart from './PerformanceBarChart';
import PerformanceLineChart from './PerformanceLineChart';

const PerformanceCard = () => {
  return (
    <Card className="performance-card">
      <Card.Header>
        <div className="box-header">
          <Image src={Images.performance_box_icon} />
          <div className="title">Performance</div>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="performance-content text-center">
          <div className="custom-chart-bg">
            <PerformanceBarChart></PerformanceBarChart>
          </div>
          <div className="custom-chart-bg">
            <PerformanceLineChart></PerformanceLineChart>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PerformanceCard;
