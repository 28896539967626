import React, { useContext, useState } from 'react';
import { Breadcrumb, Image } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageTitleAndBreadcrumbs } from '../../../constants';
import Images from '../../../constants/images';
import NotificationContext from '../../../contexts/NotificationContext';
import OffCanvas from '../offCanvas/offCanvas';

import './styles.css';

const PageHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    newNotificaton,
    notifications = [],
    setNotificationContextData
  } = useContext(NotificationContext);

  const params = useParams();
  let path = location.pathname;

  if (path.includes('/merchant/deals/update')) {
    path = '/merchant/deals/update';
  }

  if (path.includes('/admin/merchant/management/view-deal')) {
    path = '/admin/merchant/management/view-deal';
  }

  if (path.includes('/admin/marketplace/stock/stock-category')) {
    path = '/admin/marketplace/stock/stock-category';
  }

  if (path.includes('/admin/merchant-survey/edit')) {
    path = '/admin/merchant-survey/edit';
  }

  if (path.includes('/admin/user-management/edit')) {
    path = '/admin/user-management/edit';
  }

  if (path.includes('/admin/merchant/management/edit')) {
    path = '/admin/merchant/management/edit';
  }

  const pageSettings = PageTitleAndBreadcrumbs?.[path] ?? {};

  if (path.includes('/admin/merchant/management/view-deal')) {
    pageSettings.breadcrumb[2] = {
      title: params?.businessName,
      icon: '',
      link: `/admin/merchant/management/edit/${params?.businessName}/${params?.merchantId}`
    };
  }

  if (path.includes('/admin/marketplace/stock/stock-category')) {
    pageSettings.breadcrumb[3] = {
      title: params?.categoryName,
      icon: '',
      link: location.pathname
    };
  }

  if (path.includes('/admin/merchant-survey/edit')) {
    pageSettings.breadcrumb[2] = {
      title: params?.surveyName,
      icon: '',
      link: location.pathname
    };
  }

  if (path.includes('/admin/user-management/edit')) {
    pageSettings.breadcrumb[2] = {
      title: params?.userName ?? '',
      icon: '',
      link: location.pathname
    };
  }

  if (path.includes('/admin/merchant/management/edit')) {
    pageSettings.breadcrumb[2] = {
      title: params?.merchantName ?? '',
      icon: '',
      link: location.pathname
    };
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setNotificationContextData({
      newNotificaton: 0,
      notifications
    });
    setShow(true);
  };

  const renderLink = (d) => {
    if (d?.same) {
      return (
        <a className="fonts-regular text-capitalize" href="javascript:void(0)">
          {d.title}
        </a>
      );
    }
    if (d?.goBack) {
      return (
        <a
          className="fonts-regular text-capitalize"
          href="javascript:void(0)"
          onClick={() => navigate(-1, { replace: true })}>
          {d.title}
        </a>
      );
    }
    return (
      <Link className="fonts-regular text-capitalize" to={d?.link}>
        {d.title}
      </Link>
    );
  };
  return (
    <div className={`page-header breadcrum-spacing ${location.pathname}`}>
      <div className="header-left">
        <p className="title fonts-quicksand-medium mb-1">{pageSettings?.title}</p>
        <Breadcrumb>
          <Image src={Images.breadcrum_home} at=""></Image>
          {Array.isArray(pageSettings?.breadcrumb)
            ? pageSettings?.breadcrumb.map((d) => {
                return (
                  <Breadcrumb.Item key={d.title} active>
                    {renderLink(d)}
                  </Breadcrumb.Item>
                );
              })
            : null}
        </Breadcrumb>
      </div>
      {window.location.pathname.includes('admin') ? (
        <div className="header-right">
          <div className="notification-area">
            <button type="button" className="position-relative" onClick={handleShow}>
              <Image src={Images.notification_bell}></Image>
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-orange">
                {newNotificaton}
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>
          </div>
        </div>
      ) : (
        <div className="header-right">
          <div className="notification-area">
            <button type="button" className="position-relative" onClick={handleShow}>
              <Image src={Images.notification_bell}></Image>
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-orange">
                {newNotificaton}
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>
          </div>
        </div>
      )}
      <OffCanvas
        show={show}
        onHide={handleClose}
        placement="end"
        title="Notifications"
        className="notification-off-canvas"
        notifications={notifications}></OffCanvas>
    </div>
  );
};

export default PageHeader;
