import React from 'react';
import { Image } from 'react-bootstrap';

const CommonFooter = () => {
  return (
    <>
      <div className="sidebar-footer d-flex">
        <div className="left">
          <span>&copy; Centavizer {new Date().getFullYear()}</span>
        </div>
        <div className="right">
          <a href="mailto:info@centavizer.com" style={{ textDecoration: 'none' }}>
            <span>
              <Image src="/assets/images/email.png" alt="" /> info@centavizer.com
            </span>
          </a>
        </div>
      </div>
    </>
  );
};

export default CommonFooter;
