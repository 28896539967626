import { gql } from '@apollo/client';

export const GET_SUB_CATEGORIES = gql`
  mutation GetSubCategory($input: getSubCategory!) {
    getSubCategory(input: $input) {
      status
      message
      errorCode
      data {
        _id
        name
        refId {
          _id
          name
        }
      }
    }
  }
`;
