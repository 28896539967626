import React, { useEffect, useState } from 'react';
import { Image, ListGroup } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Images from '../../../constants/images';
import { doLogout } from '../../../services/logoutService';
import AppButton from '../../common/button/Button';
import FeedbackModal from '../../common/feedback-modal/FeedbackModal';
import './styles.css';

const menuItems = [
  {
    key: 'user_management',
    title: 'User Management',
    link: '/admin/user-management',
    icon: Images.UserManagementActive,
    iconInactive: Images.UserManagementDeactive
  },
  {
    key: 'merchant_management',
    title: 'Merchant',
    link: '/admin/merchant/management',
    icon: Images.MerchantManagementActive,
    iconInactive: Images.MerchantManagementDeactive,
    children: [
      {
        key: 'merchant/merchant_management',
        title: 'Merchant Management',
        link: '/admin/merchant/management',
        icon: ''
      },
      {
        key: 'merchant/merchant_category',
        title: 'Merchant Category',
        link: '/admin/merchant/category',
        icon: ''
      }
    ]
  },
  {
    key: 'merchant_survey',
    title: 'Merchant Survey',
    link: '/admin/merchant-survey',
    icon: Images.MerchantSurveyActive,
    iconInactive: Images.MerchantSurveyDeactive
  },
  {
    key: 'marketplace',
    title: 'Marketplace',
    link: '/admin/marketplace/stock',
    icon: Images.MarketplaceActive,
    iconInactive: Images.MarketplaceDeactive,
    children: [
      {
        key: 'marketplace/stock',
        title: 'Stock Category',
        link: '/admin/marketplace/stock',
        icon: ''
      },
      {
        key: 'marketplace/account_settings',
        title: 'Transactions',
        link: '/admin/marketplace/transactions',
        icon: ''
      }
    ]
  },
  {
    key: 'suggested_business',
    title: 'Suggested Business',
    link: '/admin/suggested-business',
    icon: Images.SuggestedBusinessActive,
    iconInactive: Images.SuggestedBusinessDeactive
  },
  /*add other menu above this line*/
  {
    key: 'profile_settings',
    title: 'Profile Settings',
    link: '/admin/profile-settings',
    icon: Images.SettingsActive,
    iconInactive: Images.SettingsInactive
  }
];
const AdminSideMenu = ({ user }) => {
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState();
  const [showLogoutModal, setShowLogoutModal] = useState();

  useEffect(() => {
    if (location.pathname.includes('settings')) {
      setOpenMenu('settings');
    }
  }, [location.pathname]);

  return (
    <>
      <FeedbackModal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        title="Logout"
        subTitle="Are you sure you want to logout?"
        className="delete-deal-modal"
        buttons={[
          <AppButton
            title={'Cancel'}
            key="1"
            buttonClass={'btn-cancel'}
            onClick={() => setShowLogoutModal(false)}
            id="logout.cancel"
          />,
          <AppButton
            buttonClass={'error-bg'}
            title={'Yes, Logout'}
            key="2"
            onClick={() => {
              doLogout({ isAdmin: true });
              setShowLogoutModal(false);
            }}
            id="logout.confirm"
          />
        ]}
      />
      <div className="onboarding-step-bar side-menu">
        <Image src={Images.logo_with_name} alt="centavizer-logo" className="logo" />
        <input type="checkbox" className="mobile-menu-checkbox" />
        <div className="menu-icon">
          <span className="hemburger"></span>
          <span className="hemburger"></span>
          <span className="hemburger"></span>
        </div>
        <div className="menu-items">
          <div className="mt-3 menu-links" user={user}>
            <ListGroup as="ul">
              {menuItems.map((menuItem) => {
                let isActive = location?.pathname?.includes(menuItem?.link);
                if (Array.isArray(menuItem.children)) {
                  menuItem.children.forEach((c) => {
                    if (location?.pathname?.includes(c?.link)) {
                      isActive = true;
                    }
                  });
                }
                const haveChild = Array.isArray(menuItem.children) && menuItem.children.length;
                return (
                  <ListGroup.Item
                    action
                    key={menuItem.key}
                    as="li"
                    active={isActive}
                    className={`${isActive ? 'active' : ''} ${haveChild ? 'active-child' : ''}`}
                    href={menuItem.link}>
                    {haveChild ? (
                      <a
                        href="javascript:void(0)"
                        onClick={() => setOpenMenu(openMenu === menuItem.key ? 0 : menuItem.key)}>
                        <Image src={isActive ? menuItem.icon : menuItem.iconInactive} />
                        <span className="fonts-regular">&nbsp;&nbsp;{menuItem.title}</span>
                        <Image
                          className="float-end dropdown-icon"
                          src={openMenu === menuItem.key ? Images.up : Images.down}
                        />
                      </a>
                    ) : (
                      <Link to={menuItem.link}>
                        <Image src={isActive ? menuItem.icon : menuItem.iconInactive} />
                        <span className="fonts-regular">&nbsp;&nbsp;{menuItem.title}</span>
                      </Link>
                    )}

                    {Array.isArray(menuItem.children) && openMenu === menuItem.key ? (
                      <div className="child-menu mt-3">
                        {menuItem.children.map((_menuItem) => {
                          const _isActive = location?.pathname?.includes(_menuItem?.link);
                          return (
                            <ListGroup.Item
                              action
                              key={_menuItem.key}
                              as="li"
                              active={_isActive}
                              className={`${_isActive ? 'active' : ''}`}
                              href={_menuItem.link}>
                              <Link to={_menuItem.link}>
                                <span className="fonts-regular">&nbsp;&nbsp;{_menuItem.title}</span>
                              </Link>
                            </ListGroup.Item>
                          );
                        })}
                      </div>
                    ) : null}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </div>
          <div className="sidebar-bottom">
            <div className="sidebar-footer d-flex align-items-center justify-content-between">
              <div className="admin-info">
                <Image className="company-img" src={Images.admin_logo} />
                <span className="text-white">Centavizer Admin</span>
              </div>
              <Image
                id="logout-btn"
                className="logout-img"
                src={Images.logout}
                onClick={() => setShowLogoutModal(true)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSideMenu;
