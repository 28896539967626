import React from 'react';
import { Card } from 'react-bootstrap';
import AppButton from '../button/Button';

const FormWrapper = ({
  children,
  handleSubmit,
  anotherHandleSubmit,
  showCancel = true,
  showFooter = true,
  onCancel = () => null,
  title = 'Save changes',
  otherHandleSubmitTitle
}) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}>
      <Card className="mt-3 bg-white form-card">
        <Card.Body className="p-0">{children}</Card.Body>
        {showFooter ? (
          <Card.Footer className="bg-primary d-flex justify-content-end form-card-footer">
            {showCancel ? (
              <AppButton
                title="Cancel"
                buttonClass={'btn-cancel'}
                onClick={onCancel}
                id="form.cancel"
                type="reset"
              />
            ) : null}
            <AppButton title={title} onClick={handleSubmit} id="form.save" type="submit" />
            {otherHandleSubmitTitle ? (
              <AppButton
                title={otherHandleSubmitTitle}
                onClick={anotherHandleSubmit}
                id="form.submit"
                type="button"
              />
            ) : null}
          </Card.Footer>
        ) : null}
      </Card>
    </form>
  );
};

export default FormWrapper;
