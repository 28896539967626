import { gql } from '@apollo/client';

export const GET_TERMS_AND_CONDITION = gql`
  query GetTermsAndCondition {
    getTermsAndCondition {
      status
      message
      data
      errorCode
    }
  }
`;
