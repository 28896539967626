import React, { useState } from 'react';
import { Col, Image, ListGroup, ListGroupItem } from 'react-bootstrap';
import Images from '../../../constants/images';
import CacheImage from '../CacheImage/CacheImage';

const ImagePreviews = ({
  previewImages,
  selectedImagesFormated,
  uploading,
  doDeleteDealImage,
  _id,
  selectedImages,
  setSelectedImages,
  imageProgressRef
}) => {
  const [progress, setProgress] = useState(0);
  const [deleteIndex, setDeleteIndex] = useState();
  React.useEffect(() => {
    if (imageProgressRef) {
      imageProgressRef.current = updateProgress;
    }
  }, []);

  const updateProgress = (progress) => {
    setProgress((progress * 100).toFixed(2));
  };
  return (
    <Col md={12}>
      <ListGroup>
        {Array.isArray(previewImages) && Array.isArray(selectedImagesFormated)
          ? [...selectedImagesFormated, ...previewImages].map((previewImage, index) => {
              let size = previewImage?.file?.size;
              if (size > 1024 * 1024) {
                size = `${(size / (1024 * 1024)).toFixed(2)} MB`;
              } else {
                size = `${size} B`;
              }
              return (
                <ListGroupItem
                  key={previewImage?.url}
                  className="d-flex align-items-center justify-content-between image-section-line"
                  style={{ borderTopWidth: index !== 0 ? 1 : 0 }}>
                  {progress > 0 && progress <= 100 && previewImage?.file?.size && (
                    <div className="image-progress-indicator" style={{ width: `${progress}%` }} />
                  )}
                  <div className="d-flex">
                    <CacheImage
                      url={previewImage?.url}
                      style={{
                        height: 60,
                        width: 60,
                        marginRight: 12,
                        borderRadius: 12
                      }}
                    />
                    <div className="d-flex flex-column justify-content-center">
                      <p className="image-name fonts-regular m-0">{previewImage?.name}</p>
                      {previewImage?.file?.size ? (
                        <p className="image-name-size fonts-regular m-0">
                          {size}
                          {progress > 0 && progress <= 100 && previewImage?.file?.size
                            ? ` - ${progress}% uploaded`
                            : ''}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  {uploading || (deleteIndex && previewImage?.name === deleteIndex) ? (
                    <span className="sm-loader float-end mt-2"></span>
                  ) : (
                    <Image
                      role="button"
                      className="float-end"
                      src={Images.close}
                      onClick={() => {
                        if (!previewImage?.file) {
                          setDeleteIndex(previewImage?.name);
                          doDeleteDealImage?.({
                            variables: {
                              input: {
                                _id: _id,
                                filename: previewImage?.name
                              }
                            }
                          });
                        } else {
                          selectedImages.splice(previewImage?.index, 1);
                          setSelectedImages([...selectedImages]);
                        }
                      }}
                    />
                  )}
                </ListGroupItem>
              );
            })
          : null}
      </ListGroup>
    </Col>
  );
};

export default ImagePreviews;
