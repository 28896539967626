import React, { useEffect, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { ListGroupItem } from 'react-bootstrap';
import TextInput from '../text-input/TextInput';

const GoogleAutoComplete = ({ placeholder, onSelect, selectedPlaceIs }) => {
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      options: {
        componentRestrictions: {
          country: 'us'
        }
      }
    });

  const [selectedPlace, setSelectedPlace] = useState('');
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (selectedPlaceIs === false && selectedPlace !== '') {
      setSelectedPlace('');
    }
  }, [selectedPlaceIs]);

  const savePlaceDetailsToState = (details) => {
    setSelected(true);
    setSelectedPlace(details?.formatted_address);
    onSelect?.(details);
  };

  const getDetailsOfPlace = (placeId) => {
    placesService?.getDetails(
      {
        placeId
      },
      (placeDetails) => savePlaceDetailsToState(placeDetails)
    );
  };

  const renderItem = (item) => {
    return (
      <ListGroupItem
        style={{ cursor: 'pointer' }}
        onClick={() => getDetailsOfPlace(item?.place_id)}>
        {item?.description}
      </ListGroupItem>
    );
  };

  return (
    <>
      <TextInput
        value={selectedPlace}
        placeholder={placeholder}
        onChange={(evt) => {
          setSelectedPlace(evt.target.value);
          if (evt.target.value?.length >= 3) {
            setSelected(false);
            getPlacePredictions({ input: evt.target.value });
          }
        }}
        loading={isPlacePredictionsLoading}
        id="googleAutoComplete.input"
        error={
          !isPlacePredictionsLoading &&
          selectedPlace?.length >= 3 &&
          placePredictions?.length === 0 &&
          'No Results found.'
        }
      />
      {!selected && selectedPlace?.length >= 3 && placePredictions.map((item) => renderItem(item))}
    </>
  );
};

export default GoogleAutoComplete;
