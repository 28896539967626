import { gql } from '@apollo/client';

export const DELETE_SURVEY_OPTION = gql`
  query DeleteOption($id: ID) {
    deleteOption(_id: $id) {
      status
      message
      errorCode
    }
  }
`;
