import React from 'react';
import { Card, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { baseFilePaths } from '../../../constants';
import Images from '../../../constants/images';
import { formatPhoneNo, getAddress } from '../../../services';

const DealInfoCard = ({ merchantDetails, merchant }) => {
  let bgImage = '';
  if (
    merchantDetails?.business_data?.businessLogo &&
    merchantDetails?.business_data?.businessLogo !== null &&
    merchantDetails?.business_data?.businessLogo !== 'null'
  ) {
    bgImage = `${baseFilePaths.businessProfile}${merchantDetails?.business_data?.businessLogo}`;
  } else if (
    merchantDetails?.business_data?.logoUrl &&
    merchantDetails?.business_data?.logoUrl !== null &&
    merchantDetails?.business_data?.logoUrl !== 'null'
  ) {
    bgImage = `${merchantDetails?.business_data?.logoUrl}`;
  }

  return (
    <Card className="deal-info-card">
      <Card.Body>
        <div className="deal-info-content">
          <div className="image-content">
            <Image
              src={
                merchantDetails?.business_data?.bannerImages?.[0]
                  ? `${baseFilePaths.businessBanner}${merchantDetails?.business_data?.bannerImages?.[0]}`
                  : Images.deal_info_dummy_image
              }
            />
          </div>
          <div className="text-content">
            <div className="top-info">
              <div className="logo-name-detail">
                <div className="logo">
                  <Image src={bgImage || Images.dashboard_logo} />
                </div>
                <div className="name">
                  <div className="title fonts-quicksand-bold">
                    {merchantDetails?.business_data?.businessName}
                  </div>
                  <div className="category">
                    {merchantDetails?.business_data?.category_id?.name}
                  </div>
                </div>
              </div>
              <div className="edit-profile">
                {merchant ? (
                  <Link to="/merchant/settings/business-profile">
                    <Image src={Images.dashboard_edit} /> Edit profile
                  </Link>
                ) : null}
              </div>
            </div>
            <div className="bottom-info">
              <div className="address-detail">
                <Image src={Images.location} />
                <div className="text-line">{getAddress(merchantDetails?.business_data)}</div>
              </div>
              <div className="address-detail">
                <Image src={Images.dashboard_phone} />
                <div className="text-line">
                  {formatPhoneNo(merchantDetails?.business_data?.phoneno)}
                </div>
              </div>
              <div className="address-detail">
                <Image src={Images.website} />
                <div className="text-line" style={{ overflow: 'hidden', wordBreak: 'break-all' }}>
                  <a
                    href={`${merchantDetails?.business_data?.website}`}
                    target="_blank"
                    rel="noreferrer">
                    {merchantDetails?.business_data?.website}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default DealInfoCard;
