import React from 'react';
import { Card, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Images from '../../../constants/images';
import './styles.css';

const CreateNewDeal = () => {
  const navigate = useNavigate();
  return (
    <Card className="new-deal-card" onClick={() => navigate('/merchant/deals/create')}>
      <Image src={Images.plus} />
      <p className="fonts-quicksand-medium new-deal-card-text">add a new deal</p>
    </Card>
  );
};

export default CreateNewDeal;
