import { gql } from '@apollo/client';

export const UPDATE_CATEGORY_DISCOUNT = gql`
  mutation UpdateCatDiscount($input: updateDiscount!) {
    updateCatDiscount(input: $input) {
      status
      message
      data {
        _id
        primaryCat
        secondaryCat
        tertiaryCat
        discount
        catImage
      }
      errorCode
    }
  }
`;
