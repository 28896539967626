import { gql } from '@apollo/client';

export const GET_TOP_PERFORMING_DEALS = gql`
  query TopPerformingDealsChart {
    topPerformingDealsChart {
      status
      message
      data {
        dealName
        topDeal
      }
      errorCode
    }
  }
`;
