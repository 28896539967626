import { gql } from '@apollo/client';

export const CHANGE_MERCHANT_PASSWORD = gql`
  mutation ChangePasswordMerchant($input: chnagePassword!) {
    changePasswordMerchant(input: $input) {
      status
      message
      errorCode
    }
  }
`;
