import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AppButton from '../../../components/common/button/Button';
import GoogleAutoComplete from '../../../components/common/google-autocomplete/GoogleAutoComplete';
import LinkText from '../../../components/common/link-text/LinkText';
import withMerchantOnboarding from '../../../components/hoc/merchant/withMerchantOnboarding';
import ContentCardWrapper from '../../../components/merchant/onboarding/content-card-wrapper/ContentCardWrapper';
import { UserContext } from '../../../contexts';
import ToastContext from '../../../contexts/ToastContext';
import { MERCHANT_ADD_BUSINESS } from '../../../graphql/schema/mutations/onboarding/MERCHANT_ADD_BUSINESS';
import { sanitizeInput } from '../../merchant/settings/merchant-account/formUtils';
import { BusinessInformationForm } from './components/BusinessInformationForm';
import { SelectedBusiness } from './components/SelectedBusiness';

import './styles.css';

const timings = [
  {
    status: true,
    openingTime: '',
    closingTime: '',
    isOpenDay: false,
    index: 1
  },
  {
    status: true,
    openingTime: '',
    closingTime: '',
    isOpenDay: false,
    index: 2
  },
  {
    status: true,
    openingTime: '',
    closingTime: '',
    isOpenDay: false,
    index: 3
  },
  {
    status: true,
    openingTime: '',
    closingTime: '',
    isOpenDay: false,
    index: 4
  },
  {
    status: true,
    openingTime: '',
    closingTime: '',
    isOpenDay: false,
    index: 5
  },
  {
    status: true,
    openingTime: '',
    closingTime: '',
    isOpenDay: false,
    index: 6
  },
  {
    status: true,
    openingTime: '',
    closingTime: '',
    isOpenDay: false,
    index: 0
  }
];

const getTwoDigits = (number) =>
  number
    ? number?.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
    : '00';

const BusinessSearch = () => {
  const { setUserDetails } = useContext(UserContext);

  const { setToastDetails } = useContext(ToastContext);

  const navigate = useNavigate();
  const [selectedPlace, setSelectedPlace] = useState({});
  const [showForm, setShowForm] = useState(false);

  const [doAddBusiness, { data, loading, error }] = useMutation(MERCHANT_ADD_BUSINESS);
  const saveBusiness = (values) => {
    const phoneno = selectedPlace?.international_phone_number
      ? String(selectedPlace?.international_phone_number)
          .replace('+1', '')
          .replace('-', '')
          .replace(/ /g, '_')
      : undefined;
    let website = selectedPlace?.website ? selectedPlace?.website : undefined;
    if (website) {
      website = !website?.startsWith('http')
        ? `https://${selectedPlace?.website}`
        : selectedPlace?.website;
    }

    const timingsFormate = timings.map((t) => {
      const hours = selectedPlace?.opening_hours?.periods?.find((p) => p?.open?.day === t?.index);

      return {
        ...t.closingTime,
        openingTime: hours
          ? `${getTwoDigits(hours?.open?.hours)}:${getTwoDigits(hours?.open?.minutes)}`
          : ' ',
        closingTime: hours
          ? `${getTwoDigits(hours?.close?.hours)}:${getTwoDigits(hours?.close?.minutes)}`
          : ' ',
        status: hours ? true : false
      };
    });

    const latitude = selectedPlace?.geometry?.location?.lat?.();
    const longitude = selectedPlace?.geometry?.location?.lng?.();

    doAddBusiness({
      variables: {
        input: {
          ...values,
          phoneno: sanitizeInput(phoneno),
          website,
          timings: timingsFormate,
          logoUrl: selectedPlace?.photos?.[0]?.getUrl() || selectedPlace?.icon,
          apartmentNo: values?.apartmentNo || '',
          latitude: String(latitude || ''),
          longitude: String(longitude || '')
        }
      }
    });
  };

  useEffect(() => {
    if (!loading && data?.updateBusiness?.business?._id) {
      setUserDetails();
      navigate('/onboarding/terms-and-condition');
    } else {
      if (
        !loading &&
        data?.updateBusiness?.message &&
        data?.updateBusiness?.errorCode &&
        data?.updateBusiness?.errorCode !== '0'
      ) {
        setToastDetails({
          show: true,
          title: 'Error!',
          message: data?.updateBusiness?.message,
          type: 'danger'
        });
      }
    }
  }, [
    loading,
    error,
    data?.updateBusiness?.errorCode,
    data?.updateBusiness?.message,
    data?.updateBusiness?.business?._id
  ]);

  const renderSelectedPlace = () => {
    if (!selectedPlace?.place_id || showForm) {
      return null;
    }
    return (
      <SelectedBusiness
        navigate={() => setShowForm(true)}
        selectedPlace={selectedPlace}
        setSelectedPlace={setSelectedPlace}
      />
    );
  };

  const renderSearchInput = () => {
    if (selectedPlace?.place_id) {
      return null;
    }
    return (
      <>
        <div className="business-search-wrapper">
          <FormLabel>Business name</FormLabel>
          <GoogleAutoComplete
            placeholder="Search by name, address, zip code.."
            onSelect={(place) => setSelectedPlace(place)}
            selectedPlaceIs={selectedPlace}
          />
        </div>
        <LinkText
          onClick={() => setShowForm(true)}
          title={'Search could not find me'}
          className="mt-4"
        />
        <div className="mt-4">
          <AppButton title={'Search'} onClick={() => setSelectedPlace()} id="businessInfo.search" />
        </div>
      </>
    );
  };

  const renderBusinessForm = () => {
    if (!showForm) {
      return null;
    }
    return <BusinessInformationForm onSubmit={saveBusiness} selectedPlace={selectedPlace} />;
  };

  console.log({ selectedPlace });

  return (
    <ContentCardWrapper
      title={showForm ? 'Business information' : 'Business search'}
      subTitle={
        showForm
          ? 'Fill out the form below to register your business.'
          : "Let's find your business in our search engine."
      }>
      {renderSelectedPlace()}
      {renderSearchInput()}
      {renderBusinessForm()}
    </ContentCardWrapper>
  );
};

export default withMerchantOnboarding(BusinessSearch);
