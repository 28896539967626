import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { GET_STATES } from '../../../graphql/schema/query/masters/GET_STATE';
import SelectInput from '../../common/select-input/SelectInput';
import TextInput from '../../common/text-input/TextInput';

const MerchantFormLocationDetails = ({
  handleChange,
  setFieldValue,
  values,
  touched,
  errors,
  isAdmin
}) => {
  const [stateData, setStateData] = useState([]);

  const [doGetState] = useLazyQuery(GET_STATES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.state?.data?.find((d) => d?.name === null)) {
        return;
      }
      setStateData(data?.state?.data);
    }
  });

  useEffect(() => {
    doGetState();
  }, []);

  return (
    <div className="wrapper-padding">
      <p className="form-title mb-0 fonts-quicksand-medium">Location information</p>
      <span className="form-subtitle fonts-regular">Merchant&apos;s business location details</span>
      <Row className="mt-2">
        <Col md={6}>
          <TextInput
            label="Street No./Street Name"
            placeholder="Street No./Street Name"
            value={values.address}
            name="address"
            error={touched.address && errors.address}
            onChange={handleChange}
            id="merchantFormLocation.address"
          />
        </Col>
        <Col md={6}>
          <TextInput
            label="Address Line 2"
            placeholder="Address Line 2"
            value={values.apartmentNo}
            name="apartmentNo"
            error={touched.apartmentNo && errors.apartmentNo}
            onChange={handleChange}
            id="merchantFormLocation.apartmentNo"
          />
        </Col>
        <Col lg={4} md={6}>
          <TextInput
            label="City"
            placeholder="City"
            name="city"
            value={values.city}
            error={touched.city && errors.city}
            onChange={handleChange}
            id="merchantFormLocation.city"
          />
        </Col>
        <Col lg={4} md={6}>
          <SelectInput
            label="State"
            placeholder="Select State"
            options={Array.isArray(stateData) ? stateData : []}
            value={values.state_id?._id ?? values.state_id}
            error={touched.state_id && errors.state_id}
            onChange={(value) => {
              setFieldValue('state_id', value);
            }}
            name="state_id"
            id="merchantFormLocation.stateId"
          />
        </Col>
        <Col lg={4} md={6}>
          <TextInput
            label="Zip code"
            placeholder="Zip code"
            value={values.zipcode}
            name="zipcode"
            error={touched.zipcode && errors.zipcode}
            onChange={handleChange}
            id="merchantFormLocation.zipcode"
            maskingProps={{
              mask: '99999',
              maskChar: ' '
            }}
          />
        </Col>
        {isAdmin ? (
          <>
            <Col lg={4} md={6}>
              <TextInput
                label="Longitude"
                placeholder="Longitude"
                value={values.longitude}
                error={touched.longitude && errors.longitude}
                onChange={handleChange}
                name="longitude"
                id="businessInfo.longitude"
                type="number"
              />
            </Col>
            <Col lg={4} md={6}>
              <TextInput
                label="Latitude"
                placeholder="latitude"
                value={values.latitude}
                error={touched.latitude && errors.latitude}
                onChange={handleChange}
                name="latitude"
                id="businessInfo.latitude"
                type="number"
              />
            </Col>
          </>
        ) : null}
      </Row>
    </div>
  );
};

export default MerchantFormLocationDetails;
