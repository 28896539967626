import React, { useContext, useEffect, useState } from 'react';
import withAdmin from '../../../components/hoc/admin/withAdmin';
import FormWrapper from '../../../components/common/form-wrapper/FormWrapper';
import SurveySettings from '../../../components/admin/common-form-sections/SurveySettings';
import SurveyContent from '../../../components/admin/common-form-sections/SurveyContent';
import './styles.css';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { initValues, surveySchema } from './formUtils';
import ErrorFocus from '../../../components/common/ErrorFocus/ErrorFocus';
import { useMutation } from '@apollo/client';
import { CREATE_SURVEY } from '../../../graphql/schema/query/admin/survey/CREATE_SURVEY';
import ToastContext from '../../../contexts/ToastContext';
import moment from 'moment';
import LoaderComponent from '../../../components/common/loader/LoaderComponent';

const MerchantSurveyCreate = () => {
  const { setToastDetails } = useContext(ToastContext);

  const navigate = useNavigate();
  const [options, setOptions] = useState([
    {
      option: '',
      logo: null
    }
  ]);

  const localImagesPreview = options?.map((o) => {
    return o?.logo ? URL.createObjectURL(o?.logo) : null;
  });

  const [doAddSurvey, { loading, data, error }] = useMutation(CREATE_SURVEY);

  useEffect(() => {
    if (
      !loading &&
      data?.addSurvey?.message &&
      data?.addSurvey?.errorCode &&
      data?.addSurvey?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: data?.addSurvey?.message,
        type: 'danger'
      });
    } else {
      if (data?.addSurvey?.status) {
        setToastDetails({
          show: true,
          title: 'Survey Created',
          message: 'Survey created successfully',
          type: 'success'
        });
        navigate('/admin/merchant-survey', { replace: true });
      }
    }
  }, [
    loading,
    error,
    data?.addSurvey?.errorCode,
    data?.addSurvey?.message,
    data?.addSurvey?.status
  ]);

  const addSurvey = (values) => {
    const options = values?.options?.map((o) => ({
      value: o?.option,
      file: o?.logo
    }));
    const input = {
      title: values?.title,
      startDate: moment(values?.startDate).startOf('date'),
      endDate: moment(values?.endDate).endOf('date'),
      question: values?.question,
      option: options
    };

    doAddSurvey({
      variables: { input }
    });
  };

  return (
    <div className="merchantsurvey-create-edit-wrapper">
      {loading ? <LoaderComponent /> : null}
      <Formik
        initialValues={initValues}
        validationSchema={surveySchema}
        onSubmit={addSurvey}
        onCancel={() => {
          navigate('/admin/merchant-survey', { replace: true });
        }}>
        {({ values, handleSubmit, handleChange, errors, touched, setFieldValue }) => {
          return (
            <FormWrapper
              handleSubmit={handleSubmit}
              onCancel={() => {
                navigate('/admin/merchant-survey', { replace: true });
              }}>
              <SurveySettings
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
              />
              <hr />
              <SurveyContent
                values={values}
                localImagesPreview={localImagesPreview}
                setOptions={setOptions}
                options={options}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
              />
              <ErrorFocus />
            </FormWrapper>
          );
        }}
      </Formik>
    </div>
  );
};

export default withAdmin(MerchantSurveyCreate);
