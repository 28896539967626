import { useLazyQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppButton from '../../../../components/common/button/Button';
import ErrorFocus from '../../../../components/common/ErrorFocus/ErrorFocus';
import FeedbackModal from '../../../../components/common/feedback-modal/FeedbackModal';
import FormWrapper from '../../../../components/common/form-wrapper/FormWrapper';
import LoaderComponent from '../../../../components/common/loader/LoaderComponent';
import SelectInput from '../../../../components/common/select-input/SelectInput';
import SSNTextInput from '../../../../components/common/text-input/SSNTextInput';
import TextInput from '../../../../components/common/text-input/TextInput';
import withMerchantAdmin from '../../../../components/hoc/merchant/withMerchantAdmin';
import PayPalMerchantAccount from '../../../../components/merchant/paypal-merchant-account/PayPalMerchantAccount';
import Images from '../../../../constants/images';
import { UserContext } from '../../../../contexts';
import ToastContext from '../../../../contexts/ToastContext';
import { GET_SUB_CATEGORIES } from '../../../../graphql/schema/mutations/categories/GET_SUB_CATEGORIES';
import { MERCHANT_ADD_DETAILS } from '../../../../graphql/schema/mutations/merchant/ADD_MERCHANT_DETAILS';
import { MERCHANT_ADD_BUSINESS } from '../../../../graphql/schema/mutations/onboarding/MERCHANT_ADD_BUSINESS';
import { GET_CATEGORIES } from '../../../../graphql/schema/query/masters/GET_CATEGORIES';
import { GET_COUNTRIES } from '../../../../graphql/schema/query/masters/GET_COUNTRY';
import { GET_STATES } from '../../../../graphql/schema/query/masters/GET_STATE';
import { GET_MERCHANT_DETAILS } from '../../../../graphql/schema/query/merchant/GET_MERCHANT_DETAILS';
import { UPDATE_BUSINESS_STATUS } from '../../../../graphql/schema/query/merchant/UPDATE_BUSINESS_STATUS';
import {
  mccCodeMappingWithCategory,
  MerChantApplicationInitValues,
  MerchantApplicationSchema,
  sanitizeInput
} from './formUtils';

const MerchantSettingAccount = () => {
  const formRef = useRef();
  const navigate = useNavigate();
  const { setUserDetails } = useContext(UserContext);
  const { setToastDetails } = useContext(ToastContext);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [countryData, setCountryData] = useState([]);

  const [doGetState, { loading: stateLoading }] = useLazyQuery(GET_STATES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.state?.data?.find((d) => d?.name === null)) {
        return;
      }
      setStateData(data?.state?.data);
    }
  });

  const [doGetCountries, { loading: countryLoading }] = useLazyQuery(GET_COUNTRIES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.country?.data?.find((d) => d?.name === null)) {
        return;
      }
      setCountryData(data?.country?.data);
    }
  });

  const [doGetMerchantDetails, { loading, data }] = useLazyQuery(GET_MERCHANT_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      getSubCategory(data?.getMerchantDetail?.merchantDetail?.[0]?.business_data?.category_id?._id);
    }
  });

  const [categoryData, setCategoryData] = useState([]);

  console.log({ categoryData });

  const [doGetCategories] = useLazyQuery(GET_CATEGORIES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.category?.data?.find((d) => d?.name === null)) {
        return;
      }
      let dataArr = data?.category?.data ? [...data.category.data] : [];

      dataArr.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      dataArr = dataArr?.map((d) => {
        return {
          ...d,
          name: `${d?.name} - ${mccCodeMappingWithCategory?.[d?.name]}`,
          nameOnly: d?.name
        };
      });
      setCategoryData(dataArr);
    }
  });

  const [doGetSubCategories] = useMutation(GET_SUB_CATEGORIES);

  useEffect(() => {
    doGetCategories();
  }, []);

  const getSubCategory = (category_id) => {
    if (!category_id) {
      return;
    }
    doGetSubCategories({
      variables: {
        input: {
          refId: category_id
        }
      }
    });
  };

  useEffect(() => {
    doGetState();
    doGetMerchantDetails();
    doGetCountries();
  }, []);

  const [doAddMerchantDetails, { loading: saving, error, data: saved }] =
    useMutation(MERCHANT_ADD_DETAILS);

  const [doAddBusiness, { loading: savingBusiness }] = useMutation(MERCHANT_ADD_BUSINESS);

  const [
    doUpdateStatus,
    { loading: updatingStatus, data: updateStatusData, error: updateStatusError }
  ] = useLazyQuery(UPDATE_BUSINESS_STATUS, {
    fetchPolicy: 'network-only'
  });

  const location = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(location?.search);

    const merchantIdInPayPal = query.get('merchantIdInPayPal');
    const permissionsGranted = query.get('permissionsGranted');
    const accountStatus = query.get('accountStatus');
    const consentStatus = query.get('consentStatus');
    const productIntentId = query.get('productIntentId');
    const isEmailConfirmed = query.get('isEmailConfirmed');
    const returnMessage = query.get('returnMessage');
    const riskStatus = query.get('riskStatus');

    if (merchantIdInPayPal) {
      doAddMerchantDetails({
        variables: {
          input: {
            merchantIdInPayPal,
            permissionsGranted,
            accountStatus,
            consentStatus,
            productIntentId,
            isEmailConfirmed,
            returnMessage,
            riskStatus
          }
        },
        onCompleted: () => {
          window.location.replace('/merchant/settings/merchant-account-status');
        }
      });
    }
  }, []);

  const saveBusiness = (values, submit = false) => {
    doAddMerchantDetails({
      variables: {
        input: {
          zipcode: sanitizeInput(String(values.zipcode), true),
          securityNo: sanitizeInput(String(values.securityNo), true),
          phonNo: sanitizeInput(String(values.phonNo), true),
          personalPhoneNo: sanitizeInput(String(values.personalPhoneNo), true),
          ownership: sanitizeInput(String(values.ownership), true),
          lastname: String(values.lastname),
          firstname: String(values.firstname),
          city: String(values.city),
          address: String(values.address),
          apartmentNo: String(values.apartmentNo),
          dob: moment(values.dob).toISOString(),
          startupDate: moment(values.startupDate).toISOString(),
          country_id: values?.country_id?._id || values?.country_id || undefined,
          state_id: values?.state_id?._id || values?.state_id || undefined
        }
      },
      onCompleted: () => {
        const inputs = {
          businessName: values?.business_data?.businessName,
          address: values?.business_data?.address,
          apartmentNo: values?.business_data?.apartmentNo,
          city: values?.business_data?.city,
          zipcode: values?.business_data?.zipcode,
          website: values?.business_data?.website,
          ...(values?.business_data?.email && { email: values?.business_data?.email }),
          country_id: values?.business_data?.country_id?._id,
          state_id: values?.business_data?.state_id?._id,
          socialUrl: values?.business_data?.socialUrl,
          phoneno: sanitizeInput(values.business_data?.phoneno, true),
          longitude: String(values?.business_data?.longitude || ''),
          latitude: String(values?.business_data?.latitude || ''),
          timings: values.business_data?.timings,
          businessDescription: values?.business_data?.businessDescription ?? '',

          category_id:
            values?.category_id?._id ??
            values?.category_id ??
            values.business_data?.category_id?._id,
          subCategory_id:
            values?.subCategory_id?._id ??
            values?.subCategory_id ??
            values.business_data?.subCategory_id?._id
        };
        doAddBusiness({
          variables: {
            input: inputs
          },
          onCompleted: () => {
            toast.success('Merchant details saved successfully', { autoClose: true });
            doGetMerchantDetails();
            if (submit) {
              doUpdateStatus();
            }
          }
        });
      }
    });
  };

  const camelCaseToNoraml = (string) => {
    return String(string ?? '')
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, function (str) {
        return str.toUpperCase();
      });
  };

  const getErrorFromJSONString = (jsonString) => {
    let message = '';

    try {
      const json = JSON.parse(jsonString);

      console.log({ json });
      message = Object.keys(json ?? {})
        ?.splice(0, 1)
        .map((k) => {
          const _message = `${message} ${String(k)
            .split('.')
            .map((_k) => camelCaseToNoraml(_k))
            .join(' ')}`;

          const _errors = json?.[k]?.join(' and ');

          return `${_message} ${_errors}`;
        })
        .join('\n');
    } catch (error) {
      console.log(error);
    }
    return message;
  };
  useEffect(() => {
    if (!updatingStatus && updateStatusError) {
      const message = getErrorFromJSONString(updateStatusError?.message);
      if (message) {
        toast.error(message, {
          autoClose: false,
          closeButton: true
        });
      }
    }

    if (
      !updatingStatus &&
      updateStatusData?.updateBusinessStatus?.message &&
      updateStatusData?.updateBusinessStatus?.errorCode &&
      updateStatusData?.updateBusinessStatus?.errorCode !== '0'
    ) {
      const message = getErrorFromJSONString(updateStatusData?.updateBusinessStatus?.message);
      if (message) {
        toast.error(message, {
          autoClose: false,
          closeButton: true
        });
      }
    } else {
      if (updateStatusData?.updateBusinessStatus?.businessDetail?.[0]?._id) {
        setUserDetails();
        setSuccessModal(true);
      }
    }
  }, [
    updatingStatus,
    updateStatusError,
    updateStatusData?.updateBusinessStatus?.errorCode,
    updateStatusData?.updateBusinessStatus?.message,
    updateStatusData?.updateBusinessStatus?.businessDetail
  ]);

  useEffect(() => {
    if (
      !loading &&
      saved?.addMerchantDetail?.message &&
      saved?.addMerchantDetail?.errorCode &&
      saved?.addMerchantDetail?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: saved?.addMerchantDetail?.message,
        type: 'danger'
      });
    }
  }, [
    loading,
    error,
    saved?.addMerchantDetail?.errorCode,
    saved?.addMerchantDetail?.message,
    saved?.addMerchantDetail?.merchantDetail
  ]);

  let details = data?.getMerchantDetail?.merchantDetail?.[0];
  Object.keys(details ?? {}).map((_key) => {
    details[_key] =
      details?.[_key] && details?.[_key] !== 'null' && details?.[_key] !== 'undefined'
        ? details?.[_key]
        : '';
  });

  if (details) {
    details['category_id'] = details?.business_data?.category_id?._id;
    details['subCategory_id'] = details?.business_data?.subCategory_id?._id;
  }

  if (loading || saving || updatingStatus || savingBusiness) {
    return <LoaderComponent />;
  }

  return (
    <>
      {stateLoading || countryLoading || saving || savingBusiness ? <LoaderComponent /> : null}

      <FeedbackModal
        show={showSuccessModal}
        onHide={() => {
          setSuccessModal(false);
          navigate('/merchant/dashboard', { replace: true });
        }}
        icon={Images.success_icon}
        title="Application submitted"
        subTitle="Your application has been submitted, we’ll let you know when it’s approved."
        buttons={[
          <AppButton
            title={'Continue'}
            key="1"
            buttonClass={'btn-cancel'}
            onClick={() => {
              setSuccessModal(false);
              navigate('/merchant/settings/merchant-account-status', { replace: true });
            }}
            id="merchantAccount.continue"
          />
        ]}
      />
      <FeedbackModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        icon={Images.arrow_right}
        title="Submit merchant application?"
        subTitle="Are you sure you want to submit your merchant application?"
        className="merchant-application"
        buttons={[
          <AppButton
            title={'Cancel'}
            key="1"
            buttonClass={'btn-cancel'}
            onClick={() => setShowConfirmationModal(false)}
            id="merchantAccount.cancel"
          />,
          <AppButton
            title={'Confirm'}
            key="2"
            onClick={() => {
              setShowConfirmationModal(false);
              saveBusiness(formRef.current?.values, true);
            }}
            id="merchantAccount.confirm"
          />
        ]}
      />
      <Formik
        innerRef={formRef}
        initialValues={{
          ...MerChantApplicationInitValues,
          ...details,
          _id: undefined,
          __typename: undefined
        }}
        validationSchema={MerchantApplicationSchema}
        onSubmit={() => {}}>
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          setFieldError,
          validateForm,
          setFieldTouched
        }) => {
          return (
            <FormWrapper
              loading={stateLoading || countryLoading || saving}
              anotherHandleSubmit={async () => {
                const errorAre = await validateForm(values);
                const errorGiven = {
                  firstname: errorAre?.firstname,
                  lastname: errorAre?.lastname,
                  dob: errorAre?.dob,
                  securityNo: errorAre?.securityNo,
                  address: errorAre?.address,
                  city: errorAre?.city,
                  zipcode: errorAre?.zipcode,
                  phonNo: errorAre?.phonNo,
                  personalPhoneNo: errorAre?.personalPhoneNo,
                  startupDate: errorAre?.startupDate,
                  ownership: errorAre?.ownership,
                  accountNo: errorAre?.accountNo,
                  routingNo: errorAre?.routingNo,
                  fedralTax: errorAre?.fedralTax,
                  productSold: errorAre?.productSold,
                  // merchantCategoryClassification: errorAre?.merchantCategoryClassification,
                  merchandiseServicesSold: errorAre?.merchandiseServicesSold,
                  monthInBusiness: errorAre?.monthInBusiness,
                  yearInBusiness: errorAre?.yearInBusiness,
                  cardSwiped: errorAre?.cardSwiped,
                  averageTicketAmount: errorAre?.averageTicketAmount,
                  averageMonthlyVolume: errorAre?.averageMonthlyVolume,
                  highTicketAmount: errorAre?.highTicketAmount,
                  keyedCardPresentNotImprinted: errorAre?.keyedCardPresentNotImprinted,
                  mailOrPhoneOrder: errorAre?.mailOrPhoneOrder,
                  internet: errorAre?.internet,
                  title: errorAre?.title,
                  state_id: errorAre?.state_id,
                  country_id: errorAre?.country_id,
                  accountType: errorAre?.accountType,
                  ownershipType: errorAre?.ownershipType,
                  category_id: errorAre?.category_id,
                  subCategory_id: errorAre?.subCategory_id,
                  federalTaxIdType: errorAre?.federalTaxIdType
                };

                const errorArray = Object.keys(errorGiven ?? {})?.filter((d) => {
                  return errorGiven?.[d] !== undefined;
                });

                if (errorGiven && errorArray.length > 0) {
                  errorArray.forEach((errorKey) => {
                    setFieldTouched(errorKey, true);
                    setFieldError(errorKey, errorGiven?.[errorKey]);
                  });
                  const nameKey = errorArray?.find((d) => {
                    return errorGiven?.[d] !== undefined;
                  });
                  const errorElement = document.querySelector(`[name="${nameKey}"]`);
                  errorElement?.focus();
                  return;
                }
                if (errorGiven && errorArray.length === 0) {
                  setShowConfirmationModal(true);
                }
              }}
              handleSubmit={async () => {
                saveBusiness(values);
              }}
              showCancel={false}>
              <div className="wrapper-padding">
                <p className="form-title mb-0 fonts-quicksand-medium">Business Information</p>
                <span className="form-subtitle fonts-regular">
                  Fill out your business information below.
                </span>

                <Row className="mt-2">
                  <Col md={6}>
                    <TextInput
                      label="First name"
                      placeholder="First name"
                      name="firstname"
                      value={values.firstname}
                      error={touched.firstname && errors.firstname}
                      onChange={handleChange}
                      id="merchantAccount.firstname"
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="Last name"
                      placeholder="Last name"
                      name="lastname"
                      value={values.lastname}
                      error={touched.lastname && errors.lastname}
                      onChange={handleChange}
                      id="merchantAccount.lastname"
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      max={moment().format('YYYY-MM-DD')}
                      label="Date of birth"
                      placeholder="Date of birth"
                      name="dob"
                      value={values.dob && moment(values.dob).format('YYYY-MM-DD')}
                      error={touched.dob && errors.dob}
                      onChange={handleChange}
                      type="date"
                      id="merchantAccount.dob"
                    />
                  </Col>
                  <Col md={6}>
                    <SSNTextInput
                      value={values.securityNo}
                      error={touched.securityNo && errors.securityNo}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="Street No./Street Name"
                      placeholder="Street No./Street Name"
                      value={values.address}
                      name="address"
                      error={touched.address && errors.address}
                      onChange={handleChange}
                      id="merchantAccount.address"
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="Address Line 2"
                      placeholder="Address Line 2"
                      name="apartmentNo"
                      value={values.apartmentNo}
                      error={touched.apartmentNo && errors.apartmentNo}
                      onChange={handleChange}
                      id="merchantAccount.apartmentNo"
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="City"
                      placeholder="City"
                      name="city"
                      value={values.city}
                      error={touched.city && errors.city}
                      onChange={handleChange}
                      id="merchantAccount.city"
                    />
                  </Col>
                  <Col md={6}>
                    <SelectInput
                      label="State"
                      placeholder="Select State"
                      options={Array.isArray(stateData) ? stateData : []}
                      value={values.state_id?._id ?? values.state_id}
                      error={touched.state_id && errors.state_id}
                      onChange={(value) => {
                        setFieldValue('state_id', value);
                      }}
                      name="state_id"
                      id="merchantAccount.stateId"
                    />
                  </Col>
                  <Col md={6}>
                    <SelectInput
                      label={'Country'}
                      placeholder={'Select Country'}
                      options={Array.isArray(countryData) ? countryData : []}
                      value={values.country_id?._id ?? values.country_id}
                      error={touched.country_id && errors.country_id}
                      onChange={(value) => {
                        setFieldValue('country_id', value);
                      }}
                      name="country_id"
                      id="merchantAccount.countryId"
                    />
                  </Col>

                  <Col md={6}>
                    <TextInput
                      label="Zipcode"
                      placeholder="Zipcode"
                      value={values.zipcode}
                      name="zipcode"
                      error={touched.zipcode && errors.zipcode}
                      onChange={handleChange}
                      id="merchantAccount.zipcode"
                      maskingProps={{
                        mask: '99999',
                        maskChar: ''
                      }}
                    />
                  </Col>

                  <Col md={6}>
                    <TextInput
                      label="Business phone number"
                      placeholder="Owner phone"
                      name="phonNo"
                      value={values.phonNo}
                      error={touched.phonNo && errors.phonNo}
                      onChange={handleChange}
                      leftText={'+1'}
                      id="merchantAccount.phonNo"
                      maskingProps={{
                        mask: '999-999-9999',
                        maskChar: ' '
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="Personal phone number"
                      placeholder="Personal phone number"
                      name="personalPhoneNo"
                      value={values.personalPhoneNo}
                      error={touched.personalPhoneNo && errors.personalPhoneNo}
                      onChange={handleChange}
                      leftText={'+1'}
                      id="merchantAccount.personalPhoneNo"
                      maskingProps={{
                        mask: '999-999-9999',
                        maskChar: ' '
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      max={moment().format('YYYY-MM-DD')}
                      label="Business start up date"
                      placeholder="Business start up date"
                      name="startupDate"
                      value={values.startupDate && moment(values.startupDate).format('YYYY-MM-DD')}
                      error={touched.startupDate && errors.startupDate}
                      onChange={handleChange}
                      type="date"
                      leftIcon={Images.calendar}
                      id="merchantAccount.startupDate"
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="Ownership"
                      placeholder="Ownership"
                      name="ownership"
                      value={values.ownership}
                      error={touched.ownership && errors.ownership}
                      onChange={handleChange}
                      leftText="%"
                      id="merchantAccount.ownership"
                      type="number"
                    />
                  </Col>
                </Row>
              </div>
              {details?.business_data?.businessStatus !== 'success' ? (
                <PayPalMerchantAccount url={details?.payPalPartnerReferralLink} />
              ) : null}
              {/* <hr />
              <div className="wrapper-padding">
                <p className="form-title mb-0 fonts-quicksand-medium">Bank Details</p>
                <span className="form-subtitle fonts-regular">
                  Please fill out your banking details.
                </span>
                <Row className="mt-2">
                  <Col md={4}>
                    <SelectInput
                      label="Account Type"
                      placeholder="Select account type"
                      options={selectInputData.accountTypes}
                      value={values.accountType?._id ?? values.accountType}
                      error={touched.accountType && errors.accountType}
                      onChange={(value) => {
                        setFieldValue('accountType', value);
                      }}
                      name="accountType"
                      id="merchantAccount.accountType"
                    />
                  </Col>
                  <Col md={4}>
                    <TextInput
                      label="Account number"
                      placeholder="Account number"
                      name="accountNo"
                      value={values.accountNo}
                      error={touched.accountNo && errors.accountNo}
                      onChange={handleChange}
                      id="merchantAccount.accountNo"
                    />
                  </Col>
                  <Col md={4}>
                    <TextInput
                      label="Routing number"
                      placeholder="Routing number"
                      name="routingNo"
                      value={values.routingNo}
                      error={touched.routingNo && errors.routingNo}
                      onChange={handleChange}
                      id="merchantAccount.routingNo"
                      maskingProps={{
                        mask: '999999999',
                        maskChar: ''
                      }}
                    />
                  </Col>
                  <Col md={12} className="mt-5 mb-2">
                    <span className="form-subtitle fonts-regular">
                      Where to find your account and routing numbers
                    </span>
                  </Col>
                  <Col md={12}>
                    <Image className="cheque-image" src={Images.cheque_img} />
                  </Col>
                </Row>
              </div>
              <hr />

              <div className="wrapper-padding">
                <p className="form-title mb-0 fonts-quicksand-medium">Legal & tax</p>
                <span className="form-subtitle fonts-regular">
                  Please fill out your banking details.
                </span>
                <Row className="mt-2">
                  <Col md={12}>
                    <SelectInput
                      label="Ownership type"
                      placeholder={
                        <div>
                          <Image src={Images.person} className="mr-2" />
                          Select Ownership type
                        </div>
                      }
                      options={ownershipTypes}
                      value={values.ownershipType}
                      error={touched.ownershipType && errors.ownershipType}
                      onChange={(value) => {
                        setFieldValue('ownershipType', value);
                      }}
                      name="ownershipType"
                      id="merchantAccount.ownershipType"
                    />
                  </Col>
                  <Col md={6} className="merchant-form">
                    <Form.Check
                      className="float-start me-3 toggle-switch status mt-5"
                      checked={values?.sameAsDBA}
                      type="switch"
                      label={'Legal Address same as DBA'}
                      id={'merchantAccount.sameAsDBA'}
                      onChange={(e) => {
                        setFieldValue(`sameAsDBA`, e.target.checked);
                      }}
                      name="sameAsDBA"
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="Products sold"
                      placeholder="Products sold"
                      name="productSold"
                      value={values.productSold}
                      error={touched.productSold && errors.productSold}
                      onChange={handleChange}
                      id="merchantAccount.productSold"
                    />
                  </Col>
                  <Col md={6}>
                    <SelectInput
                      label="Merchant Category Classification (MCC)"
                      placeholder="Merchant Category Classification (MCC)"
                      options={Array.isArray(categoryData) ? categoryData : []}
                      value={values.category_id ?? values.business_data?.category_id?._id}
                      error={touched.category_id && errors.category_id}
                      onChange={(value) => {
                        setFieldValue('category_id', value);
                        setFieldValue(
                          'merchantCategoryClassification',
                          mccCodeMappingWithCategory?.[
                            categoryData?.find((d) => d?._id === value)?.nameOnly
                          ]
                        );
                        setFieldValue('subCategory_id', '');
                        doGetSubCategories({
                          variables: {
                            input: {
                              refId: value
                            }
                          }
                        });
                      }}
                      name="category_id"
                      id="merchantFormBusinessDetails.categoryId"
                    />
                  </Col>
                  <Col md={6}>
                    <SelectInput
                      parentId={
                        categoryData?.find(
                          (d) =>
                            d?._id === values.category_id ||
                            d?._id === values.business_data?.category_id?._id
                        )?.nameOnly
                      }
                      label="Sub Category"
                      placeholder="Select Sub Category"
                      options={
                        Array.isArray(subCategories?.getSubCategory?.data)
                          ? subCategories?.getSubCategory?.data
                          : []
                      }
                      value={values.subCategory_id ?? values.business_data?.subCategory_id?._id}
                      error={touched.subCategory_id && errors.subCategory_id}
                      onChange={(value) => {
                        setFieldValue('subCategory_id', value);
                      }}
                      name="subCategory_id"
                      id="merchantFormBusinessDetails.subCategoryId"
                    />
                  </Col>
                  {/* <Col md={6}>
                    <SelectInput
                      label="Merchant Category Classification (MCC)"
                      placeholder="Merchant Category Classification (MCC)"
                      options={selectInputData.mccOptions}
                      value={String(
                        values.merchantCategoryClassification?._id ??
                          values.merchantCategoryClassification
                      )}
                      error={
                        touched.merchantCategoryClassification &&
                        errors.merchantCategoryClassification
                      }
                      onChange={(value) => {
                        setFieldValue('merchantCategoryClassification', value);
                      }}
                      name="merchantCategoryClassification"
                      id="merchantAccount.merchantCategoryClassification"
                    />
                  </Col> 
                  <Col md={6}>
                    <TextInput
                      label="Years in business"
                      placeholder="Years in business"
                      name="yearInBusiness"
                      value={values.yearInBusiness}
                      error={touched.yearInBusiness && errors.yearInBusiness}
                      onChange={handleChange}
                      id="merchantAccount.yearInBusiness"
                      type="number"
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="Months in business"
                      placeholder="Months in business"
                      name="monthInBusiness"
                      value={values.monthInBusiness}
                      error={touched.monthInBusiness && errors.monthInBusiness}
                      onChange={handleChange}
                      id="merchantAccount.monthInBusiness"
                      maskingProps={{
                        mask: '99',
                        maskChar: ''
                      }}
                    />
                  </Col>

                  <Col md={4}>
                    <SelectInput
                      label="Federal Tax Id Type"
                      placeholder="Select federal Tax Type"
                      options={selectInputData.federalTaxIdTypes}
                      value={values.federalTaxIdType?._id ?? values.federalTaxIdType}
                      error={touched.federalTaxIdType && errors.federalTaxIdType}
                      onChange={(value) => {
                        setFieldValue('federalTaxIdType', value);
                      }}
                      name="federalTaxIdType"
                      id="merchantAccount.federalTaxIdType"
                    />
                  </Col>
                  <Col md={4}>
                    <TextInput
                      label="Federal Tax ID/EIN"
                      placeholder="Federal Tax ID/EIN "
                      name="fedralTax"
                      value={values.fedralTax}
                      error={touched.fedralTax && errors.fedralTax}
                      onChange={handleChange}
                      id="merchantAccount.fedralTax"
                      maskingProps={{
                        mask: '99-9999999',
                        maskChar: ''
                      }}
                    />
                  </Col>

                  <Col md={4}>
                    <SelectInput
                      label="Title"
                      placeholder="Select title"
                      options={selectInputData.titles}
                      value={values.title?._id ?? values.title}
                      error={touched.title && errors.title}
                      onChange={(value) => {
                        setFieldValue('title', value);
                      }}
                      name="title"
                      id="merchantAccount.title"
                    />
                  </Col>

                  <Col md={4}>
                    <TextInput
                      label="Merchandise Services Sold"
                      placeholder="Merchandise Services Sold"
                      name="merchandiseServicesSold"
                      value={values.merchandiseServicesSold}
                      error={touched.merchandiseServicesSold && errors.merchandiseServicesSold}
                      onChange={handleChange}
                      id="merchantAccount.merchandiseServicesSold"
                    />
                  </Col>
                  <Col md={4}>
                    <TextInput
                      label="High Ticket Amount"
                      placeholder="High Ticket Amount"
                      name="highTicketAmount"
                      value={values.highTicketAmount}
                      error={touched.highTicketAmount && errors.highTicketAmount}
                      onChange={(value) => {
                        setFieldValue('highTicketAmount', value);
                      }}
                      id="merchantAccount.highTicketAmount"
                      maskingProps={{
                        currency: true
                      }}
                    />
                  </Col>

                  <Col md={4}>
                    <SelectInput
                      label="Average Sale Value"
                      placeholder="Average Sale Value"
                      name="averageTicketAmount"
                      options={selectInputData.averageTicketAmount}
                      value={String(values.averageTicketAmount?._id ?? values.averageTicketAmount)}
                      error={touched.averageTicketAmount && errors.averageTicketAmount}
                      onChange={(value) => {
                        setFieldValue('averageTicketAmount', value);
                      }}
                      id="merchantAccount.averageTicketAmount"
                    />
                  </Col>
                  <Col md={4}>
                    <SelectInput
                      label="Average Monthly Sales"
                      placeholder="Average Monthly Sales"
                      name="averageMonthlyVolume"
                      options={selectInputData.averageMonthlyVolume}
                      value={String(
                        values.averageMonthlyVolume?._id ?? values.averageMonthlyVolume
                      )}
                      error={touched.averageMonthlyVolume && errors.averageMonthlyVolume}
                      onChange={(value) => {
                        setFieldValue('averageMonthlyVolume', value);
                      }}
                      id="merchantAccount.averageMonthlyVolume"
                    />
                  </Col>
                  <Col md={4} className="merchant-form">
                    <Form.Check
                      className="float-start me-3 toggle-switch status mt-5"
                      checked={values?.isPersonalGuarantor}
                      type="switch"
                      label={'Is Personal Guarantor?'}
                      id={'merchantAccount.isPersonalGuarantor'}
                      onChange={(e) => {
                        setFieldValue(`isPersonalGuarantor`, e.target.checked);
                      }}
                      name="isPersonalGuarantor"
                    />
                  </Col>
                </Row>
              </div> */}
              <ErrorFocus />
            </FormWrapper>
          );
        }}
      </Formik>
    </>
  );
};
export default withMerchantAdmin(MerchantSettingAccount);
