import { gql } from '@apollo/client';

export const GET_MARKET_PLACE_CATEGORIES = gql`
  query GetMarketplaceCategory {
    getMarketplaceCategory {
      status
      message
      data {
        _id
        primaryCat
        secondaryCat
        tertiaryCat
        discount
        catImage
      }
      errorCode
    }
  }
`;
