import { gql } from '@apollo/client';

export const DELETE_DEAL = gql`
  query DeleteDeal($deleteDealId: ID) {
    deleteDeal(id: $deleteDealId) {
      status
      message
      errorCode
    }
  }
`;
