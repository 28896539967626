import { gql } from '@apollo/client';

export const UPDATE_DEAL = gql`
  mutation UpdateDeal($input: updateDeal!) {
    updateDeal(input: $input) {
      data {
        _id
      }
      status
      message
      errorCode
    }
  }
`;
