import { useLazyQuery } from '@apollo/client';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import AppButton from '../../../../components/common/button/Button';
import SelectInput from '../../../../components/common/select-input/SelectInput';
import TextInput from '../../../../components/common/text-input/TextInput';
import { GET_CATEGORIES } from '../../../../graphql/schema/query/masters/GET_CATEGORIES';
import { GET_COUNTRIES } from '../../../../graphql/schema/query/masters/GET_COUNTRY';
import { GET_STATES } from '../../../../graphql/schema/query/masters/GET_STATE';
import { BusinessInfoInitValues, BusinessInfoSchema } from './businessFormUtils';

export const BusinessInformationForm = ({ onSubmit, selectedPlace = {} }) => {
  const formRef = useRef();
  const [doGetState, { loading: stateLoading, data: stateData }] = useLazyQuery(GET_STATES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const stateIs = selectedPlace?.address_components?.find((s) =>
        s?.types?.includes('administrative_area_level_1')
      )?.long_name;
      const stateId = data?.state?.data?.find(
        (d) => String(d?.name).toLowerCase() === String(stateIs).toLowerCase()
      )?._id;
      formRef?.current?.setFieldValue?.('state_id', stateId);
    }
  });
  const [doGetContries, { loading: countryLoading, data: countryData }] = useLazyQuery(
    GET_COUNTRIES,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        formRef?.current?.setFieldValue?.('country_id', data?.country?.data?.[0]?._id);
      }
    }
  );
  const [doGetCategories, { loading: categoryLoading, data: categoryData }] = useLazyQuery(
    GET_CATEGORIES,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  useEffect(() => {
    doGetState();
    doGetContries();
    doGetCategories();
  }, []);

  console.log({ selectedPlace });
  useEffect(() => {
    if (formRef) {
      const addressLine1 = selectedPlace?.address_components
        ?.map((adr) => {
          if (
            adr?.types?.includes('street_number') ||
            adr?.types?.includes('street') ||
            adr?.types?.includes('route')
          ) {
            return adr?.long_name;
          }
        })
        .filter((d) => d)
        .join(' ');

      const addressLine2 = selectedPlace?.address_components
        ?.map((adr) => {
          if (adr?.types?.includes('floor') || adr?.types?.includes('subpremise')) {
            return adr?.long_name ?? '';
          }
          return '';
        })
        .filter((d) => d !== '')
        .join(' ');

      formRef.current.setFieldValue('businessName', selectedPlace?.name);
      formRef.current.setFieldValue('address', addressLine1);
      formRef.current.setFieldValue('apartmentNo', addressLine2 ?? '');

      let city = selectedPlace?.address_components?.find((s) =>
        s?.types?.includes('locality')
      )?.long_name;

      if (!city) {
        city = selectedPlace?.address_components?.find((s) =>
          s?.types?.includes('administrative_area_level_3')
        )?.long_name;
      }
      if (!city) {
        city = selectedPlace?.address_components?.find((s) =>
          s?.types?.includes('administrative_area_level_2')
        )?.long_name;
      }
      formRef.current.setFieldValue('city', city);
      const zipcode = selectedPlace?.address_components?.find((s) =>
        s?.types?.includes('postal_code')
      )?.long_name;

      formRef.current.setFieldValue('zipcode', zipcode);
      formRef.current.setFieldValue(
        'latitude',
        selectedPlace?.geometry?.location?.lat()
          ? String(selectedPlace?.geometry?.location?.lat?.())
          : ''
      );
      formRef.current.setFieldValue(
        'longitude',
        selectedPlace?.geometry?.location?.lat()
          ? String(selectedPlace?.geometry?.location?.lng?.())
          : ''
      );

      const stateIs = selectedPlace?.address_components?.find((s) =>
        s?.types?.includes('administrative_area_level_1')
      )?.long_name;
      const stateId = stateData?.state?.data?.find(
        (d) => String(d?.name).toLowerCase() === String(stateIs).toLowerCase()
      )?._id;
      formRef?.current?.setFieldValue?.('state_id', stateId);

      validateFormExplicitly(
        formRef.current?.validateForm,
        formRef.current?.values,
        formRef.current?.setFieldTouched,
        formRef.current?.setFieldError
      );
    }
  }, [selectedPlace?.place_id, formRef]);

  const validateFormExplicitly = async (validateForm, values, setFieldTouched, setFieldError) => {
    const errorGiven = await validateForm?.(values);
    if (errorGiven && Object.keys(errorGiven ?? {}).length > 0) {
      Object.keys(errorGiven).forEach((errorKey) => {
        setFieldTouched?.(errorKey, true);
        setFieldError?.(errorKey, errorGiven?.[errorKey]);
      });
      return;
    }
  };

  return (
    <Formik
      innerRef={formRef}
      onSubmit={onSubmit}
      initialValues={BusinessInfoInitValues}
      validationSchema={BusinessInfoSchema}>
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleSubmit,
        setFieldError,
        setFieldTouched,
        validateForm
      }) => {
        return (
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              validateFormExplicitly(validateForm, values, setFieldTouched, setFieldError);
              handleSubmit();
            }}>
            <TextInput
              label="Business name"
              placeholder="Business name"
              value={values.businessName}
              error={touched.businessName && errors.businessName}
              onChange={handleChange}
              name="businessName"
              id="businessInfo.businessName"
            />

            <TextInput
              label="Address"
              placeholder="Address"
              value={values.address}
              error={touched.address && errors.address}
              onChange={handleChange}
              name="address"
              id="businessInfo.address"
            />

            <TextInput
              label="Address Line 2"
              placeholder="Address Line 2"
              value={values.apartmentNo}
              error={touched.apartmentNo && errors.apartmentNo}
              onChange={handleChange}
              name="apartmentNo"
              id="businessInfo.apartmentNo"
            />

            <TextInput
              label="City"
              placeholder="City"
              value={values.city}
              error={touched.city && errors.city}
              onChange={handleChange}
              name="city"
              id="businessInfo.city"
            />

            <SelectInput
              label={'State'}
              placeholder={'Select State'}
              options={Array.isArray(stateData?.state?.data) ? stateData?.state?.data : []}
              value={values.state_id}
              error={touched.state_id && errors.state_id}
              onChange={(value) => setFieldValue('state_id', value)}
              name="state_id"
              id="businessInfo.stateId"
            />

            <TextInput
              label="Zipcode"
              placeholder="Zipcode"
              value={values.zipcode}
              error={touched.zipcode && errors.zipcode}
              onChange={handleChange}
              name="zipcode"
              id="businessInfo.zipcode"
              maskingProps={{
                mask: '99999',
                maskChar: ' '
              }}
            />

            <SelectInput
              label={'Country'}
              placeholder={'Select Country'}
              options={Array.isArray(countryData?.country?.data) ? countryData?.country?.data : []}
              value={values.country_id}
              error={touched.country_id && errors.country_id}
              onChange={(value) => setFieldValue('country_id', value)}
              name="country_id"
              id="businessInfo.countryId"
            />

            <SelectInput
              label={'Category'}
              placeholder={'Select Category'}
              options={
                Array.isArray(categoryData?.category?.data) ? categoryData?.category?.data : []
              }
              value={values.category_id}
              error={touched.category_id && errors.category_id}
              onChange={(value) => setFieldValue('category_id', value)}
              name="category_id"
              id="businessInfo.categoryId"
            />

            <div className="mt-4">
              <AppButton
                title={'Complete Sign up'}
                onClick={(e) => {
                  validateFormExplicitly(validateForm, values, setFieldTouched, setFieldError);
                  handleSubmit(e);
                }}
                disabled={stateLoading || countryLoading || categoryLoading}
                id="businessInfo.completeSignUp"
                type="submit"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
