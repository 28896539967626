import { gql } from '@apollo/client';

const MERCHANT_CREATE_SUBSCRIPTION = gql`
  subscription merchant_create {
    merchant_create {
      data
    }
  }
`;

const SUGGESTED_BUSINESS_SUBSCRIPTION = gql`
  subscription User_suggestedbusiness {
    user_suggestedbusiness {
      data
    }
  }
`;

const DEAL_TRANSACTION_SUBSCRIPTION = gql`
  subscription Deal_transaction($merchantId: ID) {
    deal_transaction(merchant_id: $merchantId) {
      keyType
      data
    }
  }
`;

const APP_SUBSCRIPTIONS = {
  MERCHANT_CREATE_SUBSCRIPTION,
  SUGGESTED_BUSINESS_SUBSCRIPTION,
  DEAL_TRANSACTION_SUBSCRIPTION
};

export default APP_SUBSCRIPTIONS;
