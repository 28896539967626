import React from 'react';
import { connect } from 'formik';

class ErrorFocus extends React.Component {
  componentDidUpdate(prevProps) {
    const { errors, isSubmitting, isValidating } = prevProps.formik;
    const keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      let nameKey = keys?.[0];
      if (typeof errors?.[nameKey] === 'object') {
        nameKey = `${nameKey}.${Object?.keys(errors?.[nameKey])?.[0]}`;
      }
      const errorElement = document.querySelector(`[name="${nameKey}"]`);
      errorElement?.focus();
    }
  }

  render() {
    return null;
  }
}

export default connect(ErrorFocus);
