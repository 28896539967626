import { gql } from '@apollo/client';

export const DELETE_SURVEY = gql`
  query DeleteSurvey($id: ID) {
    deleteSurvey(_id: $id) {
      status
      message
      errorCode
    }
  }
`;
