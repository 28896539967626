import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useGetUser from '../../../hooks/users/useGetUser';
import OnboardingStepSideBar from '../../merchant/onboarding/step-indicator/OnboardingStepSideBar';
import CommonFooter from '../../../pages/onboarding/footer/CommonFooter';

import './styles.css';

// eslint-disable-next-line react/display-name
const withMerchantOnboarding = (Component) => (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useGetUser('onboarding', navigate);

  const isTermsPage = location?.pathname === '/onboarding/terms-and-condition';
  const isCreateAccountPage = location?.pathname === '/onboarding';
  const isBusinessPage = location?.pathname === '/onboarding/business-search';

  let additionalClass = '';
  if (isCreateAccountPage) {
    additionalClass = 'long-form-account';
  }
  if (isBusinessPage) {
    additionalClass = 'long-form-business';
  }
  return (
    <>
      <div className="merchant-onboarding-container main-wrapper-container step-container">
        <div className="main-content-area">
          <div className="left-side-container">
            <OnboardingStepSideBar user={user} />
            <div className="p-0">
              <CommonFooter />
            </div>
          </div>
          <div className="merchant-onboarding-content right-side-container">
            <div className={`main-box ${additionalClass}`}>
              <div className={`content-box-wrapper scrollbar ${isTermsPage ? 'terms-card' : ''}`}>
                <Component {...props} />
              </div>
              <div className="text-center">
                <span
                  className={`dot ${location?.pathname === '/onboarding' ? 'active' : ''}`}></span>
                <span
                  className={`dot ${
                    location?.pathname === '/onboarding/business-search' ? 'active' : ''
                  }`}></span>
                <span
                  className={`dot ${
                    location?.pathname === '/onboarding/terms-and-condition' ? 'active' : ''
                  }`}></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withMerchantOnboarding;
