import React, { useContext, useEffect, useRef } from 'react';
import { Form, Image } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AppButton from '../../../components/common/button/Button';
import TextInput from '../../../components/common/text-input/TextInput';
import ContentCardWrapper from '../../../components/merchant/onboarding/content-card-wrapper/ContentCardWrapper';
import SignInSidebarInfo from '../../../components/merchant/onboarding/step-indicator/SignInSidebarInfo';
import CommonFooter from '../../../pages/onboarding/footer/CommonFooter';
import { MERCHANT_FORGOT_PASSWORD } from '../../../graphql/schema/mutations/onboarding/MERCHANT_FORGOT_PASSWORD';
import ToastContext from '../../../contexts/ToastContext';
import Images from '../../../constants/images';
import { ADMIN_FORGOT_PASSWORD } from '../../../graphql/schema/mutations/admin/ADMIN_FORGOT_PASSWORD';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter valid email').required('Please enter email')
});
const ForgotPassword = () => {
  const formRef = useRef();
  const { setToastDetails } = useContext(ToastContext);

  const navigate = useNavigate();
  const [doForgotPassword, { loading, data, error }] = useMutation(MERCHANT_FORGOT_PASSWORD);
  const [doForgotPasswordAdmin, { loading: adminLoading, data: adminData, error: adminError }] =
    useMutation(ADMIN_FORGOT_PASSWORD);

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    if (error) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: error?.message,
        type: 'danger'
      });
      return;
    }
    if (
      !loading &&
      data?.forgotPasswordMerchant?.message &&
      data?.forgotPasswordMerchant?.errorCode &&
      data?.forgotPasswordMerchant?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: data?.forgotPasswordMerchant?.message,
        type: 'danger'
      });
    } else if (data?.forgotPasswordMerchant && !loading) {
      navigate(
        '/email-verification' + `${query.get('return') ? `?return=${query.get('return')}` : ''}`,
        {
          state: {
            email: formRef?.current?.values?.email
          }
        }
      );
    }
  }, [
    loading,
    error,
    data?.forgotPasswordMerchant?.errorCode,
    data?.forgotPasswordMerchant?.message
  ]);

  useEffect(() => {
    if (error) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: error?.message,
        type: 'danger'
      });
      return;
    }
    if (
      !adminLoading &&
      adminData?.forgotPasswordSuperAdmin?.message &&
      adminData?.forgotPasswordSuperAdmin?.errorCode &&
      adminData?.forgotPasswordSuperAdmin?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: adminData?.forgotPasswordSuperAdmin?.message,
        type: 'danger'
      });
    } else if (adminData?.forgotPasswordSuperAdmin && !adminLoading) {
      navigate(
        '/email-verification' + `${query.get('return') ? `?return=${query.get('return')}` : ''}`,
        {
          state: {
            email: formRef?.current?.values?.email
          }
        }
      );
    }
  }, [
    adminLoading,
    adminError,
    adminData?.forgotPasswordSuperAdmin?.errorCode,
    adminData?.forgotPasswordSuperAdmin?.message
  ]);

  return (
    <div className="merchant-onboarding-container main-wrapper-container">
      <div className="main-content-area">
        <div className="left-side-container">
          <SignInSidebarInfo />
          <div className="p-0">
            <CommonFooter />
          </div>
        </div>
        <div className="right-side-container merchant-onboarding-content">
          <div className="main-box signin">
            <div className="content-box-wrapper scrollbar">
              <ContentCardWrapper
                title={'Forgot password?'}
                subTitle={'No worries, we’ll send you reset instructions.'}>
                <Formik
                  innerRef={formRef}
                  initialValues={{ email: '' }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    if (query.get('return') === '/admin/login') {
                      doForgotPasswordAdmin({
                        variables: {
                          input: {
                            email: values.email,
                            returnUrl: query.get('return') || '/login'
                          }
                        }
                      });
                      return;
                    }
                    doForgotPassword({
                      variables: {
                        input: {
                          email: values.email,
                          returnUrl: query.get('return') || '/login'
                        }
                      }
                    });
                  }}>
                  {({ values, errors, touched, handleChange, handleSubmit }) => (
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}>
                      <TextInput
                        label="Email address"
                        placeholder="Email address"
                        name="email"
                        value={values.email}
                        error={touched.email && errors.email}
                        onChange={handleChange}
                        id="forgotPassword.email"
                      />
                      <div className="mt-4">
                        <AppButton
                          title={'Submit'}
                          onClick={handleSubmit}
                          disabled={loading}
                          id="forgotPassword.email"
                          type="submit"
                        />
                      </div>
                      <div className="text-center fonts-medium back-link">
                        <Link to={`${query.get('return') || '/login'}`}>
                          <Image src={Images.right_arrow} alt=""></Image> Back to login
                        </Link>
                      </div>
                    </Form>
                  )}
                </Formik>
              </ContentCardWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
