import { gql } from '@apollo/client';

export const SET_PRODUCT_FEATURED = gql`
  query FeaturedVariant($featuredVariantId: ID, $sort: String, $isFeature: Boolean) {
    featuredVariant(id: $featuredVariantId, sort: $sort, isFeature: $isFeature) {
      status
      message
      data {
        _id
        featured
      }
      total
      limit
      offset
      errorCode
    }
  }
`;
