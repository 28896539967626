import { gql } from '@apollo/client';

export const CREATE_SURVEY = gql`
  mutation AddSurvey($input: addSurvey!) {
    addSurvey(input: $input) {
      status
      message
      errorCode
    }
  }
`;
