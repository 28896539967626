import { gql } from '@apollo/client';

export const MERCHANT_SET_NEW_PASSWORD = gql`
  mutation SetNewMerchantPassword($input: setnewMerchantPassword) {
    setNewMerchantPassword(input: $input) {
      status
      message
      errorCode
    }
  }
`;
