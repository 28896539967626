import React from 'react';
const ToastContext = React.createContext({
  show: false,
  title: '',
  type: '',
  message: '',
  setToastDetails: () => null
});

export default ToastContext;
