import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useLazyQuery } from '@apollo/client';
import { GET_TOP_PERFORMING_DEALS } from '../../../graphql/schema/query/admin/merchant/GET_TOP_PERFORMING_DEALS';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const options = {
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        color: '#667085',
        pointStyle: 'circle',
        usePointStyle: true,
        boxHeight: 5,
        boxWidth: 7,
        padding: 8,
        font: {
          size: 14,
          family: 'Noto Sans',
          weight: '300'
        }
      }
    }
  },
  responsive: true,
  animation: {
    easing: 'easeInOutQuad',
    duration: 520
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false
      },
      title: {
        display: true
      }
    },
    y: {
      grid: {
        color: '#F2F4F7'
      },
      title: {
        display: true,
        text: 'Purchases'
      }
    }
  }
};
const colors = ['rgba(82, 255, 154, 1)', 'rgba(0, 194, 255, 1)', 'rgba(255, 143, 80, 1)'];
const backgroundColors = ['#52FF9A', '#00C2FF', '#FF8F50'];

const PerformanceLineChart = () => {
  const [doGetTopPerformingDeals, { loading, data: chartResponseData }] = useLazyQuery(
    GET_TOP_PERFORMING_DEALS,
    {
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    doGetTopPerformingDeals();
  }, []);

  const labels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const data = {
    labels,
    datasets: chartResponseData?.topPerformingDealsChart?.data?.dealName?.map((dealName, index) => {
      const randomColor = '#' + (((1 << 24) * Math.random()) | 0).toString(16);
      return {
        label: dealName,
        data: chartResponseData?.topPerformingDealsChart?.data?.topDeal?.[index] ?? [],
        backgroundColor: colors?.[index] ?? randomColor,
        borderColor: backgroundColors?.[index] ?? randomColor,
        borderRadius: '100px'
      };
    })
  };

  return (
    <div>
      <p className="chart-title">Top Performing Deals</p>
      {!loading && chartResponseData?.topPerformingDealsChart?.data && (
        <Line options={options} data={data} id="canvas" />
      )}
    </div>
  );
};

export default PerformanceLineChart;
