import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { onError } from '@apollo/client/link/error';
import { persistCache } from 'apollo3-cache-persist';
import { createUploadLink } from 'apollo-upload-client';

import App from './App';

const parseHeaders = (rawHeaders) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
  preProcessedHeaders.split(/\r?\n/).forEach((line) => {
    const parts = line.split(':');
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });
  return headers;
};

export const uploadFetch = (url, options) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const opts = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || '')
      };
      opts.url = 'responseURL' in xhr ? xhr.responseURL : opts.headers.get('X-Request-URL');
      const body = 'response' in xhr ? xhr.response : xhr.responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.open(options.method, url, true);

    Object.keys(options.headers).forEach((key) => {
      xhr.setRequestHeader(key, options.headers[key]);
    });

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress;
    }

    options.onAbortPossible(() => {
      xhr.abort();
    });

    xhr.send(options.body);
  });

const customFetch = (uri, options) => {
  if (options.useUpload) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};

const httpLink = new createUploadLink({
  uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
  fetch: customFetch
});

const token = localStorage.getItem('token');
const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_APOLLO_CLIENT_WS_URI,
    reconnect: true,
    connectionParams: {
      'x-token': token
    }
  })
);

const terminatingLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);
const signOut = (client) => {
  localStorage.clear();
  sessionStorage.clear();
  client.resetStore();
  window.location.replace('/');
};
function stripTypenames(obj, propToDelete) {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      delete obj.property;
      const newData = stripTypenames(obj[property], propToDelete);
      obj[property] = newData;
    } else {
      if (property === propToDelete && Object.hasOwn(propToDelete)) {
        delete obj[property];
      }
    }
  }
  return obj;
}

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = localStorage.getItem('token');
    if (token) {
      headers = { ...headers, 'x-token': token };
    }
    return { headers };
  });
  if (operation.variables) {
    operation.variables = stripTypenames(operation.variables, '__typename');
    return forward ? forward(operation) : null;
  }
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log({ graphQLErrors, networkError });
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, extensions, path }) => {
      if (
        extensions?.code === 'UNAUTHENTICATED' &&
        path?.[0] !== 'changePasswordBySuperAdmin' &&
        path?.[0] !== 'changePasswordMerchant'
      ) {
        localStorage.clear();
        console.log(message);
        toast.error(message);
        setTimeout(() => {
          if (window.location.pathname !== '/login') {
            window.location.replace('/login');
          }
        }, 5000);
      }
      if (message == 'Context creation failed: Your session expired. Sign in again.') {
        signOut(client);
      }
    });
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const link = ApolloLink.from([authLink, errorLink, terminatingLink, wsLink]);

const cache = new InMemoryCache();
const storage = window.localStorage;
const waitOnCache = persistCache({ cache, storage });

const client = new ApolloClient({ link, cache });
waitOnCache.then(() => {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <App />
      <ToastContainer autoClose={5000} closeOnClick newestOnTop limit={1} />
    </ApolloProvider>,
    document.getElementById('root')
  );
});
