import React, { useState } from 'react';
import { Col, FormLabel, Image, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { baseFilePaths } from '../../../constants';
import Images from '../../../constants/images';
import FileDragNDrop from '../../common/file-drag-and-drop/FileDragNDrop';
import TextInput from '../../common/text-input/TextInput';

const baseFilePath = baseFilePaths.businessProfile;
const baseFilePathForBanner = baseFilePaths.businessBanner;

const MerchantFormImagesAndVideo = ({
  doDeleteBusinessBanner,
  handleChange,
  values,
  touched,
  errors,
  setSelectedProfilePage,
  selectedProfilePage,
  setSelectedBanners,
  selectedBanners,
  // setFieldValue,
  setToastDetails,
  loading = false,
  profileImageCache,
  imageProgressRef
}) => {
  const [progress, setProgress] = useState(0);
  const [deleteIndex, setDeleteIndex] = useState();

  React.useEffect(() => {
    if (imageProgressRef) {
      imageProgressRef.current = updateProgress;
    }
  }, []);

  const updateProgress = (progress) => {
    setProgress((progress * 100).toFixed(2));
  };

  let bgImage = '';
  if (values?.businessLogo && values?.businessLogo !== null && values?.businessLogo !== 'null') {
    bgImage = `url('${baseFilePath}${values?.businessLogo}?cacheBuster=${profileImageCache}')`;
  } else if (values.logoUrl && values.logoUrl !== null && values.logoUrl !== 'null') {
    bgImage = `url('${values.logoUrl}')`;
  }

  if (selectedProfilePage) {
    bgImage = `url(${URL.createObjectURL(selectedProfilePage)})`;
  }

  const renderPreviewImage = (bannerImage, index, isLocal, file) => {
    let imgSrc = `${baseFilePathForBanner}${bannerImage}`;
    if (isLocal && file) {
      imgSrc = URL.createObjectURL(file);
    }

    let size = file?.size;
    if (size) {
      if (size > 1024 * 1024) {
        size = `${(size / (1024 * 1024)).toFixed(2)} MB`;
      } else {
        size = `${size} B`;
      }
    }
    return (
      <ListGroupItem
        key={bannerImage}
        className="mt-2 d-flex align-items-center justify-content-between"
        style={{ borderTopWidth: index !== 0 ? 1 : 0 }}>
        {progress > 0 && progress <= 100 && isLocal && (
          <div className="image-progress-indicator" style={{ width: `${progress}%` }} />
        )}
        <div className="d-flex align-items-center">
          <Image src={imgSrc} style={{ height: 60, width: 60 }} />
          <div className="">
            <span className="image-name fonts-regular">{bannerImage}</span>
            <br />
            <span className="image-name-size fonts-regular">
              {size} {progress > 0 && progress <= 100 && isLocal ? ` - ${progress}% uploaded` : ''}
            </span>
          </div>
        </div>

        {loading || (deleteIndex && bannerImage === deleteIndex) ? (
          <span className="sm-loader float-end mt-2"></span>
        ) : (
          <Image
            role="button"
            className="float-end mt-2"
            src={Images.close}
            style={{ height: 20, width: 20 }}
            onClick={() => {
              if (!isLocal) {
                setDeleteIndex(bannerImage);
                doDeleteBusinessBanner?.(bannerImage);
              } else {
                selectedBanners.splice(index, 1);
                setSelectedBanners([...selectedBanners]);
              }
            }}
          />
        )}
      </ListGroupItem>
    );
  };

  const labelProps = {};
  if (bgImage) {
    labelProps.style = {
      backgroundImage: bgImage
    };
  }

  return (
    <div className="wrapper-padding pt-0">
      <label
        id="file-label"
        className={`file-input-picker-rounded ${bgImage !== '' ? 'image-uploaded' : ''}`}
        {...labelProps}
        data-selected={bgImage ? '1' : '0'}>
        <input
          type="file"
          id="file"
          aria-label="Logo"
          onClick={(e) => {
            e.target.value = '';
            if (!selectedProfilePage) {
              setSelectedProfilePage();
            }
          }}
          onChange={(event) => {
            if (event?.target?.files?.[0]) {
              setSelectedProfilePage(event.target.files[0]);
            }
          }}
          accept="image/*"
        />
        <Image src={Images.edit} />
        {/* <span className="fonts-medium">{values?.businessLogo ? 'Change ' : 'Add a'} logo</span> */}
      </label>
      <p className="form-title mb-0 fonts-quicksand-medium">Banner Images</p>
      <span className="form-subtitle fonts-regular">Merchant&apos;s Banner Image</span>
      <Row className="mt-2">
        <Col md={12}>
          <FormLabel className="images-label">
            Images — <span>Optional</span>
          </FormLabel>
          <FileDragNDrop
            setToastDetails={setToastDetails}
            maxFiles={15}
            onSelectImage={(images) => {
              if (images?.length > 0) {
                const existingImages = values?.bannerImages ?? [];
                if ([...selectedBanners, ...images, ...existingImages].length > 15) {
                  setToastDetails?.({
                    show: true,
                    title: 'Error!',
                    message: `You can select maximum 15 images`,
                    type: 'danger'
                  });
                  return;
                }
                setSelectedBanners([...selectedBanners, ...images].slice(0, 15));
              }
            }}
          />
        </Col>
        <Col md={12} className="upload-file-list">
          <ListGroup>
            {Array.isArray(selectedBanners)
              ? selectedBanners.map((selectedBanner, index) => {
                  return renderPreviewImage(selectedBanner?.name, index, true, selectedBanner);
                })
              : null}
            {Array.isArray(values?.bannerImages)
              ? values?.bannerImages.map((bannerImage, index) => {
                  return renderPreviewImage(bannerImage, index, false, null);
                })
              : null}
          </ListGroup>
        </Col>
        <Col md={12} className="youtube-label">
          <FormLabel>
            Youtube URL — <span>Optional (Your Business Commercial to showcase your company)</span>
          </FormLabel>
          <TextInput
            placeholder="https://"
            name="socialUrl"
            value={values.socialUrl}
            error={touched.socialUrl && errors.socialUrl}
            onChange={handleChange}
            leftIcon={Images.play}
            id="merchantFormImageAndVideos.socialUrl"
            // onKeyDown={() => {
            //   if (!values?.socialUrl || values?.socialUrl === '') {
            //     setFieldValue('socialUrl', 'https://');
            //   }
            // }}
            // onBlur={() => {
            //   if (
            //     !values?.socialUrl ||
            //     values?.socialUrl === '' ||
            //     values?.socialUrl === 'https://'
            //   ) {
            //     setFieldValue('socialUrl', '');
            //   }
            // }}
          />
          <span className="youtube-url-text">Add a Youtube URL</span>
        </Col>
      </Row>
    </div>
  );
};

export default MerchantFormImagesAndVideo;
