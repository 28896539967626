import { gql } from '@apollo/client';

export const GET_STATES = gql`
  query Data {
    state {
      data {
        _id
        name
      }
      status
      message
      errorCode
    }
  }
`;
