import { gql } from '@apollo/client';

export const MERCHANT_SIGNIN = gql`
  mutation SignInByMerchant($input: SignInByMerchant!) {
    signInByMerchant(input: $input) {
      status
      message
      email
      business {
        merchantID
        isActive
        businessName
        address
        businessDescription
        apartmentNo
        city
        state_id {
          _id
          name
        }
        country_id {
          _id
          name
        }
        zipcode
        website
        email
        category_id {
          _id
          name
        }
        subCategory_id {
          _id
          name
          refId {
            _id
            name
          }
        }
        socialUrl
        phoneno
        businessLogo
        bannerImages
        timings {
          status
          openingTime
          closingTime
          isOpenDay
        }
        businessStatus
        latitude
        longitude
        logoUrl
        timezone
        totalDeal
      }
      jwttoken
      errorCode
      message
      status
    }
  }
`;
