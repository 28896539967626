export const isProd = () => process.env?.APP_ENV === 'production';
export const isEpxFlowDone = () => String(process.env?.REACT_APP_EPX_FLOW_DONE) === 'true';

export const formatPhoneNo = (phone) => {
  if (!phone) {
    return '-';
  }
  let phoneString = String(phone);
  phoneString = phoneString.replace(/ /g, '');
  phoneString = phoneString.replace(/-/g, '');
  phoneString = phoneString.replace(/_/g, '');
  if (phoneString.startsWith('+') && phoneString?.length === 12) {
    return phoneString;
  }

  if (phoneString.startsWith('+')) {
    phoneString = phoneString.replace('+', '');
  }

  return `+1 ${phoneString.slice(0, 3)} ${phoneString.slice(3, 6)} ${phoneString.slice(6)}`;
};

export const getAddress = (data) => {
  let address = '';

  if (data?.address && data?.address !== data?.apartmentNo) {
    address += `${data?.address}, `;
  }

  if (data?.apartmentNo && data?.apartmentNo !== 'undefined') {
    address += `${data?.apartmentNo}, `;
  }

  if (data?.city) {
    address += `${data?.city}, `;
  }

  if (data?.state_id?.name) {
    address += `${data?.state_id?.name}, `;
  }

  if (data?.country_id?.name) {
    address += `${data?.country_id?.name} `;
  }

  if (data?.zipcode) {
    address += `${data?.zipcode} `;
  }

  return address;
};
