import { gql } from '@apollo/client';

export const GET_MERCHANT_DEALS = gql`
  query GetDealsByUser(
    $merchantId: ID
    $search: String
    $isFeatured: Boolean
    $isActive: Boolean
    $viewAll: Boolean
    $offset: Int
    $limit: Int
  ) {
    getDealsByUser(
      merchantID: $merchantId
      search: $search
      isFeatured: $isFeatured
      isActive: $isActive
      offset: $offset
      viewAll: $viewAll
      limit: $limit
    ) {
      status
      message
      data {
        _id
        isActive
        merchant_id {
          _id
        }
        dealImages
        name
        decription
        price
        discountType
        discountValue
        startDate
        endDate
        featured
      }
      total
      limit
      offset
      errorCode
    }
  }
`;
