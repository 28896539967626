import { gql } from '@apollo/client';

export const DELETE_USER = gql`
  query DeleteUser($deleteUserId: ID) {
    deleteUser(id: $deleteUserId) {
      status
      message
      errorCode
    }
  }
`;
