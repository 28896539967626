import { useLazyQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';

import withAdmin from '../../../../components/hoc/admin/withAdmin';

import FormWrapper from '../../../../components/common/form-wrapper/FormWrapper';
import MerchantFormBusinessDetails from '../../../../components/merchant/profile-form-sections/MerchantFormBusinessDetails';
import MerchantFormImagesAndVideo from '../../../../components/merchant/profile-form-sections/MerchantFormImagesAndVideo';
import MerchantFormLocationDetails from '../../../../components/merchant/profile-form-sections/MerchantFormLocationDetails';
import MerchantFormOpeningHoursDetails from '../../../../components/merchant/profile-form-sections/MerchantFormOpeningHoursDetails';

import ToastContext from '../../../../contexts/ToastContext';

import { BusinessInfoInitValues, BusinessInfoSchema } from '../edit/formUtils';

import '../styles.css';
import FinancialInformation from '../../../../components/admin/common-form-sections/FinancialInformation';
import { GET_MERCHANT_DETAILS } from '../../../../graphql/schema/query/admin/merchant/GET_MERCHANT_DETAILS';
import { useNavigate, useParams } from 'react-router-dom';
import { UPDATE_MERCHANT_DETAILS } from '../../../../graphql/schema/query/admin/merchant/UPDATE_MERCHANT_DETAILS';
import ErrorFocus from '../../../../components/common/ErrorFocus/ErrorFocus';
import { DELETE_BANNER_IMAGES_ADMIN } from '../../../../graphql/schema/query/admin/merchant/DELETE_BANNER_IMAGES_ADMIN';
import LoaderComponent from '../../../../components/common/loader/LoaderComponent';

const MerchantManagementEdit = () => {
  const { merchantId } = useParams();
  const navigate = useNavigate();
  const { setToastDetails } = useContext(ToastContext);
  const [doGetDetails, { loading, data }] = useLazyQuery(GET_MERCHANT_DETAILS, {
    fetchPolicy: 'network-only'
  });

  const [doUpdateBusiness, { loading: saving, error, data: saved }] =
    useMutation(UPDATE_MERCHANT_DETAILS);

  const [doDeleteBusinessBanner, { loading: deleting, error: deleteError, data: deleted }] =
    useMutation(DELETE_BANNER_IMAGES_ADMIN);

  const [selectedProfilePage, setSelectedProfilePage] = useState();
  const [selectedBanners, setSelectedBanners] = useState([]);

  useEffect(() => {
    doGetDetails({
      variables: {
        id: merchantId
      }
    });
  }, [merchantId]);

  const saveBusiness = (values) => {
    const inputs = {
      _id: merchantId,
      business_data: {
        businessName: values?.businessName,
        address: values?.address,
        apartmentNo: values?.apartmentNo,
        city: values?.city,
        zipcode: values?.zipcode,
        website: values?.website,
        ...(values?.email && { email: values?.email }),
        category_id: values?.category_id?._id ?? values?.category_id,
        country_id: values?.country_id?._id ?? values?.country_id,
        state_id: values?.state_id?._id ?? values?.state_id,
        subCategory_id: values?.subCategory_id?._id ?? values?.subCategory_id,
        socialUrl: values?.socialUrl ? values?.socialUrl : '',
        phoneno: values?.phoneno,
        timings: values.timings,
        longitude: String(values?.longitude),
        latitude: String(values?.latitude),
        logoUrl: String(values?.logoUrl),
        businessDescription: values?.businessDescription
      },
      businessLogo: selectedProfilePage,
      bannerImages: selectedBanners,
      epxID: values?.epxID
    };
    doUpdateBusiness({
      variables: {
        input: inputs
      }
    });
  };

  useEffect(() => {
    if (
      !saving &&
      saved?.updateBusinessByAdmin?.message &&
      saved?.updateBusinessByAdmin?.errorCode &&
      saved?.updateBusinessByAdmin?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: saved?.updateBusinessByAdmin?.message,
        type: 'danger'
      });
    } else {
      if (saved?.updateBusinessByAdmin?.business?._id) {
        setToastDetails({
          show: true,
          title: 'Merchant Updated',
          message: 'Merchant details saved successfully.',
          type: 'success'
        });
        navigate(-1, { replace: true });
      }
    }
  }, [
    saving,
    error,
    saved?.updateBusinessByAdmin?.errorCode,
    saved?.updateBusinessByAdmin?.message,
    saved?.updateBusinessByAdmin?.business?._id
  ]);

  useEffect(() => {
    if (!deleting && deleteError) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: 'Something went wrong!, Please try again after sometime',
        type: 'danger'
      });
    }
    if (
      !deleting &&
      deleted?.deleteBannerImageAndVideoByAdmin?.message &&
      deleted?.deleteBannerImageAndVideoByAdmin?.errorCode &&
      deleted?.deleteBannerImageAndVideoByAdmin?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: deleted?.deleteBannerImageAndVideoByAdmin?.message,
        type: 'danger'
      });
    } else {
      if (deleted?.deleteBannerImageAndVideoByAdmin?.status) {
        setToastDetails({
          show: true,
          title: 'Banner deleted',
          message: 'Merchant Banner deleted successfully.',
          type: 'success'
        });
        doGetDetails({
          variables: {
            id: merchantId
          }
        });
      }
    }
  }, [
    deleting,
    deleteError,
    deleted?.deleteBannerImageAndVideoByAdmin?.errorCode,
    deleted?.deleteBannerImageAndVideoByAdmin?.message,
    deleted?.deleteBannerImageAndVideoByAdmin?.status
  ]);

  const details = data?.getMerchantList?.user?.[0].business_data;
  if (details && !details?.email) {
    details.email = data?.getMerchantList?.user?.[0]?.email;
  }
  // data
  if (loading) {
    return <LoaderComponent />;
  }

  const timings = details?.timings?.length > 0 ? details?.timings : BusinessInfoInitValues.timings;

  return (
    <>
      <Formik
        initialValues={{
          ...BusinessInfoInitValues,
          ...details,
          category_id: details?.category_id?._id,
          subCategory_id: details?.subCategory_id?._id,
          state_id: details?.state_id?._id,
          timings,
          epxID: data?.getMerchantList?.user?.[0]?.epxID,
          _id: undefined,
          __typename: undefined
        }}
        validationSchema={BusinessInfoSchema}
        onSubmit={saveBusiness}>
        {({ values, errors, touched, handleChange, setFieldValue, handleSubmit }) => {
          return (
            <div className="business-profile-card merchant-edit-wrapper">
              {loading || saving ? <LoaderComponent /> : null}
              <FormWrapper
                loading={saving}
                handleSubmit={handleSubmit}
                onCancel={() => {
                  navigate(-1, { replace: true });
                }}>
                <MerchantFormImagesAndVideo
                  setToastDetails={setToastDetails}
                  setSelectedBanners={setSelectedBanners}
                  selectedBanners={selectedBanners}
                  setSelectedProfilePage={setSelectedProfilePage}
                  selectedProfilePage={selectedProfilePage}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  values={values}
                  touched={touched}
                  errors={errors}
                  doDeleteBusinessBanner={(filename) => {
                    doDeleteBusinessBanner({
                      variables: {
                        input: {
                          filename,
                          merchant_id: merchantId
                        }
                      }
                    });
                  }}
                />
                <hr />
                <MerchantFormBusinessDetails
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  values={values}
                  touched={touched}
                  errors={errors}
                  isAdmin
                />
                <hr />
                <FinancialInformation></FinancialInformation>
                <hr />
                <MerchantFormLocationDetails
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  values={values}
                  touched={touched}
                  errors={errors}
                  isAdmin
                />
                <hr />
                <MerchantFormOpeningHoursDetails
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  values={values}
                  touched={touched}
                  errors={errors}
                  timings={timings}
                />
                <ErrorFocus />
              </FormWrapper>
            </div>
          );
        }}
      </Formik>
    </>
  );
};
export default withAdmin(MerchantManagementEdit);
