import React, { useContext, useEffect } from 'react';
import { Form, Image } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client/react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import AppButton from '../../../components/common/button/Button';
import TextInput from '../../../components/common/text-input/TextInput';
import ContentCardWrapper from '../../../components/merchant/onboarding/content-card-wrapper/ContentCardWrapper';
import SignInSidebarInfo from '../../../components/merchant/onboarding/step-indicator/SignInSidebarInfo';
import { MERCHANT_SET_NEW_PASSWORD } from '../../../graphql/schema/mutations/onboarding/MERCHANT_SET_NEW_PASSWORD';
import CommonFooter from '../../../pages/onboarding/footer/CommonFooter';
import Images from '../../../constants/images';
import ToastContext from '../../../contexts/ToastContext';
import { passwordMessage, passwordRegex } from '../../../constants';

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Please enter password').matches(passwordRegex, passwordMessage),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Please enter confirm password')
    .matches(passwordRegex, passwordMessage)
});
const NewPassword = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [doSetNewPassword, { data, loading, error }] = useMutation(MERCHANT_SET_NEW_PASSWORD);
  const { setToastDetails } = useContext(ToastContext);

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    if (error) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: error?.message,
        type: 'danger'
      });
      return;
    }
    if (
      !loading &&
      data?.setNewMerchantPassword?.message &&
      data?.setNewMerchantPassword?.errorCode &&
      data?.setNewMerchantPassword?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: data?.setNewMerchantPassword?.message,
        type: 'danger'
      });
    } else if (data?.setNewMerchantPassword && !loading) {
      setToastDetails({
        show: true,
        title: 'Password reset',
        message: 'Password reset successfully.',
        type: 'success'
      });
      navigate(`/reset-password-successfully?return=${query?.get('returnUrl') || '/login'}`, {
        replace: true
      });
    }
  }, [
    loading,
    error,
    data?.setNewMerchantPassword?.errorCode,
    data?.setNewMerchantPassword?.message
  ]);

  return (
    <div className="merchant-onboarding-container main-wrapper-container">
      <div className="main-content-area">
        <div className="left-side-container">
          <SignInSidebarInfo />
          <div className="p-0">
            <CommonFooter />
          </div>
        </div>
        <div className="right-side-container merchant-onboarding-content">
          <div className="main-box signin">
            <div className="content-box-wrapper scrollbar">
              <ContentCardWrapper
                title={'Set new password'}
                subTitle={'Your new password must be different to previously used passwords.'}>
                <Formik
                  initialValues={{ password: '', passwordConfirm: '' }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    doSetNewPassword({
                      variables: {
                        input: {
                          token: params.token,
                          newPassword: values.password
                        }
                      }
                    });
                  }}>
                  {({ values, errors, touched, handleChange, handleSubmit }) => {
                    return (
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit();
                        }}>
                        <TextInput
                          label="New Password"
                          placeholder="New Password"
                          name="password"
                          type="password"
                          rightIcon={Images.eye_cross}
                          error={touched.password && errors.password}
                          value={values.password}
                          onChange={handleChange}
                          id="newPassword.password"
                        />
                        <TextInput
                          label="Confirm New Password"
                          placeholder="Confirm New Password"
                          name="passwordConfirm"
                          type="password"
                          rightIcon={Images.eye_cross}
                          value={values.passwordConfirm}
                          error={touched.passwordConfirm && errors.passwordConfirm}
                          onChange={handleChange}
                          id="newPassword.passwordConfirm"
                        />
                        <div className="mt-4">
                          <AppButton
                            title={'Reset Password'}
                            onClick={handleSubmit}
                            disabled={loading}
                            id="newPassword.resetPassword"
                            type="submit"
                          />
                        </div>
                        <div className="text-center fonts-medium back-link">
                          <Link to="/login">
                            <Image src={Images.right_arrow} alt=""></Image> Back to login
                          </Link>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </ContentCardWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
