import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import AppButton from '../../../components/common/button/Button';
import withMerchantOnboarding from '../../../components/hoc/merchant/withMerchantOnboarding';
import ContentCardWrapper from '../../../components/merchant/onboarding/content-card-wrapper/ContentCardWrapper';

import './styles.css';
import { ACCEPT_TERMS } from '../../../graphql/schema/query/users/ACCEPT_TERMS';
import { GET_TERMS_AND_CONDITION } from '../../../graphql/schema/query/masters/GET_TERMS_AND_CONDITION';
import FeedbackModal from '../../../components/common/feedback-modal/FeedbackModal';
import Images from '../../../constants/images';
import { UserContext } from '../../../contexts';

const TermsAndCondition = () => {
  const { setUserDetails } = useContext(UserContext);

  const navigate = useNavigate();
  const [showSuccessModal, setSuccessModal] = useState(false);

  const [doGeterms, { data: termsContent, loading: fetching }] = useLazyQuery(
    GET_TERMS_AND_CONDITION,
    {
      fetchPolicy: 'network-only'
    }
  );
  const [doAcceptTerms, { data, loading }] = useLazyQuery(ACCEPT_TERMS);

  useEffect(() => {
    if (data?.termsAndConditionStatus?.user?._id && !loading) {
      setSuccessModal(true);
    }
  }, [data?.termsAndConditionStatus?.user?._id, loading]);

  useEffect(() => {
    doGeterms();
  }, []);

  return (
    <ContentCardWrapper
      title={'Centavizer Terms & Conditions'}
      subTitle={'Please read the terms and conditions below.'}>
      <div className="terms-container">
        <h5>Terms & Conditions</h5>
        {fetching ? (
          <p>Loading</p>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: termsContent?.getTermsAndCondition?.data || ''
            }}
          />
        )}
      </div>
      <div className="mt-4 text-center">
        <AppButton
          disabled={loading}
          title={loading ? 'Please wait...' : 'Accept & Continue'}
          onClick={() => {
            doAcceptTerms();
          }}
          width={'w-custom'}
          id="terms.acceptAndContinue"
        />
      </div>
      <FeedbackModal
        show={showSuccessModal}
        onHide={() => {
          setUserDetails();
          setSuccessModal(false);
          navigate('/merchant/dashboard', { replace: true });
        }}
        icon={Images.success_icon}
        title="Merchant account created"
        subTitle="You have successfully created your merchant account. Click below to begin using your dashboard."
        buttons={[
          <AppButton
            title={'Go to your dashboard'}
            key="1"
            onClick={() => {
              setUserDetails();
              setSuccessModal(false);
              navigate('/merchant/dashboard', { replace: true });
            }}
            id="terms.goToDashboard"
          />
        ]}
      />
    </ContentCardWrapper>
  );
};

export default withMerchantOnboarding(TermsAndCondition);
