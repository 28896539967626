import React, { useEffect } from 'react';
import PerformanceCard from '../../../components/common/dashboard/PerformanceCard';
import ActiveDealCard from '../../../components/common/dashboard/ActiveDealCard';
import DealInfoCard from '../../../components/common/dashboard/DealInfoCard';
import NotificationCard from '../../../components/common/dashboard/NotificationCard';
import withMerchantAdmin from '../../../components/hoc/merchant/withMerchantAdmin';
import './styles.css';
import { useLazyQuery } from '@apollo/client';
import { GET_MERCHANT_DETAILS } from '../../../graphql/schema/query/merchant/GET_MERCHANT_DETAILS';
import LoaderComponent from '../../../components/common/loader/LoaderComponent';

const MerchantDashboardPage = () => {
  const [doGetMerchantDetails, { loading, data }] = useLazyQuery(GET_MERCHANT_DETAILS, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    doGetMerchantDetails();
  }, []);

  const merchantDetails = data?.getMerchantDetail?.merchantDetail?.[0];

  return (
    <>
      <div className="dashboard-wrapper">
        {loading ? <LoaderComponent /> : null}
        <div className="deal-info">
          <DealInfoCard merchantDetails={merchantDetails} merchant />
        </div>
        <div className="main-wrapper-space">
          <div className="left-wrapper">
            <div className="notification-wrapper">
              <NotificationCard merchantId={merchantDetails?._id}></NotificationCard>
            </div>
            <div className="active-deal-wrapper">
              <ActiveDealCard></ActiveDealCard>
            </div>
          </div>
          <div className="right-wrapper">
            <PerformanceCard></PerformanceCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default withMerchantAdmin(MerchantDashboardPage);
