import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TextInput from '../../common/text-input/TextInput';

const SurveySettings = ({ values, handleChange, errors, touched, init, isActive }) => {
  return (
    <>
      <div className="wrapper-padding">
        <p className="form-title mb-0 fonts-quicksand-medium">Survey Settings</p>
        <span className="form-subtitle fonts-regular">
          This information will not be shown the users
        </span>
        <Row className="mt-2">
          <Col md={12}>
            <TextInput
              readOnly={isActive}
              label="Survey title"
              placeholder="Add a title"
              name="title"
              value={values?.title}
              onChange={handleChange}
              error={touched.title && errors.title}
            />
          </Col>
          <Col md={6}>
            <TextInput
              readOnly={isActive}
              label="Start date"
              type="date"
              onChange={handleChange}
              name="startDate"
              error={touched.startDate && errors.startDate}
              min={moment.min([moment(init?.startDate), moment()]).format('YYYY-MM-DD')}
              max={'9999-12-31'}
              value={values.startDate && moment(values.startDate).format('YYYY-MM-DD')}
              id="survey.startDate"
            />
          </Col>
          <Col md={6}>
            <TextInput
              label="End date"
              type="date"
              onChange={handleChange}
              name="endDate"
              error={touched.endDate && errors.endDate}
              min={moment(values.startDate || new Date()).format('YYYY-MM-DD')}
              id="survey.endDate"
              max={'9999-12-31'}
              value={values.endDate && moment(values.endDate).format('YYYY-MM-DD')}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SurveySettings;
