import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Form, Image, Row } from 'react-bootstrap';
import Images from '../../../constants/images';
import ToastContext from '../../../contexts/ToastContext';
import { DELETE_DEAL } from '../../../graphql/schema/mutations/deals/DELETE_DEAL';
import AppButton from '../button/Button';
import FeedbackModal from '../feedback-modal/FeedbackModal';
import './styles.css';
import CacheImage from '../CacheImage/CacheImage';
import { FEATURE_DEAL } from '../../../graphql/schema/query/admin/merchant/FEATURE_DEAL';

const icons = {
  flat: Images.dollar,
  percent: Images.percentage
};
const DealCard = ({
  image,
  type,
  title,
  from,
  to,
  rrp,
  discount,
  id,
  navigate,
  refresh,
  isProfileCompleted,
  isActive,
  isAdmin,
  isFeatured
}) => {
  const { setToastDetails } = useContext(ToastContext);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [doDeleteDeal, { loading, data }] = useLazyQuery(DELETE_DEAL, {
    variables: {
      deleteDealId: id
    }
  });

  const [doMakeDealFeatured, { loading: featuring, data: featuredDeal }] = useLazyQuery(
    FEATURE_DEAL,
    {
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (!featuring && featuredDeal?.featureDeal?.message) {
      if (featuredDeal?.featureDeal?.errorCode === '0') {
        setToastDetails({
          show: true,
          title: 'Deal Featured',
          message: `Deal ${
            featuredDeal?.featureDeal?.data?.featured ? 'added' : 'removed'
          } as featured  successfully`,
          type: 'success'
        });
      }
      if (featuredDeal?.featureDeal?.errorCode === '1') {
        setToastDetails({
          show: true,
          title: 'Deal Featured',
          message: featuredDeal?.featureDeal?.message,
          type: 'danger'
        });
      }
    }
  }, [
    featuring,
    featuredDeal?.featureDeal?.errorCode,
    featuredDeal?.featureDeal?.message,
    featuredDeal?.featureDeal?.status,
    featuredDeal?.featureDeal?.data?.featured
  ]);

  useEffect(() => {
    if (data?.deleteDeal?.status && !loading) {
      setToastDetails({
        show: true,
        title: 'Deal Deleted',
        message: 'Deal Deleted successfully',
        type: 'success'
      });
      refresh?.();
    }
  }, [loading, data?.deleteDeal?.status]);

  return (
    <Card
      className={`deal-card ${!isProfileCompleted || !isActive ? 'not-completed' : 'completed'}`}
      key={id}
      type={type}>
      {/*style={{ opacity: !isProfileCompleted && 0.4 }}*/}
      <FeedbackModal
        show={showConfirmDelete}
        onHide={() => setShowConfirmDelete(false)}
        icon={Images.delete_confirm}
        title="Delete Deal"
        subTitle="Are you sure you want to delete this deal? This action cannot be undone."
        className="delete-deal-modal"
        buttons={[
          <AppButton
            title={'Cancel'}
            key="1"
            buttonClass={'btn-cancel'}
            onClick={() => setShowConfirmDelete(false)}
            id="deal.delete.cancel"
          />,
          <AppButton
            buttonClass={'error-bg'}
            title={'Delete'}
            key="2"
            onClick={() => {
              doDeleteDeal();
              setShowConfirmDelete(false);
            }}
            id="deal.delete.confirm"
          />
        ]}
      />
      <div className="w-100 position-relative">
        <CacheImage url={image} className="deal-image" />
        <Image src={icons?.[type]} className="type-image" />
        <div
          className={`deal-badge ${
            !isProfileCompleted || !isActive ? 'inactive-badge' : 'active-badge'
          }`}>
          <span className="active-view">Active</span>
          <span className="inactive-view">Inactive</span>
        </div>
        <span></span>
      </div>
      <Card.Body className={type === 'percent' ? 'green-line' : 'orange-line'}>
        <div className="deal-name-top">
          <div className="card-content left-name">
            <span className="m-0 title fonts-quicksand-medium">{title}</span>
          </div>
          {!isAdmin ? (
            <div className="d-flex justify-content-end align-content-center right-btn">
              <Image
                src={Images.edit_sm}
                className="action-btn"
                onClick={() => navigate(`/merchant/deals/update/${id}`)}
              />
              <Image
                src={Images.delete_sm}
                className="action-btn"
                onClick={() => {
                  setShowConfirmDelete(true);
                }}
              />
            </div>
          ) : null}
        </div>
        <Row className="card-content">
          <div className="left">
            <span className="data-label fonts-medium">From:</span>
          </div>
          <div className="right">
            <span className="data-value fonts-medium">{from}</span>
          </div>
        </Row>
        <Row className="card-content">
          <div className="left">
            <span className="data-label fonts-medium">To:</span>
          </div>
          <div className="right">
            <span className="data-value fonts-medium">{to}</span>
          </div>
        </Row>
        <Row className="card-content">
          <div className="left">
            <span className="data-label fonts-medium">RRP:</span>
          </div>
          <div className="right">
            <span className="data-value fonts-medium">{rrp}</span>
          </div>
        </Row>
        <Row className="card-content">
          <div className="left">
            <span className="data-label fonts-medium">Discount:</span>
          </div>
          <div className="right">
            <span className="data-value fonts-medium color-error">
              {type === 'flat' && '$'}
              {discount}
              {type === 'percent' && '%'}
            </span>
          </div>
        </Row>
        <Row>
          {isAdmin ? (
            <div className="featured-product">
              <div className="feature-area">
                <Form.Check
                  className="global float-start toggle-switch status"
                  type="switch"
                  label={featuring ? 'Please wait...' : 'Featured Deal'}
                  name="featured_deal"
                  checked={isFeatured}
                  onChange={() => {
                    doMakeDealFeatured({
                      variables: {
                        dealId: id
                      }
                    });
                  }}
                />
              </div>
            </div>
          ) : null}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DealCard;
