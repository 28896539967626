import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import moment from 'moment';
import TextInput from '../../common/text-input/TextInput';
import Images from '../../../constants/images';
import { baseFilePaths } from '../../../constants';
import SelectInput from '../../common/select-input/SelectInput';

const PersonalInformation = ({
  values,
  handleChange,
  errors,
  touched,
  selectedImage,
  profileImageCache,
  setSelectedImage
}) => {
  let bgImage = '';
  if (values?.profilepicture) {
    bgImage = `url('${baseFilePaths.userProfileImage}${values?.profilepicture}?cacheBuster=${profileImageCache}')`;
  } else if (values.logoUrl) {
    bgImage = `url('${values.logoUrl}')`;
  }

  if (selectedImage) {
    bgImage = `url(${URL.createObjectURL(selectedImage)})`;
  }
  const labelProps = {};
  if (bgImage) {
    labelProps.style = {
      backgroundImage: bgImage
    };
  }

  return (
    <>
      <div className="wrapper-padding">
        <div className="top-profile-info">
          <div className="user-profile-pic">
            <label
              className={`file-input-picker-rounded ${bgImage !== '' ? 'image-uploaded' : ''}`}
              {...labelProps}>
              <input
                type="file"
                id="file"
                aria-label="Logo"
                accept="image/jpg,image/jpeg,image/png"
                onClick={(e) => {
                  e.target.value = '';
                  if (!selectedImage) {
                    setSelectedImage();
                  }
                }}
                onChange={(event) => {
                  if (event?.target?.files?.[0]) {
                    setSelectedImage(event.target.files[0]);
                  }
                }}
              />
              <Image src={Images.edit} />
            </label>
          </div>
          <div className="user-score">
            <div className="blue-box p-3">
              <Image src={Images.wallet} />
              <p>Current Centz</p>
              <TextInput
                inputGroupClass="bg-white border-radius-6 centz-input"
                noLabel
                className="count"
                leftText="$"
                onChange={handleChange}
                value={values?.totalCentz ?? 0}
                name="totalCentz"
              />
            </div>
            <div className="blue-box">
              <Image src={Images.piggy_bank} />
              <p>Lifetime earnings</p>
              <div className="count">
                $<span>{Number(values?.lifeTimeEarnings ?? 0).toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>
        <p className="form-title mb-0 fonts-quicksand-medium">Personal information</p>
        <span className="form-subtitle fonts-regular">User&apos;s Personal Details</span>
        <Row className="mt-2">
          <Col md={6}>
            <TextInput
              label="First name"
              placeholder="Enter first name"
              id="personalInfo.fname"
              name="firstname"
              onChange={handleChange}
              value={values?.firstname}
              error={touched?.firstname && errors?.firstname}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label="Last name"
              placeholder="Enter last name"
              id="personalInfo.lname"
              name="lastname"
              onChange={handleChange}
              value={values?.lastname}
              error={touched?.lastname && errors?.lastname}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label="Email address"
              placeholder="Email address"
              id="userLocation.email"
              name="email"
              onChange={handleChange}
              value={values?.email}
              error={touched?.email && errors?.email}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label="Contact number"
              placeholder="Contact number"
              id="personalInfo.contactnumber"
              name="phoneno"
              onChange={handleChange}
              value={values?.phoneno}
              error={touched?.phoneno && errors?.phoneno}
              leftText="+1"
              maskingProps={{
                mask: '999-999-9999',
                maskChar: ' '
              }}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label="Date of birth"
              id="personalInfo.dob"
              type="date"
              name="dob"
              onChange={handleChange}
              value={values?.dob ? moment(values?.dob).format('YYYY-MM-DD') : null}
              error={touched?.dob && errors?.dob}
              max={moment().format('YYYY-MM-DD')}
            />
          </Col>
          <Col md={6}>
            <SelectInput
              isDisabled
              label="Gender"
              placeholder="Select Category"
              value={values.gender?._id ?? values.gender}
              options={[
                {
                  name: 'Male',
                  _id: 'male',
                  isDisabled: true
                },
                {
                  name: 'Female',
                  _id: 'female',
                  isDisabled: true
                },
                {
                  name: 'Other',
                  _id: 'other',
                  isDisabled: true
                }
              ]}
              error={touched.gender && errors.gender}
              name="gender"
              id="personalInfo.gender"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PersonalInformation;
