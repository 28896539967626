import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { GET_RETURNING_NON_RETURNING_USER } from '../../../graphql/schema/query/admin/merchant/GET_RETURNING_NON_RETURNING_USER';
import { useLazyQuery } from '@apollo/client';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
  plugins: {
    title: {
      display: true,
      text: 'Customers',
      align: 'start',
      color: '#000000',
      padding: {
        bottom: 50
      },
      font: {
        size: 16,
        family: 'Noto Sans',
        weight: '400'
      }
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        color: '#667085',
        pointStyle: 'circle',
        usePointStyle: true,
        boxHeight: 6,
        boxWidth: 8,
        padding: 8,
        font: {
          size: 14,
          family: 'Noto Sans',
          weight: '300'
        }
      }
    }
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false
      },
      // ticks: {
      //   // For a category axis, the val is the index so the lookup via getLabelForValue is needed
      //   callback: function (val, index) {
      //     // Hide every 2nd tick label
      //     return index % 2 === 0 ? this.getLabelForValue(val) : '';
      //   }
      // },
      color: '#667085'
    },
    y: {
      stacked: true,
      grid: {
        color: '#F2F4F7'
      }
    }
  }
};

const PerformanceBarChart = () => {
  const [doGetTopPerformingDeals, { loading, data: chartResponseData }] = useLazyQuery(
    GET_RETURNING_NON_RETURNING_USER,
    {
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    doGetTopPerformingDeals();
  }, []);

  const labels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const data = {
    labels,
    datasets: [
      {
        label: 'New',
        data: chartResponseData?.newVsReturningUserChart?.data?.newUsers ?? [],
        backgroundColor: '#0F0742',
        borderSkipped: false,
        barThickness: 16
      },
      {
        label: 'Returning',
        data: chartResponseData?.newVsReturningUserChart?.data?.returningUsers ?? [],
        backgroundColor: '#00C2FF',
        borderSkipped: false,
        barThickness: 16
      }
    ]
  };
  return (
    <div>
      {!loading && chartResponseData?.newVsReturningUserChart?.data && (
        <Bar options={options} data={data} />
      )}
    </div>
  );
};

export default PerformanceBarChart;
