import { gql } from '@apollo/client';

export const GET_NOTIFICATION = gql`
  query AdminNotification {
    adminNotification {
      data {
        _id
        completedSurvey {
          _id
          endDate
          startDate
          title
        }
        date
        isEnable
        message
        newMerchant {
          _id
          firstname
          lastname
          createdAt
          city
          business_data {
            businessName
            city
          }
        }
        notificationType
        read
        newSuggestedBusiness {
          businessName
        }
      }
    }
  }
`;
