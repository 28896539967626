import React from 'react';
import { Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Images from '../../../../constants/images';
import './styles.css';

const steps = [
  {
    key: 'create_account',
    title: 'Create an account',
    subTitle: 'Please provide your name and email',
    classname: 'step-one'
  },
  {
    key: 'business_details',
    title: 'Add business details',
    subTitle: 'Choose secure password',
    classname: 'step-two'
  },
  {
    key: 'terms',
    title: 'Terms & Condition',
    subTitle: 'Start collaborating with your team',
    classname: 'step-three'
  }
];

const OnboardingStepSideBar = ({ user }) => {
  const location = useLocation();
  return (
    <>
      <div className="onboarding-step-bar">
        <Image src={Images.logo_with_name} alt="centavizer-logo" className="logo" />
        <div className="step-list">
          {steps.map((step, index) => {
            let statusImg = Images.progress_incomplete;
            const statusCompletedImg = Images.progress_complete;
            const current = Images.current_step;
            const pathname = location?.pathname;
            switch (step?.key) {
              case 'create_account':
                if (user?._id) {
                  statusImg = statusCompletedImg;
                }
                if (pathname === '/onboarding') {
                  statusImg = current;
                }
                break;
              case 'business_details':
                if (user?.business_data?.businessName) {
                  statusImg = statusCompletedImg;
                }
                if (pathname === '/onboarding/business-search') {
                  statusImg = current;
                }
                break;
              case 'terms':
                if (user?.termsAndConditationStatus) {
                  statusImg = statusCompletedImg;
                }
                if (pathname === '/onboarding/terms-and-condition') {
                  statusImg = current;
                }
                break;
              default:
                break;
            }
            return (
              <div key={step.key} className={`mt-2 d-flex ${step.classname}`}>
                <div className={statusImg == statusCompletedImg ? 'completed' : null}>
                  <Image src={statusImg} alt="status-icon" className="" />
                  {index < steps.length - 1 ? <div className="step-connector" /> : null}
                </div>
                <div className="step-details">
                  <h5 className="text-white fonts-medium mb-0">{step.title}</h5>
                  <p>{step.subTitle}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default OnboardingStepSideBar;
