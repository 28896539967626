import React, { useState, useEffect, useContext, useRef } from 'react';
import { Row, Col, Image, Form } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import withAdmin from '../../../../components/hoc/admin/withAdmin';
import Images from '../../../../constants/images';
import TextInput from '../../../../components/common/text-input/TextInput';
import SelectInput from '../../../../components/common/select-input/SelectInput';
import AppButton from '../../../../components/common/button/Button';
import UploadModal from '../../../../components/common/upload-modal/UploadModal';
import { GET_MARKET_PLACE_CATEGORIES } from '../../../../graphql/schema/query/admin/marketplace/GET_MARKET_PLACE_CATEGORIES';

import '../styles.css';
import { baseFilePaths } from '../../../../constants';

import * as Yup from 'yup';
import { UPDATE_CATEGORY_DISCOUNT } from '../../../../graphql/schema/query/admin/marketplace/UPDATE_CATEGORY_DISCOUNT';
import ToastContext from '../../../../contexts/ToastContext';
import { UPDATE_CATEGORY_IMAGE } from '../../../../graphql/schema/query/admin/marketplace/UPDATE_CATEGORY_IMAGE';
import { GET_PRODUCTS_VARIANTS } from '../../../../graphql/schema/query/admin/marketplace/GET_PRODUCTS_VARIANTS';
import { SET_PRODUCT_FEATURED } from '../../../../graphql/schema/query/admin/marketplace/SET_PRODUCT_FEATURED';
import LoaderComponent from '../../../../components/common/loader/LoaderComponent';

export const stockEditSchema = Yup.object().shape({
  discount: Yup.number()
    .typeError('Please enter valid discount')
    .min(0.1, 'Please enter percentage more than 0')
    .max(100, 'Please enter percentage less than 100')
    .required('Please enter discount')
});

const MarketplaceStockCategory = () => {
  const imageProgressRef = useRef();

  const { categoryId } = useParams();

  const { setToastDetails } = useContext(ToastContext);
  const [search, setSearch] = useState();
  const [keyword, setKeyword] = useState();
  const [isFeatured, setIsFeatured] = useState();
  const [variantSorts, setVariantSorts] = useState({});

  const [doGetMarketPlaceCategory, { data, loading }] = useLazyQuery(GET_MARKET_PLACE_CATEGORIES, {
    fetchPolicy: 'cache-and-network'
  });

  const [doGetMarketPlaceCategoryProducts, { data: products, loading: productLoading }] =
    useLazyQuery(GET_PRODUCTS_VARIANTS, {
      fetchPolicy: 'network-only'
    });

  const [
    doUpdateCategoryDiscount,
    { data: updateDiscount, loading: updateDiscountLoading, error }
  ] = useMutation(UPDATE_CATEGORY_DISCOUNT);

  const [doSetFeatureProduct, { data: featureProduct, loading: featuringProduct }] = useLazyQuery(
    SET_PRODUCT_FEATURED,
    { fetchPolicy: 'network-only' }
  );

  const [
    doUpdateCategoryImage,
    { data: updateImage, loading: updateImageLoading, error: updateImageError }
  ] = useMutation(UPDATE_CATEGORY_IMAGE, {
    context: {
      fetchOptions: {
        useUpload: true,
        onProgress: (ev) => {
          imageProgressRef?.current(ev.loaded / ev.total);
        },
        onAbortPossible: (abortHandler) => {
          console.log({ abortHandler });
        }
      }
    }
  });

  // upload file modal
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState();
  const modalClose = () => {
    setShow({});
    setSelectedImage();
    imageProgressRef?.current(0);
  };
  const modalShow = (item) => setShow(item);

  useEffect(() => {
    doGetMarketPlaceCategory();
  }, []);

  const categoryDetails = data?.getMarketplaceCategory?.data?.find((d) => d?._id === categoryId);

  useEffect(() => {
    if (categoryDetails?.primaryCat) {
      doGetMarketPlaceCategoryProducts({
        variables: {
          limit: 12,
          offset: (page - 1) * 12,
          category: categoryDetails?.primaryCat,
          search: keyword,
          isFeatured
        }
      });
    }
  }, [categoryId, page, categoryDetails?.primaryCat, keyword, isFeatured]);

  let totalPageArray = [];

  const totalRecords = products?.getVariants?.total;
  if (totalRecords) {
    totalPageArray = Array(Math.ceil((totalRecords ?? 0) / 12)).fill(Math.random());
  }

  useEffect(() => {
    if (!updateDiscountLoading && error) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: 'Something went wrong!, Please try again after sometime',
        type: 'danger'
      });
    }
    if (
      !updateDiscountLoading &&
      updateDiscount?.updateCatDiscount?.message &&
      updateDiscount?.updateCatDiscount?.errorCode &&
      updateDiscount?.updateCatDiscount?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: updateDiscount?.updateCatDiscount?.message,
        type: 'danger'
      });
    } else {
      if (updateDiscount?.updateCatDiscount?.data?._id) {
        // update local state discount
        setToastDetails({
          show: true,
          title: 'Discount updated',
          message: 'Discount updated successfully.',
          type: 'success'
        });
      }
    }
  }, [
    updateDiscountLoading,
    error,
    updateDiscount?.updateCatDiscount?.errorCode,
    updateDiscount?.updateCatDiscount?.message,
    updateDiscount?.updateCatDiscount?.data?._id
  ]);

  useEffect(() => {
    if (
      !featuringProduct &&
      featureProduct?.featuredVariant?.message &&
      featureProduct?.featuredVariant?.errorCode &&
      featureProduct?.featuredVariant?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: featureProduct?.featuredVariant?.message,
        type: 'danger'
      });
    } else {
      if (featureProduct?.featuredVariant?.data?.[0]?._id) {
        // update local state discount
        setToastDetails({
          show: true,
          title: 'Product featured',
          message: `Product ${
            featureProduct?.featuredVariant?.data?.[0]?.featured ? 'added as' : 'removed from'
          } featured`,
          type: 'success'
        });
        doGetMarketPlaceCategoryProducts({
          variables: {
            limit: 12,
            offset: (page - 1) * 12,
            category: categoryDetails?.primaryCat,
            search: keyword,
            isFeatured
          }
        });
        setVariantSorts({});
      }
    }
  }, [
    featuringProduct,
    featureProduct?.featuredVariant?.errorCode,
    featureProduct?.featuredVariant?.message,
    featureProduct?.featuredVariant?.data?._id
  ]);

  useEffect(() => {
    if (!updateImageLoading && updateImageError) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: 'Something went wrong!, Please try again after sometime',
        type: 'danger'
      });
    }
    if (
      !updateImageLoading &&
      updateImage?.uploadCatImage?.message &&
      updateImage?.uploadCatImage?.errorCode &&
      updateImage?.uploadCatImage?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: updateImage?.uploadCatImage?.message,
        type: 'danger'
      });
    } else {
      if (updateImage?.uploadCatImage?.data?.catImage) {
        modalClose();
        doGetMarketPlaceCategory();
        setToastDetails({
          show: true,
          title: 'Image Updated',
          message: 'Category image updated successfully.',
          type: 'success'
        });
      }
    }
  }, [
    updateImageLoading,
    updateImageError,
    updateImage?.uploadCatImage?.errorCode,
    updateImage?.uploadCatImage?.message,
    updateImage?.uploadCatImage?.data?.catImage
  ]);

  const updateDiscountAPICall = (id, oldValue, newValue) => {
    if (newValue && String(oldValue) !== String(newValue)) {
      doUpdateCategoryDiscount({
        variables: {
          input: {
            discount: String(newValue),
            cat_id: id
          }
        }
      });
    }
  };

  const sortOptions = [
    {
      name: '1',
      _id: '1'
    },
    {
      name: '2',
      _id: '2'
    },
    {
      name: '3',
      _id: '3'
    },
    {
      name: '4',
      _id: '4'
    },
    {
      name: '5',
      _id: '5'
    }
  ];

  return (
    <div className="marketplace-stock-category-wrapper">
      {loading || updateDiscountLoading || productLoading || featuringProduct ? (
        <LoaderComponent />
      ) : null}
      <div className="top-header-container">
        <Row>
          <Col lg={12}>
            <div className="stock-category">
              <div className="image-area">
                <Image
                  className="stock-category-img"
                  src={
                    categoryDetails?.catImage
                      ? `${baseFilePaths.marketplace}${
                          categoryDetails?.catImage
                        }?cacheBuster=${Math.random()}`
                      : Images.deal_info_dummy_image
                  }></Image>
                <div className="edit-icon-wrapper">
                  <div onClick={() => modalShow(categoryDetails)}>
                    <Image className="edit-icon" src={Images.edit} />
                  </div>
                </div>
              </div>
              <div className="content-area">
                <div className="title">{categoryDetails?.primaryCat}</div>
                <div className="product-count">Total products: {totalRecords}</div>
                {categoryDetails?._id ? (
                  <Formik
                    className="discount-text-box"
                    validationSchema={stockEditSchema}
                    initialValues={{
                      discount: categoryDetails?.discount
                    }}
                    onSubmit={(values) => {
                      updateDiscountAPICall(
                        categoryDetails?._id,
                        categoryDetails?.discount,
                        values?.discount
                      );
                    }}>
                    {({ values, handleSubmit, handleChange, errors, touched }) => {
                      return (
                        <TextInput
                          name="discount"
                          type="text"
                          value={values.discount}
                          onChange={handleChange}
                          leftText="%"
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              handleSubmit();
                            }
                          }}
                          onBlur={() => {
                            handleSubmit();
                          }}
                          error={touched.discount && errors.discount}
                        />
                      );
                    }}
                  </Formik>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="product-container">
        <Row>
          <Col lg={12}>
            <div className="header-action">
              <div className="search-box">
                <TextInput
                  placeholder="Search stock"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value === '') {
                      setKeyword('');
                    }
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      setKeyword(search);
                    }
                  }}
                />
              </div>
              <div className="filter-option">
                <SelectInput
                  value={isFeatured}
                  onChange={(value) => {
                    setPage(1);
                    setIsFeatured(value);
                  }}
                  placeholder={'View all'}
                  options={[
                    {
                      name: 'View All',
                      _id: undefined
                    },
                    {
                      name: 'Featured',
                      _id: true
                    },
                    {
                      name: 'Not Featured',
                      _id: false
                    }
                  ]}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {!loading && products?.getVariants?.data?.length <= 0 ? (
            <p className="text-center mt-5 mb-5">No product variants found</p>
          ) : null}
          {products?.getVariants?.data?.map((product) => {
            return (
              <Col lg={4} md={6} key={product?._id} className="product-list-contant">
                <div className="product-list">
                  <div className="image-wrapper">
                    <Image
                      className="stock-category-img"
                      src={product?.images?.[0] ?? Images.deal_info_dummy_image}></Image>
                  </div>
                  <div className="product-detail">
                    <div className="top-contant">
                      <div className="product-name">{product?.title}</div>
                      <div className="amount-detail">
                        <div className="name">RRP:</div>
                        <div className="amount">${product?.price}</div>
                      </div>
                    </div>
                    <div className="featured-product">
                      <div className="feature-area">
                        <Form.Check
                          className="float-start toggle-switch status d-flex"
                          type="switch"
                          label={'Featured Product'}
                          name="featured_product"
                          checked={product?.featured}
                          onChange={(e) => {
                            if (variantSorts?.[product?._id] ?? product?.sort) {
                              doSetFeatureProduct({
                                variables: {
                                  featuredVariantId: product?._id,
                                  sort: variantSorts?.[product?._id] ?? product?.sort,
                                  isFeature: e.target.checked
                                },
                                onCompleted: () => {
                                  doGetMarketPlaceCategoryProducts({
                                    variables: {
                                      limit: 12,
                                      offset: (page - 1) * 12,
                                      category: categoryDetails?.primaryCat,
                                      search: keyword,
                                      isFeatured
                                    }
                                  });
                                }
                              });
                            } else {
                              setToastDetails({
                                type: 'info',
                                message: 'Please select sort for the item',
                                show: true
                              });
                            }
                          }}
                        />
                      </div>
                      <div className="select-area">
                        <SelectInput
                          value={variantSorts?.[product?._id] ?? product?.sort}
                          onChange={(value) => {
                            if (product?.featured) {
                              doSetFeatureProduct({
                                variables: {
                                  featuredVariantId: product?._id,
                                  sort: value,
                                  isFeature: true
                                }
                              });
                            }
                            setVariantSorts({
                              [product?._id]: value
                            });
                          }}
                          placeholder={'Select'}
                          options={sortOptions}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
        <Row className="mt-3 border-top">
          <div className="pagination-wrapper">
            <Pagination>
              <div className="left-content">
                <Pagination.Prev
                  className="previous-btn arrow-btn"
                  disabled={page === 1}
                  onClick={() => setPage(page - 1)}
                />
              </div>
              <div className="center-content">
                {totalPageArray?.map((_, index) => {
                  if (page !== 1 && page !== 2 && index === 0) {
                    return (
                      <>
                        <Pagination.Item
                          onClick={() => setPage(1)}
                          active={index + 1 === page}
                          key={index}>
                          {index + 1}
                        </Pagination.Item>
                        <Pagination.Ellipsis />
                      </>
                    );
                  }

                  if (index === page - 1 || index === page) {
                    return (
                      <Pagination.Item
                        onClick={() => setPage(index + 1)}
                        active={index + 1 === page}
                        key={index}>
                        {index + 1}
                      </Pagination.Item>
                    );
                  }
                  if (index === page + 1) {
                    return <Pagination.Ellipsis />;
                  }

                  if (
                    index === totalPageArray?.length - 1 ||
                    index === totalPageArray?.length - 2 ||
                    index === totalPageArray?.length - 3
                  ) {
                    return (
                      <Pagination.Item
                        onClick={() => setPage(index + 1)}
                        active={index + 1 === page}
                        key={index}>
                        {index + 1}
                      </Pagination.Item>
                    );
                  }
                  return null;
                })}
              </div>
              <div className="right-content">
                <Pagination.Next
                  className="next-btn arrow-btn"
                  disabled={page === totalPageArray?.length}
                  onClick={() => setPage(page + 1)}
                />
              </div>
            </Pagination>
          </div>
        </Row>
      </div>
      <UploadModal
        show={show?._id}
        onHide={() => modalClose()}
        title="Upload category image"
        subTitle="Upload display picture for this category."
        className="stock-upload-modal"
        onSelectImage={(file) => {
          setSelectedImage(file);
        }}
        selectedImage={selectedImage}
        setToastDetails={setToastDetails}
        onError={() => modalClose()}
        imageProgressRef={imageProgressRef}
        uploading={updateImageLoading}
        buttons={[
          <AppButton
            key="1"
            title={'Cancel'}
            id="upload-cancel-btn"
            buttonClass={'btn-cancel'}
            onClick={() => modalClose()}></AppButton>,
          <AppButton
            key="2"
            title={updateImageLoading ? 'Uploading...' : 'Save'}
            id="upload-attach-btn"
            onClick={() => {
              if (selectedImage && selectedImage?.length > 0) {
                doUpdateCategoryImage({
                  variables: {
                    input: {
                      file: selectedImage?.[0],
                      cat_id: show?._id
                    }
                  }
                });
                return;
              }
              modalClose();
              setToastDetails({
                show: true,
                title: 'Error!',
                message: 'Please select image',
                type: 'danger'
              });
            }}></AppButton>
        ]}></UploadModal>
    </div>
  );
};

export default withAdmin(MarketplaceStockCategory);
