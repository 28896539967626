import { gql } from '@apollo/client';

export const UPDATE_BUSINESS_STATUS = gql`
  query UpdateBusinessStatus {
    updateBusinessStatus {
      status
      message
      businessDetail {
        _id
      }
      errorCode
    }
  }
`;
