import { gql } from '@apollo/client';

export const FEATURE_DEAL = gql`
  query FeatureDeal($dealId: ID) {
    featureDeal(deal_id: $dealId) {
      status
      message
      data {
        _id
        featured
      }
      errorCode
    }
  }
`;
