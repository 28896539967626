import { gql } from '@apollo/client';

export const DELETE_MERCHANT = gql`
  query DeleteMerchant($deleteMerchantId: ID) {
    deleteMerchant(id: $deleteMerchantId) {
      status
      message
      errorCode
    }
  }
`;
