import { gql } from '@apollo/client';

export const GET_ADMIN_USER = gql`
  query Query {
    me {
      user {
        _id
        firstname
        lastname
        dob
        email
        phoneno
        profilepicture
      }
      status
      message
      errorCode
    }
  }
`;
