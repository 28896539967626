import { gql } from '@apollo/client';

export const REFUND_TRANSACTION = gql`
  query RefundTransaction($id: ID, $sku: [String]) {
    refundTransaction(_id: $id, sku: $sku) {
      status
      message
      data {
        _id
      }
      errorCode
    }
  }
`;
