import React from 'react';
import { Image } from 'react-bootstrap';
import Images from '../../../../constants/images';
import './styles.css';

const SignInSidebarInfo = ({ isAdmin }) => {
  return (
    <>
      <div className="signin-sidebar-info">
        <Image src={Images.logo_with_name} alt="centavizer-logo" className="logo" />
        <div className="information">
          <div className="line-first fonts-quicksand-semibold">Be active.</div>
          <div className="line-second fonts-quicksand-semibold">Be rewarded.</div>
          <div className="sub-content">
            {isAdmin
              ? `Motivate your patrons to live a healthy lifestyle and gain more loyal customers daily as a Centavizer partner.`
              : `Feel motivated to live a healthier lifestyle and feel the benefit from being rewarded
            with actual cash value.`}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInSidebarInfo;
