import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppButton from '../../../components/common/button/Button';
import TextInput from '../../../components/common/text-input/TextInput';
import ContentCardWrapper from '../../../components/merchant/onboarding/content-card-wrapper/ContentCardWrapper';
import Images from '../../../constants/images';
import ToastContext from '../../../contexts/ToastContext';
import { MERCHANT_SIGNIN } from '../../../graphql/schema/mutations/onboarding/MERCHANT_SINGIN';
import { setLocalStorageItem } from '../../../services/localStorageService';
import { SignInInitValues, SignInSchema } from './formUtils';
import SignInSidebarInfo from '../../../components/merchant/onboarding/step-indicator/SignInSidebarInfo';
import CommonFooter from '../../../pages/onboarding/footer/CommonFooter';
import { UserContext } from '../../../contexts';
import { SUPER_ADMIN_SIGN_IN } from '../../../graphql/schema/mutations/admin/ADMIN_SIGN_IN';
import { APP_ROLES } from '../../../constants';

const SignIn = () => {
  const navigate = useNavigate();
  const [doSignIn, { data, loading, error }] = useMutation(MERCHANT_SIGNIN);
  const [doAdminSignIn, { data: adminData, loading: adminLoading, error: adminError }] =
    useMutation(SUPER_ADMIN_SIGN_IN);

  const { details: userDetails, setUserDetails } = useContext(UserContext);

  const { setToastDetails } = useContext(ToastContext);
  const path = location.pathname;
  const isAdminPage = path === '/admin/login';

  const onSubmit = (values) => {
    if (isAdminPage) {
      doAdminSignIn({
        variables: {
          input: {
            email: values.email,
            password: values.password
          }
        }
      });
      return;
    }
    doSignIn({
      variables: {
        input: {
          email: values.email,
          password: values.password
        }
      }
    });
  };

  useEffect(() => {
    if (!loading && data?.signInByMerchant?.jwttoken) {
      setLocalStorageItem('token', data?.signInByMerchant?.jwttoken);
      setUserDetails();
    }
    if (error?.graphQLErrors?.[0]?.extensions?.code !== 'UNAUTHENTICATED') {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: error?.message,
        type: 'danger'
      });
      return;
    }
    if (
      !loading &&
      data?.signInByMerchant?.message &&
      data?.signInByMerchant?.errorCode &&
      data?.signInByMerchant?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: data?.signInByMerchant?.message,
        type: 'danger'
      });
    }
  }, [loading, error, data?.signInByMerchant?.jwttoken, data?.signInByMerchant?.errorCode]);

  useEffect(() => {
    if (!adminLoading && adminData?.signInBySuperAdmin?.jwttoken) {
      setLocalStorageItem('token', adminData?.signInBySuperAdmin?.jwttoken);
      setUserDetails();
      navigate('/admin/user-management', { replace: true });
    }

    if (adminError) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: adminError?.message,
        type: 'danger'
      });
      return;
    }
    if (
      !loading &&
      adminData?.signInBySuperAdmin?.message &&
      adminData?.signInBySuperAdmin?.errorCode &&
      adminData?.signInBySuperAdmin?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: adminData?.signInBySuperAdmin?.message,
        type: 'danger'
      });
    }
  }, [
    adminLoading,
    adminError,
    adminData?.signInBySuperAdmin?.jwttoken,
    adminData?.signInBySuperAdmin?.errorCode
  ]);

  useEffect(() => {
    if (
      userDetails?.me?.merchant?._id &&
      !isAdminPage &&
      userDetails?.me?.merchant?.userType === APP_ROLES.MERCHANT
    ) {
      if (userDetails?.me?.merchant?.termsAndConditationStatus) {
        navigate?.('/merchant/dashboard', { replace: true });
        return;
      }
      if (userDetails?.me?.merchant?.business_data?.businessName) {
        navigate?.('/onboarding/terms-and-condition', { replace: true });
        return;
      }
      if (userDetails?.me?.merchant?.email) {
        navigate?.('/onboarding/business-search');
        return;
      }
      navigate('/onboarding', { replace: true });
    }

    if (isAdminPage && userDetails?.me?.merchant?.userType === 'superadmin') {
      navigate('/admin/user-management', { replace: true });
    }
  }, [userDetails?.me?.errorCode, userDetails?.me?.merchant?._id]);

  return (
    <div className="merchant-onboarding-container main-wrapper-container">
      <div className="main-content-area">
        <div className="left-side-container">
          <SignInSidebarInfo isAdmin={isAdminPage} />
          <div className="p-0">
            <CommonFooter />
          </div>
        </div>
        <div className="merchant-onboarding-content right-side-container">
          <div className="main-box signin-main">
            <div className="content-box-wrapper scrollbar">
              <ContentCardWrapper
                title={'Sign in to your account'}
                subTitle={'Sign in with your registered email address and password.'}>
                <Formik
                  validationSchema={SignInSchema}
                  initialValues={SignInInitValues}
                  onSubmit={onSubmit}>
                  {({ values, errors, touched, handleChange, handleSubmit }) => {
                    return (
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit();
                        }}>
                        <TextInput
                          label="Email address"
                          placeholder="Email address"
                          value={values.email}
                          error={touched.email && errors.email}
                          onChange={handleChange}
                          name="email"
                          id="signin.email"
                        />
                        <TextInput
                          label="Password"
                          placeholder="Enter password"
                          value={values.password}
                          error={touched.password && errors.password}
                          onChange={handleChange}
                          name="password"
                          type="password"
                          rightIcon={Images.eye_cross}
                          id="signin.password"
                        />
                        <div className="mt-2 text-end fonts-medium font-14">
                          <Link
                            to={`/forgot-password${isAdminPage ? '?return=/admin/login' : ''}`}
                            className="link-color">
                            Forgot password?
                          </Link>
                        </div>

                        <div className="mt-4">
                          <AppButton
                            type="submit"
                            title={'Sign in'}
                            onClick={handleSubmit}
                            disabled={loading}
                            id="signin.continue"
                          />
                        </div>
                        {!isAdminPage ? (
                          <div className="mt-4 text-center fonts-medium font-14">
                            Don’t have an account?{' '}
                            <Link to="/onboarding" className="link-color">
                              Sign Up
                            </Link>
                          </div>
                        ) : null}
                      </Form>
                    );
                  }}
                </Formik>
              </ContentCardWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
