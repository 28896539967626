import React, { useEffect, useMemo, useState } from 'react';
import { Image } from 'react-bootstrap';
import withAdmin from '../../../components/hoc/admin/withAdmin';
import DataTable from 'react-data-table-component';
import Images from '../../../constants/images';
import TextInput from '../../../components/common/text-input/TextInput';
import './styles.css';
import { useLazyQuery } from '@apollo/client';
import { GET_SUGGESTED_BUSINESS } from '../../../graphql/schema/query/admin/merchant/GET_SUGGESTED_BUSINESS';
import { formatPhoneNo } from '../../../services';
import { baseFilePaths } from '../../../constants';

const SuggestedBusinessList = () => {
  const [searchData, setSearchData] = useState('');
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sorting, setSorting] = useState({
    name: null,
    businessName: null,
    zipcode: null,
    email: null
  });

  const [doGetMerchants, { data: suggestedBusiness, loading }] = useLazyQuery(
    GET_SUGGESTED_BUSINESS,
    {
      fetchPolicy: 'network-only'
    }
  );

  const getList = () => {
    if (page <= 0) {
      return;
    }
    doGetMerchants({
      variables: {
        offset: (page - 1) * perPage,
        limit: perPage,
        search: keyword,
        sorting: {
          ...sorting,
          id: undefined
        }
      }
    });
  };

  useEffect(() => {
    getList();
  }, [
    page,
    perPage,
    keyword,
    sorting?.name,
    sorting?.businessName,
    sorting?.zipcode,
    sorting?.email
  ]);

  const columns = [
    {
      name: 'User Name',
      selector1: (row) => row.user_name,
      selector2: (row) => row.profile,
      sortable: true,
      cell: (row) => (
        <div className="user-detail">
          <img height="40px" width="40px" src={row.profile} alt={row.user_name} />
          <div className="name">{row.user_name}</div>
        </div>
      )
    },
    {
      name: 'Business Name',
      selector: (row) => row.business_name ?? '-',
      sortable: true
    },
    {
      name: 'Zipcode',
      selector: (row) => row.zipcode ?? '-',
      sortable: true
    },
    {
      name: 'Email Address',
      selector: (row) => row.email ?? '-',
      sortable: true
    },
    {
      name: 'Phone',
      selector: (row) => formatPhoneNo(row.phone),
      sortable: false
    }
  ];

  const businessList = useMemo(() => {
    return suggestedBusiness?.getSuggestBusiness?.data?.map((u) => {
      return {
        id: u?.user_id?._id,
        profile: u?.user_id?.profilepicture
          ? `${baseFilePaths.userProfileImage}${u?.user_id?.profilepicture}`
          : '/assets/images/default_avatar.png',
        user_name: `${u?.user_id?.firstname || ''} ${u?.user_id?.lastname || ''}`,
        business_name: u?.businessName,
        zipcode: u?.zipcode || '-',
        email: u?.email || '-',
        phone: u?.phoenNo
      };
    });
  }, [suggestedBusiness?.getSuggestBusiness?.data]);

  return (
    <>
      <div className="suggested-business-list-wrapper">
        <div className="suggested-business-list-header">
          <div className="header-title">
            <div className="image">
              <Image src={Images.suggested_business_icon} />
            </div>
            <div className="text">
              <div>All suggested business</div>
            </div>
          </div>
          <div className="header-action">
            <div className="search-box">
              <TextInput
                placeholder="Enter a keyword to search"
                value={searchData}
                onChange={(e) => {
                  setSearchData(e.target.value);
                  if (e.target.value === '') {
                    setKeyword('');
                  }
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter' && searchData?.trim()?.length > 0) {
                    setKeyword(searchData);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <DataTable
          defaultSortAsc={false}
          defaultSortFieldId={sorting?.id}
          columns={columns}
          data={businessList}
          pagination={true}
          fixedHeader={true}
          responsive={true}
          loading={loading}
          onChangeRowsPerPage={(newPerPage) => {
            setPerPage(newPerPage);
          }}
          onChangePage={(page) => {
            setPage(page);
          }}
          paginationTotalRows={suggestedBusiness?.getSuggestBusiness?.total}
          paginationServer
          progressPending={loading}
          paginationPerPage={perPage}
          conditionalRowStyles={[]}
          sortServer
          onSort={(sortData, sortDirection) => {
            const nameToKeyMap = {
              'User Name': 'name',
              'Business Name': 'businessName',
              Zipcode: 'zipcode',
              'Email Address': 'email'
            };
            const key = [nameToKeyMap?.[sortData.name]];
            setSorting({
              id: sortData?.id,
              name: null,
              businessName: null,
              zipcode: null,
              email: null,
              [key]: sortDirection === 'asc'
            });
          }}
        />
      </div>
    </>
  );
};

export default withAdmin(SuggestedBusinessList);
