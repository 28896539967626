import { gql } from '@apollo/client';

export const UPLOAD_BUSINESS_BANNER = gql`
  mutation UploadBannerImageAndVideo($file: [Upload]!) {
    uploadBannerImageAndVideo(file: $file) {
      status
      message
      business {
        business_data {
          bannerImages
        }
      }
      errorCode
    }
  }
`;
