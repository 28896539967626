import React from 'react';
import { Form, FormCheck, Row } from 'react-bootstrap';
// import moment from 'moment';
import TextInput from '../../common/text-input/TextInput';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const MerchantFormOpeningHoursDetails = ({
  setFieldValue,
  handleChange,
  values,
  touched,
  errors,
  timings
}) => {
  return (
    <div className="wrapper-padding">
      <p className="form-title mb-0 fonts-quicksand-medium">Opening hours</p>
      <span className="form-subtitle fonts-regular">Business Opening Hours</span>
      <Row className="opening-hours">
        <div className="d-flex title-block">
          <div className="wc-20"></div>
          <div className="title-name wc-35 me-3">Opens at</div>
          <div className="title-name wc-35">Closes at</div>
          <div className="title-name text-center wc-10">24 Hours</div>
        </div>
        {days.map((day, index) => {
          return (
            <div key={index} className="d-flex opening-hours-content">
              <div className="wc-20 mt-2">
                <span className="day-name">{day}</span>
                <Form.Check
                  className="float-start me-3 toggle-switch status"
                  checked={values?.timings?.[index]?.status}
                  type="switch"
                  label={''}
                  id={day}
                  onChange={(e) => {
                    setFieldValue(`timings[${index}][status]`, e.target.checked);
                    if (!e.target.checked) {
                      setFieldValue(`timings[${index}][isOpenDay]`, false);
                      setFieldValue(`timings[${index}][closingTime]`, ' ');
                      setFieldValue(`timings[${index}][openingTime]`, ' ');
                    } else {
                      setFieldValue(`timings[${index}][closingTime]`, '');
                      setFieldValue(`timings[${index}][openingTime]`, '');
                    }
                  }}
                  name={`timings[${index}][status]`}
                />
              </div>
              {values?.timings?.[index]?.status ? (
                <>
                  <div className="wc-35 me-3">
                    <TextInput
                      readOnly={!values?.timings?.[index]?.status}
                      disabled={!values?.timings?.[index]?.status}
                      label=""
                      placeholder="Add Opening time"
                      type="time"
                      value={values?.timings?.[index]?.openingTime ?? timings?.[index]?.openingTime}
                      name={`timings[${index}][openingTime]`}
                      error={
                        touched.timings?.[index]?.openingTime &&
                        errors.timings?.[index]?.openingTime
                      }
                      onChange={handleChange}
                      id={`timings[${index}][openingTime]`}
                      className="openingTime"
                    />
                  </div>
                  <div className="wc-35">
                    <TextInput
                      readOnly={!values?.timings?.[index]?.status}
                      disabled={!values?.timings?.[index]?.status}
                      label=""
                      placeholder="Add Closing time"
                      type="time"
                      value={values?.timings?.[index]?.closingTime ?? timings?.[index]?.closingTime}
                      name={`timings[${index}]['closingTime']`}
                      onChange={(e) => {
                        setFieldValue(`timings[${index}][closingTime]`, e.target.value);
                      }}
                      error={
                        touched.timings?.[index]?.closingTime &&
                        errors.timings?.[index]?.closingTime
                      }
                      id={`timings[${index}]['closingTime']`}
                      className="closingTime"
                    />
                  </div>
                </>
              ) : (
                <div className="w-71 close-input">
                  <TextInput
                    readOnly={!values?.timings?.[index]?.status}
                    disabled={!values?.timings?.[index]?.status}
                    label=""
                    placeholder="Closed"
                    type="test"
                    id={`timings[${index}]['closingTime']`}
                    className="closingTime"
                  />
                </div>
              )}

              <div className="text-center tick-mark wc-10 mt-2">
                <FormCheck
                  disabled={!values?.timings?.[index]?.status}
                  checked={values?.timings?.[index]?.isOpenDay ?? timings?.[index]?.isOpenDay}
                  name={`timings[${index}].isOpenDay`}
                  onChange={(e) => {
                    setFieldValue(`timings[${index}].isOpenDay`, e.target.checked);
                    if (e.target.checked) {
                      if (
                        String(values?.timings?.[index]?.openingTime).trim() === '' ||
                        String(values?.timings?.[index]?.openingTime).trim() === '00:00'
                      ) {
                        setFieldValue(`timings[${index}][openingTime]`, '00:00');
                        setFieldValue(`timings[${index}][closingTime]`, '23:59');
                        return;
                      }
                      setFieldValue(
                        `timings[${index}][openingTime]`,
                        values?.timings?.[index]?.openingTime
                      );
                      setFieldValue(
                        `timings[${index}][closingTime]`,
                        values?.timings?.[index]?.openingTime
                      );
                    }
                  }}
                  id={`timings[${index}].isOpenDay`}
                  className="isOpenDay"
                />
              </div>
            </div>
          );
        })}
      </Row>
    </div>
  );
};

export default MerchantFormOpeningHoursDetails;
