import { gql } from '@apollo/client';

export const UPDATE_MERCHANT_CATEGORY_IMAGE = gql`
  mutation UploadImageMerchatnCategory($input: uploadCatImage!) {
    uploadImageMerchatnCategory(input: $input) {
      status
      message
      errorCode
      data {
        _id
        name
        catImage
      }
    }
  }
`;
