import React, { useContext, useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import TextInput from '../../common/text-input/TextInput';
import Images from '../../../constants/images';
import { Link } from 'react-router-dom';
import UploadModal from '../../common/upload-modal/UploadModal';
import AppButton from '../../common/button/Button';
import ToastContext from '../../../contexts/ToastContext';

const SurveyContent = ({
  values,
  localImagesPreview,
  setOptions,
  handleChange,
  errors,
  touched,
  setFieldValue,
  doDeleteOption,
  isActive
}) => {
  const { setToastDetails } = useContext(ToastContext);
  // upload file modal
  const [show, setShow] = useState(false);
  const modalClose = () => setShow(false);
  const modalShow = (index) => setShow(index);

  return (
    <>
      <div className="wrapper-padding">
        <p className="form-title mb-0 fonts-quicksand-medium">Survey Content</p>
        <span className="form-subtitle fonts-regular">
          This is the information shown to the user
        </span>
        <Row className="mt-2">
          <Col md={12}>
            <TextInput
              readOnly={isActive}
              label="Question"
              placeholder="Add your survey question"
              value={values.question}
              onChange={handleChange}
              name="question"
              error={touched.question && errors.question}
            />
          </Col>
        </Row>
        <Row>
          {values?.options?.map((option, index) => {
            return (
              <div className="combine-option" key={index}>
                <div className="textbox-option-area">
                  <TextInput
                    readOnly={isActive}
                    label="Options"
                    placeholder={`Option ${index + 1}`}
                    value={option?.option}
                    name={`options[${index}][option]`}
                    error={touched.options?.[index]?.option && errors.options?.[index]?.option}
                    onChange={handleChange}
                  />
                </div>
                <div className="add-logo-part">
                  {!isActive && (
                    <span
                      onClick={() => {
                        if (isActive) {
                          return;
                        }
                        modalShow(index);
                      }}
                      className="add-logo-div">
                      <Image src={Images.close} />
                      {localImagesPreview?.[index] || option?.savedLogo
                        ? 'Change Logo'
                        : 'Add a logo'}
                    </span>
                  )}

                  {localImagesPreview?.[index] ? (
                    <span className="display-logo-div">
                      <Image className="w-100" src={localImagesPreview?.[index]} />
                    </span>
                  ) : option?.savedLogo ? (
                    <span className="display-logo-div">
                      <Image className="w-100" src={option?.savedLogo} />
                    </span>
                  ) : null}
                  {!isActive && (
                    <Link
                      to="#javascript:void(0)"
                      className="close-btn"
                      onClick={() => {
                        if (isActive) {
                          return;
                        }
                        values?.options.splice(index, 1);
                        setOptions(values?.options);

                        if (option?._id) {
                          doDeleteOption?.(option?._id);
                        }
                      }}>
                      <Image src={Images.close_light} />
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
          {!isActive && (
            <div
              style={{ width: 180 }}
              className="add-another-option"
              onClick={() => {
                values?.options.push({
                  option: '',
                  logo: null
                });
                setFieldValue('options', values?.options);
              }}>
              <span>
                <Image src={Images.close} />
                Add another option
              </span>
            </div>
          )}
        </Row>
      </div>
      <UploadModal
        show={show !== false}
        onHide={modalClose}
        title="Upload and attach files"
        subTitle="Upload and attach files to this project."
        className="stock-upload-modal"
        onSelectImage={(image) => {
          if (image?.length <= 0) {
            return;
          }
          const options = values?.options;
          options[show].logo = image?.[0];
          setOptions([...options]);
          setShow(false);
        }}
        setToastDetails={setToastDetails}
        onError={() => {
          setShow(false);
        }}
        buttons={[
          <AppButton
            key="1"
            title={'Cancel'}
            id="upload-cancel-btn"
            onClick={modalClose}></AppButton>,
          <AppButton key="2" title={'Attach files'} id="upload-attach-btn"></AppButton>
        ]}></UploadModal>
    </>
  );
};

export default SurveyContent;
