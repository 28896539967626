import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TextInput from '../../common/text-input/TextInput';
import moment from 'moment';

const OtherInformation = ({ values }) => {
  console.log({ values });
  let loginType = 'Normal';

  if (values?.isSocial) {
    if (values?.appleRefID) {
      loginType = 'Apple';
    }
    if (values?.googleRefID) {
      loginType = 'Google';
    }
    if (values?.facebookRefID) {
      loginType = 'Facebook';
    }
  }

  return (
    <>
      <div className="wrapper-padding">
        <p className="form-title mb-0 fonts-quicksand-medium">Other information</p>
        <span className="form-subtitle fonts-regular">User&apos;s Other Details</span>
        <Row className="mt-2">
          <Col md={6}>
            <TextInput
              readOnly
              disabled
              label="Login type"
              placeholder="Login type"
              name="login-type"
              id="otherInfo.fname"
              value={loginType}
            />
          </Col>
          <Col md={6}>
            <TextInput
              readOnly
              disabled
              label="Last active"
              placeholder="Last active"
              name="last-active"
              id="otherInfo.last-active"
              value={
                values?.updatedAt ? moment(values?.updatedAt).format('MM-DD-YYYY hh:mm A') : null
              }
            />
          </Col>
          <Col md={6}>
            <TextInput
              readOnly
              disabled
              label="Device type"
              placeholder="Device type"
              name="device-type"
              id="userLocation.devicetype"
              value={values?.deviceType ?? 'N/A'}
            />
          </Col>
          <Col md={6}>
            <TextInput
              readOnly
              disabled
              label="Tracker"
              placeholder="Tracker"
              name="tracker"
              id="otherInfo.tracker"
              value={values?.trackerType ?? 'N/A'}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OtherInformation;
