import { gql } from '@apollo/client';

export const READ_NOTIFICATION = gql`
  mutation ReadNotifaction($input: notificationID!) {
    readNotifaction(input: $input) {
      status
      message
      data {
        _id
      }
      errorCode
    }
  }
`;
