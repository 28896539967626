import { gql } from '@apollo/client';

export const GET_TX_HISTORY_NOTIFICATION_UPDATE = gql`
  query MerchantTranNotification($fullfill: Number!, $tranId: ID) {
    merchantTranNotification(fullfill: $fullfill, tran_id: $tranId) {
      status
      message
      errorCode
    }
  }
`;
