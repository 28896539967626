import React, { useContext } from 'react';
import UserContext from '../../../contexts/user/UserContext';
import PageHeader from '../../common/page-header/PageHeader';
import AdminSideMenu from '../../merchant/side-bar-menu/AdminSideMenu';

import './styles.css';

// eslint-disable-next-line react/display-name
const withAdmin = (Component) => (props) => {
  const { details, loading } = useContext(UserContext);
  if (loading) {
    return 'Loading...';
  }
  return (
    <>
      <div fluid className="merchant-admin-container">
        <div className="main-dashboard-content-area">
          <div className="sidebar-menu dashboard-left-side admin-sidebar-menu">
            <AdminSideMenu user={details?.me?.merchant} />
          </div>
          <div className="merchant-admin-content dashboard-right-side">
            <PageHeader />
            <Component {...props} />
          </div>
        </div>
      </div>
    </>
  );
};

export default withAdmin;
