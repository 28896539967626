import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Card, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { baseFilePaths } from '../../../constants';
import Images from '../../../constants/images';
import { GET_DEALS } from '../../../graphql/schema/query/deals/GET_DEALS';

const icons = {
  flat: Images.dollar,
  percent: Images.percentage
};

const ActiveDealCard = () => {
  const [getDeals, { data, loading }] = useLazyQuery(GET_DEALS, {
    fetchPolicy: 'network-only',
    variables: {
      limit: 1000,
      offset: 0,
      status: 'active'
    }
  });
  const recordsAre = Array.isArray(data?.getDeals?.deals) ? data?.getDeals?.deals : [];
  const total = data?.getDeals?.total;
  useEffect(() => {
    getDeals();
  }, []);

  return (
    <Card className="active-deal-card">
      <Card.Header>
        <div className="box-header">
          <Image src={Images.active_deal_box_icon} />
          <div className="title">
            Active Deals
            {total > 0 ? <span className="active-deal-counter">{total}</span> : null}
          </div>
        </div>
        <div className="text-end">
          <Link to="/merchant/deals" className="view-all-link">
            <Image src={Images.view_all} />
            View all
          </Link>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="active-deal-content text-center">
          {/* when no data is available */}
          {!loading && recordsAre?.length === 0 ? (
            <div className="no-data">
              <Image src={Images.no_data_active_deal} />
              <div className="no-data-content">
                <h5>No Deals</h5>
                <span>You’ll receive deals here</span>
                <div className="text-center mt-4">
                  <Link
                    to="/merchant/deals/create"
                    className="app-button btn btn-primary create-new-btn">
                    <Image src={Images.tag} />
                    Create a new deal
                  </Link>
                </div>
              </div>
            </div>
          ) : null}

          {loading ? (
            <div className="d-flex justify-content-center">
              <span className="sm-loader"></span>
            </div>
          ) : null}

          {/* when data is available */}
          <div className="available-deal-content">
            {recordsAre?.map((record) => {
              return (
                <div className="active-deal-box" key={record?._id}>
                  <div className="image-box">
                    <Image
                      src={
                        record?.dealImages?.[0]
                          ? `${baseFilePaths?.dealImage}${record?.dealImages?.[0]}`
                          : Images.deal_1
                      }
                      className="deal-image"
                    />
                    <Image src={icons?.[record?.discountType]} className="type-image" />
                  </div>
                  <div
                    className={`content-box ${
                      record?.discountType === 'flat' ? 'orange' : 'green'
                    } `}>
                    <div className="deal-name fonts-quicksand-bold">{record?.name}</div>
                    <div className="deal-basic-info">
                      {record?.decription?.length > 80
                        ? record?.decription?.slice(0, 80) + '...'
                        : record?.decription}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Card.Body>
      <style jsx="true" global="true">
        {`
          .active-deal-counter {
            background: #52ff9a;
            padding: 4px 14px;
            border-radius: 42%;
            height: 8px;
            width: 8px;
            margin-left: 6px;
          }
        `}
      </style>
    </Card>
  );
};

export default ActiveDealCard;
