import { gql } from '@apollo/client';

export const GET_SURVEY_LIST = gql`
  query GetSurveyForAdmin {
    getSurveyForAdmin {
      status
      message
      data {
        _id
        title
        startDate
        endDate
        question
        responses
        option {
          _id
          user_id {
            _id
            email
            firstname
            lastname
            profilepicture
          }
          value
          logoUrl
        }
      }
      errorCode
    }
  }
`;
