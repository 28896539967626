import React from 'react';
import { Form, Image } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AppButton from '../../../components/common/button/Button';
import ContentCardWrapper from '../../../components/merchant/onboarding/content-card-wrapper/ContentCardWrapper';
import SignInSidebarInfo from '../../../components/merchant/onboarding/step-indicator/SignInSidebarInfo';
import Images from '../../../constants/images';
import CommonFooter from '../../../pages/onboarding/footer/CommonFooter';

const ResetPassword = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();
  return (
    <div className="merchant-onboarding-container main-wrapper-container">
      <div className="main-content-area">
        <div className="left-side-container">
          <SignInSidebarInfo />
          <div className="p-0">
            <CommonFooter />
          </div>
        </div>
        <div className="right-side-container merchant-onboarding-content">
          <div className="main-box signin">
            <div className="content-box-wrapper scrollbar">
              <ContentCardWrapper
                title={'Password reset'}
                subTitle={
                  'Your new password has been successfully updated. Click below to continue.'
                }>
                <Form>
                  <div className="mt-4">
                    <AppButton
                      title={'Continue'}
                      onClick={() => navigate(query.get('return') || '/login', { replace: true })}
                      id="passwordReset.continue"
                    />
                  </div>
                  <div className="text-center fonts-medium back-link">
                    <Link to={`${query.get('return') || '/login'}`}>
                      <Image src={Images.right_arrow} alt=""></Image> Back to login
                    </Link>
                  </div>
                </Form>
              </ContentCardWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
