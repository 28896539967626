import { gql } from '@apollo/client';

export const FREEZE_MERCHANT = gql`
  query FreezeMerchant($freezeMerchantId: ID) {
    freezeMerchant(id: $freezeMerchantId) {
      status
      message
      user {
        isActive
        _id
      }
      total
      limit
      offset
      errorCode
    }
  }
`;
