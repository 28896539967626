import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import withAdmin from '../../../components/hoc/admin/withAdmin';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Images from '../../../constants/images';
import AppButton from '../../../components/common/button/Button';
import FeedbackModal from '../../../components/common/feedback-modal/FeedbackModal';
import './styles.css';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import { DELETE_SURVEY } from '../../../graphql/schema/query/admin/survey/DELETE_SURVEY';
import ToastContext from '../../../contexts/ToastContext';
import { GET_SURVEY_LIST } from '../../../graphql/schema/query/admin/survey/GET_SURVEY_LIST';
import LoaderComponent from '../../../components/common/loader/LoaderComponent';

const COLORS = ['#52FF9A', '#0F0742', '#FFFFFF', '#FF8F50', '#00C2FF'];
ChartJS.register(ArcElement, Tooltip, Legend);

const plugin1 = [
  {
    // beforeDraw: function (chart) {
    //   const { datasets } = chart.data;
    //   let total = 0;
    //   datasets?.[0]?.data?.forEach((d) => {
    //     total = total + d;
    //   });
    //   var width = chart.width;
    //   const height = chart.height;
    //   const ctx = chart.ctx;
    //   ctx.restore();
    //   var fontSize = (height / 114).toFixed(2);
    //   ctx.font = fontSize + 'em Noto Sans';
    //   ctx.textBaseline = 'middle';
    //   var text = `${total}`;
    //   const textX = Math.round((width - ctx.measureText(text).width) / 2.8);
    //   const textY = height / 2;
    //   ctx.fillText(text, textX, textY);
    //   ctx.save();
    // }
  }
];

const plugin2 = [
  {
    id: 'emptyDoughnut',
    afterDraw(chart, args, options) {
      const { datasets } = chart.data;
      const { color, width, radiusDecrease } = options;
      let hasData = false;

      for (let i = 0; i < datasets.length; i += 1) {
        const dataset = datasets[i];
        hasData |= dataset.data.length > 0;
      }

      if (!hasData) {
        const {
          chartArea: { left, top, right, bottom },
          ctx
        } = chart;
        const centerX = (left + right) / 2;
        const centerY = (top + bottom) / 2;
        const r = Math.min(right - left, bottom - top) / 2;

        ctx.beginPath();
        ctx.lineWidth = width || 2;
        ctx.strokeStyle = color || 'rgba(255, 128, 0, 0.5)';
        ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
        ctx.stroke();
      }
    }
  }
];

const MerchantSurvey = () => {
  const options1 = {
    maintainAspectRatio: false,
    cutout: 44,
    borderWidth: 0,
    responsive: true,

    plugins: {
      tooltip: {
        displayColors: false,
        yAlign: 'bottom',
        padding: 8,
        caretPadding: 8,
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem?.formattedValue ?? '-';
          },
          backgroundColor: '#101828',
          titleFont: {
            family: 'Noto Sans',
            size: '10px'
          }
        }
      },
      legend: {
        display: true,
        position: 'right',
        labels: {
          color: '#667085',
          pointStyle: 'circle',
          usePointStyle: true,
          boxHeight: 6,
          boxWidth: 8,
          padding: 8,
          font: {
            size: 14,
            family: 'Noto Sans',
            weight: '300'
          }
        }
      }
    }
  };
  const options2 = {
    maintainAspectRatio: false,
    cutout: 44,
    borderWidth: 0,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          color: '#667085',
          pointStyle: 'circle',
          usePointStyle: true,
          boxHeight: 6,
          boxWidth: 8,
          padding: 8,
          font: {
            size: 14,
            family: 'Noto Sans',
            weight: '300'
          }
        }
      },
      emptyDoughnut: {
        color: '#E4E7EC',
        width: 16,
        radiusDecrease: 8
      }
    }
  };

  const { setToastDetails } = useContext(ToastContext);

  // api
  const [doDeleteSurvey, { loading: deleting, data: deleted }] = useLazyQuery(DELETE_SURVEY, {
    fetchPolicy: 'network-only'
  });

  const [doGetSurveys, { loading: loading, data }] = useLazyQuery(GET_SURVEY_LIST, {
    fetchPolicy: 'network-only'
  });

  // api done
  const navigate = useNavigate();
  // delete modal
  const [show, setShow] = useState(false);
  const modalClose = () => setShow(false);
  const modalShow = (survey) => setShow(survey);

  useEffect(() => {
    doGetSurveys();
  }, []);

  const surveys = data?.getSurveyForAdmin?.data?.map((d) => {
    let responses = parseInt(d?.responses ?? 0, 10);

    return {
      title: d?.title,
      chartData: {
        labels: d?.option?.map((d) => d?.value),
        datasets: [
          {
            label: '# of Votes',
            data: d?.option?.map((d) => d?.user_id?.length),
            backgroundColor: d?.option?.map((_, index) => COLORS?.[index])
          }
        ]
      },
      chartOption: d?.option?.length > 0 && responses > 0 ? options1 : options2,
      plugin: d?.option?.length > 0 && responses > 0 ? plugin1 : (plugin1, plugin2),
      status: moment().isBetween(moment(d?.startDate), moment(d?.endDate), undefined, '[]')
        ? 'active'
        : 'inactive',
      responses,
      startDate: moment(d?.startDate).toDate(),
      endDate: moment(d?.endDate).toDate(),
      id: d?._id,
      isEmpty: !(d?.option?.length > 0 && responses > 0)
    };
  });

  const deleteSurvey = (id) => {
    doDeleteSurvey({
      variables: {
        id
      }
    });
  };

  useEffect(() => {
    if (deleted?.deleteSurvey?.status && !deleting) {
      setToastDetails({
        show: true,
        title: 'Survey Deleted',
        message: 'Survey Deleted successfully',
        type: 'success'
      });
      doGetSurveys();
    }
  }, [
    deleting,
    deleted?.deleteSurvey?.errorCode,
    deleted?.deleteSurvey?.message,
    deleted?.deleteSurvey?.status
  ]);

  return (
    <div className="markesurvey-wrapper">
      {deleting || loading ? <LoaderComponent /> : null}
      <Row className="text-end">
        <Col>
          <div className="create-survey-btn">
            <AppButton
              title={'Create a new survey'}
              onClick={() => navigate('/admin/merchant-survey/create')}
            />
          </div>
        </Col>
      </Row>
      <Row>
        {surveys?.map((s) => {
          return (
            <Col lg={4} md={6} key={s?.id}>
              <div className={`survey-box ${s?.status !== 'active' ? 'in-active-box' : ''}`}>
                <div className="top-title-bar">
                  <div className="title">{s?.title}</div>
                  <div className="action-btn">
                    <div className="right-action-btn">
                      <Link to={`/admin/merchant-survey/edit/${encodeURI(s?.title)}/${s?.id}`}>
                        <Image src={Images.edit_sm} className="me-3" />
                      </Link>
                      <span onClick={() => modalShow(s)}>
                        <Image src={Images.delete_sm} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="survey-chart">
                  {s?.chartData && !s?.isEmpty ? (
                    <>
                      <div className="total-responses">{s?.responses} Responses</div>
                    </>
                  ) : null}
                  <div className="survey-chart-wrapper">
                    {s?.chartData && !s?.isEmpty ? (
                      <>
                        <Doughnut data={s?.chartData} options={s?.chartOption} plugins={s.plugin} />
                        {/* <div className="center-value">{s?.responses}</div> */}
                      </>
                    ) : null}
                    {s?.isEmpty ? (
                      <Doughnut
                        data={{
                          labels: [],
                          datasets: [
                            {
                              label: '# of Votes',
                              data: []
                            }
                          ]
                        }}
                        options={options2}
                        plugins={(plugin1, plugin2)}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="survey-content">
                  <div className="survey-detail">
                    <div className="left-title">
                      <span>Status:</span>
                    </div>
                    <div className="right-value">
                      <span className={s?.status === 'active' ? 'text-green' : 'text-red'}>
                        {s?.status}
                      </span>
                    </div>
                  </div>
                  <div className="survey-detail">
                    <div className="left-title">
                      <span>Responses:</span>
                    </div>
                    <div className="right-value">
                      <span>{s?.responses}</span>
                    </div>
                  </div>
                  <div className="survey-detail">
                    <div className="left-title">
                      <span>Start date:</span>
                    </div>
                    <div className="right-value">
                      <span>{moment(s?.startDate).format('MMM DD YYYY')}</span>
                    </div>
                  </div>
                  <div className="survey-detail">
                    <div className="left-title">
                      <span>End date:</span>
                    </div>
                    <div className="right-value">
                      <span>{moment(s?.endDate).format('MMM DD YYYY')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
      <FeedbackModal
        show={show?.id}
        onHide={modalClose}
        icon={Images.delete_confirm}
        title="Delete Survey"
        subTitle="Are you sure you want to delete this survey? This action cannot be undone."
        className="delete-survey-modal"
        buttons={[
          <AppButton
            title={'Cancel'}
            key="1"
            buttonClass={'btn-cancel'}
            onClick={modalClose}
            id="survey.delete.cancel"
          />,
          <AppButton
            buttonClass={'error-bg'}
            title={'Delete'}
            key="2"
            id="survey.delete.confirm"
            onClick={() => {
              deleteSurvey(show?.id);
              modalClose();
            }}
          />
        ]}
      />
    </div>
  );
};

export default withAdmin(MerchantSurvey);
