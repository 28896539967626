import { gql } from '@apollo/client';

export const ADMIN_FORGOT_PASSWORD = gql`
  mutation ForgotPasswordSuperAdmin($input: forgotPassword) {
    forgotPasswordSuperAdmin(input: $input) {
      status
      message
      errorCode
    }
  }
`;
