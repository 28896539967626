import { gql } from '@apollo/client';

export const GET_SUGGESTED_BUSINESS = gql`
  query GetSuggestBusiness($offset: Int, $limit: Int, $search: String, $sorting: sorting) {
    getSuggestBusiness(offset: $offset, limit: $limit, search: $search, sorting: $sorting) {
      status
      message
      data {
        businessName
        zipcode
        email
        phoenNo
        user_id {
          email
          firstname
          lastname
          phoneno
          profilepicture
          _id
        }
      }
      errorCode
      total
      limit
      offset
    }
  }
`;
