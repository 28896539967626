import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import withAdmin from '../../../../components/hoc/admin/withAdmin';
import Images from '../../../../constants/images';
import TextInput from '../../../../components/common/text-input/TextInput';
import AppButton from '../../../../components/common/button/Button';
import UploadModal from '../../../../components/common/upload-modal/UploadModal';
import '../styles.css';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_MARKET_PLACE_CATEGORIES } from '../../../../graphql/schema/query/admin/marketplace/GET_MARKET_PLACE_CATEGORIES';
import { UPDATE_CATEGORY_DISCOUNT } from '../../../../graphql/schema/query/admin/marketplace/UPDATE_CATEGORY_DISCOUNT';
import ToastContext from '../../../../contexts/ToastContext';
import { SYNC_MARKETPLACE } from '../../../../graphql/schema/query/admin/marketplace/SYNC_MARKETPLACE';
import { PUBLISH_MARKETPLACE } from '../../../../graphql/schema/query/admin/marketplace/PUBLISH_MARKETPLACE';
import { UPDATE_CATEGORY_IMAGE } from '../../../../graphql/schema/query/admin/marketplace/UPDATE_CATEGORY_IMAGE';
import { baseFilePaths } from '../../../../constants';
import { Formik } from 'formik';

import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import LoaderComponent from '../../../../components/common/loader/LoaderComponent';

export const stockEditSchema = Yup.object().shape({
  discount: Yup.number()
    .typeError('Please enter valid discount')
    .min(0.1, 'Please enter percentage more than 0')
    .max(100, 'Please enter percentage less than 100')
    .required('Please enter discount')
});

const MarketplaceStock = () => {
  const imageProgressRef = useRef();

  const { setToastDetails } = useContext(ToastContext);

  // upload file modal
  const [show, setShow] = useState({});
  const [selectedImage, setSelectedImage] = useState();
  const [search, setSearch] = useState();
  const modalClose = () => {
    setShow({});
    setSelectedImage();
    imageProgressRef?.current(0);
  };
  const modalShow = (item) => setShow(item);

  const [doGetMarketPlaceCategory, { data, loading }] = useLazyQuery(GET_MARKET_PLACE_CATEGORIES, {
    fetchPolicy: 'cache-and-network'
  });

  const [
    doUpdateCategoryDiscount,
    { data: updateDiscount, loading: updateDiscountLoading, error }
  ] = useMutation(UPDATE_CATEGORY_DISCOUNT);

  const [doSyncMarketplace, { data: syncData, loading: syncLoading, error: syncError }] =
    useLazyQuery(SYNC_MARKETPLACE, {
      fetchPolicy: 'network-only'
    });

  const [
    doPublishMarketplace,
    { data: publishData, loading: publishLoading, error: publishError }
  ] = useLazyQuery(PUBLISH_MARKETPLACE, {
    fetchPolicy: 'network-only'
  });

  const [
    doUpdateCategoryImage,
    { data: updateImage, loading: updateImageLoading, error: updateImageError }
  ] = useMutation(UPDATE_CATEGORY_IMAGE, {
    context: {
      fetchOptions: {
        useUpload: true,
        onProgress: (ev) => {
          imageProgressRef?.current(ev.loaded / ev.total);
        },
        onAbortPossible: (abortHandler) => {
          console.log({ abortHandler });
        }
      }
    }
  });

  useEffect(() => {
    doGetMarketPlaceCategory();
  }, []);

  useEffect(() => {
    if (!updateDiscountLoading && error) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: 'Something went wrong!, Please try again after sometime',
        type: 'danger'
      });
    }
    if (
      !updateDiscountLoading &&
      updateDiscount?.updateCatDiscount?.message &&
      updateDiscount?.updateCatDiscount?.errorCode &&
      updateDiscount?.updateCatDiscount?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: updateDiscount?.updateCatDiscount?.message,
        type: 'danger'
      });
    } else {
      if (updateDiscount?.updateCatDiscount?.data?._id) {
        // update local state discount
        setToastDetails({
          show: true,
          title: 'Discount updated',
          message: 'Discount updated successfully.',
          type: 'success'
        });
      }
    }
  }, [
    updateDiscountLoading,
    error,
    updateDiscount?.updateCatDiscount?.errorCode,
    updateDiscount?.updateCatDiscount?.message,
    updateDiscount?.updateCatDiscount?.data?._id
  ]);

  useEffect(() => {
    if (!syncLoading && syncError) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: 'Something went wrong!, Please try again after sometime',
        type: 'danger'
      });
    }
    if (
      !syncLoading &&
      syncData?.toSyncMarketplace?.message &&
      syncData?.toSyncMarketplace?.errorCode &&
      syncData?.toSyncMarketplace?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: syncData?.toSyncMarketplace?.message,
        type: 'danger'
      });
    } else {
      if (syncData?.toSyncMarketplace?.status) {
        doGetMarketPlaceCategory();
        setToastDetails({
          show: true,
          title: 'Synced',
          message: 'Marketplace synced successfully.',
          type: 'success'
        });
      }
    }
  }, [
    syncLoading,
    syncError,
    syncData?.toSyncMarketplace?.errorCode,
    syncData?.toSyncMarketplace?.message
  ]);

  useEffect(() => {
    if (!publishLoading && publishError) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: 'Something went wrong!, Please try again after sometime',
        type: 'danger'
      });
    }
    if (
      !syncLoading &&
      publishData?.toPublishMarketplace?.message &&
      publishData?.toPublishMarketplace?.errorCode &&
      publishData?.toPublishMarketplace?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: publishData?.toPublishMarketplace?.message,
        type: 'danger'
      });
    } else {
      if (publishData?.toPublishMarketplace?.status) {
        doGetMarketPlaceCategory();
        setToastDetails({
          show: true,
          title: 'Marketplace published',
          message: 'Marketplace published successfully.',
          type: 'success'
        });
      }
    }
  }, [
    publishLoading,
    publishError,
    publishData?.toPublishMarketplace?.errorCode,
    publishData?.toPublishMarketplace?.message
  ]);

  useEffect(() => {
    if (!updateImageLoading && updateImageError) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: 'Something went wrong!, Please try again after sometime',
        type: 'danger'
      });
    }
    if (
      !updateImageLoading &&
      updateImage?.uploadCatImage?.message &&
      updateImage?.uploadCatImage?.errorCode &&
      updateImage?.uploadCatImage?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: updateImage?.uploadCatImage?.message,
        type: 'danger'
      });
    } else {
      if (updateImage?.uploadCatImage?.data?.catImage) {
        modalClose();
        doGetMarketPlaceCategory();
        setToastDetails({
          show: true,
          title: 'Image Updated',
          message: 'Category image updated successfully.',
          type: 'success'
        });
      }
    }
  }, [
    updateImageLoading,
    updateImageError,
    updateImage?.uploadCatImage?.errorCode,
    updateImage?.uploadCatImage?.message,
    updateImage?.uploadCatImage?.data?.catImage
  ]);

  const updateDiscountAPICall = (id, oldValue, newValue) => {
    if (newValue && String(oldValue) !== String(newValue)) {
      doUpdateCategoryDiscount({
        variables: {
          input: {
            discount: String(newValue),
            cat_id: id
          }
        }
      });
    }
  };

  let searched = data?.getMarketplaceCategory?.data;
  if (search) {
    searched = data?.getMarketplaceCategory?.data?.filter((d) => {
      return String(d?.primaryCat).toLowerCase().includes(String(search).toLowerCase());
    });
  }

  return (
    <div className="marketplace-stock-wrapper">
      {loading || updateDiscountLoading ? <LoaderComponent /> : null}
      <div className="marketplace-stock-header">
        <div className="header-button">
          <AppButton
            disabled={publishLoading}
            title={publishLoading ? 'Publishing...' : 'Publish'}
            className="stock-btn"
            id="publish-btn"
            onClick={() => {
              setToastDetails({
                show: true,
                title: 'Marketplace Publish',
                message: 'Publish process has been started',
                type: 'info'
              });
              doPublishMarketplace();
            }}></AppButton>
          <AppButton
            disabled={syncLoading}
            title={syncLoading ? 'Syncing...' : 'Sync'}
            className="stock-btn"
            id="sync-btn"
            onClick={() => doSyncMarketplace()}></AppButton>
        </div>
        <div className="header-action">
          <div className="search-box">
            <TextInput
              placeholder="Search stock"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
          <div className="filter-btn-wrapper"></div>
        </div>
      </div>
      {loading ? <p className="text-center mb-5 mt-5">Fetching...</p> : null}
      {searched?.length === 0 && search ? (
        <p className="text-center mb-5 mt-5">No search result found.</p>
      ) : null}
      <Row>
        {searched?.map((marketPlaceCategory) => {
          return (
            <Col lg={4} md={6} key={marketPlaceCategory?._id}>
              <div className="marketplace-stock-content">
                <div className="image-sec">
                  <Link
                    to={`/admin/marketplace/stock/stock-category/${encodeURI(
                      marketPlaceCategory?.primaryCat
                    )}/${marketPlaceCategory?._id}`}>
                    <Image
                      className="stock-banner-img"
                      src={
                        marketPlaceCategory?.catImage
                          ? `${baseFilePaths.marketplace}${
                              marketPlaceCategory?.catImage
                            }?cacheBuster=${Math.random()}`
                          : Images.deal_info_dummy_image
                      }
                    />
                  </Link>
                  <div className="edit-icon-wrapper">
                    <div onClick={() => modalShow(marketPlaceCategory)}>
                      <Image className="edit-icon" src={Images.edit} />
                    </div>
                  </div>
                </div>
                <div className="content-sec">
                  <Link
                    to={`/admin/marketplace/stock/stock-category/${encodeURI(
                      marketPlaceCategory?.primaryCat
                    )}/${marketPlaceCategory?._id}`}>
                    <div className="title">{marketPlaceCategory?.primaryCat}</div>
                  </Link>
                  {/* <div className="product-count">Total products: 25</div> */}
                  <Formik
                    validationSchema={stockEditSchema}
                    initialValues={{
                      discount: marketPlaceCategory?.discount
                    }}
                    onSubmit={(values) => {
                      updateDiscountAPICall(
                        marketPlaceCategory?._id,
                        marketPlaceCategory?.discount,
                        values?.discount
                      );
                    }}>
                    {({ values, handleSubmit, handleChange, errors, touched }) => {
                      return (
                        <TextInput
                          name="discount"
                          type="text"
                          value={values.discount}
                          onChange={handleChange}
                          leftText="%"
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              handleSubmit();
                            }
                          }}
                          onBlur={() => {
                            handleSubmit();
                          }}
                          error={touched.discount && errors.discount}
                        />
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
      <UploadModal
        show={show?._id}
        onHide={() => modalClose()}
        title="Upload category image"
        subTitle="Upload display picture for this category."
        className="stock-upload-modal"
        onSelectImage={(file) => {
          setSelectedImage(file);
        }}
        selectedImage={selectedImage}
        setToastDetails={setToastDetails}
        onError={() => modalClose()}
        imageProgressRef={imageProgressRef}
        uploading={updateImageLoading}
        buttons={[
          <AppButton
            key="1"
            title={'Cancel'}
            id="upload-cancel-btn"
            buttonClass={'btn-cancel'}
            onClick={() => modalClose()}></AppButton>,
          <AppButton
            key="2"
            title={updateImageLoading ? 'Uploading...' : 'Save'}
            id="upload-attach-btn"
            onClick={() => {
              if (selectedImage && selectedImage?.length > 0) {
                doUpdateCategoryImage({
                  variables: {
                    input: {
                      file: selectedImage?.[0],
                      cat_id: show?._id
                    }
                  }
                });
                return;
              }
              modalClose();
              setToastDetails({
                show: true,
                title: 'Error!',
                message: 'Please select image',
                type: 'danger'
              });
            }}></AppButton>
        ]}></UploadModal>
    </div>
  );
};

export default withAdmin(MarketplaceStock);
