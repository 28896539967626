import React, { useEffect, useState } from 'react';
import { Col, Pagination, Row } from 'react-bootstrap';
// import Pagination from 'react-bootstrap/Pagination';
import withAdmin from '../../../../components/hoc/admin/withAdmin';
import DealInfoCard from '../../../../components/common/dashboard/DealInfoCard';
import DealCard from '../../../../components/common/deals/DealCard';
import SelectInput from '../../../../components/common/select-input/SelectInput';
import TextInput from '../../../../components/common/text-input/TextInput';
import '../styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GET_MERCHANT_DEALS } from '../../../../graphql/schema/query/admin/merchant/GET_MERCHANT_DEALS';
import Images from '../../../../constants/images';
import { baseFilePaths } from '../../../../constants';
import moment from 'moment';
import { GET_MERCHANT_LIST } from '../../../../graphql/schema/query/admin/merchant/GET_MERCHANT_LIST';
import LoaderComponent from '../../../../components/common/loader/LoaderComponent';

const PER_PAGE = 9;
const MerchantManagementViewDeal = () => {
  const { merchantId } = useParams();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(null);
  const [doGetMerchantDeals, { loading, data }] = useLazyQuery(GET_MERCHANT_DEALS, {
    fetchPolicy: 'network-only'
  });

  const [doGetMerchants, { data: merchants, loading: merchantLoading }] = useLazyQuery(
    GET_MERCHANT_LIST,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  const getDeals = () => {
    let isActive;
    let isFeatured;
    let viewAll = true;
    if (status === 'active') {
      isActive = true;
      viewAll = false;
    }
    if (status === 'inactive') {
      isActive = false;
      viewAll = false;
    }

    if (status === 'featured') {
      isFeatured = true;
      viewAll = false;
    }

    doGetMerchantDeals({
      variables: {
        merchantId: merchantId,
        search: keyword,
        isFeatured,
        isActive,
        viewAll,
        offset: (page - 1) * PER_PAGE,
        limit: PER_PAGE
      }
    });
  };

  useEffect(() => {
    getDeals();
  }, [merchantId, keyword, status, page]);

  useEffect(() => {
    doGetMerchants({
      variables: {
        id: merchantId
      }
    });
  }, [merchantId]);

  const merchantDetails = merchants?.getMerchantList?.user?.[0];
  const isProfileCompleted = true;

  let searched = data?.getDealsByUser?.data;

  let totalPageArr = [];
  if (data?.getDealsByUser?.total > PER_PAGE) {
    totalPageArr = Array(Math.ceil(parseInt(data?.getDealsByUser?.total, 10) / PER_PAGE, 10)).fill(
      Math.random()
    );
  }

  return (
    <>
      <div className="merchant-view-deal-wrapper">
        {loading || merchantLoading ? <LoaderComponent /> : null}
        <div className="dashboard-wrapper">
          <div className="deal-info">
            <DealInfoCard merchantDetails={merchantDetails} />
          </div>
        </div>
        <div className="view-deal-wrapper">
          <div className="deal-top-bar">
            <div className="search-deal-box">
              <TextInput
                placeholder="Search deal"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  if (e.target.value === '') {
                    setKeyword('');
                  }
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    setKeyword(search);
                  }
                }}
              />
            </div>
            <div className="d-flex justify-content-end">
              <SelectInput
                value={status}
                onChange={(value) => setStatus(value)}
                placeholder={'View all'}
                options={[
                  {
                    name: 'View All',
                    _id: null
                  },
                  {
                    name: 'Featured',
                    _id: 'featured'
                  },
                  {
                    name: 'Active',
                    _id: 'active'
                  },
                  {
                    name: 'Inactive',
                    _id: 'inactive'
                  }
                ]}
              />
            </div>
          </div>

          {!loading && searched?.length <= 0 ? (
            <p className="text-center mt-5 mb-5">No deals found.</p>
          ) : null}
          <Row className="mt-4">
            {searched?.map((d) => {
              return (
                <Col xl={4} lg={6} md={6} className="deal-box" key={d?._id}>
                  <DealCard
                    isAdmin
                    image={
                      d?.dealImages?.[0]
                        ? `${baseFilePaths.dealImage}${d?.dealImages?.[0]}`
                        : Images.deal_1
                    }
                    type={d?.discountType}
                    title={d?.name}
                    from={moment(d?.startDate).format('MMM DD YYYY')}
                    to={moment(d?.endDate).format('MMM DD YYYY')}
                    rrp={`$${d?.price}`}
                    discount={`${d?.discountValue}`}
                    id={d?._id}
                    navigate={navigate}
                    refresh={() => {
                      getDeals();
                    }}
                    isActive={d?.isActive}
                    isProfileCompleted={isProfileCompleted}
                    isFeatured={d?.featured}
                  />
                </Col>
              );
            })}
          </Row>
          <Row className="mt-3 border-top">
            <div className="pagination-wrapper">
              <Pagination>
                <div className="left-content">
                  <Pagination.Prev
                    className="previous-btn arrow-btn"
                    disabled={page === 1}
                    onClick={() => setPage(page - 1)}
                  />
                </div>
                <div className="center-content">
                  {totalPageArr?.map((d, i) => {
                    return (
                      <Pagination.Item
                        key={i}
                        active={page === i + 1}
                        onClick={() => setPage(i + 1)}>
                        {i + 1}
                      </Pagination.Item>
                    );
                  })}
                </div>
                <div className="right-content">
                  <Pagination.Next
                    onClick={() => setPage(page + 1)}
                    className="next-btn arrow-btn"
                    disabled={totalPageArr?.length <= 0 || page === totalPageArr?.length}
                  />
                </div>
              </Pagination>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default withAdmin(MerchantManagementViewDeal);
