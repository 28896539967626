import moment from 'moment';
import * as Yup from 'yup';

export const surveySchema = Yup.object().shape({
  title: Yup.string().typeError('Please enter title').required('Please enter title'),
  startDate: Yup.date()
    .typeError('Please enter start date')
    .test('minDateToday', 'Please enter valid date', (item) => {
      return moment(item).isSameOrAfter(moment(), 'd');
    })
    .required('Please enter start date'),
  endDate: Yup.date()
    .typeError('Please enter end date')
    .test('minDateToday', 'Please enter valid date', (item) => {
      return moment(item).isSameOrAfter(moment(), 'd');
    })
    .min(Yup.ref('startDate'), "End date can't be before start date")
    .required('Please enter end date'),
  options: Yup.array().of(
    Yup.object().shape({
      option: Yup.string().required('Option text is required')
    })
  ),
  question: Yup.string().typeError('Please enter question').required('Please enter question')
});

export const surveySchemaEdit = Yup.object().shape({
  title: Yup.string().typeError('Please enter title').required('Please enter title'),
  startDate: Yup.date().typeError('Please enter start date').required('Please enter start date'),
  endDate: Yup.date()
    .typeError('Please enter end date')
    .test('minDateToday', 'Please enter valid date', (item) => {
      return moment(item).isSameOrAfter(moment(), 'd');
    })
    .min(Yup.ref('startDate'), "End date can't be before start date")
    .required('Please enter end date'),
  options: Yup.array().of(
    Yup.object().shape({
      option: Yup.string().required('Option text is required')
    })
  ),
  question: Yup.string().typeError('Please enter question').required('Please enter question')
});

export const initValues = {
  title: '',
  startDate: '',
  endDate: '',
  question: '',
  options: [
    {
      option: '',
      logo: null
    }
  ]
};
