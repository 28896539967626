import React, { useContext, useEffect, useRef, useState } from 'react';
import FormWrapper from '../../../../components/common/form-wrapper/FormWrapper';
import withAdmin from '../../../../components/hoc/admin/withAdmin';
import LocationInformation from '../../../../components/admin/common-form-sections/LocationInformation';

import '../styles.css';
import PersonalInformation from '../../../../components/admin/common-form-sections/PersonalInformation';
import OtherInformation from '../../../../components/admin/common-form-sections/OtherInformation';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_USER_DETAILS } from '../../../../graphql/schema/query/admin/user/GET_USER_DETAILS';
import { Formik } from 'formik';
import { userFormInitValues, userFormSchema } from './formUtils';
import { EDIT_USER } from '../../../../graphql/schema/query/admin/user/EDIT_USER';
import ToastContext from '../../../../contexts/ToastContext';
import ErrorFocus from '../../../../components/common/ErrorFocus/ErrorFocus';
import { GET_STATES } from '../../../../graphql/schema/query/masters/GET_STATE';
import { GET_COUNTRIES } from '../../../../graphql/schema/query/masters/GET_COUNTRY';
import { EDIT_USER_ADDRESS } from '../../../../graphql/schema/query/admin/user/EDIT_USER_ADDRESS';
import { EDIT_USER_CENTZ } from '../../../../graphql/schema/query/admin/user/EDIT_USER_CENTZ';
import LoaderComponent from '../../../../components/common/loader/LoaderComponent';

const cacheBuster = Math.random();

const UserListEdit = () => {
  const formRef = useRef();
  const params = useParams();
  const navigate = useNavigate();

  const { setToastDetails } = useContext(ToastContext);
  const [selectedImage, setSelectedImage] = useState();
  const [profileImageCache, setProfileImageCache] = useState(cacheBuster);
  const [stateData, setStateData] = useState([]);
  const [countryData, setCountryData] = useState([]);

  const [doUpdateUser, { data, loading: updating, error }] = useMutation(EDIT_USER);
  const [doUpdateUserAddress] = useMutation(EDIT_USER_ADDRESS);
  const [doUpdateUserCentz, { loading: centzUpdating }] = useMutation(EDIT_USER_CENTZ);

  const [doGetUserDetails, { loading }] = useLazyQuery(GET_USER_DETAILS, {
    variables: {
      id: params?.id
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const details = data?.getUsersList?.user?.[0];
      Object.keys(details ?? {}).map((k) => {
        formRef?.current?.setFieldValue(k, details?.[k] || '');
        if (k === 'currentCentz') {
          formRef?.current?.setFieldValue(
            'totalCentz',
            Number(details?.[k] || '0') > 0 ? Number(details?.[k] || '0').toFixed(2) : '0'
          );
        }
      });
    }
  });

  const [doGetState] = useLazyQuery(GET_STATES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.state?.data?.find((d) => d?.name === null)) {
        return;
      }
      setStateData(data?.state?.data);
    }
  });

  const [doGetCountries] = useLazyQuery(GET_COUNTRIES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.country?.data?.find((d) => d?.name === null)) {
        return;
      }
      setCountryData(data?.country?.data);
    }
  });

  useEffect(() => {
    if (params?.id) {
      doGetUserDetails();
      doGetState();
      doGetCountries();
    }
  }, [params?.id]);

  const doUpdateUserDetails = (values) => {
    const data = {
      _id: params?.id,
      userData: {
        email: values?.email,
        firstname: values?.firstname,
        lastname: values?.lastname,
        phoneno: values?.phoneno,
        dob: values?.dob
      },
      profilepictureFile: selectedImage ?? null
    };

    doUpdateUser({
      variables: {
        input: data
      }
    });
  };

  useEffect(() => {
    if (!updating && error) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: 'Something went wrong!, Please try again after sometime',
        type: 'danger'
      });
    }
    if (
      !updating &&
      data?.editUser?.message &&
      data?.editUser?.errorCode &&
      data?.editUser?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: data?.editUser?.message,
        type: 'danger'
      });
    } else {
      if (data?.editUser?.status) {
        setToastDetails({
          show: true,
          title: 'User Updated',
          message: 'User updated successfully.',
          type: 'success'
        });
        setSelectedImage();
        setProfileImageCache(Math.random());
        navigate(-1, { replace: true });
      }
    }
  }, [updating, error, data?.editUser?.errorCode, data?.editUser?.message, data?.editUser?.status]);

  return (
    <>
      {loading || centzUpdating ? <LoaderComponent /> : null}
      <div className="user-list-edit-wrapper">
        <Formik
          innerRef={formRef}
          initialValues={userFormInitValues}
          validationSchema={userFormSchema}
          onSubmit={(values) => {
            doUpdateUserDetails(values);
            if (values?.defaultAddress?._id) {
              // update address
              doUpdateUserAddress({
                variables: {
                  input: {
                    address_id: values?.defaultAddress?._id,
                    addressLine1: values?.defaultAddress?.addressLine1,
                    addressLine2: values?.defaultAddress?.addressLine2,
                    state_id:
                      values?.defaultAddress?.state_id?._id || values?.defaultAddress?.state_id,
                    city: values?.defaultAddress?.city,
                    country_id:
                      values?.defaultAddress?.country_id?._id || values?.defaultAddress?.country_id,
                    zipcode: values?.defaultAddress?.zipcode
                  }
                }
              });
            }
            if (String(values?.totalCentz) !== String(values?.currentCentz)) {
              doUpdateUserCentz({
                variables: {
                  input: {
                    newBalance: values?.totalCentz,
                    user_id: params.id
                  }
                }
              });
            }
          }}>
          {({ values, handleChange, handleSubmit, errors, touched, setFieldValue }) => {
            return (
              <FormWrapper
                handleSubmit={handleSubmit}
                onCancel={() => {
                  navigate(-1, { replace: true });
                }}>
                <div className="main-content-wrapper">
                  <PersonalInformation
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                    profileImageCache={profileImageCache}
                    doUpdateUserCentz={doUpdateUserCentz}
                    setFieldValue={setFieldValue}
                    id={params?.id}
                  />
                  <div className="divider-line" />
                  <LocationInformation
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    values={values?.defaultAddress}
                    errors={errors}
                    stateData={stateData}
                    countryData={countryData}
                    touched={touched}
                    userManagement
                  />
                  <div className="divider-line" />
                  <OtherInformation values={values} handleChange={handleChange} />
                </div>
                <ErrorFocus />
              </FormWrapper>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default withAdmin(UserListEdit);
