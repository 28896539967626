import React, { useState } from 'react';

import './styles.css';
import TextInput from './TextInput';

const SSNTextInput = (props) => {
  const [showSSNInput, setShowSSNInput] = useState(false);

  const maskedSSN = () => {
    if (!props.value) {
      return '';
    }
    const res = props.value;
    const len = res.length;
    const stars =
      len > 0
        ? len > 1
          ? len > 2
            ? len > 3
              ? len > 4
                ? '***-**-'
                : '***-*'
              : '***-'
            : '**'
          : '*'
        : '';

    const masked = stars + res.substring(7);

    return masked;
  };

  if (!showSSNInput) {
    return (
      <TextInput
        maxLength={11}
        label="Social security number"
        placeholder="Social security number"
        value={maskedSSN()}
        id="merchantAccount.securityNoMasked"
        onFocus={() => {
          setShowSSNInput(true);
          setTimeout(() => {
            document.getElementById('merchantAccount.securityNo')?.focus?.();
          }, 200);
        }}
        error={props.error}
      />
    );
  }

  return (
    <TextInput
      label="Social security number"
      placeholder="Social security number"
      name="securityNo"
      value={props.value}
      error={props.error}
      onChange={props.onChange}
      id="merchantAccount.securityNo"
      maskingProps={{
        mask: '999-99-9999',
        maskChar: '',
        onBlur: () => {
          setShowSSNInput(false);
        }
      }}
    />
  );
};

export default SSNTextInput;
