import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AppButton from '../../../components/common/button/Button';
import TextInput from '../../../components/common/text-input/TextInput';
import withMerchantOnboarding from '../../../components/hoc/merchant/withMerchantOnboarding';
import ContentCardWrapper from '../../../components/merchant/onboarding/content-card-wrapper/ContentCardWrapper';
import Images from '../../../constants/images';
import { UserContext } from '../../../contexts';
import ToastContext from '../../../contexts/ToastContext';
import { MERCHANT_SIGNUP } from '../../../graphql/schema/mutations/onboarding/MERCHANT_SIGNUP';
import { setLocalStorageItem } from '../../../services/localStorageService';
import { SignUpInitValues, SignupSchema } from './formUtils';

const SignUp = () => {
  const { setUserDetails } = useContext(UserContext);
  const navigate = useNavigate();
  const [doSignUp, { data, loading, error }] = useMutation(MERCHANT_SIGNUP);
  const { setToastDetails } = useContext(ToastContext);

  const onSubmit = (values) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    doSignUp({
      variables: {
        signUpByMerchantInput2: {
          email: values.email,
          password: values.password,
          timezone
        }
      }
    });
  };

  useEffect(() => {
    if (!loading && data?.signUpByMerchant?.jwttoken) {
      setLocalStorageItem('token', data?.signUpByMerchant?.jwttoken);
      setUserDetails();
      navigate('/onboarding/business-search');
    }

    if (
      !loading &&
      data?.signUpByMerchant?.message &&
      data?.signUpByMerchant?.errorCode &&
      data?.signUpByMerchant?.errorCode !== '0'
    ) {
      setToastDetails({
        show: true,
        title: 'Error!',
        message: data?.signUpByMerchant?.message,
        type: 'danger'
      });
    }
  }, [loading, error, data?.signUpByMerchant?.jwttoken, data?.signUpByMerchant?.errorCode]);

  return (
    <>
      <ContentCardWrapper
        title={'Create an account'}
        subTitle={'Create a login that you can share with any employees that may access.'}>
        <Formik
          validationSchema={SignupSchema}
          initialValues={SignUpInitValues}
          onSubmit={onSubmit}>
          {({ values, errors, touched, handleChange, handleSubmit }) => {
            return (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}>
                <TextInput
                  label="Email Address"
                  placeholder="Email Address"
                  value={values.email}
                  error={touched.email && errors.email}
                  onChange={handleChange}
                  name="email"
                  id="signup.email"
                />
                <TextInput
                  label="Password"
                  placeholder="Choose a password"
                  value={values.password}
                  error={touched.password && errors.password}
                  onChange={handleChange}
                  name="password"
                  type="password"
                  rightIcon={Images.eye_cross}
                  id="signup.password"
                />
                <TextInput
                  label=""
                  placeholder="Confirm password"
                  value={values.passwordConfirm}
                  error={touched.passwordConfirm && errors.passwordConfirm}
                  onChange={handleChange}
                  name="passwordConfirm"
                  type="password"
                  rightIcon={Images.eye_cross}
                  id="signup.passwordConfirm"
                />
                <div className="mt-4">
                  <AppButton
                    title={'Continue'}
                    onClick={handleSubmit}
                    disabled={loading}
                    id="signup.continue"
                    type="submit"
                  />
                </div>
                <div className="mt-4 text-center bottom-link fonts-medium font-14 ">
                  Already have an account?{' '}
                  <Link to="/login" className="link-color">
                    Sign In
                  </Link>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ContentCardWrapper>
    </>
  );
};

export default withMerchantOnboarding(SignUp);
