import { gql } from '@apollo/client';

export const UPLOAD_BUSINESS_LOGO = gql`
  mutation UploadBusinessLogo($file: Upload!) {
    uploadBusinessLogo(file: $file) {
      status
      message
      business {
        business_data {
          businessLogo
        }
      }
      errorCode
    }
  }
`;
