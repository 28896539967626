import { gql } from '@apollo/client';

export const ADD_DEAL = gql`
  mutation AddDeal($input: addDeal!) {
    addDeal(input: $input) {
      status
      message
      data {
        _id
      }
      errorCode
    }
  }
`;
