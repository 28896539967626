import { gql } from '@apollo/client';

export const UPDATE_SURVEY = gql`
  mutation UpdateSurvey($input: addSurvey!) {
    updateSurvey(input: $input) {
      status
      message
      data {
        _id
      }
      errorCode
    }
  }
`;
