import { gql } from '@apollo/client';

export const GET_DEALS = gql`
  query GetDeals($offset: Int, $limit: Int, $status: String, $dealId: ID) {
    getDeals(offset: $offset, limit: $limit, status: $status, dealId: $dealId) {
      status
      message
      deals {
        _id
        isActive
        name
        decription
        price
        discountType
        discountValue
        startDate
        endDate
        dealImages
      }
      total
      limit
      offset
      errorCode
    }
  }
`;
