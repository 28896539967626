import React, { useState } from 'react';
import {
  Dropdown,
  DropdownButton,
  FormControl,
  FormLabel,
  InputGroup,
  Button,
  Image
} from 'react-bootstrap';
import ReactInputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input-field';
import Images from '../../../constants/images';
import './styles.css';

const eyeIcon = Images.eye;

const TextInput = (props) => {
  const {
    label,
    placeholder,
    onChange,
    error,
    rightIcon,
    type,
    onRightIconClick = () => null,
    leftIcon,
    onLeftIconClick = () => null,
    leftText,
    id,
    maskingProps,
    noLabel,
    inputGroupClass
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  let typeToConsider = type;

  if (type === 'password') {
    if (showPassword) {
      typeToConsider = 'text';
    } else {
      typeToConsider = 'password';
    }
  }

  const renderInput = () => {
    if (maskingProps?.currency) {
      return (
        <CurrencyInput
          className="form-control"
          placeholder={placeholder}
          value={props.value}
          decimalsLimit={2}
          onValueChange={(value) => {
            onChange(value);
          }}
          prefix="$"
          name={props.name}
          intlConfig={{
            currency: 'USD',
            locale: 'en-US'
          }}
        />
      );
    }
    if (maskingProps?.mask) {
      return (
        <ReactInputMask
          mask={maskingProps?.mask}
          value={props.value}
          onChange={onChange}
          maskChar={maskingProps?.maskChar}
          onFocus={maskingProps?.onFocus}
          onBlur={maskingProps?.onBlur}>
          {(inputProps) => (
            <FormControl
              {...inputProps}
              id={id}
              label={label}
              placeholder={placeholder}
              onChange={(e) => {
                onChange(e);
              }}
              {...props}
              className={`${rightIcon ? 'with-icon' : ''} ${leftIcon ? 'with-icon-left' : ''} ${
                leftText ? 'with-icon-left-text' : ''
              }`}
              type={typeToConsider}
            />
          )}
        </ReactInputMask>
      );
    }

    return (
      <FormControl
        id={id}
        label={label}
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e);
        }}
        {...props}
        className={`${rightIcon ? 'with-icon' : ''} ${leftIcon ? 'with-icon-left' : ''} ${
          leftText ? 'with-icon-left-text' : ''
        }`}
        type={typeToConsider}
      />
    );
  };

  return (
    <div>
      {!noLabel ? (
        <FormLabel className={label ? 'mt-3' : 'mt-1'} title={label}>
          {label}
        </FormLabel>
      ) : null}
      <InputGroup className={inputGroupClass}>
        {props.withOptions ? (
          <DropdownButton
            variant="outline-secondary"
            title={props.options?.find((o) => o._id === props.dropdownValue)?.name}
            id="input-group-select">
            {props.options?.map((option) => {
              return (
                <Dropdown.Item
                  onClick={() => props.onSelectOption(option?._id)}
                  key={option?._id}
                  value={option?._id}>
                  {option?.name}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        ) : null}
        {leftIcon ? (
          <Button
            variant="outline-secondary button-prefix-icon"
            id="button-addon2"
            onClick={() => {
              onLeftIconClick?.();
            }}>
            <Image src={leftIcon} />
          </Button>
        ) : null}
        {leftText ? (
          <Button
            variant="outline-secondary button-prefix-icon"
            id="button-addon2"
            onClick={() => {
              onLeftIconClick?.();
            }}>
            {leftText}
          </Button>
        ) : null}
        {renderInput()}
        {rightIcon ? (
          <Button
            variant="outline-secondary button-suffix-icon"
            id="button-addon2"
            onClick={() => {
              if (type === 'password') {
                setShowPassword(!showPassword);
              }
              onRightIconClick?.();
            }}>
            <Image src={showPassword ? eyeIcon : rightIcon} />
          </Button>
        ) : null}
      </InputGroup>
      <span className="text-helper text-danger">{error}</span>
    </div>
  );
};

export default TextInput;
