import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { MERCHANT_APPLICATION_SETTINGS } from '../../../constants';
import AppButton from '../../common/button/Button';
import './styles.css';

const rightBtnStyle = {
  [MERCHANT_APPLICATION_SETTINGS.pending]: {
    color: '#00C2FF'
  },
  [MERCHANT_APPLICATION_SETTINGS.submitted]: {
    color: '#98A2B3'
  },
  [MERCHANT_APPLICATION_SETTINGS.approved]: {
    color: '#32D583'
  }
};

const ctaStyles = {
  [MERCHANT_APPLICATION_SETTINGS.approved]: {
    backgroundColor: '#0F0742',
    color: '#ffffff'
  }
};

const cardStyles = {
  [MERCHANT_APPLICATION_SETTINGS.approved]: {
    backgroundColor: '#52FF9A'
  }
};

const lineStyle = {
  [MERCHANT_APPLICATION_SETTINGS.pending]: {
    color: '#98A2B3'
  },
  [MERCHANT_APPLICATION_SETTINGS.submitted]: {
    color: '#98A2B3'
  },
  [MERCHANT_APPLICATION_SETTINGS.approved]: {
    color: '#0F0742'
  }
};

const AccountStatusCard = ({
  line1 = 'Application status',
  line2 = 'Not submitted',
  rightBtnText = 'Not submitted',
  ctaText = 'Start your application',
  status = MERCHANT_APPLICATION_SETTINGS.pending,
  onCTAClick = () => null
}) => {
  return (
    <div className="merchant-account-status mt-4" status={status} style={cardStyles?.[status]}>
      <Row>
        <Col md={8}>
          <p className="fonts-medium m-0 title">{line1}</p>
          <span className="fonts-medium sub-title" style={lineStyle?.[status]}>
            {line2}
          </span>
        </Col>
        <Col md={4} className="text-end top-button-custom">
          <AppButton
            title={rightBtnText}
            containerClass="bg-transparent"
            buttonClass={'application-status-top w-auto'}
            style={rightBtnStyle?.[status]}
            id="accountStatus.cta-0"
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={12}>
          <AppButton
            title={ctaText}
            onClick={onCTAClick}
            style={ctaStyles?.[status]}
            buttonClass={'application-status-bottom w-auto'}
            id="accountStatus.cta"
          />
        </Col>
      </Row>
      <style jsx>{`
        .merchant-account-status .application-status-top:before {
          background: ${rightBtnStyle?.[status]?.color};
        }
      `}</style>
    </div>
  );
};

export default AccountStatusCard;
