import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Image } from 'react-bootstrap';
import Images from '../../../constants/images';

const FileDragNDrop = ({
  maxFiles = 5,
  typesText = 'SVG, PNG, JPG or GIF (max. 800x400px)',
  onSelectImage,
  maxSize = 10485760,
  setToastDetails,
  onError = () => null
}) => {
  const { acceptedFiles, getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      'image/*': []
    },
    maxFiles,
    maxSize
  });

  const checkHeightAndWidthOfSelectedImages = async (acceptedImages) => {
    const imagesHeightAndWidth = await Promise.all(
      acceptedImages?.map((a) => {
        return window.getUploadedImageHeightAndWidth(a);
      })
    );

    // width should be below 800 and height should be below 400
    const passedImages = imagesHeightAndWidth?.filter((i) => {
      return i?.width <= 800 && i?.height <= 400;
    });
    if (acceptedImages?.length > 0 && passedImages.length !== acceptedImages?.length) {
      setToastDetails?.({
        show: true,
        title: 'Error!',
        message: `Please select images with height and width less than or equals to dimensions 800*400`,
        type: 'danger'
      });
      onError?.();
      return;
    }

    // check extension strict to svg, png, jpeg, jpg, gif
    const validExtensions = passedImages?.filter((p) => {
      const fileExt = p?.file?.name?.split?.('.').pop();
      return ['png', 'jpg', 'jpeg', 'gif', 'svg'].includes(String(fileExt).toLowerCase());
    });

    if (acceptedImages?.length > 0 && validExtensions.length !== acceptedImages?.length) {
      setToastDetails?.({
        show: true,
        title: 'Error!',
        message: `Please select images with extensions 'png', 'jpg', 'jpeg', 'gif', 'svg'.`,
        type: 'danger'
      });
      onError?.();
      return;
    }
    onSelectImage?.(validExtensions?.map((p) => p?.file));
  };

  useEffect(() => {
    const acceptedImages = acceptedFiles?.filter((a) => {
      return a?.size <= maxSize;
    });

    if (acceptedFiles?.length > 0 && acceptedImages.length !== acceptedFiles?.length) {
      setToastDetails?.({
        show: true,
        title: 'Error!',
        message: `You can select image of maximum size ${(maxSize / (1024 * 1024)).toFixed(0)}Mb `,
        type: 'danger'
      });
      onError?.();
      return;
    }
    checkHeightAndWidthOfSelectedImages(acceptedFiles);
  }, [acceptedFiles]);

  useEffect(() => {
    if (fileRejections?.length > maxFiles) {
      setToastDetails?.({
        show: true,
        title: 'Error!',
        message: `You can select maximum ${maxFiles} images`,
        type: 'danger'
      });
      onError?.();
    }

    if (fileRejections?.[0]?.errors?.[0]?.code === 'file-too-large') {
      setToastDetails?.({
        show: true,
        title: 'Error!',
        message: `You can select image of maximum size ${(maxSize / (1024 * 1024)).toFixed(0)}Mb`,
        type: 'danger'
      });
      onError?.();
    }
  }, [fileRejections]);

  return (
    <>
      <div {...getRootProps({ className: 'dropzone file-upload-container' })}>
        <input {...getInputProps()} />
        <Image src={Images.upload_icon} />
        <p className="m-0 font-regular">
          <strong className="fonts-bold">Click to upload</strong> <span>or drag and drop</span>
        </p>
        <span className="font-regular info-text-file-upload">{typesText}</span>
      </div>
    </>
  );
};

export default FileDragNDrop;
