import { gql } from '@apollo/client';

export const FREEZE_USER = gql`
  query FreezeUser($freezeUserId: ID) {
    freezeUser(id: $freezeUserId) {
      status
      message
      user {
        _id
        isActive
      }
      total
      limit
      offset
      errorCode
    }
  }
`;
