import React, { useState } from 'react';
import { Image, ListGroupItem, Modal } from 'react-bootstrap';
import Images from '../../../constants/images';
import FileDragNDrop from '../file-drag-and-drop/FileDragNDrop';
import './styles.css';

const UploadModal = (props) => {
  const file = props.selectedImage?.[0];
  const [progress, setProgress] = useState(0);
  React.useEffect(() => {
    if (props.imageProgressRef) {
      props.imageProgressRef.current = updateProgress;
    }
  }, []);

  const updateProgress = (progress) => {
    setProgress((progress * 100).toFixed(2));
  };
  let size = file?.size;
  if (size > 1024 * 1024) {
    size = `${(size / (1024 * 1024)).toFixed(2)} MB`;
  } else {
    size = `${size} B`;
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`upload-file-modal ${props.className}`}>
      <Modal.Header>
        <div className="heading">{props.title}</div>
        <span className="sub-heading">{props.subTitle}</span>
        <div className="category-name">{props.categoryName}</div>
      </Modal.Header>
      <Modal.Body>
        <div className="upload-content-wrapper">
          <FileDragNDrop
            onSelectImage={props.onSelectImage}
            setToastDetails={props.setToastDetails}
            onError={props.onError}
            maxFiles={1}
          />
        </div>
        {file ? (
          <ListGroupItem
            key={'upload-image'}
            className="d-flex align-items-center justify-content-between image-section-line mb-2 preview-image-container">
            {progress > 0 && progress <= 100 && file?.size && (
              <div className="image-progress-indicator" style={{ width: `${progress}%` }} />
            )}
            <div className="d-flex">
              <Image
                src={URL.createObjectURL(file)}
                style={{
                  height: 60,
                  width: 60,
                  marginRight: 12,
                  borderRadius: 12
                }}
              />
              <div className="d-flex flex-column justify-content-center">
                <p className="image-name fonts-regular m-0">{file?.name}</p>
                {file?.size ? (
                  <p className="image-name-size fonts-regular m-0">
                    {size}
                    {progress > 0 && progress <= 100 && file?.size
                      ? ` - ${progress}% uploaded`
                      : ''}
                  </p>
                ) : null}
              </div>
            </div>
            <Image
              role="button"
              className="float-end mt-2"
              src={Images.close}
              style={{ height: 20, width: 20 }}
              onClick={() => {
                props.onSelectImage?.();
              }}
            />
          </ListGroupItem>
        ) : null}
      </Modal.Body>
      <Modal.Footer>{props.buttons?.map((button) => button)}</Modal.Footer>
    </Modal>
  );
};
export default UploadModal;
