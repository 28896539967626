import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInput from '../../common/text-input/TextInput';
import FormWrapper from '../../common/form-wrapper/FormWrapper';
import Images from '../../../constants/images';
import { baseFilePaths } from '../../../constants';
import { useLazyQuery, useMutation } from '@apollo/client';
import ErrorFocus from '../../common/ErrorFocus/ErrorFocus';
import { EDIT_USER } from '../../../graphql/schema/query/admin/user/EDIT_USER';
import { GET_ADMIN_USER } from '../../../graphql/schema/query/admin/user/GET_ADMIN_USER';
import ToastContext from '../../../contexts/ToastContext';
import { DELETE_ADMIN_PROFILE_LOGO } from '../../../graphql/schema/query/admin/user/DELETE_ADMIN_PROFILE_LOGO';
import LoaderComponent from '../../common/loader/LoaderComponent';

export const FormSchema = Yup.object().shape({
  email: Yup.string().email('Please enter valid email').required('Please enter email'),
  firstName: Yup.string().required('Please enter first name'),
  lastName: Yup.string().required('Please enter last name'),
  phoneNo: Yup.string().required('Please enter phone no.')
});

const ProfileInformation = () => {
  const [doGetMe, { data, loading }] = useLazyQuery(GET_ADMIN_USER, {
    fetchPolicy: 'network-only'
  });

  const [doDeleteLogo, { loading: deleting }] = useLazyQuery(DELETE_ADMIN_PROFILE_LOGO, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      doGetMe();
    }
  });

  const [doUpdateUser, user] = useMutation(EDIT_USER, {
    onCompleted: () => {
      doGetMe();
    }
  });

  const { setToastDetails } = useContext(ToastContext);

  const [merchant, setMerchant] = useState(data?.me?.user);
  const [selectedImage, setSelectedImage] = useState();
  const [bgImage, setBgImage] = useState();

  useEffect(() => {
    if (selectedImage) {
      setBgImage(`url(${URL.createObjectURL(selectedImage)})`);
    }
  }, [selectedImage]);

  useEffect(() => {
    doGetMe();
  }, []);

  useEffect(() => {
    setMerchant(data?.me?.user);
    if (data?.me?.user?.profilepicture) {
      setBgImage(`url('${baseFilePaths.userProfileImage}${data?.me?.user?.profilepicture}')`);
    }
  }, [data]);

  useEffect(() => {
    if (user.called) {
      if (user.data && user.data.editUser) {
        setToastDetails({
          show: true,
          title: user?.data?.editUser?.error === '1' ? 'Error!' : 'Details Updated',
          message: user?.data?.editUser?.message,
          type: user?.data?.editUser?.error === '1' ? 'danger' : 'success'
        });
      }
    }
  }, [user.data]);

  const doUpdateUserDetails = (values) => {
    const updatedData = {
      _id: data?.me?.user?._id,
      userData: {
        email: values?.email,
        firstname: values?.firstName,
        lastname: values?.lastName,
        phoneno: values?.phoneNo
      }
    };
    if (selectedImage) {
      updatedData['profilepictureFile'] = selectedImage;
    }
    doUpdateUser({
      variables: {
        input: updatedData
      }
    });
  };

  const renderForm = () => {
    if (!data?.me?.user?._id) {
      return null;
    }

    return (
      <Formik
        validationSchema={FormSchema}
        initialValues={{
          firstName: merchant?.firstname,
          lastName: merchant?.lastname,
          email: merchant?.email,
          phoneNo: merchant?.phoneno
        }}
        onSubmit={(values) => {
          doUpdateUserDetails(values);
        }}>
        {({ values, handleChange, handleSubmit, errors, touched, resetForm }) => {
          return (
            <FormWrapper
              handleSubmit={handleSubmit}
              onCancel={() =>
                resetForm({
                  firstName: merchant?.firstname,
                  lastName: merchant?.lastname,
                  email: merchant?.email,
                  phonNo: merchant?.phoneno
                })
              }>
              <div className="wrapper-padding">
                <p className="form-title mb-0 fonts-quicksand-medium">Profile information</p>
                <span className="form-subtitle fonts-regular">
                  Fill out your information below.
                </span>
                <Row className="mt-4">
                  <Col md={12}>
                    {bgImage ? (
                      <div
                        className="file-input-picker-rounded admin mb-0"
                        style={bgImage && { backgroundImage: bgImage }}>
                        <label>
                          <Image className="edit" src={Images.editOutline} role="button" />
                          <input
                            hidden
                            type="file"
                            id="file"
                            aria-label="Logo"
                            accept="image/*"
                            onChange={(event) => {
                              if (event?.target?.files?.[0]) {
                                setSelectedImage(event.target.files[0]);
                              }
                            }}
                          />
                        </label>

                        <Image
                          className="delete"
                          src={Images.deleteOutline}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedImage();
                            setBgImage();
                            if (data?.me?.user?.profilepicture) {
                              doDeleteLogo();
                            }
                          }}
                        />
                        <br />
                        <span className="text-white">Profile Picture</span>
                      </div>
                    ) : (
                      <label className="file-input-picker-rounded mb-0">
                        <input
                          type="file"
                          id="file"
                          aria-label="Logo"
                          accept="image/*"
                          onChange={(event) => {
                            if (event?.target?.files?.[0]) {
                              setSelectedImage(event.target.files[0]);
                            }
                          }}
                        />
                        <Image className="edit" src={Images.edit} />
                      </label>
                    )}
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="First name"
                      placeholder="First name"
                      name="firstName"
                      onChange={handleChange}
                      value={values.firstName}
                      error={touched.firstName && errors.firstName}
                      id="profileinfo.fname"
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="Last name"
                      placeholder="Last name"
                      name="lastName"
                      onChange={handleChange}
                      value={values.lastName}
                      error={touched.lastName && errors.lastName}
                      id="profileinfo.lname"
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="Email address"
                      placeholder="Email address"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      error={touched.email && errors.email}
                      leftIcon={Images.mail}
                      id="profileinfo.email"
                      readOnly
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="Contact number"
                      placeholder="Contact number"
                      name="phoneNo"
                      value={values.phoneNo}
                      error={touched.phoneNo && errors.phoneNo}
                      onChange={handleChange}
                      leftIcon={Images.phone}
                      id="profileinfo.cnumber"
                    />
                  </Col>
                </Row>
              </div>
              <ErrorFocus />
            </FormWrapper>
          );
        }}
      </Formik>
    );
  };
  return (
    <>{loading || !merchant || deleting || user?.loading ? <LoaderComponent /> : renderForm()}</>
  );
};

export default ProfileInformation;
