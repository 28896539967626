import React from 'react';
import SelectInput from '../select-input/SelectInput';

const timezones = [
  {
    _id: 'America/Puerto_Rico',
    name: 'Puerto Rico (Atlantic)'
  },
  {
    _id: 'America/New_York',
    name: 'New York (Eastern)'
  },
  {
    _id: 'America/Chicago',
    name: 'Chicago (Central)'
  },
  {
    _id: 'America/Denver',
    name: 'Denver (Mountain)'
  },
  {
    _id: 'America/Phoenix',
    name: 'Phoenix (MST)'
  },
  {
    _id: 'America/Los_Angeles',
    name: 'Los Angeles (Pacific)'
  },
  {
    _id: 'America/Anchorage',
    name: 'Anchorage (Alaska)'
  },
  {
    _id: 'Pacific/Honolulu',
    name: 'Honolulu (Hawaii)'
  },
  {
    _id: 'America/Adak',
    name: 'Adak'
  },
  {
    _id: 'America/Anchorage',
    name: 'Anchorage'
  },
  {
    _id: 'America/Anguilla',
    name: 'Anguilla'
  },
  {
    _id: 'America/Antigua',
    name: 'Antigua'
  },
  {
    _id: 'America/Araguaina',
    name: 'Araguaina'
  },
  {
    _id: 'America/Argentina/Buenos_Aires',
    name: 'Argentina - Buenos Aires'
  },
  {
    _id: 'America/Argentina/Catamarca',
    name: 'Argentina - Catamarca'
  },
  {
    _id: 'America/Argentina/ComodRivadavia',
    name: 'Argentina - ComodRivadavia'
  },
  {
    _id: 'America/Argentina/Cordoba',
    name: 'Argentina - Cordoba'
  },
  {
    _id: 'America/Argentina/Jujuy',
    name: 'Argentina - Jujuy'
  },
  {
    _id: 'America/Argentina/La_Rioja',
    name: 'Argentina - La Rioja'
  },
  {
    _id: 'America/Argentina/Mendoza',
    name: 'Argentina - Mendoza'
  },
  {
    _id: 'America/Argentina/Rio_Gallegos',
    name: 'Argentina - Rio Gallegos'
  },
  {
    _id: 'America/Argentina/Salta',
    name: 'Argentina - Salta'
  },
  {
    _id: 'America/Argentina/San_Juan',
    name: 'Argentina - San Juan'
  },
  {
    _id: 'America/Argentina/San_Luis',
    name: 'Argentina - San Luis'
  },
  {
    _id: 'America/Argentina/Tucuman',
    name: 'Argentina - Tucuman'
  },
  {
    _id: 'America/Argentina/Ushuaia',
    name: 'Argentina - Ushuaia'
  },
  {
    _id: 'America/Aruba',
    name: 'Aruba'
  },
  {
    _id: 'America/Asuncion',
    name: 'Asuncion'
  },
  {
    _id: 'America/Atikokan',
    name: 'Atikokan'
  },
  {
    _id: 'America/Atka',
    name: 'Atka'
  },
  {
    _id: 'America/Bahia',
    name: 'Bahia'
  },
  {
    _id: 'America/Barbados',
    name: 'Barbados'
  },
  {
    _id: 'America/Belem',
    name: 'Belem'
  },
  {
    _id: 'America/Belize',
    name: 'Belize'
  },
  {
    _id: 'America/Blanc-Sablon',
    name: 'Blanc-Sablon'
  },
  {
    _id: 'America/Boa_Vista',
    name: 'Boa Vista'
  },
  {
    _id: 'America/Bogota',
    name: 'Bogota'
  },
  {
    _id: 'America/Boise',
    name: 'Boise'
  },
  {
    _id: 'America/Buenos_Aires',
    name: 'Buenos Aires'
  },
  {
    _id: 'America/Cambridge_Bay',
    name: 'Cambridge Bay'
  },
  {
    _id: 'America/Campo_Grande',
    name: 'Campo Grande'
  },
  {
    _id: 'America/Cancun',
    name: 'Cancun'
  },
  {
    _id: 'America/Caracas',
    name: 'Caracas'
  },
  {
    _id: 'America/Catamarca',
    name: 'Catamarca'
  },
  {
    _id: 'America/Cayenne',
    name: 'Cayenne'
  },
  {
    _id: 'America/Cayman',
    name: 'Cayman'
  },
  {
    _id: 'America/Chicago',
    name: 'Chicago'
  },
  {
    _id: 'America/Chihuahua',
    name: 'Chihuahua'
  },
  {
    _id: 'America/Coral_Harbour',
    name: 'Coral Harbour'
  },
  {
    _id: 'America/Cordoba',
    name: 'Cordoba'
  },
  {
    _id: 'America/Costa_Rica',
    name: 'Costa Rica'
  },
  {
    _id: 'America/Cuiaba',
    name: 'Cuiaba'
  },
  {
    _id: 'America/Curacao',
    name: 'Curacao'
  },
  {
    _id: 'America/Danmarkshavn',
    name: 'Danmarkshavn'
  },
  {
    _id: 'America/Dawson',
    name: 'Dawson'
  },
  {
    _id: 'America/Dawson_Creek',
    name: 'Dawson Creek'
  },
  {
    _id: 'America/Denver',
    name: 'Denver'
  },
  {
    _id: 'America/Detroit',
    name: 'Detroit'
  },
  {
    _id: 'America/Dominica',
    name: 'Dominica'
  },
  {
    _id: 'America/Edmonton',
    name: 'Edmonton'
  },
  {
    _id: 'America/Eirunepe',
    name: 'Eirunepe'
  },
  {
    _id: 'America/El_Salvador',
    name: 'El Salvador'
  },
  {
    _id: 'America/Ensenada',
    name: 'Ensenada'
  },
  {
    _id: 'America/Fortaleza',
    name: 'Fortaleza'
  },
  {
    _id: 'America/Fort_Wayne',
    name: 'Fort Wayne'
  },
  {
    _id: 'America/Glace_Bay',
    name: 'Glace Bay'
  },
  {
    _id: 'America/Godthab',
    name: 'Godthab'
  },
  {
    _id: 'America/Goose_Bay',
    name: 'Goose Bay'
  },
  {
    _id: 'America/Grand_Turk',
    name: 'Grand Turk'
  },
  {
    _id: 'America/Grenada',
    name: 'Grenada'
  },
  {
    _id: 'America/Guadeloupe',
    name: 'Guadeloupe'
  },
  {
    _id: 'America/Guatemala',
    name: 'Guatemala'
  },
  {
    _id: 'America/Guayaquil',
    name: 'Guayaquil'
  },
  {
    _id: 'America/Guyana',
    name: 'Guyana'
  },
  {
    _id: 'America/Halifax',
    name: 'Halifax'
  },
  {
    _id: 'America/Havana',
    name: 'Havana'
  },
  {
    _id: 'America/Hermosillo',
    name: 'Hermosillo'
  },
  {
    _id: 'America/Indiana/Indianapolis',
    name: 'Indiana - Indianapolis'
  },
  {
    _id: 'America/Indiana/Knox',
    name: 'Indiana - Knox'
  },
  {
    _id: 'America/Indiana/Marengo',
    name: 'Indiana - Marengo'
  },
  {
    _id: 'America/Indiana/Petersburg',
    name: 'Indiana - Petersburg'
  },
  {
    _id: 'America/Indiana/Tell_City',
    name: 'Indiana - Tell City'
  },
  {
    _id: 'America/Indiana/Vevay',
    name: 'Indiana - Vevay'
  },
  {
    _id: 'America/Indiana/Vincennes',
    name: 'Indiana - Vincennes'
  },
  {
    _id: 'America/Indiana/Winamac',
    name: 'Indiana - Winamac'
  },
  {
    _id: 'America/Indianapolis',
    name: 'Indianapolis'
  },
  {
    _id: 'America/Inuvik',
    name: 'Inuvik'
  },
  {
    _id: 'America/Iqaluit',
    name: 'Iqaluit'
  },
  {
    _id: 'America/Jamaica',
    name: 'Jamaica'
  },
  {
    _id: 'America/Jujuy',
    name: 'Jujuy'
  },
  {
    _id: 'America/Juneau',
    name: 'Juneau'
  },
  {
    _id: 'America/Kentucky/Louisville',
    name: 'Kentucky - Louisville'
  },
  {
    _id: 'America/Kentucky/Monticello',
    name: 'Kentucky - Monticello'
  },
  {
    _id: 'America/Knox_IN',
    name: 'Knox IN'
  },
  {
    _id: 'America/La_Paz',
    name: 'La Paz'
  },
  {
    _id: 'America/Lima',
    name: 'Lima'
  },
  {
    _id: 'America/Los_Angeles',
    name: 'Los Angeles'
  },
  {
    _id: 'America/Louisville',
    name: 'Louisville'
  },
  {
    _id: 'America/Maceio',
    name: 'Maceio'
  },
  {
    _id: 'America/Managua',
    name: 'Managua'
  },
  {
    _id: 'America/Manaus',
    name: 'Manaus'
  },
  {
    _id: 'America/Marigot',
    name: 'Marigot'
  },
  {
    _id: 'America/Martinique',
    name: 'Martinique'
  },
  {
    _id: 'America/Matamoros',
    name: 'Matamoros'
  },
  {
    _id: 'America/Mazatlan',
    name: 'Mazatlan'
  },
  {
    _id: 'America/Mendoza',
    name: 'Mendoza'
  },
  {
    _id: 'America/Menominee',
    name: 'Menominee'
  },
  {
    _id: 'America/Merida',
    name: 'Merida'
  },
  {
    _id: 'America/Mexico_City',
    name: 'Mexico City'
  },
  {
    _id: 'America/Miquelon',
    name: 'Miquelon'
  },
  {
    _id: 'America/Moncton',
    name: 'Moncton'
  },
  {
    _id: 'America/Monterrey',
    name: 'Monterrey'
  },
  {
    _id: 'America/Montevideo',
    name: 'Montevideo'
  },
  {
    _id: 'America/Montreal',
    name: 'Montreal'
  },
  {
    _id: 'America/Montserrat',
    name: 'Montserrat'
  },
  {
    _id: 'America/Nassau',
    name: 'Nassau'
  },
  {
    _id: 'America/New_York',
    name: 'New York'
  },
  {
    _id: 'America/Nipigon',
    name: 'Nipigon'
  },
  {
    _id: 'America/Nome',
    name: 'Nome'
  },
  {
    _id: 'America/Noronha',
    name: 'Noronha'
  },
  {
    _id: 'America/North_Dakota/Center',
    name: 'North Dakota - Center'
  },
  {
    _id: 'America/North_Dakota/New_Salem',
    name: 'North Dakota - New Salem'
  },
  {
    _id: 'America/Ojinaga',
    name: 'Ojinaga'
  },
  {
    _id: 'America/Panama',
    name: 'Panama'
  },
  {
    _id: 'America/Pangnirtung',
    name: 'Pangnirtung'
  },
  {
    _id: 'America/Paramaribo',
    name: 'Paramaribo'
  },
  {
    _id: 'America/Phoenix',
    name: 'Phoenix'
  },
  {
    _id: 'America/Port-au-Prince',
    name: 'Port-au-Prince'
  },
  {
    _id: 'America/Porto_Acre',
    name: 'Porto Acre'
  },
  {
    _id: 'America/Port_of_Spain',
    name: 'Port of Spain'
  },
  {
    _id: 'America/Porto_Velho',
    name: 'Porto Velho'
  },
  {
    _id: 'America/Puerto_Rico',
    name: 'Puerto Rico'
  },
  {
    _id: 'America/Rainy_River',
    name: 'Rainy River'
  },
  {
    _id: 'America/Rankin_Inlet',
    name: 'Rankin Inlet'
  },
  {
    _id: 'America/Recife',
    name: 'Recife'
  },
  {
    _id: 'America/Regina',
    name: 'Regina'
  },
  {
    _id: 'America/Resolute',
    name: 'Resolute'
  },
  {
    _id: 'America/Rio_Branco',
    name: 'Rio Branco'
  },
  {
    _id: 'America/Rosario',
    name: 'Rosario'
  },
  {
    _id: 'America/Santa_Isabel',
    name: 'Santa Isabel'
  },
  {
    _id: 'America/Santarem',
    name: 'Santarem'
  },
  {
    _id: 'America/Santiago',
    name: 'Santiago'
  },
  {
    _id: 'America/Santo_Domingo',
    name: 'Santo Domingo'
  },
  {
    _id: 'America/Sao_Paulo',
    name: 'Sao Paulo'
  },
  {
    _id: 'America/Scoresbysund',
    name: 'Scoresbysund'
  },
  {
    _id: 'America/Shiprock',
    name: 'Shiprock'
  },
  {
    _id: 'America/St_Barthelemy',
    name: 'St Barthelemy'
  },
  {
    _id: 'America/St_Johns',
    name: 'St Johns'
  },
  {
    _id: 'America/St_Kitts',
    name: 'St Kitts'
  },
  {
    _id: 'America/St_Lucia',
    name: 'St Lucia'
  },
  {
    _id: 'America/St_Thomas',
    name: 'St Thomas'
  },
  {
    _id: 'America/St_Vincent',
    name: 'St Vincent'
  },
  {
    _id: 'America/Swift_Current',
    name: 'Swift Current'
  },
  {
    _id: 'America/Tegucigalpa',
    name: 'Tegucigalpa'
  },
  {
    _id: 'America/Thule',
    name: 'Thule'
  },
  {
    _id: 'America/Thunder_Bay',
    name: 'Thunder Bay'
  },
  {
    _id: 'America/Tijuana',
    name: 'Tijuana'
  },
  {
    _id: 'America/Toronto',
    name: 'Toronto'
  },
  {
    _id: 'America/Tortola',
    name: 'Tortola'
  },
  {
    _id: 'America/Vancouver',
    name: 'Vancouver'
  },
  {
    _id: 'America/Virgin',
    name: 'Virgin'
  },
  {
    _id: 'America/Whitehorse',
    name: 'Whitehorse'
  },
  {
    _id: 'America/Winnipeg',
    name: 'Winnipeg'
  },
  {
    _id: 'America/Yakutat',
    name: 'Yakutat'
  },
  {
    _id: 'America/Yellowknife',
    name: 'Yellowknife'
  },
  {
    _id: 'Europe/Amsterdam',
    name: 'Amsterdam'
  },
  {
    _id: 'Europe/Andorra',
    name: 'Andorra'
  },
  {
    _id: 'Europe/Athens',
    name: 'Athens'
  },
  {
    _id: 'Europe/Belfast',
    name: 'Belfast'
  },
  {
    _id: 'Europe/Belgrade',
    name: 'Belgrade'
  },
  {
    _id: 'Europe/Berlin',
    name: 'Berlin'
  },
  {
    _id: 'Europe/Bratislava',
    name: 'Bratislava'
  },
  {
    _id: 'Europe/Brussels',
    name: 'Brussels'
  },
  {
    _id: 'Europe/Bucharest',
    name: 'Bucharest'
  },
  {
    _id: 'Europe/Budapest',
    name: 'Budapest'
  },
  {
    _id: 'Europe/Chisinau',
    name: 'Chisinau'
  },
  {
    _id: 'Europe/Copenhagen',
    name: 'Copenhagen'
  },
  {
    _id: 'Europe/Dublin',
    name: 'Dublin'
  },
  {
    _id: 'Europe/Gibraltar',
    name: 'Gibraltar'
  },
  {
    _id: 'Europe/Guernsey',
    name: 'Guernsey'
  },
  {
    _id: 'Europe/Helsinki',
    name: 'Helsinki'
  },
  {
    _id: 'Europe/Isle_of_Man',
    name: 'Isle of Man'
  },
  {
    _id: 'Europe/Istanbul',
    name: 'Istanbul'
  },
  {
    _id: 'Europe/Jersey',
    name: 'Jersey'
  },
  {
    _id: 'Europe/Kaliningrad',
    name: 'Kaliningrad'
  },
  {
    _id: 'Europe/Kiev',
    name: 'Kiev'
  },
  {
    _id: 'Europe/Lisbon',
    name: 'Lisbon'
  },
  {
    _id: 'Europe/Ljubljana',
    name: 'Ljubljana'
  },
  {
    _id: 'Europe/London',
    name: 'London'
  },
  {
    _id: 'Europe/Luxembourg',
    name: 'Luxembourg'
  },
  {
    _id: 'Europe/Madrid',
    name: 'Madrid'
  },
  {
    _id: 'Europe/Malta',
    name: 'Malta'
  },
  {
    _id: 'Europe/Mariehamn',
    name: 'Mariehamn'
  },
  {
    _id: 'Europe/Minsk',
    name: 'Minsk'
  },
  {
    _id: 'Europe/Monaco',
    name: 'Monaco'
  },
  {
    _id: 'Europe/Moscow',
    name: 'Moscow'
  },
  {
    _id: 'Europe/Nicosia',
    name: 'Nicosia'
  },
  {
    _id: 'Europe/Oslo',
    name: 'Oslo'
  },
  {
    _id: 'Europe/Paris',
    name: 'Paris'
  },
  {
    _id: 'Europe/Podgorica',
    name: 'Podgorica'
  },
  {
    _id: 'Europe/Prague',
    name: 'Prague'
  },
  {
    _id: 'Europe/Riga',
    name: 'Riga'
  },
  {
    _id: 'Europe/Rome',
    name: 'Rome'
  },
  {
    _id: 'Europe/Samara',
    name: 'Samara'
  },
  {
    _id: 'Europe/San_Marino',
    name: 'San Marino'
  },
  {
    _id: 'Europe/Sarajevo',
    name: 'Sarajevo'
  },
  {
    _id: 'Europe/Simferopol',
    name: 'Simferopol'
  },
  {
    _id: 'Europe/Skopje',
    name: 'Skopje'
  },
  {
    _id: 'Europe/Sofia',
    name: 'Sofia'
  },
  {
    _id: 'Europe/Stockholm',
    name: 'Stockholm'
  },
  {
    _id: 'Europe/Tallinn',
    name: 'Tallinn'
  },
  {
    _id: 'Europe/Tirane',
    name: 'Tirane'
  },
  {
    _id: 'Europe/Tiraspol',
    name: 'Tiraspol'
  },
  {
    _id: 'Europe/Uzhgorod',
    name: 'Uzhgorod'
  },
  {
    _id: 'Europe/Vaduz',
    name: 'Vaduz'
  },
  {
    _id: 'Europe/Vatican',
    name: 'Vatican'
  },
  {
    _id: 'Europe/Vienna',
    name: 'Vienna'
  },
  {
    _id: 'Europe/Vilnius',
    name: 'Vilnius'
  },
  {
    _id: 'Europe/Volgograd',
    name: 'Volgograd'
  },
  {
    _id: 'Europe/Warsaw',
    name: 'Warsaw'
  },
  {
    _id: 'Europe/Zagreb',
    name: 'Zagreb'
  },
  {
    _id: 'Europe/Zaporozhye',
    name: 'Zaporozhye'
  },
  {
    _id: 'Europe/Zurich',
    name: 'Zurich'
  },
  {
    _id: 'Asia/Aden',
    name: 'Aden'
  },
  {
    _id: 'Asia/Almaty',
    name: 'Almaty'
  },
  {
    _id: 'Asia/Amman',
    name: 'Amman'
  },
  {
    _id: 'Asia/Anadyr',
    name: 'Anadyr'
  },
  {
    _id: 'Asia/Aqtau',
    name: 'Aqtau'
  },
  {
    _id: 'Asia/Aqtobe',
    name: 'Aqtobe'
  },
  {
    _id: 'Asia/Ashgabat',
    name: 'Ashgabat'
  },
  {
    _id: 'Asia/Ashkhabad',
    name: 'Ashkhabad'
  },
  {
    _id: 'Asia/Baghdad',
    name: 'Baghdad'
  },
  {
    _id: 'Asia/Bahrain',
    name: 'Bahrain'
  },
  {
    _id: 'Asia/Baku',
    name: 'Baku'
  },
  {
    _id: 'Asia/Bangkok',
    name: 'Bangkok'
  },
  {
    _id: 'Asia/Beirut',
    name: 'Beirut'
  },
  {
    _id: 'Asia/Bishkek',
    name: 'Bishkek'
  },
  {
    _id: 'Asia/Brunei',
    name: 'Brunei'
  },
  {
    _id: 'Asia/Calcutta',
    name: 'Calcutta'
  },
  {
    _id: 'Asia/Choibalsan',
    name: 'Choibalsan'
  },
  {
    _id: 'Asia/Chongqing',
    name: 'Chongqing'
  },
  {
    _id: 'Asia/Chungking',
    name: 'Chungking'
  },
  {
    _id: 'Asia/Colombo',
    name: 'Colombo'
  },
  {
    _id: 'Asia/Dacca',
    name: 'Dacca'
  },
  {
    _id: 'Asia/Damascus',
    name: 'Damascus'
  },
  {
    _id: 'Asia/Dhaka',
    name: 'Dhaka'
  },
  {
    _id: 'Asia/Dili',
    name: 'Dili'
  },
  {
    _id: 'Asia/Dubai',
    name: 'Dubai'
  },
  {
    _id: 'Asia/Dushanbe',
    name: 'Dushanbe'
  },
  {
    _id: 'Asia/Gaza',
    name: 'Gaza'
  },
  {
    _id: 'Asia/Harbin',
    name: 'Harbin'
  },
  {
    _id: 'Asia/Ho_Chi_Minh',
    name: 'Ho Chi Minh'
  },
  {
    _id: 'Asia/Hong_Kong',
    name: 'Hong Kong'
  },
  {
    _id: 'Asia/Hovd',
    name: 'Hovd'
  },
  {
    _id: 'Asia/Irkutsk',
    name: 'Irkutsk'
  },
  {
    _id: 'Asia/Istanbul',
    name: 'Istanbul'
  },
  {
    _id: 'Asia/Jakarta',
    name: 'Jakarta'
  },
  {
    _id: 'Asia/Jayapura',
    name: 'Jayapura'
  },
  {
    _id: 'Asia/Jerusalem',
    name: 'Jerusalem'
  },
  {
    _id: 'Asia/Kabul',
    name: 'Kabul'
  },
  {
    _id: 'Asia/Kamchatka',
    name: 'Kamchatka'
  },
  {
    _id: 'Asia/Karachi',
    name: 'Karachi'
  },
  {
    _id: 'Asia/Kashgar',
    name: 'Kashgar'
  },
  {
    _id: 'Asia/Kathmandu',
    name: 'Kathmandu'
  },
  {
    _id: 'Asia/Katmandu',
    name: 'Katmandu'
  },
  {
    _id: 'Asia/Kolkata',
    name: 'Kolkata'
  },
  {
    _id: 'Asia/Krasnoyarsk',
    name: 'Krasnoyarsk'
  },
  {
    _id: 'Asia/Kuala_Lumpur',
    name: 'Kuala Lumpur'
  },
  {
    _id: 'Asia/Kuching',
    name: 'Kuching'
  },
  {
    _id: 'Asia/Kuwait',
    name: 'Kuwait'
  },
  {
    _id: 'Asia/Macao',
    name: 'Macao'
  },
  {
    _id: 'Asia/Macau',
    name: 'Macau'
  },
  {
    _id: 'Asia/Magadan',
    name: 'Magadan'
  },
  {
    _id: 'Asia/Makassar',
    name: 'Makassar'
  },
  {
    _id: 'Asia/Manila',
    name: 'Manila'
  },
  {
    _id: 'Asia/Muscat',
    name: 'Muscat'
  },
  {
    _id: 'Asia/Nicosia',
    name: 'Nicosia'
  },
  {
    _id: 'Asia/Novokuznetsk',
    name: 'Novokuznetsk'
  },
  {
    _id: 'Asia/Novosibirsk',
    name: 'Novosibirsk'
  },
  {
    _id: 'Asia/Omsk',
    name: 'Omsk'
  },
  {
    _id: 'Asia/Oral',
    name: 'Oral'
  },
  {
    _id: 'Asia/Phnom_Penh',
    name: 'Phnom Penh'
  },
  {
    _id: 'Asia/Pontianak',
    name: 'Pontianak'
  },
  {
    _id: 'Asia/Pyongyang',
    name: 'Pyongyang'
  },
  {
    _id: 'Asia/Qatar',
    name: 'Qatar'
  },
  {
    _id: 'Asia/Qyzylorda',
    name: 'Qyzylorda'
  },
  {
    _id: 'Asia/Rangoon',
    name: 'Rangoon'
  },
  {
    _id: 'Asia/Riyadh',
    name: 'Riyadh'
  },
  {
    _id: 'Asia/Saigon',
    name: 'Saigon'
  },
  {
    _id: 'Asia/Sakhalin',
    name: 'Sakhalin'
  },
  {
    _id: 'Asia/Samarkand',
    name: 'Samarkand'
  },
  {
    _id: 'Asia/Seoul',
    name: 'Seoul'
  },
  {
    _id: 'Asia/Shanghai',
    name: 'Shanghai'
  },
  {
    _id: 'Asia/Singapore',
    name: 'Singapore'
  },
  {
    _id: 'Asia/Taipei',
    name: 'Taipei'
  },
  {
    _id: 'Asia/Tashkent',
    name: 'Tashkent'
  },
  {
    _id: 'Asia/Tbilisi',
    name: 'Tbilisi'
  },
  {
    _id: 'Asia/Tehran',
    name: 'Tehran'
  },
  {
    _id: 'Asia/Tel_Aviv',
    name: 'Tel Aviv'
  },
  {
    _id: 'Asia/Thimbu',
    name: 'Thimbu'
  },
  {
    _id: 'Asia/Thimphu',
    name: 'Thimphu'
  },
  {
    _id: 'Asia/Tokyo',
    name: 'Tokyo'
  },
  {
    _id: 'Asia/Ujung_Pandang',
    name: 'Ujung Pandang'
  },
  {
    _id: 'Asia/Ulaanbaatar',
    name: 'Ulaanbaatar'
  },
  {
    _id: 'Asia/Ulan_Bator',
    name: 'Ulan Bator'
  },
  {
    _id: 'Asia/Urumqi',
    name: 'Urumqi'
  },
  {
    _id: 'Asia/Vientiane',
    name: 'Vientiane'
  },
  {
    _id: 'Asia/Vladivostok',
    name: 'Vladivostok'
  },
  {
    _id: 'Asia/Yakutsk',
    name: 'Yakutsk'
  },
  {
    _id: 'Asia/Yekaterinburg',
    name: 'Yekaterinburg'
  },
  {
    _id: 'Asia/Yerevan',
    name: 'Yerevan'
  },
  {
    _id: 'Africa/Abidjan',
    name: 'Abidjan'
  },
  {
    _id: 'Africa/Accra',
    name: 'Accra'
  },
  {
    _id: 'Africa/Addis_Ababa',
    name: 'Addis Ababa'
  },
  {
    _id: 'Africa/Algiers',
    name: 'Algiers'
  },
  {
    _id: 'Africa/Asmara',
    name: 'Asmara'
  },
  {
    _id: 'Africa/Asmera',
    name: 'Asmera'
  },
  {
    _id: 'Africa/Bamako',
    name: 'Bamako'
  },
  {
    _id: 'Africa/Bangui',
    name: 'Bangui'
  },
  {
    _id: 'Africa/Banjul',
    name: 'Banjul'
  },
  {
    _id: 'Africa/Bissau',
    name: 'Bissau'
  },
  {
    _id: 'Africa/Blantyre',
    name: 'Blantyre'
  },
  {
    _id: 'Africa/Brazzaville',
    name: 'Brazzaville'
  },
  {
    _id: 'Africa/Bujumbura',
    name: 'Bujumbura'
  },
  {
    _id: 'Africa/Cairo',
    name: 'Cairo'
  },
  {
    _id: 'Africa/Casablanca',
    name: 'Casablanca'
  },
  {
    _id: 'Africa/Ceuta',
    name: 'Ceuta'
  },
  {
    _id: 'Africa/Conakry',
    name: 'Conakry'
  },
  {
    _id: 'Africa/Dakar',
    name: 'Dakar'
  },
  {
    _id: 'Africa/Dar_es_Salaam',
    name: 'Dar es Salaam'
  },
  {
    _id: 'Africa/Djibouti',
    name: 'Djibouti'
  },
  {
    _id: 'Africa/Douala',
    name: 'Douala'
  },
  {
    _id: 'Africa/El_Aaiun',
    name: 'El Aaiun'
  },
  {
    _id: 'Africa/Freetown',
    name: 'Freetown'
  },
  {
    _id: 'Africa/Gaborone',
    name: 'Gaborone'
  },
  {
    _id: 'Africa/Harare',
    name: 'Harare'
  },
  {
    _id: 'Africa/Johannesburg',
    name: 'Johannesburg'
  },
  {
    _id: 'Africa/Kampala',
    name: 'Kampala'
  },
  {
    _id: 'Africa/Khartoum',
    name: 'Khartoum'
  },
  {
    _id: 'Africa/Kigali',
    name: 'Kigali'
  },
  {
    _id: 'Africa/Kinshasa',
    name: 'Kinshasa'
  },
  {
    _id: 'Africa/Lagos',
    name: 'Lagos'
  },
  {
    _id: 'Africa/Libreville',
    name: 'Libreville'
  },
  {
    _id: 'Africa/Lome',
    name: 'Lome'
  },
  {
    _id: 'Africa/Luanda',
    name: 'Luanda'
  },
  {
    _id: 'Africa/Lubumbashi',
    name: 'Lubumbashi'
  },
  {
    _id: 'Africa/Lusaka',
    name: 'Lusaka'
  },
  {
    _id: 'Africa/Malabo',
    name: 'Malabo'
  },
  {
    _id: 'Africa/Maputo',
    name: 'Maputo'
  },
  {
    _id: 'Africa/Maseru',
    name: 'Maseru'
  },
  {
    _id: 'Africa/Mbabane',
    name: 'Mbabane'
  },
  {
    _id: 'Africa/Mogadishu',
    name: 'Mogadishu'
  },
  {
    _id: 'Africa/Monrovia',
    name: 'Monrovia'
  },
  {
    _id: 'Africa/Nairobi',
    name: 'Nairobi'
  },
  {
    _id: 'Africa/Ndjamena',
    name: 'Ndjamena'
  },
  {
    _id: 'Africa/Niamey',
    name: 'Niamey'
  },
  {
    _id: 'Africa/Nouakchott',
    name: 'Nouakchott'
  },
  {
    _id: 'Africa/Ouagadougou',
    name: 'Ouagadougou'
  },
  {
    _id: 'Africa/Porto-Novo',
    name: 'Porto-Novo'
  },
  {
    _id: 'Africa/Sao_Tome',
    name: 'Sao Tome'
  },
  {
    _id: 'Africa/Timbuktu',
    name: 'Timbuktu'
  },
  {
    _id: 'Africa/Tripoli',
    name: 'Tripoli'
  },
  {
    _id: 'Africa/Tunis',
    name: 'Tunis'
  },
  {
    _id: 'Africa/Windhoek',
    name: 'Windhoek'
  },
  {
    _id: 'Australia/ACT',
    name: 'ACT'
  },
  {
    _id: 'Australia/Adelaide',
    name: 'Adelaide'
  },
  {
    _id: 'Australia/Brisbane',
    name: 'Brisbane'
  },
  {
    _id: 'Australia/Broken_Hill',
    name: 'Broken Hill'
  },
  {
    _id: 'Australia/Canberra',
    name: 'Canberra'
  },
  {
    _id: 'Australia/Currie',
    name: 'Currie'
  },
  {
    _id: 'Australia/Darwin',
    name: 'Darwin'
  },
  {
    _id: 'Australia/Eucla',
    name: 'Eucla'
  },
  {
    _id: 'Australia/Hobart',
    name: 'Hobart'
  },
  {
    _id: 'Australia/LHI',
    name: 'LHI'
  },
  {
    _id: 'Australia/Lindeman',
    name: 'Lindeman'
  },
  {
    _id: 'Australia/Lord_Howe',
    name: 'Lord Howe'
  },
  {
    _id: 'Australia/Melbourne',
    name: 'Melbourne'
  },
  {
    _id: 'Australia/North',
    name: 'North'
  },
  {
    _id: 'Australia/NSW',
    name: 'NSW'
  },
  {
    _id: 'Australia/Perth',
    name: 'Perth'
  },
  {
    _id: 'Australia/Queensland',
    name: 'Queensland'
  },
  {
    _id: 'Australia/South',
    name: 'South'
  },
  {
    _id: 'Australia/Sydney',
    name: 'Sydney'
  },
  {
    _id: 'Australia/Tasmania',
    name: 'Tasmania'
  },
  {
    _id: 'Australia/Victoria',
    name: 'Victoria'
  },
  {
    _id: 'Australia/West',
    name: 'West'
  },
  {
    _id: 'Australia/Yancowinna',
    name: 'Yancowinna'
  },
  {
    _id: 'Indian/Antananarivo',
    name: 'Antananarivo'
  },
  {
    _id: 'Indian/Chagos',
    name: 'Chagos'
  },
  {
    _id: 'Indian/Christmas',
    name: 'Christmas'
  },
  {
    _id: 'Indian/Cocos',
    name: 'Cocos'
  },
  {
    _id: 'Indian/Comoro',
    name: 'Comoro'
  },
  {
    _id: 'Indian/Kerguelen',
    name: 'Kerguelen'
  },
  {
    _id: 'Indian/Mahe',
    name: 'Mahe'
  },
  {
    _id: 'Indian/Maldives',
    name: 'Maldives'
  },
  {
    _id: 'Indian/Mauritius',
    name: 'Mauritius'
  },
  {
    _id: 'Indian/Mayotte',
    name: 'Mayotte'
  },
  {
    _id: 'Indian/Reunion',
    name: 'Reunion'
  },
  {
    _id: 'Atlantic/Azores',
    name: 'Azores'
  },
  {
    _id: 'Atlantic/Bermuda',
    name: 'Bermuda'
  },
  {
    _id: 'Atlantic/Canary',
    name: 'Canary'
  },
  {
    _id: 'Atlantic/Cape_Verde',
    name: 'Cape Verde'
  },
  {
    _id: 'Atlantic/Faeroe',
    name: 'Faeroe'
  },
  {
    _id: 'Atlantic/Faroe',
    name: 'Faroe'
  },
  {
    _id: 'Atlantic/Jan_Mayen',
    name: 'Jan Mayen'
  },
  {
    _id: 'Atlantic/Madeira',
    name: 'Madeira'
  },
  {
    _id: 'Atlantic/Reykjavik',
    name: 'Reykjavik'
  },
  {
    _id: 'Atlantic/South_Georgia',
    name: 'South Georgia'
  },
  {
    _id: 'Atlantic/Stanley',
    name: 'Stanley'
  },
  {
    _id: 'Atlantic/St_Helena',
    name: 'St Helena'
  },
  {
    _id: 'Pacific/Apia',
    name: 'Apia'
  },
  {
    _id: 'Pacific/Auckland',
    name: 'Auckland'
  },
  {
    _id: 'Pacific/Chatham',
    name: 'Chatham'
  },
  {
    _id: 'Pacific/Easter',
    name: 'Easter'
  },
  {
    _id: 'Pacific/Efate',
    name: 'Efate'
  },
  {
    _id: 'Pacific/Enderbury',
    name: 'Enderbury'
  },
  {
    _id: 'Pacific/Fakaofo',
    name: 'Fakaofo'
  },
  {
    _id: 'Pacific/Fiji',
    name: 'Fiji'
  },
  {
    _id: 'Pacific/Funafuti',
    name: 'Funafuti'
  },
  {
    _id: 'Pacific/Galapagos',
    name: 'Galapagos'
  },
  {
    _id: 'Pacific/Gambier',
    name: 'Gambier'
  },
  {
    _id: 'Pacific/Guadalcanal',
    name: 'Guadalcanal'
  },
  {
    _id: 'Pacific/Guam',
    name: 'Guam'
  },
  {
    _id: 'Pacific/Honolulu',
    name: 'Honolulu'
  },
  {
    _id: 'Pacific/Johnston',
    name: 'Johnston'
  },
  {
    _id: 'Pacific/Kiritimati',
    name: 'Kiritimati'
  },
  {
    _id: 'Pacific/Kosrae',
    name: 'Kosrae'
  },
  {
    _id: 'Pacific/Kwajalein',
    name: 'Kwajalein'
  },
  {
    _id: 'Pacific/Majuro',
    name: 'Majuro'
  },
  {
    _id: 'Pacific/Marquesas',
    name: 'Marquesas'
  },
  {
    _id: 'Pacific/Midway',
    name: 'Midway'
  },
  {
    _id: 'Pacific/Nauru',
    name: 'Nauru'
  },
  {
    _id: 'Pacific/Niue',
    name: 'Niue'
  },
  {
    _id: 'Pacific/Norfolk',
    name: 'Norfolk'
  },
  {
    _id: 'Pacific/Noumea',
    name: 'Noumea'
  },
  {
    _id: 'Pacific/Pago_Pago',
    name: 'Pago Pago'
  },
  {
    _id: 'Pacific/Palau',
    name: 'Palau'
  },
  {
    _id: 'Pacific/Pitcairn',
    name: 'Pitcairn'
  },
  {
    _id: 'Pacific/Ponape',
    name: 'Ponape'
  },
  {
    _id: 'Pacific/Port_Moresby',
    name: 'Port Moresby'
  },
  {
    _id: 'Pacific/Rarotonga',
    name: 'Rarotonga'
  },
  {
    _id: 'Pacific/Saipan',
    name: 'Saipan'
  },
  {
    _id: 'Pacific/Samoa',
    name: 'Samoa'
  },
  {
    _id: 'Pacific/Tahiti',
    name: 'Tahiti'
  },
  {
    _id: 'Pacific/Tarawa',
    name: 'Tarawa'
  },
  {
    _id: 'Pacific/Tongatapu',
    name: 'Tongatapu'
  },
  {
    _id: 'Pacific/Truk',
    name: 'Truk'
  },
  {
    _id: 'Pacific/Wake',
    name: 'Wake'
  },
  {
    _id: 'Pacific/Wallis',
    name: 'Wallis'
  },
  {
    _id: 'Pacific/Yap',
    name: 'Yap'
  },
  {
    _id: 'Antarctica/Casey',
    name: 'Casey'
  },
  {
    _id: 'Antarctica/Davis',
    name: 'Davis'
  },
  {
    _id: 'Antarctica/DumontDUrville',
    name: 'DumontDUrville'
  },
  {
    _id: 'Antarctica/Macquarie',
    name: 'Macquarie'
  },
  {
    _id: 'Antarctica/Mawson',
    name: 'Mawson'
  },
  {
    _id: 'Antarctica/McMurdo',
    name: 'McMurdo'
  },
  {
    _id: 'Antarctica/Palmer',
    name: 'Palmer'
  },
  {
    _id: 'Antarctica/Rothera',
    name: 'Rothera'
  },
  {
    _id: 'Antarctica/South_Pole',
    name: 'South Pole'
  },
  {
    _id: 'Antarctica/Syowa',
    name: 'Syowa'
  },
  {
    _id: 'Antarctica/Vostok',
    name: 'Vostok'
  },
  {
    _id: 'Arctic/Longyearbyen',
    name: 'Longyearbyen'
  },
  {
    _id: 'UTC',
    name: 'UTC'
  },
  {
    _id: 'UTC-12',
    name: 'UTC-12'
  },
  {
    _id: 'UTC-11',
    name: 'UTC-11'
  },
  {
    _id: 'UTC-10',
    name: 'UTC-10'
  },
  {
    _id: 'UTC-9',
    name: 'UTC-9'
  },
  {
    _id: 'UTC-8',
    name: 'UTC-8'
  },
  {
    _id: 'UTC-7',
    name: 'UTC-7'
  },
  {
    _id: 'UTC-6',
    name: 'UTC-6'
  },
  {
    _id: 'UTC-5',
    name: 'UTC-5'
  },
  {
    _id: 'UTC-4',
    name: 'UTC-4'
  },
  {
    _id: 'UTC-3',
    name: 'UTC-3'
  },
  {
    _id: 'UTC-2',
    name: 'UTC-2'
  },
  {
    _id: 'UTC-1',
    name: 'UTC-1'
  },
  {
    _id: 'UTC+0',
    name: 'UTC+0'
  },
  {
    _id: 'UTC+1',
    name: 'UTC+1'
  },
  {
    _id: 'UTC+2',
    name: 'UTC+2'
  },
  {
    _id: 'UTC+3',
    name: 'UTC+3'
  },
  {
    _id: 'UTC+4',
    name: 'UTC+4'
  },
  {
    _id: 'UTC+5',
    name: 'UTC+5'
  },
  {
    _id: 'UTC+6',
    name: 'UTC+6'
  },
  {
    _id: 'UTC+7',
    name: 'UTC+7'
  },
  {
    _id: 'UTC+8',
    name: 'UTC+8'
  },
  {
    _id: 'UTC+9',
    name: 'UTC+9'
  },
  {
    _id: 'UTC+10',
    name: 'UTC+10'
  },
  {
    _id: 'UTC+11',
    name: 'UTC+11'
  },
  {
    _id: 'UTC+12',
    name: 'UTC+12'
  },
  {
    _id: 'UTC+13',
    name: 'UTC+13'
  },
  {
    _id: 'UTC+14',
    name: 'UTC+14'
  }
];
const TimeZoneDropdown = ({ setFilter, value }) => {
  return (
    <SelectInput
      placeholder={'Timezone'}
      options={timezones}
      id="timezone.select"
      value={value}
      onChange={(value) => {
        setFilter(value);
      }}
    />
  );
};

export default TimeZoneDropdown;
