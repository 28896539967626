import React from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import AppButton from '../../../../components/common/button/Button';
import LinkText from '../../../../components/common/link-text/LinkText';

export const SelectedBusiness = ({ selectedPlace, setSelectedPlace, navigate }) => {
  return (
    <div className="searched-place">
      <Card className="main-card-color">
        <Card className="p-2 company-info-card">
          <Row>
            <Col md={3}>
              <div className="image-wrapper">
                <Image
                  style={{ objectFit: 'contain', maxHeight: '100%' }}
                  className="searched-place-image"
                  src={selectedPlace?.photos?.[0]?.getUrl() || selectedPlace?.icon}
                  height="auto"
                  width="auto"
                />
              </div>
            </Col>
            <Col md={9} className="d-flex flex-column justify-content-center">
              <div className="content-area">
                <h4 className="fonts-quicksand-bold">{selectedPlace?.name}</h4>
                <span>{selectedPlace?.formatted_address}</span>
              </div>
            </Col>
          </Row>
        </Card>
        <LinkText
          className={'text-center mt-3'}
          color={'#667085'}
          title={'Not me, search again'}
          onClick={() => setSelectedPlace(false)}
        />
      </Card>
      <div className="mt-4">
        <AppButton
          title={'Continue sign up'}
          onClick={() => {
            navigate();
          }}
          id="selectedBusiness.continue"
        />
      </div>
    </div>
  );
};
