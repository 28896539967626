import React from 'react';

const LoaderComponent = () => {
  return (
    <div className="page-loading show">
      <span className="sm-loader"></span>
    </div>
  );
};

export default LoaderComponent;
