import { gql } from '@apollo/client';

// {
//     "input": {
//       "file": null,
//       "cat_id": null
//     }
//   }

export const UPDATE_CATEGORY_IMAGE = gql`
  mutation UploadCatImage($input: uploadCatImage!) {
    uploadCatImage(input: $input) {
      status
      message
      data {
        _id
        primaryCat
        secondaryCat
        tertiaryCat
        discount
        catImage
      }
      errorCode
    }
  }
`;
