import { gql } from '@apollo/client';

export const MERCHANT_FORGOT_PASSWORD = gql`
  mutation ForgotPasswordMerchant($input: forgotPassword) {
    forgotPasswordMerchant(input: $input) {
      status
      message
      errorCode
    }
  }
`;
