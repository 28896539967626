import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
  query Query {
    category {
      data {
        catImage
        name
        _id
      }
      status
      message
      errorCode
    }
  }
`;
