import { gql } from '@apollo/client';

export const EDIT_USER_CENTZ = gql`
  mutation EditCentzBankBalance($input: editCentzBankBalanceInput!) {
    editCentzBankBalance(input: $input) {
      status
      message
      errorCode
    }
  }
`;
