import React from 'react';
import { Image } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import ContentCardWrapper from '../../../components/merchant/onboarding/content-card-wrapper/ContentCardWrapper';
import SignInSidebarInfo from '../../../components/merchant/onboarding/step-indicator/SignInSidebarInfo';
import Images from '../../../constants/images';
import CommonFooter from '../../../pages/onboarding/footer/CommonFooter';
const EmailVerification = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  return (
    <div className="merchant-onboarding-container main-wrapper-container">
      <div className="main-content-area">
        <div className="left-side-container">
          <SignInSidebarInfo />
          <div className="p-0">
            <CommonFooter />
          </div>
        </div>
        <div className="right-side-container merchant-onboarding-content">
          <div className="main-box signin email-verification">
            <div className="content-box-wrapper scrollbar">
              <ContentCardWrapper
                title={'Check your email'}
                subTitle={'We sent a password reset link to'}>
                <span className="text-center email">{location?.state?.email}</span>
                <div className="mt-4 text-center fonts-medium">
                  Didn’t receive the email? &nbsp;
                  <Link
                    to={`/forgot-password?return=${query.get('return') || '/login'}`}
                    className="link-color">
                    Click to resend
                  </Link>
                </div>
                <div className="text-center fonts-medium back-link">
                  <Link to={`${query.get('return') || '/login'} `}>
                    <Image src={Images.right_arrow} alt=""></Image> Back to login
                  </Link>
                </div>
              </ContentCardWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
