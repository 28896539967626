import { gql } from '@apollo/client';

export const MERCHANT_SIGNUP = gql`
  mutation SignUpByMerchant($signUpByMerchantInput2: SignUpMerchant!) {
    signUpByMerchant(input: $signUpByMerchantInput2) {
      email
      jwttoken
      status
      message
      errorCode
    }
  }
`;
