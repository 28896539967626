import { gql } from '@apollo/client';

export const EDIT_USER = gql`
  mutation EditUser($input: editUser!) {
    editUser(input: $input) {
      status
      message
      email
      user {
        _id
      }
      errorCode
    }
  }
`;
