import React from 'react';
import { Card, Image, Row } from 'react-bootstrap';
import Images from '../../../../constants/images';
import './styles.css';

const ContentCardWrapper = ({ children, title, subTitle }) => {
  return (
    <Card className="onboarding-content-card bg-white scrollbar-style">
      <div className="bg-white">
        <Row className="d-flex justify-content-center">
          <Image src={Images.logo} alt="centavizer-logo" className="logo text-center" />
        </Row>
      </div>
      <Row className="mt-3">
        <h3 className="fonts-quicksand-bold">{title}</h3>
        <p>{subTitle}</p>
      </Row>
      {children}
    </Card>
  );
};

export default ContentCardWrapper;
