import { gql } from '@apollo/client';

export const DELETE_BUSINESS_BANNER = gql`
  mutation DeleteBannerImageAndVideo($input: deleteBannerImageAndVideo) {
    deleteBannerImageAndVideo(input: $input) {
      status
      message
      errorCode
    }
  }
`;
