import { useContext, useEffect } from 'react';
import { UserContext } from '../../contexts';

const useGetUser = (checkType = 'onboarding', navigate) => {
  const { details: data, loading } = useContext(UserContext);

  useEffect(() => {
    if (checkType === 'onboarding') {
      if (!loading && data?.me?.merchant) {
        if (data?.me?.merchant?.termsAndConditationStatus) {
          navigate?.('/merchant/dashboard', { replace: true });
          return;
        }
        if (data?.me?.merchant?.business_data?.businessName) {
          navigate?.('/onboarding/terms-and-condition', { replace: true });
          return;
        }
        if (data?.me?.merchant?.email) {
          navigate?.('/onboarding/business-search', { replace: true });
          return;
        }
      }
    }
  }, [loading, data?.me?.merchant, checkType, navigate]);

  return {
    user: data?.me?.merchant,
    fetchingUser: loading
  };
};

export default useGetUser;
