import React from 'react';
import { Alert, Badge, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Images from '../../../constants/images';

const AppAlert = ({
  show,
  variant,
  message,
  rightIcon = false,
  to = '',
  rightIconText,
  className
}) => {
  return (
    <Alert show={show} variant={variant} className={`app-alerts ${className}`}>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <Badge bg={variant} text="light" className="fonts-regular">
            {variant}
          </Badge>{' '}
          <span className="message fonts-regular">{message}</span>
        </div>
        {rightIcon ? (
          <div className="right-side">
            <Link to={to} className="fonts-medium">
              {rightIconText}
              <Image src={Images.right_arrow} />
            </Link>
          </div>
        ) : null}
      </div>
    </Alert>
  );
};

export default AppAlert;
