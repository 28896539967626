import { gql } from '@apollo/client';

export const CHANGE_ADMIN_PASSWORD = gql`
  mutation ChangePasswordBySuperAdmin($input: changePassword!) {
    changePasswordBySuperAdmin(input: $input) {
      status
      message
      errorCode
    }
  }
`;
