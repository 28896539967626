import { gql } from '@apollo/client';

export const MERCHANT_ADD_DETAILS = gql`
  mutation AddMerchantDetail($input: addMerchantDetail!) {
    addMerchantDetail(input: $input) {
      status
      message
      merchantDetail {
        _id
      }
      errorCode
    }
  }
`;
