import { gql } from '@apollo/client';

export const UPDATE_BUSINESS_STATUS_BY_ADMIN = gql`
  query UpdateBusinessStatusByAdmin($updateBusinessStatusByAdminId: ID) {
    updateBusinessStatusByAdmin(id: $updateBusinessStatusByAdminId) {
      status
      message
      errorCode
    }
  }
`;
