import { gql } from '@apollo/client';

export const PUBLISH_MARKETPLACE = gql`
  query ToPublishMarketplace {
    toPublishMarketplace {
      status
      message
      errorCode
    }
  }
`;
